import { CircularProgress, Dialog, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import useMobileDetect from "../useMobileDetect";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ZoomInIcon, ZoomOutIcon, RefreshIcon } from "@heroicons/react/outline";

export default function CustomPdfViewer({ myFile, pdfData, scale }) {
  const { isMobile } = useMobileDetect();
  const maxScale = 1.8;
  const minScale = isMobile() ? 0.4 : 1.4;
  const [numPages, setNumPages] = useState(null);
  const [scaleValue, setScaleValue] = useState(1);

  useEffect(() => {
    if (scale) {
      setScaleValue(scale);
    } else {
      setScaleValue(isMobile() ? 0.5 : 1.4);
    }
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const zoomIn = () => {
    if (scaleValue < maxScale) setScaleValue(scaleValue + 0.1);
  };

  const zoomOut = () => {
    if (scaleValue > minScale) setScaleValue(scaleValue - 0.1);
  };

  const resetTransform = () => {
    if (scale) {
      setScaleValue(scale);
    } else {
      setScaleValue(isMobile() ? 0.4 : 1.4);
    }
  };

  return (
    <>
      <div className="w-full flex justify-center bg-gray-500 text-white p-1 gap-2  top-14 z-10 sticky">
        <div></div>
        <ZoomInIcon
          onClick={() => zoomIn()}
          className="h-5 w-5 cursor-pointer"
        />
        <ZoomOutIcon
          onClick={() => zoomOut()}
          className="h-5 w-5 cursor-pointer"
        />
        <RefreshIcon
          onClick={() => resetTransform()}
          className="h-5 w-5 cursor-pointer"
        />
      </div>
      <div className="overflow-scroll">
        <Document
          file={pdfData ? { data: pdfData } : myFile}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div className="h-32 w-32 flex justify-center items-center">
              <CircularProgress />
            </div>
          }
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              scale={scaleValue}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            >
              <span className="flex justify-center">
                Page : {index + 1} / {numPages}
              </span>
            </Page>
          ))}
        </Document>
      </div>
    </>
  );
}
