import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { primaryColor } from "./constant";

export default function CustomAccordian({
  header,
  children,
  defaultExpanded = false,
  expanded,
  onChange,
  panel,
  disable = false,
  icon,
}) {
  return (
    <div>
      <Accordion
        defaultExpanded={defaultExpanded}
        expanded={expanded.panel === panel}
        onChange={onChange}
        style={{ marginBottom: "20px" }}
        disabled={disable}
      >
        <AccordionSummary
          expandIcon={icon ? icon : <ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div
            style={{
              fontSize: "18px",
              color: primaryColor,
              fontWeight: "500",
            }}
          >
            {header}
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ width: "100%" }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
