import React from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";

export function InputSelect({
  loading = false,
  label = "",
  name = "",
  labelLink = null,
  attrs = {},
  options = [],
  requiredMark = "",

  defaultValue = "",
  width = 36,
  error = "",
  className = "",
  showSelectOption = true,
}) {
  let loading_disabled = loading ? { disabled: true } : {};

  return (
    <div className="mb-5">
      <InputLabel
        label={label}
        labelLink={labelLink}
        requiredMark={requiredMark}
      />
      <div className="mt-2 flex space-x-4">
        <select
          className={[
            `w-full focus:ring-0 focus:border-primary focus:bg-primary-50 block  w-${width} px-4 text-base border-gray-300 rounded-xl  disabled:bg-gray-100`,
            className,
          ].join(" ")}
          {...attrs}
          {...loading_disabled}
          name={name}
          required={!attrs.value}
        >
          {showSelectOption && (
            <option value="" disabled selected hidden>
              Select
            </option>
          )}
          {options.map((option, index) => {
            return (
              <option
                selected={
                  defaultValue && defaultValue == option.value ? true : false
                }
                key={index}
                value={option.value}
              >
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-500">{error?.join(" ")}</p>
      ) : (
        false
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
  return <InputSelect {...props} />;
});
