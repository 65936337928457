import React from 'react'
import { AiqaIcon } from './AppIcons'

export default function Logo({ width = 48 }) {
    return (
        <div className={`w-${width}`}>
            <AiqaIcon className="text-primary w-full" />
        </div>
    )
}
