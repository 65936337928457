import React from 'react'

export default function AppSpinner({ size = 100, color = "primary", radius = 35, speed = "fast" }) {

  let dur = speed === "fast" ? "0.50s" : (speed === "superfast" ? "0.25s" : "1s");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto" }}
      width={size}
      height={size}
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      className={`text-${color}`}
    >
      <circle
        cx="50"
        cy="50"
        r="35"
        fill="none"
        stroke="currentColor"
        strokeDasharray="164.93361431346415 56.97787143782138"
        strokeWidth="3"
      >
        <animateTransform
          attributeName="transform"
          dur={dur}
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
    </svg>
  )
}
