import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { ApiGet, getApiClaim } from "../../lib/AppHelper";
import moment from "moment";
import { AppStore } from "../../lib/AppStore";

const keyObj = {
  memberId: "Member Id",
  name: "Name",
  mobileNumber: "Mobile number",
  gender: "Gender",
  dob: "D.O.B",
  policyStartDate: "Policy start date",
  policyEndDate: "Policy end date",
};

const UserInformation = ({ setValue, state, claimId, subClaimId, isMobile }) => {
  const [userInfo, setUserInfo] = useState([]);

  const fetchUserInfo = async (id) => {
    const response = await getApiClaim(`customerDetails/${id}`);
    if (response.code === 200) {
      let policyEndDate = moment(response?.data[0]?.policyEndDate).format(
        "DD-MM-YYYY"
      );
      let policyStartDate = moment(response?.data[0]?.policyStartDate).format(
        "DD-MM-YYYY"
      );
      setUserInfo(response.data);
      setValue({
        policyEndDate: policyEndDate,
        policyStartDate: policyStartDate,
      });
    } else {
      console.log("failed to fetch user");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const fetchProductSpecification = async (claimId, subClaimId) => {
    const response = await ApiGet(
      `claim?claim_id=${claimId}&sub_claim_id=${subClaimId}`
    );
    const { sub_policy_detail } = response.data;
    setValue({
      productSpecification:
        sub_policy_detail?.product_specification?.product_specification,
    });
  };

  function capitalizeFirstLetter(inputString) {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }
  useEffect(() => {
    if (state?.memberId) {
      if (claimId && subClaimId) fetchProductSpecification(claimId, subClaimId);
      AppStore.dispatch({ type: "LOADING", loading: true });
      fetchUserInfo(state.memberId);
    }
  }, []);

  return userInfo.length > 0 ? (
    <div style={{ padding: isMobile ? "0px 0px" : "0px 30px" }}>
      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse" id="data-table">
          <thead className="border-b bg-red-500 text-white">
            {Object.keys(userInfo[0]).map((key, index) => {
              return (
                <td
                  key={index}
                  className="text-sm font-large px-6 py-4 whitespace-nowrap text-center"
                >
                  {keyObj[key]}
                </td>
              );
            })}
          </thead>
          <tbody>
            {Object.keys(userInfo[0]).map((key, index) => {
              return (
                <td
                  className="border-2 font-medium py-4 text-center"
                  style={{ textTransform: "capitalize", opacity: 0.7 }}
                >
                  {key === "policyStartDate" || key === "policyEndDate"
                    ? moment(userInfo[0][key]?.split(" ")[0]).format(
                        "DD-MM-YYYY"
                      )
                    : key === "dob"
                    ? moment(userInfo[0][key]).format("DD-MM-YYYY")
                    : capitalizeFirstLetter(userInfo[0][key])}
                </td>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div
      style={{ height: "200px", display: "grid", placeItems: "center" }}
    ></div>
  );
};

export default UserInformation;
