import React, { useCallback, useState, useEffect } from "react";
import InputLabel from "../../components/form/InputLabel";
import { SelectPicker } from "rsuite";

export const DropDown = ({
  attrs: { value, disabled = false },
  label,
  requiredMark,
  error,
  formatter,
  request,
  options = [],
  valueField,
  labelField,
  setValue,
  field,
  endpoint,
}) => {
  const [dropDownData, setDropdownData] = useState([]);

  const defaultFormatter = useCallback(
    (option) => {
      if (!option) {
        return {};
      }
      if (formatter) {
        return formatter(option);
      }
      return {
        value: String(option[valueField]),
        label: String(option[labelField]),
        ...option,
      };
    },
    [valueField, labelField]
  );

  useEffect(() => {
    if (request) {
      (async () => {
        try {
          const res = await request(endpoint);
          const updated = res.data.map((item) => defaultFormatter(item));
          setDropdownData(updated);
        } catch (err) {
          setDropdownData([]);
        }
      })();
    } else if (options) {
      const updated = options.map((item) => defaultFormatter(item));
      setDropdownData(updated);
    }
  }, [request, options]);

  return (
    <div className="mb-5 mt-1">
      <InputLabel label={label} requiredMark={requiredMark} />
      <div className="mt-2 relative">
        <SelectPicker
          placeholder="Please Select"
          className="genderClass padding0"
          value={`${value}`}
          disabled={disabled}
          style={{ width: "100%" }}
          onSelect={(value, item) => {
            setValue({ [field]: value }, item);
          }}
          onClean={() => setValue({ [field]: null })}
          data={dropDownData}
        />
        {error ? <p className="mt-2 text-xs text-red-500">{error}</p> : false}
      </div>
    </div>
  );
};
