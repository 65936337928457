import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";
import PdfViewer from "../../utils/view_downloadPrescription/PDFViewer";
import {
  EyeIcon,
  XCircleIcon,
  ZoomInIcon,
  ZoomOutIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import defImg from "../../images/default-img.png";
import { CloseOutlined } from "@mui/icons-material";
import { Dialog, Tooltip } from "@mui/material";
import { PRIMARY_COLOR } from "../../utils/constants";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import useMobileDetect from "../../utils/useMobileDetect";
import CustomPdfViewer from "../../utils/view_downloadPrescription/CustomPdfViewer";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";

export const Controls = ({ zoomIn, zoomOut, resetTransform }) => {
  return (
    <div className="flex justify-center bg-gray-500 text-white p-1 gap-2">
      <ZoomInIcon onClick={() => zoomIn()} className="h-5 w-5 cursor-pointer" />

      <ZoomOutIcon
        onClick={() => zoomOut()}
        className="h-5 w-5 cursor-pointer"
      />

      <RefreshIcon
        onClick={() => resetTransform()}
        className="h-5 w-5 cursor-pointer"
      />
    </div>
  );
};

export function InputFile({
  label = "",
  placeholder = false,
  file,
  attrs = {},
  error = "",
  className = "",
  starMark = false,
  inputRef = React.createRef(),
  url = "",
  readOnly = false,
  onDocDelete = () => {},
  paddingX = "4",
  paddingY = "4",
  isZoom = false,
  customWidth = "",
  customHeight = "",
}) {
  const [imgSrc, setImgSrc] = useState("");
  const [pdfSrc, setPdfSrc] = useState("");
  const [open, setOpen] = useState(null);
  const getLastExtensionFromUrl = (url) => {
    const regex = /\.([^.\/]+)$/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  useEffect(() => {
    if (url) {
      const ext = getLastExtensionFromUrl(url);
      if (ext === "pdf") {
        setPdfSrc(url);
        setImgSrc("");
      } else if (ext === "jpg" || ext === "jpeg" || ext === "png") {
        setImgSrc(url);
        setPdfSrc("");
      } else {
        setImgSrc(defImg);
        setPdfSrc("");
      }
    } else {
      if (
        file &&
        (file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg")
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setPdfSrc("");
          setImgSrc(event.target.result);
        };
        reader.readAsDataURL(file);
      } else if (file && file.type === "application/pdf") {
        setImgSrc("");
        setPdfSrc(file);
      } else {
        setImgSrc("");
        setPdfSrc("");
        if (inputRef?.current) inputRef.current.value = "";
      }
    }
  }, [file, url]);

  const onDeleteDoc = (e) => {
    e.stopPropagation();
    onDocDelete();
    if (inputRef?.current) inputRef.current.value = "";
  };

  const setShowPreview = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const onDocHide = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const { isMobile } = useMobileDetect();
  return (
    <>
      <div className={`mb-${attrs?.style?.marginBottom || 5} mr-4`}>
        <InputLabel label={label} starMark={starMark} />
        <div className="mt-2 relative">
          <div
            onClick={() => inputRef?.current?.click()}
            tabIndex={0}
            className={[
              `focus:outline-none focus:ring-0 border border-dashed focus:border-primary focus:bg-primary-50 block w-full shadow-sm px-${paddingX} py-${paddingY} text-base border-gray-300 rounded-xl inline-flex flex-col items-center disabled:bg-gray-100`,
              attrs?.disable || readOnly
                ? "border-gray-300 bg-primary-50"
                : "border-primary-500 cursor-pointer",
              className,
            ].join(" ")}
            style={{ height: attrs?.style?.height }}
          >
            {(imgSrc || pdfSrc) && (
              <div
                className="w-full text-red-500 font-bold absolute z-10"
                style={{ top: 2, right: 2 }}
              >
                {!readOnly && (
                  <XCircleIcon
                    onClick={onDeleteDoc}
                    className="h-5 w-5 float-right cursor-pointer "
                  />
                )}
                {!isZoom && (
                  <EyeIcon
                    onClick={(e) => setShowPreview(e)}
                    className="h-5 w-5 float-right cursor-pointer mr-2"
                  />
                )}
                {isZoom && (
                  <Tooltip title="Zoom" placeholder="right" arrow>
                    <ZoomOutMapOutlinedIcon
                      onClick={(e) => setShowPreview(e)}
                      className="h-5 w-5 float-right cursor-pointer mr-2 mt-1"
                    />
                  </Tooltip>
                )}
              </div>
            )}
            <div>
              <div> {placeholder !== false ? placeholder : false}</div>
              <div>
                <div>
                  {imgSrc ? (
                    <img
                      src={imgSrc}
                      alt="preview"
                      style={{
                        width: customWidth ? customWidth : "320px",
                        height: customHeight ? customHeight : "200px",
                      }}
                    />
                  ) : pdfSrc ? (
                    <PdfViewer
                      scale={0.5}
                      myFile={pdfSrc}
                      height={customHeight ? customHeight : "200px"}
                      width={customWidth ? customWidth : "270px"}
                    />
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>

          <input
            type="file"
            disabled={attrs?.disable}
            className="hidden"
            ref={inputRef}
            {...attrs}
          />
        </div>
        {error ? (
          <p className="mt-2 text-xs text-red-500 text-center">{error}</p>
        ) : (
          false
        )}
      </div>

      <Dialog
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={onDocHide}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: "111",
            backgroundColor: PRIMARY_COLOR,
          }}
        >
          <p>
            <span className="text-white m-4 float-left">Preview</span>
            <CloseOutlined
              className="text-white m-4 cursor-pointer float-right"
              onClick={onDocHide}
            />
          </p>
        </div>
        <div>
          {imgSrc ? (
            <TransformWrapper centerOnInit={true}>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <Controls
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    resetTransform={resetTransform}
                  />
                  <TransformComponent>
                    <img
                      src={imgSrc}
                      className="relative"
                      alt="preview"
                      style={{ minHeight: "200px", minWidth: "150px" }}
                    />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          ) : pdfSrc ? (
            // <PdfViewer scale={isMobile() ? 0.7 : ""} myFile={pdfSrc} />
            <CustomPdfViewer scale={isMobile() ? 0.7 : ""} myFile={pdfSrc} />
          ) : (
            false
          )}
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
  return <InputFile {...props} />;
});
