import CustomToggle from "../../components/form/CustomToggle";
import { InputTextArea } from "../../components/form/InputTextArea";
import { DateInput } from "./DateInput";
import { DropDown } from "./Dropdown";
import { FileInput } from "./FileInput";
import InfintyDropdown from "./InfintyDropdown";
import NumberInput from "./NumberInput";
import TextInput from "./TextInput";

export const inputTypes = {
  textarea: InputTextArea,
  text: TextInput,
  number: NumberInput,
  date: DateInput,
  file: FileInput,
  dropdown: DropDown,
  toggle: CustomToggle,
  infintydropdown: InfintyDropdown,
};
