import { FemaleIcon, MaleIcon } from "../../components/AppIcons";
import { FaUser } from "react-icons/fa";
import HeightIcon from "@mui/icons-material/Height";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
export const SEARCH_PRODUCT_KEY = "Health Camp";

export const DRAFT = "DRAFT";
export const FINAL = "FINAL";
export const REPORT = "report";
export const SAVED = "SAVED";

export const NEW_MEMBER = "new_member";
export const DRAFT_MEMBER = "draft_member";
export const SAVED_MEMBER = "saved_member";
export const FINAL_REPORT = "final_report";
export const POLICY_PUNCHED_BY = "POLICY_PUNCHED_BY";
export const HEALTH_CAMP_PUNCHED_BY = "HEALTH_CAMP_PUNCHED_BY";

export const dropDownOptions = [
  { label: "New Member", value: NEW_MEMBER },
  { label: "Draft Member", value: DRAFT_MEMBER },
  { label: "Saved Member", value: SAVED_MEMBER },
  { label: "Report", value: FINAL_REPORT },
];

export const ViewedByOptions = [
  { label: "Subscription punched ", value: POLICY_PUNCHED_BY },
  { label: "Health Camp punched ", value: HEALTH_CAMP_PUNCHED_BY },
];

export const API_URL = {
  new_member: FINAL,
  draft_member: DRAFT,
  saved_member: SAVED,
  final_report: REPORT,
};
export const Page_SIZE = {
  new_member: 100,
  draft_member: 15,
  saved_member: 15,
  final_report: 15,
};

export const headingLabel = {
  new_member: "Covered Member",
  draft_member: "Draft Member",
  saved_member: "Saved member",
  final_report: "Reports",
};

export const showLabels = (arr, label) => {
  if (arr?.length === 0) return;
  const matchedItem = arr?.find(
    (item) => item.value.toLowerCase() === label.toLowerCase()
  );
  return matchedItem ? matchedItem.name : "";
};
export const GenderData = [
  {
    label: "Male",
    value: "m",
  },
  { label: "Female", value: "f" },
  { label: "Other", value: "o" },
];

export const findProduct = (search, products) => {
  if (products?.length === 0) return;
  return products?.find((item) => item?.product_id == "1163");
};

export const memberIcon = (gender) => {
  const g = gender?.toUpperCase();
  if (g === "MALE") return <MaleIcon size={30} className="absolute" />;

  if (g === "FEMALE")
    return <FemaleIcon className="absolute" size={30}></FemaleIcon>;

  if (g === "OTHER" || g === "OTHERS")
    return <FaUser size={30} className="absolute" />;
};
export const ImageType = ["image/jpeg", "image/jpg", "image/png"];

export const getSortIcon = (sort, label) => {
  if (sort.label === label) {
    return sort.sortBy === "ASC" ? (
      <ArrowUpwardIcon
        style={{
          color: "white",
          fontSize: "25px",
          cursor: "pointer",
        }}
      />
    ) : (
      <ArrowDownwardIcon
        style={{
          color: "white",
          fontSize: "25px",
          cursor: "pointer",
        }}
      />
    );
  }
  return (
    <HeightIcon
      style={{
        color: "white",
        fontSize: "25px",
        cursor: "pointer",
      }}
    />
  );
};
