export const productSpecification = {
  AC: "AC",
  AAC: "AAC",
  DOC: "DOC",
  HCH: "HCH",
  INDIVIDUAL: "individual",
  SKIN: "SKIN",
  OTHERS: "OTHERS",
  CARE: "CARE",
  LAC: "LAC",
  OP: "OP",
  CLOAN: "CLOAN",
  URBAN: "URBAN",
  ACH: "ACH",
  ABAC: "ABAC",
  MA: "MA",
  HI: "HI",
  LHC: "LHC",
  ABHC: "ABHC",
  NUTR: "NUTR",
};

export const productClassification = {
  COMBO: "Combo",
  INDIVIDUAL: "Individual",
};

export const reportTypes = [
  "label_sales_report",
  "reseller_sales_report",
  "update_pm_jay_information",
  "appointment_booking",
  "aiqa_center",
  "subscription_source_report",
  "reseller_product_wise_sale_report",
  "product_wise_sales_report",
  "upload_date_team_report",
  "upload_date_report",
  "data_report_view",
  "customer_lead_capture",
  "subscription_report_view",
  "claim_report_view",
  "business_report_view",
  "commission_due_report_view",
  "commission_report_view",
  "admin_dashboard",
  "policy_expire_report",
  "team_performance_report_view",
  "sales_report",
  "reseller_lead_capture",
  "hierarchy_sales_report_view",
  "action_claim_report",
];

export const checkReportPermission = () => {
  const localPermissions = localStorage.getItem("permissions");
  const permissions = JSON.parse(localPermissions);
  let permissionsValue = permissions.value;
  let count = false;
  reportTypes.map((permission) => {
    if (permissionsValue[permission]) {
      count = true;
    }
  });
  return count;
};

export function trimObjectValues(obj) {
  const trimmedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Check if the value is a string before trimming
      if (typeof value === "string") {
        trimmedObj[key] = value.trim();
      } else if (typeof value === "object" && value != null) {
        // Recursively trim values if the value is an object
        trimmedObj[key] = trimObjectValues(value);
      } else {
        // If the value is not a string or object, keep it unchanged
        trimmedObj[key] = value;
      }
    }
  }
  return trimmedObj;
}

export const PRIMARY_COLOR = "#db2228";
