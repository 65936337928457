import React from "react";

export const InputFile = ({ file, onChangeHandler }) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <svg className="w-8 h-8 mr-2" fill="currentColor" viewBox="0 0 20 20">
          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-1 mr-2 text-base leading-normal">
          Select a file
        </span>

        <input
          type="file"
          value={file}
          onChange={onChangeHandler}
          className="hidden"
          name="file"
        />
      </div>
    </>
  );
};
