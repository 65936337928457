import React from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import InputLabel from "../form/InputLabel";

const animatedComponents = makeAnimated();

export default function MultiSelector({
  requiredMark,
  labelLink,
  label,
  options = [],
  onChange,
}) {
  return (
    <div>
      <InputLabel
        label={label}
        labelLink={labelLink}
        requiredMark={requiredMark}
      />
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={options}
        onChange={onChange}
      />
    </div>
  );
}
