import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Main from "../Main";
import { JavaApiGet, JavaApiPut, setMessage } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import moment from "moment";

class FinalView extends Component {
  componentDidMount() {
    this.getApplication();
  }
  MessageRef = React.createRef();

  render() {
    const data = this.state?.members;
    let primaryMember;
    data &&
      data?.length > 0 &&
      data.map((elem) => {
        if (elem.relation === "SELF") {
          primaryMember = elem;
        }
      });

    const applicationId = this.props.match.params.id;
    return (
      <div>
        <Main MessageRef={this.MessageRef}>
          <div className="px-6 text-primary mb-6">
            <Link to={"/leads"}>
              <div className="flex items-center">
                <ArrowLeftIcon width={17} className="mr-3" />
                <p>{"Back"}</p>
              </div>
            </Link>
          </div>
          <div className="rounded overflow-hidden shadow-lg ">
            <div className="flex mb-4">
              <div className="w-full bg-red-500 h-12 text-white p-1 flex justify-between">
                <span className="pl-2 pt-2"> Primary Member Details</span>
                <button
                  className="bg-white rounded-lg w-10 pl-1 pr-1 shadow-md text-red"
                  onClick={() => {
                    this.props.history.push(`/leads`);
                  }}
                >
                  <strong className="text-2xl align-center cursor-pointer alert-del ">
                    &times;
                  </strong>
                </button>
              </div>
            </div>
            {primaryMember && (
              <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-6 p-3">
                <div className="grid grid-cols-2">
                  <div>Name</div>
                  <div>{primaryMember.name}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Phone Number</div>
                  <div>{primaryMember.phone}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Email</div>
                  <div> {primaryMember.email}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Date of Birth</div>
                  <div>
                    {primaryMember.dob
                      ? moment(primaryMember.dob).format("DD-MM-YYYY")
                      : null}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Gender</div>
                  <div>
                    {primaryMember.gender === "m"
                      ? "Male"
                      : primaryMember.gender === "f"
                      ? "Female"
                      : "Other"}
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Govt ID Type</div>
                  <div>{primaryMember.govtIDType} </div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Govt ID Number</div>
                  <div>{primaryMember.govtIDNumber}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>Pin Code</div>
                  <div>{primaryMember.pinCode}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>State</div>
                  <div>{primaryMember.state}</div>
                </div>
                {primaryMember.height && (
                  <div className="grid grid-cols-2">
                    <div>Height</div>
                    <div>{primaryMember.height}</div>
                  </div>
                )}
                {primaryMember.weight && (
                  <div className="grid grid-cols-2">
                    <div>Weight</div>
                    <div>{primaryMember.weight}</div>
                  </div>
                )}
              </div>
            )}
            {data && data?.length > 1 && (
              <div className="flex mb-4">
                <div className="w-full bg-red-500 h-12 text-white pt-3">
                  <span className="pl-2">Secondary Member Details</span>
                </div>
              </div>
            )}
            {data &&
              data?.length > 0 &&
              data.map((elem) => {
                if (elem.relation !== "SELF")
                  return (
                    <>
                      <div className="flex  mt-4">
                        <div className="w-full  h-12">
                          <span className="pl-2 text-red-500 text-lg font-bold">
                            {elem.relation.toUpperCase()}
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-6 p-3">
                        <div className="grid grid-cols-2">
                          <div>Name</div>
                          <div>{elem.name}</div>
                        </div>

                        <div className="grid grid-cols-2">
                          <div>Date of Birth</div>
                          <div>
                            {elem.dob
                              ? moment(elem.dob).format("DD-MM-YYYY")
                              : null}
                          </div>
                        </div>
                        <div className="grid grid-cols-2">
                          <div>Gender</div>
                          <div>
                            {elem.gender === "m"
                              ? "Male"
                              : elem.gender === "f"
                              ? "Female"
                              : "Other"}
                          </div>
                        </div>
                        <div className="grid grid-cols-2">
                          <div>Govt ID Type</div>
                          <div>{elem.govtIDType} </div>
                        </div>
                        <div className="grid grid-cols-2">
                          <div>Govt ID Number</div>
                          <div>{elem.govtIDNumber}</div>
                        </div>
                        {elem.height && (
                          <div className="grid grid-cols-2">
                            <div>Height</div>
                            <div>{elem.height}</div>
                          </div>
                        )}
                        {elem.weight && (
                          <div className="grid grid-cols-2">
                            <div>Weight</div>
                            <div>{elem.weight}</div>
                          </div>
                        )}
                      </div>

                      <hr />
                    </>
                  );
              })}

            <div className="flex justify-around m-3">
              <button
                className="bg-blue-500 rounded-lg p-2 w-24 text-white"
                onClick={() => {
                  this.props.history.push(
                    `/subscription/preview/${applicationId}`
                  );
                }}
              >
                Edit
              </button>
              <button
                className="bg-green-500 rounded-lg p-2 text-white"
                onClick={() => this.approve(applicationId)}
              >
                Approve
              </button>
            </div>
          </div>
        </Main>
      </div>
    );
  }
  approve = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await JavaApiPut(
      `enrollment-service/application/approvedApplication/${id}`
    );

    if (response.status === "OK") {
      setMessage("member approved successfully");
      this.props.history.push("/leads");
    } else {
      this.MessageRef.current.show(response.message, "error");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
  getApplication = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const applicationId = this.props.match.params.id;
    const response = await JavaApiGet(
      `enrollment-service/application/getApplicationPreViewDetails/${applicationId}`
    );
    if (response.code === 200) {
      let item = response.responseObject;
      this.setState(item);
      if (item.stage === "FINAL") {
        this.props.history.push(`/subscription/${applicationId}`);
      }
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

const FinalViewConnect = connect(mapStateToProps)((props) => {
  return <FinalView {...props} />;
});

export default withRouter(FinalViewConnect);
