import { Table } from "rsuite";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { Button, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  INSURANCE_DATA_SENT_TO_VENDOR,
  PAYMENT_STATUS_AIQA_TO_VENDOR,
  PAYMENT_STATUS_PATNER_TO_AIQA,
  POLICY_ENFORCEMENT_VENDOR_STATUS,
  VENDOR_POLICY_COUNT,
  VENDOR_POLICY_UPLOAD,
} from "./constant";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

const shouldShowButton = (status) => {
  return !status || status?.split("_")?.includes("PARTIALLY");
};

const { Column, HeaderCell, Cell } = Table;

const headerStyle = {
  background: "#DB2228",
  color: "white",
  fontSize: "14px",
  fontWeight: "600",
};

const buttonColor = (condition = false, bgColor) => {
  return condition ? "rgba(0, 0, 0, 0.12)" : bgColor;
};
const buttonTextColor = (condition = false, textColor) => {
  return condition ? "rgba(0,0,0,0.26)" : textColor;
};

const RsuiteTable = ({
  data,
  onChangeBatchId,
  sort,
  handleSort,
  onClickTenure,
  setCommonModalState,
  isShowButton,
  setTabModal,
}) => {
  return (
    <Table height={400} headerHeight={60} data={data || []}>
      <Column width={60} align="center" fixed>
        <HeaderCell style={headerStyle}>Sr.No</HeaderCell>
        <Cell style={{ padding: "6px" }}>
          {(_, index) => {
            return index + 1;
          }}
        </Cell>
      </Column>

      <Column width={150} fixed align="left">
        <HeaderCell style={headerStyle}>Reseller name</HeaderCell>
        <Cell style={{ padding: "6px" }}>
          {(rowData, index) => {
            return (
              <span
                onClick={() => setTabModal({ open: true, data: rowData })}
                style={{ color: "blue", cursor: "pointer" }}
              >
                {rowData?.resellerName}
              </span>
            );
          }}
        </Cell>
      </Column>

      <Column width={250} fixed align="left">
        <HeaderCell style={headerStyle}>Batch Id</HeaderCell>
        <Cell style={{ padding: "6px" }}>
          {(rowData, index) => {
            return (
              <Tooltip
                title="Click to check the breakdown"
                placement="left"
                arrow
              >
                <span
                  onClick={() => onChangeBatchId(rowData.batchId)}
                  style={{ cursor: "pointer" }}
                >
                  {rowData?.batchId || "---"}
                </span>
              </Tooltip>
            );
          }}
        </Cell>
      </Column>

      <Column width={100} align="center">
        <HeaderCell style={headerStyle}>
          Success <br />
          result
        </HeaderCell>
        <Cell style={{ padding: "6px" }}>
          {(rowData, index) => {
            return (
              <span className="cursor-pointer">
                {rowData?.totalRecord || 0}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column width={140} align="center">
        <HeaderCell style={headerStyle}>
          Product <br />
          specification
        </HeaderCell>
        <Cell style={{ padding: "6px" }}>
          {(rowData, index) => {
            return (
              <span className="cursor-pointer">
                {rowData?.productSpecification || 0}
              </span>
            );
          }}
        </Cell>
      </Column>

      <Column width={180}>
        <HeaderCell style={headerStyle}>
          {" "}
          Uploaded date{" "}
          <Tooltip
            title={`sort by ${
              sort === "ASC" ? "descending order" : "ascending order"
            }`}
            arrow
            placement="right"
          >
            <span>
              {sort === "DESC" ? (
                <ArrowUpwardIcon
                  fontSize="medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("ASC")}
                />
              ) : sort === null ? (
                <ArrowDownwardIcon
                  fontSize="medium"
                  style={{
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                  onClick={() => handleSort("DESC")}
                />
              ) : (
                <ArrowDownwardIcon
                  fontSize="medium"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSort("DESC")}
                />
              )}
            </span>
          </Tooltip>
        </HeaderCell>
        <Cell style={{ padding: "6px" }}>
          {(rowData, index) => {
            return rowData?.uploadedStartTime;
          }}
        </Cell>
      </Column>
      {isShowButton ? (
        <>
          <Column width={200} align="center">
            <HeaderCell style={headerStyle}>Partner payment status</HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rowData, index) => {
                const showButton = shouldShowButton(
                  rowData.aiqaReceivablePaymentStatus
                );
                return showButton ? (
                  <span
                    style={{
                      cursor:
                        rowData.successRecord === 0 ? "no-drop" : "pointer",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={rowData.successRecord === 0}
                      style={{
                        background: buttonColor(
                          rowData.successRecord === 0,
                          "#DB2228"
                        ),
                        color: buttonTextColor(
                          rowData.successRecord === 0,
                          "white"
                        ),

                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() =>
                        setCommonModalState({
                          open: true,
                          title: "Payment status patner to aiqa",
                          status: PAYMENT_STATUS_PATNER_TO_AIQA,
                          rowData: rowData,
                          view: false,
                        })
                      }
                    >
                      Payment update
                    </Button>
                  </span>
                ) : (
                  <>
                    <Tooltip
                      title="Click to view details"
                      arrow
                      placement="top"
                    >
                      <span
                        onClick={() =>
                          setCommonModalState({
                            open: true,
                            title: "Payment status patner to aiqa",
                            status: PAYMENT_STATUS_PATNER_TO_AIQA,
                            rowData: rowData,
                            view: true,
                          })
                        }
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        <VisibilityIcon />
                        &nbsp;
                        {moment(rowData?.aiqaReceivablePaymentDate).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </Tooltip>
                  </>
                );
              }}
            </Cell>
          </Column>

          <Column width={200} align="center">
            <HeaderCell style={headerStyle}>Vendor insurance status</HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rowData, index) => {
                const showButton = shouldShowButton(
                  rowData.insuranceDataSentStatus
                );
                return showButton ? (
                  <span
                    style={{
                      cursor: !rowData.aiqaReceivablePaymentStatus
                        ? "no-drop"
                        : "pointer",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={!rowData.aiqaReceivablePaymentStatus}
                      style={{
                        background: buttonColor(
                          !rowData.aiqaReceivablePaymentStatus,
                          "skyblue"
                        ),
                        color: buttonTextColor(
                          !rowData.aiqaReceivablePaymentStatus,
                          "black"
                        ),
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() =>
                        setCommonModalState({
                          open: true,
                          title: "Insurance data sent to vendor",
                          status: INSURANCE_DATA_SENT_TO_VENDOR,
                          rowData: rowData,
                          view: false,
                        })
                      }
                    >
                      Data update
                    </Button>
                  </span>
                ) : (
                  <>
                    <Tooltip
                      title="Click to view details"
                      arrow
                      placement="top"
                    >
                      <span
                        onClick={() =>
                          setCommonModalState({
                            open: true,
                            title: "Insurance data sent to vendor",
                            status: INSURANCE_DATA_SENT_TO_VENDOR,
                            rowData: rowData,
                            view: true,
                          })
                        }
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        <VisibilityIcon />
                        &nbsp;
                        {moment(rowData?.insuranceDataSendDate).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </Tooltip>
                  </>
                );
              }}
            </Cell>
          </Column>
          <Column width={200} align="center">
            <HeaderCell style={headerStyle}>
              {" "}
              Vendor <br />
              payment status
            </HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rowData, index) => {
                const showButton = shouldShowButton(
                  rowData?.aiqaPayablePaymentStatus
                );
                return showButton ? (
                  <span
                    style={{
                      cursor: !rowData.insuranceDataSentStatus
                        ? "no-drop"
                        : "pointer",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={!rowData.insuranceDataSentStatus}
                      style={{
                        background: buttonColor(
                          !rowData.insuranceDataSentStatus,
                          "orange"
                        ),
                        color: buttonTextColor(
                          !rowData.insuranceDataSentStatus,
                          "black"
                        ),
                        fontSize: "10px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCommonModalState({
                          open: true,
                          title: "Payment status to vendor",
                          status: PAYMENT_STATUS_AIQA_TO_VENDOR,
                          rowData: rowData,
                          view: false,
                        })
                      }
                    >
                      Status update
                    </Button>
                  </span>
                ) : (
                  <>
                    <Tooltip
                      title="Click to view details"
                      arrow
                      placement="top"
                    >
                      <span
                        onClick={() =>
                          setCommonModalState({
                            open: true,
                            title: "Payment status to vendor",
                            status: PAYMENT_STATUS_AIQA_TO_VENDOR,
                            rowData: rowData,
                            view: true,
                          })
                        }
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        <VisibilityIcon />
                        &nbsp;
                        {moment(rowData?.aiqaPayablePaymentSentDate).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </Tooltip>
                  </>
                );
              }}
            </Cell>
          </Column>

          <Column width={200} align="center">
            <HeaderCell style={headerStyle}>
              {" "}
              Policy number <br />
              received status
            </HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rowData, index) => {
                const showButton = shouldShowButton(
                  rowData?.vendorPolicyNumberReceivedStatus
                );
                return showButton ? (
                  <span
                    style={{
                      cursor: !rowData?.aiqaPayablePaymentStatus
                        ? "no-drop"
                        : "pointer",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={!rowData?.aiqaPayablePaymentStatus}
                      style={{
                        background: buttonColor(
                          !rowData?.aiqaPayablePaymentStatus,
                          "lightpink"
                        ),
                        color: buttonTextColor(
                          !rowData?.aiqaPayablePaymentStatus,
                          "black"
                        ),
                        fontSize: "10px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setCommonModalState({
                          open: true,
                          title: "Policy enforcement vendor status",
                          status: POLICY_ENFORCEMENT_VENDOR_STATUS,
                          rowData: rowData,
                          view: false,
                        })
                      }
                    >
                      Status update
                    </Button>
                  </span>
                ) : (
                  <>
                    <Tooltip
                      title="Click to view details"
                      arrow
                      placement="top"
                    >
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "600",
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setCommonModalState({
                            open: true,
                            title: "Policy enforcement vendor status",
                            status: POLICY_ENFORCEMENT_VENDOR_STATUS,
                            rowData: rowData,
                            view: true,
                          })
                        }
                      >
                        <VisibilityIcon />
                        &nbsp;{" "}
                        {moment(rowData?.enforcedDate).format("DD-MM-YYYY")}
                      </span>
                    </Tooltip>
                  </>
                );
              }}
            </Cell>
          </Column>

          <Column width={200} align="center">
            <HeaderCell style={headerStyle}>
              {" "}
              Vendor policy <br />
              number upload
            </HeaderCell>
            <Cell style={{ padding: "6px" }}>
              {(rowData, index) => {
                const showButton = shouldShowButton(
                  rowData.vendorPolicyNumberUploadedStatus
                );
                return showButton ? (
                  <span
                    style={{
                      cursor: !rowData?.vendorPolicyNumberReceivedStatus
                        ? "no-drop"
                        : "pointer",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={!rowData?.vendorPolicyNumberReceivedStatus}
                      style={{
                        background: buttonColor(
                          !rowData?.vendorPolicyNumberReceivedStatus,
                          "lightgreen"
                        ),
                        color: buttonTextColor(
                          !rowData?.vendorPolicyNumberReceivedStatus,
                          "black"
                        ),

                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                      onClick={() =>
                        setCommonModalState({
                          open: true,
                          title: "Payment status to vendor",
                          status: VENDOR_POLICY_COUNT,
                          rowData: rowData,
                          view: false,
                        })
                      }
                    >
                      Status update
                    </Button>
                  </span>
                ) : (
                  <>
                    <Tooltip
                      title="Click to view details"
                      arrow
                      placement="top"
                    >
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontWeight: "600",
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setCommonModalState({
                            open: true,
                            title: "Vendor policy upload status",
                            status: VENDOR_POLICY_COUNT,
                            rowData: rowData,
                            view: true,
                          })
                        }
                      >
                        <VisibilityIcon />
                        &nbsp;{" "}
                        {moment(rowData?.vendorPolicyNumberUploadedDate).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </Tooltip>
                  </>
                );
              }}
            </Cell>
          </Column>
        </>
      ) : (
        <Column width={480} align="center" fixed="right">
          <HeaderCell style={headerStyle}>Actions</HeaderCell>
          <Cell style={{ padding: "6px" }}>
            {(_, index) => {
              return <p>Kindly allow outside insurance to proceed further</p>;
            }}
          </Cell>
        </Column>
      )}
    </Table>
  );
};
export default RsuiteTable;
