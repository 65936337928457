import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";

const PatientDetails = ({ claim }) => {
  // Helper function to truncate text
  const truncateText = (text, length = 24) => {
    return text && text.length > length
      ? text.substring(0, length) + "..."
      : text;
  };

  return (
    <div
      id="style-1"
      style={{
        width: "350px",
        height: "400px",
        borderRadius: "10px",
        padding: "20px 20px",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <span
        style={{
          fontSize: "14px",
          color: "#DB2228",
          fontWeight: "600",
          display: "block",
          textAlign: "center",
        }}
      >
        Patient details
      </span>
      <hr />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faUserAlt}
          style={{ fontSize: "50px", color: "#Db2228" }}
        />
      </div>
      <div className="pt-4 text-sm">
        {claim?.member?.name && (
          <div className="pb-2 w-100">
            <span className="font-semibold">Name : </span>
            <span className="capitalize" title={claim?.member?.name || "---"}>
              {truncateText(claim?.member?.name?.toLowerCase()) || "---"}
            </span>
          </div>
        )}
        {claim?.member?.mobile && (
          <div className="pb-2 w-100">
            <span className="font-semibold">Mobile : </span>
            <span title={claim?.member?.mobile || "---"}>
              {truncateText(claim?.member?.mobile) || "---"}
            </span>
          </div>
        )}
        {claim?.claim_summary?.[0]?.loan_code && (
          <div className="pb-1">
            <span className="font-semibold">Loan code/Reference number :</span>{" "}
            <span title={claim?.claim_summary?.[0]?.loan_code || "---"}>
              {truncateText(claim?.claim_summary?.[0]?.loan_code) || "---"}
            </span>
          </div>
        )}
        {claim?.member?.dob && (
          <div className="pb-2">
            <span className="font-semibold">Age :</span>{" "}
            {claim?.member?.dob
              ? moment().diff(claim?.member?.dob, "years") + " Year"
              : "---"}
          </div>
        )}
        {claim?.member?.dob && (
          <div className="pb-2">
            <span className="font-semibold">D.O.B :</span>{" "}
            {claim?.member?.dob
              ? moment(claim?.member?.dob).format("DD MMM YYYY")
              : "---"}
          </div>
        )}
        {claim?.member?.gender && (
          <div className="pb-2">
            <span className="font-semibold">Gender :</span>{" "}
            {claim?.member?.gender || "---"}
          </div>
        )}
        {claim?.member?.relation && (
          <div className="pb-2">
            <span className="font-semibold">Relation :</span>{" "}
            {claim?.member?.relation || "---"}
          </div>
        )}

        {claim?.member?.gov_id_number && claim?.member?.gov_id_type && (
          <div className="pb-2">
            <span className="font-semibold capitalize">
              {claim?.member?.gov_id_type}:
            </span>{" "}
            <span title={claim?.member?.gov_id_number || "---"}>
              {truncateText(claim?.member?.gov_id_number) || "---"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientDetails;
