import React from "react";
import { AppHeading } from "../../../components/AppComponents";

const LabelHeading = ({ label }) => {
  return (
    <AppHeading
      className="pt-2 font-bold"
      size="2"
      style={{ color: "#db2228" }}
    >
      {label}
    </AppHeading>
  );
};

export default LabelHeading;
