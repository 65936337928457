import React, { Component } from "react";
import Logo from "./somethingWentWrong4.png";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error, info) {
    // Update state with error details
    this.setState({ hasError: true, error });
  }
  handleGoToHome = () => {
    // Redirect to the home page
    const { homeURL } = this.props;
    window.location.href = homeURL ? homeURL : "/"; // Replace "/" with the URL of your home page
  };

  render() {
    const {
      Image = Logo,
      ImageHeight = "300px",
      heading = "Oops! Something went wrong",
      subHeading,
      fallback,
      customButton,
      buttonText = "Back To Home",
      children,
      buttonStyle,
    } = this.props;
    if (this.state.hasError) {
      if (fallback) return fallback;

      const defaultButtonStyle = {
        padding: "10px 15px",
        border: "2px solid black",
        fontSize: "14px",
        fontWeight: "600",
        borderRadius: "5px",
        cursor: "pointer",
      };
      const mergedButtonStyle = { ...defaultButtonStyle, ...buttonStyle };

      return (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>
            <img src={Image} alt="404" style={{ height: ImageHeight }} />
          </div>
          <div style={{ fontSize: "30px" }}>
            <h2>{heading}</h2>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            {subHeading ? (
              subHeading
            ) : (
              <h4>
                Brace yourself till we get the error fixed.
                <br />
                You may also refresh the page or try again later
              </h4>
            )}
          </div>
          {customButton ? (
            customButton
          ) : (
            <div
              style={{
                ...mergedButtonStyle,
              }}
              onClick={this.handleGoToHome}
            >
              {buttonText}
            </div>
          )}
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
