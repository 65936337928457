import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const TotalSale = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "This week",
    value: "thisWeek",
  },
  {
    label: "This month",
    value: "thisMonth",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Previous Week",
    value: "previousWeek",
  },
  {
    label: "Previous Month",
    value: "previousMonth",
  },
  {
    label: "Previous Year",
    value: "previousYear",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const ProductType = (data) => {
  if (data?.length === 0) return;
  let result = Object.keys(data?.productType)?.map((key) => ({
    label: key?.toLowerCase(),
    value: data?.productType[key],
  }));
  return result;
};

export const tenureType = (data) => {
  if (data?.length === 0) return;
  let result = data?.map((item) => ({
    label: `${item?.tenure} ${item?.tenureType}`,
    value: `${item?.tenure}-${item?.tenureType}`,
  }));
  return result;
};

export const salesPersonList = (data) => {
  if (data?.length === 0) return;
  let result = data?.map((item) => ({
    label: item?.name,
    value: item.id,
  }));
  return result;
};

export const allProductValue = (data) => {
  if (data?.length === 0) return;
  let result = data?.map((item) => item.value);

  return result;
};

export const ViewData = (data) => {
  if (data?.length === 0) return;
  let result = Object.keys(data?.viewedBy)?.map((key) => ({
    label: key?.toLowerCase().replace(" by", " date"),
    value: data?.viewedBy[key],
  }));
  return result;
};

export const ResellerType = [
  { label: "Silver", value: "SILVER" },
  { label: "Gold", value: "GOLD" },
  { label: "Platinum", value: "PLATINUM" },
];
export const PaymentPartner = [
  {
    label: "Hospido",
    value: "HOSPIDO",
  },
  {
    label: "Doc",
    value: "DOC",
  },
];
export const WeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label);

  const formattedStartDate = startDate.format("YYYY-MM-DD");
  return [formattedStartDate, currentDate.format("YYYY-MM-DD")];
};

export const LastWeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label).subtract(1, label);

  const endDate = currentDate.clone().startOf(label).subtract(1, "day");
  const formattedStartDate = startDate.format("YYYY-MM-DD");
  const formattedEndDate = endDate.format("YYYY-MM-DD");
  return [formattedStartDate, formattedEndDate];
};

export const findLabelByValue = (data, inputValue) => {
  if (data?.length === 0 || inputValue === "") return;
  const result = data?.find((item) => item.value === inputValue);
  return result ? result.label : null;
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};
export const downloadExcel = (data) => {
  if (data?.length === 0) return;
  const ws = XLSX.utils.json_to_sheet(data);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const headers = Object.keys(data[0]);
  headers?.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    ws[cellAddress].v = header.toUpperCase();
    ws[cellAddress].s = {
      fill: { bgColor: { indexed: 64, rgb: "FFFF00" } },
      font: { bold: true, color: { rgb: "FFFFFF" } },
    };
  });

  const excelBlob = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "binary",
  });
  const blob = new Blob([s2ab(excelBlob)], {
    type: "application/octet-stream",
  });
  saveAs(blob, "table_data.xlsx");
};
