import React, { useEffect, useState } from "react";
import AdminMain from "../AdminMain";
import InputSearchableDropdown from "../../../components/form/InputSearchableDropdown";
import { getResellerProduct } from "../../../components/uploaderComponent/getProduct";
import { useHistory, useLocation } from "react-router-dom";

const AdminUpload = () => {
  const [loading, setLoading] = useState(false);
  const [resellerData, setResellerData] = useState(null);
  const [resellerList, setResellerList] = useState([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getResellerProductList();
  }, []);

  const getResellerProductList = async () => {
    setLoading(true);
    let data = await getResellerProduct();
    data =
      data?.length &&
      data?.map((obj) => {
        return {
          label: `${obj.name} (${obj.mobile})`,
          value: obj.id,
          ...obj,
        };
      });
    setResellerList(data);
    setLoading(false);
  };

  const onResellerSelect = (value, item) => {
    setResellerData(item);
  };

  const onCleanHandler = (value) => {
    if (!value) {
      setResellerData(null);
    }
  };

  const onClickHandler = () => {
    const data = {
      ...resellerData,
      ...location.state,
    };
    history.push({ pathname: "/excel-history", state: data });
  };

  return (
    <AdminMain>
      <div className="flex justify-center items-center w-full flex-col gap-16">
        <div className="text-center ">
          <p className="mb-3">
            Select Partner <span className="text-red-500">*</span>
          </p>

          <InputSearchableDropdown
            width={270}
            placeholder="Ex. abc"
            data={resellerList || []}
            onSelect={onResellerSelect}
            loading={loading}
            value={resellerData?.id}
            onChange={onCleanHandler}
          />
        </div>
        <button
          onClick={onClickHandler}
          disabled={!resellerData}
          className={`${
            !resellerData ? "bg-gray-500 cursor-not-allowed" : "bg-primary-500"
          } text-white p-2 rounded-xl`}
        >
          Go to history
        </button>
      </div>
    </AdminMain>
  );
};

export default AdminUpload;
