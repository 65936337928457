import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import { ApiGet } from "../../lib/AppHelper";
import moment from "moment";
import Button from "../../components/Button";
import InputDate from "../../components/form/InputDate";
import { CSVLink } from "react-csv";
import { RefreshIcon } from "@heroicons/react/solid";

export class SubscriptionReport extends Component {
  MessageRef = React.createRef();
  state = {
    reports: [],
    headers: [
      { label: "Member Name", key: "name" },
      { label: "Member Email", key: "email" },
      { label: "Member Mobile", key: "mobile" },
      { label: "Product Name", key: "product_name" },
      { label: "Product Amount", key: "policy_amount" },
      { label: "Installment Type	", key: "period" },
      { label: "Billing Amount", key: "billing_amount" },
      { label: "Total Installment", key: "total" },
      { label: "Paid Installment", key: "paid_count" },
      { label: "Remaining Installments", key: "remaining_count" },
      { label: "Amount Received", key: "paid_amount" },
      { label: "Installment Created Date", key: "created_at" },
      { label: "Installment Start Date", key: "start_at" },
      { label: "Installment End Date", key: "end_at" },
      { label: "Status", key: "subscription_status" },
    ],

    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      status: "",
      employee_id: "",
    },
    employees: [],
    toMaxDate: new Date(),
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    params["status"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, count, employees, headers, toMaxDate } =
      this.state;
    const { user } = this.props;
    const { permissions } = user;
    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.subscription_report_view ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/">Back</AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "20%" }} className="float-right">
                  <button
                    style={{ fontSize: "12px" }}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                  >
                    <CSVLink
                      data={reports}
                      headers={headers}
                      filename={"subscription-report.csv"}
                    >
                      Download
                    </CSVLink>
                  </button>
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-5 gap-4">
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "employee_id")}
                  value={params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">Me</option>
                  {employees.map((option, index) => {
                    return (
                      <option value={option.id} key={index}>
                        {option.name} ({option.mobile})
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>

              <div
                className="flex flex-row"
                style={{ maxWidth: "50%", paddingTop: "22px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="md"
                  title={"Search"}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    <table className="min-w-full border" id="table-to-xls">
                      <thead className="border-b">
                        <tr>
                          <th
                            className="text-sm  whitespace-pre-wrap font-large px-6 py-4 whitespace-nowrap text-center"
                            style={{ maxWidth: "100px" }}
                          >
                            Product Name
                          </th>
                          <th
                            className="text-sm whitespace-pre-wrap font-large px-6 py-4 whitespace-nowrap text-center"
                            style={{ maxWidth: "100px" }}
                          >
                            Product Amount
                          </th>
                          <th
                            style={{ maxWidth: "80px" }}
                            className="text-sm whitespace-pre-wrap font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Member Name
                          </th>
                          <th
                            style={{ maxWidth: "50px" }}
                            className="text-sm whitespace-pre-wrap font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Member Mobile
                          </th>
                          <th
                            style={{ maxWidth: "100px" }}
                            className="text-sm whitespace-pre-wrap font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Total Installment
                          </th>
                          <th
                            style={{ maxWidth: "100px" }}
                            className="text-sm whitespace-pre-wrap  font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Remaining Installment
                          </th>

                          <th
                            style={{ maxWidth: "100px" }}
                            className="text-sm whitespace-pre-wrap  font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Installment Amount
                          </th>

                          <th
                            style={{ maxWidth: "130px" }}
                            className="text-sm whitespace-pre-wrap  font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Amount Received
                          </th>

                          <th
                            style={{ maxWidth: "130px" }}
                            className="text-sm whitespace-pre-wrap font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Installment Start Date
                          </th>
                          <th
                            style={{ maxWidth: "120px" }}
                            className="text-sm whitespace-pre-wrap  font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Installment End Date
                          </th>
                          <th
                            style={{ maxWidth: "120px" }}
                            className="text-sm whitespace-pre-wrap  font-large px-6 py-4 whitespace-nowrap text-center"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      {reports.map((record, index) => {
                        return (
                          <tbody key={index}>
                            <tr
                              className={`border-b ${
                                record.subscription_status == "paid"
                                  ? "bg-green-100"
                                  : ""
                              }`}
                              key={index}
                            >
                              <td
                                style={{ maxWidth: "200px" }}
                                className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap whitespace-pre-wrap"
                              >
                                {record.product_display_name}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.policy_amount}
                              </td>
                              <td
                                style={{ maxWidth: "80px" }}
                                className="text-sm capitalize  whitespace-pre-wrap  text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center"
                              >
                                {record.name}
                              </td>
                              <td className="text-sm text-gray-900  whitespace-pre-wrap  font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.mobile}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.total}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.remaining_count}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.collection_amount}
                              </td>

                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.paid_amount}
                              </td>

                              <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap text-center">
                                {record.start_at}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.end_at}
                              </td>
                              <td className="text-sm capitalize text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                                {record.subscription_status}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                      {reports.length === 0 ? (
                        <tr>
                          <td
                            colSpan="9"
                            height="50"
                            className="text-red-500 font-light text-center"
                          >
                            Your result is empty
                          </td>
                        </tr>
                      ) : (
                        false
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You do not have permission to view Subscription Report</h4>
            </div>
          </div>
        )}
      </Main>
    );
  }

  componentDidMount() {
    this.getReports();
    this.getTeam();
  }
  handleClick = () => {
    this.getReports(this.state.params);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  getReports = async (request) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/subscriptions", request);
    if (response.status === "success") {
      if (response.data.items) {
        const items = Object.values(response.data.items);
        this.setState({ reports: items });
        this.setState({ count: response.data });
      } else {
        this.state.reports = [];
      }
    } else {
      this.state.reports = [];

      this.MessageRef.current.show(response.message);
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const SubscriptionReportConnect = connect(mapStateToProps)((props) => {
  return <SubscriptionReport {...props} />;
});

export default withRouter(SubscriptionReportConnect);
