import { PencilIcon, PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { AppRow } from "../../components/AppComponents";
import Button from "../../components/Button";
import Main from "./../Main";
import { InputGroup } from "../../components/form/InputGroup";
import ProfileSidebar from "./ProfileSidebar";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { InputRadio } from "../../components/form/InputRadio";
import { ApiGet, ApiPost, removeData, setError } from "../../lib/AppHelper";
import { InputSelect } from "../../components/form/InputSelect";
import { registrationGovtIds } from "../../services/common/data";
import { toast } from "react-toastify";
import { InputFile } from "../../components/form/InputFile";
import _ from "lodash";
import {
  addTeamDocSize,
  extensions,
  getFileExtension,
} from "../../lib/constants";

export class BankingProfile extends Component {
  state = {
    edit: false,
    govtIdObj: {
      ...registrationGovtIds?.filter(
        (elm) => this.props.user?.banking?.government_id_type === elm.value
      )?.[0],
    },
    init_params: {
      pan: this.props?.user?.banking?.pan,
      payment_mode: this.props?.user?.banking?.payment_mode,
      bank_name: this.props?.user?.banking?.bank_name,
      account_number: this.props?.user?.banking?.account_number,
      ifsc_code: this.props.user?.banking.ifsc,
      upi_id: this.props?.user?.banking?.upi,
      government_id_type: this.props?.user?.banking?.government_id_type,
      government_id: this.props?.user?.banking?.government_id,
      document: this.props.user?.banking?.document,
      document_url: this.props?.user?.banking?.document_url,
    },
    params: {
      pan: this.props?.user?.banking?.pan,
      payment_mode: this.props?.user?.banking?.payment_mode
        ? this.props.user.banking.payment_mode
        : "",
      bank_name: this.props?.user?.banking?.bank_name
        ? this.props?.user?.banking?.bank_name
        : "",
      account_number: this.props?.user?.banking?.account_number
        ? this.props?.user?.banking?.account_number
        : "",
      ifsc_code: this.props?.user?.banking?.ifsc
        ? this.props?.user?.banking?.ifsc
        : "",
      upi_id: this.props?.user?.banking.upi
        ? this.props?.user?.banking.upi
        : "",
      government_id_type: this.props?.user?.banking?.government_id_type
        ? this.props?.user?.banking?.government_id_type
        : "",
      government_id: this.props?.user?.banking?.government_id
        ? this.props?.user?.banking?.government_id
        : "",
      document: this.props?.user?.banking?.document
        ? this.props?.user?.banking?.document
        : "",
      document_url: this.props?.user?.banking?.document_url
        ? this.props?.user?.banking?.document_url
        : "",
    },
    errors: {},
  };

  governmentInput = React.createRef();

  render() {
    const { edit, params, errors } = this.state;
    const { user } = this.props;
    const { permissions } = user;
    const ready = this.isReady();
    return (
      <Main>
        {permissions?.hide_reseller_banking_section ? (
          <div className="text-red-500">
            <h4>You don't have permissions to view this section.</h4>
          </div>
        ) : (
          <AppRow>
            <div className="flex-1 max-w-min mr-5">
              <ProfileSidebar isDirty={edit} />
            </div>
            <div className="flex-auto">
              <div className="max-w-xl">
                <InputSelect
                  width="full"
                  loading={!edit}
                  label="Govt ID Type"
                  attrs={{
                    value: params.government_id_type,
                    onChange: (e) => {
                      this.onChangeHandler(e, "government_id_type");
                    },
                    readOnly: !edit,
                  }}
                  options={registrationGovtIds}
                ></InputSelect>
                <InputGroup
                  label="Government ID"
                  placeholder="Enter Govt ID..."
                  attrs={{
                    disabled: !params.government_id_type,
                    value: params.government_id,
                    ref: this.governmentInput,
                    onChange: (e) => {
                      if (
                        /^[a-zA-Z0-9\-_]*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          e.target.value.length <=
                          (this.state.govDetails.maxLength
                            ? this.state.govDetails.maxLength
                            : 20)
                        ) {
                          this.onChangeHandler(
                            {
                              target: {
                                value: e.target.value,
                              },
                            },
                            "government_id"
                          );
                        }
                      }
                    },
                    readOnly: !edit,
                  }}
                />

                <InputFile
                  error={errors.document}
                  file={params.document}
                  label="Attachment (png, jpg, jpeg and pdf)"
                  placeholder={
                    !params.document && (
                      <div className="text-center items-center flex-col w-full inline-flex">
                        <PlusCircleIcon className="text-primary" width={24} />
                        <p className="mt-2 text-center text-sm text-primary">
                          Upload for Govt ID
                          <br />
                          (max size 5MB)
                          <br />
                        </p>
                      </div>
                    )
                  }
                  readOnly={!edit}
                  url={params?.document_url}
                  attrs={{
                    onChange: (e) => this.onDocChange(e, "document"),
                    disabled: !params.government_id_type || !edit,
                    accept: "image/*,.pdf",
                    readOnly: !edit,
                  }}
                  onDocDelete={() => {
                    _.set(params, "document_url", null);
                    _.set(params, "document", null);
                    this.setState({ params: params });
                  }}
                />

                {edit ? (
                  <InputRadio
                    label="Payment in"
                    name="payment_mode"
                    options={[
                      { label: "Account NEFT", value: "neft" },
                      { label: "UPI", value: "upi" },
                    ]}
                    value={params.payment_mode}
                    attrs={{
                      onChange: (e) => this.onChangeHandler(e, "payment_mode"),
                    }}
                  />
                ) : (
                  false
                )}

                <div
                  className={`${
                    params.payment_mode === "neft" ? "" : "hidden"
                  }`}
                >
                  <InputGroup
                    error={errors.bank_name}
                    label="Bank Name"
                    placeholder="Enter Bank Name..."
                    attrs={{
                      value: params.bank_name,
                      onChange: (e) => this.onChangeHandler(e, "bank_name"),
                      readOnly: !edit,
                    }}
                  />

                  <InputGroup
                    error={errors.account_number}
                    label="Account Number"
                    placeholder="Enter Account Number..."
                    attrs={{
                      value: params.account_number,
                      onChange: (e) =>
                        this.onChangeHandler(e, "account_number"),
                      readOnly: !edit,
                    }}
                  />

                  <InputGroup
                    error={errors.ifsc_code}
                    label="IFSC Code"
                    placeholder="Enter IFSC Code..."
                    attrs={{
                      value: params.ifsc_code,
                      onChange: (e) => this.onChangeHandler(e, "ifsc_code"),
                      readOnly: !edit,
                    }}
                  />
                </div>
                <div
                  className={`${params.payment_mode === "upi" ? "" : "hidden"}`}
                >
                  <InputGroup
                    error={errors.upi_id}
                    label="Enter UPI ID"
                    placeholder="Enter UPI ID..."
                    attrs={{
                      value: params.upi_id ? params.upi_id : "",
                      onChange: (e) => this.onChangeHandler(e, "upi_id"),
                      readOnly: !edit,
                    }}
                  />
                </div>
                <div className="mt-8">
                  <div className="flex">
                    <div>
                      <Button
                        attrs={{ onClick: this.toggleEdit }}
                        title={!edit ? "Edit Details" : "Cancel Edit"}
                        display="inline-block"
                        outline={true}
                      >
                        {edit ? (
                          <XIcon width={16} className="mr-1" />
                        ) : (
                          <PencilIcon width={16} className="mr-1" />
                        )}
                      </Button>
                    </div>
                    <div className="flex-1 pl-5">
                      {edit ? (
                        <Button
                          attrs={{
                            type: "button",
                            onClick: this.updateBanking,
                            disabled: !ready,
                          }}
                          size="md"
                          title={"Update Details"}
                        />
                      ) : (
                        false
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AppRow>
        )}
      </Main>
    );
  }

  isReady = () => {
    const { params } = this.state;
    if (!params.government_id && !params.government_id_type) {
      return false;
    } else {
      return true;
    }
  };
  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    if (key === "government_id_type") {
      let govObj = registrationGovtIds.filter(
        (elm) => e.target.value === elm.value
      );
      params["government_id"] = "";
      this.setState({ params: params, govDetails: govObj?.[0] });
    } else {
      params[key] = e.target.value;
      this.setState({ params: params });
    }
  };

  onDocChange = (event, key) => {
    const { params } = this.state;
    const file = event?.target?.files?.[0];
    const extension = getFileExtension(file);
    if (extensions.includes(extension)) {
      if (file) {
        const fileSize = file?.size;
        if (fileSize < addTeamDocSize) {
          _.set(params, key, event.target.files[0]);
          _.set(params, "document_url", null);
          this.setState({ params: params });
        } else {
          this.setState({
            errors: {
              [key]: "File size should be less than 5mb",
            },
          });
        }
      }
    } else {
      this.setState({
        errors: {
          [key]: "File must be .pdf, .jpeg, .jpg, .png",
        },
      });
    }
  };

  componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: false });
    AppStore.dispatch({ type: "PROGRESS", progress: 0 });
  }

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit }, () => {
      if (this.state.edit) {
        this.governmentInput.current.focus();
      } else {
        this.setState({ params: { ...this.state.init_params } });
      }
    });
  };

  onGovtIdChange = (value) => {
    this.setState((prevState) => ({
      govDetails: {
        ...prevState.govDetails,
        value,
      },
    }));
  };

  updateBanking = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const {
        bank_name,
        account_number,
        ifsc_code,
        upi_id,
        payment_mode,
        government_id,
        government_id_type,
        document,
      } = this.state.params;

      const data = new FormData();
      data.append("update_only", 1);
      data.append("bank_name", bank_name);
      data.append("account_number", account_number);
      data.append("ifsc_code", ifsc_code);
      data.append("upi_id", upi_id);
      data.append("payment_mode", payment_mode);
      data.append("governmentId", government_id);
      data.append("governmentIdType", government_id_type);
      data.append("document", document);

      const response = await ApiPost("register/banking", data);

      if (response.status === "error") {
        if (response.message) {
          toast.error(response.message);
        }
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        const response = await ApiGet("me");
        if (response.status === "success") {
          AppStore.dispatch({ type: "USER_UPDATED", user: response.data });
          toast.success("Banking details updated successfully!");
        } else {
          await removeData("access_token");
          await removeData("auth_token");
          await setError(response.message);
        }
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ edit: false });
      }
    });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <BankingProfile {...props} />;
});
