import React from "react";
import {
  DaughterIcon,
  FemaleIcon,
  MaleIcon,
  ParentsIcon,
  RingIcon,
  SonIcon,
} from "./AppIcons";
import { FaUser } from "react-icons/fa";

export default function GetHeader(member, size) {
  let relation = member?.relation.toUpperCase();

  if (relation === "SELF" && member?.gender === "MALE")
    return <MaleIcon size={size} className="absolute" />;

  if (relation === "SELF" && member?.gender === "FEMALE")
    return <FemaleIcon className="absolute" size={size}></FemaleIcon>;

  if (
    relation === "SELF" &&
    (member?.gender === "OTHER" || member?.gender === "OTHERS")
  )
    return <FaUser size={size} className="absolute" />;

  if (relation === "HUSBAND" && member?.gender === "MALE")
    return <MaleIcon size={size} className="absolute" />;

  if (
    relation !== "SELF" &&
    (member?.gender === "OTHER" || member?.gender === "OTHERS")
  )
    return <FaUser size={size} className="absolute" />;

  if (relation === "SPOUSE" || relation === "WIFE")
    return <RingIcon size={size} className="absolute"></RingIcon>;

  if (relation === "DAUGHTER")
    return <DaughterIcon size={size} className="absolute"></DaughterIcon>;

  if (relation === "SON")
    return <SonIcon size={size} className="absolute"></SonIcon>;

  if (relation === "PARENT")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;

  if (relation === "MOTHER")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;

  if (relation === "FATHER")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;

  if (relation === "BROTHER")
    return <MaleIcon size={size} className="absolute" />;

  if (relation === "SISTER")
    return <DaughterIcon size={size} className="absolute"></DaughterIcon>;

  if (relation === "SIBLING")
    return <MaleIcon size={size} className="absolute" />;
  if (relation === "NEIGHBOUR")
    return <MaleIcon size={size} className="absolute" />;

  if (relation === "SON_IN_LAW" || relation === "SON-IN-LAW")
    return <SonIcon size={size} className="absolute"></SonIcon>;
  if (relation === "GRAND-SON" || relation === "GRAND SON")
    return <SonIcon size={size} className="absolute"></SonIcon>;

  if (relation === "DAUGHTER_IN_LAW" || relation === "DAUGHTER-IN-LAW")
    return <DaughterIcon size={size} className="absolute"></DaughterIcon>;

  if (relation === "BROTHER_IN_LAW" || relation === "BROTHER-IN-LAW")
    return <MaleIcon size={size} className="absolute"></MaleIcon>;

  if (relation === "SISTER_IN_LAW" || relation === "SISTER-IN-LAW")
    return <DaughterIcon size={size} className="absolute"></DaughterIcon>;

  if (relation === "MOTHER_IN_LAW" || relation === "MOTHER-IN-LAW")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;

  if (relation === "FATHER_IN_LAW" || relation === "FATHER-IN-LAW")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;

  if (relation === "SELF_PARENT_1" || relation === "SELF_PARENT_2")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;

  if (relation === "SPOUSE_PARENT_1" || relation === "SPOUSE_PARENT_2")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;

  if (relation === "SPOUSE_PARENT" || relation === "SPOUSE-PARENT")
    return <ParentsIcon size={size} className="absolute"></ParentsIcon>;
}
