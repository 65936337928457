import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ApiPost, setData } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import Main from "./Main";

export class PasswordlessLogin extends Component {
  state = {};

  MessageRef = React.createRef();

  async componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: true });
    let access_token = this.props.match.params.token;
    const token = setData("access_token", access_token);
    const response = await ApiPost("magic-login", {
      coupon: "ABXHRG",
    });

    if (response.status === "success") {
      await setData("is_admin", response.data.user.is_admin ? 1 : 0);
      await setData("permissions", response.data.user.permissions);
      await setData("is_secured_login", response.data.user.is_secured_login);

      await setData("access_token", response.data.token, () => {
        AppStore.dispatch({
          type: "SIGN_IN",
          user: response.data.user,
          token: response.data.token,
        });
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.props.history.push("/subscribe");
      });
      await setData("auth_token", response.data.authToken);
    } else {
      this.setState({ errors: response.errors }, () => {
        this.MessageRef.current.show(response.message, "error");
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  }

  render() {
    return (
      <Main MessageRef={this.MessageRef}>
        <div className="w-full px-6 md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div className="terms"></div>
        </div>
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { token } = state;
  return { token: token };
};

const PasswordlessLoginConnect = connect(mapStateToProps)((props) => {
  return <PasswordlessLogin {...props} />;
});

export default withRouter(PasswordlessLoginConnect);
