import { Tooltip } from "@mui/material";
import moment from "moment";

export const generateRandomColors = (num) => {
  const colors = [];
  for (let i = 0; i < num; i++) {
    const randomColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)}, 1)`;
    colors.push(randomColor);
  }
  return colors;
};

export const getDataFromLocal = (CACHE_KEY) => {
  const cachedData = localStorage.getItem(CACHE_KEY);

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    const cacheExpiration = moment(parsedData.timestamp).add(1, "day");
    if (moment().isBefore(cacheExpiration)) {
      return parsedData.data;
    } else return false;
  }
};

export const setDataToLocal = (data, CACHE_KEY) => {
  if (data?.records?.length > 1) {
    const cachedData = {
      data: data,
      timestamp: moment().toISOString(),
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
  }
};
export const setMultipledataToLocal = (data, CACHE_KEY) => {
  const cachedData = {
    data: data,
    timestamp: moment().toISOString(),
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(cachedData));
};

export const truncateString = (str, charLength, name) => {
  if (!str) return;
  else if (str.length <= charLength) return <span>{str ? str : name}</span>;
  else {
    const newString = str.substring(0, charLength) + "...";
    return (
      <Tooltip title={str} placement="left" arrow>
        <span className="capitalize">
          {newString ? newString.toLowerCase() : name}
        </span>
      </Tooltip>
    );
  }
};
