import React from "react";
import HeightIcon from "@mui/icons-material/Height";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getSortIcon } from "./constant";

const DataTable = ({ data, sort, setSort, handleSort }) => {
  const { label, sortBy } = sort;
  return (
    <div className="w-full overflow-scroll no-scrollbar mb-4">
      <table className="min-w-full shadow-lg " id="table-to-xls">
        <thead className="border-b-2">
          <tr style={{ backgroundColor: "#db2228", color: "#fff" }}>
            <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
              Sr.No
            </th>

            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              <div
                className="flex gap-1 items-center"
                onClick={() => handleSort("POLICY")}
              >
                <span>Subscription Punch</span>

                {getSortIcon(sort, "POLICY")}
              </div>
            </th>
            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              <div
                className="flex gap-1 items-center"
                onClick={() => handleSort("DOCUMENT")}
              >
                <span>Document Punch</span>

                {getSortIcon(sort, "DOCUMENT")}
              </div>
            </th>
            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              Branch Code
            </th>
            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center">
              Branch Name
            </th>

            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              Name
            </th>
            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              Phone
            </th>
            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              Loan Code/Reference Number
            </th>
            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              Adhaar Card
            </th>
            <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
              Prescription
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data?.map((item, index) => {
              return (
                <tr className="border-b-2" key={index}>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {index + 1}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.createdOn || "---"}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.documentDate || "---"}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.branchCode || "---"}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.branchName || "---"}
                  </td>

                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.name || "---"}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.mobile || "---"}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.loanCode || "---"}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.govtIdNumber || "---"}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {item?.prescriptionUploaded || "---"}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan="10"
                height="50"
                className="text-red-500 font-light text-center border"
              >
                Your result is empty
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
