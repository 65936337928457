import React from "react";
import { Avatar, Stack } from "@mui/material";

export const UserCard = ({
  valueField,
  labelField,
  handleRadioChange,
  selectedValue,
  item = {},
}) => {
  return (
    <Stack
      style={{
        boxShadow: "1px 0px 15px -1px rgba(0,0,0,0.56)",
        borderRadius: 10,
        flexDirection: "row",
        display: "flex",
        padding: 20,
        alignItems: "center",
        justifyContent: "space-between",
        margin: 20,
        cursor: "pointer",
        borderColor:
          selectedValue.indexOf(item[valueField]) > -1 ||
          selectedValue == item?.value
            ? "red"
            : "transparent",
        borderWidth: 1,
      }}
      onClick={() => {
        handleRadioChange({ target: { value: item } });
      }}
    >
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <Avatar sx={{ width: 40, height: 40 }}>{item[labelField][0]}</Avatar>
        <div>{item[labelField]}</div>
      </Stack>
    </Stack>
  );
};
