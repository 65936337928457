import React from "react";

const HeaderCards = ({ tab, headerData, setTabValue, setFilter, tabValue }) => {
  return (
    <>
      <div className="sm:hidden">
        <label for="tabs" className="sr-only">
          Select tab
        </label>
        <select
          id="tabs"
          className="bg-gray-50 border-0 border-b border-gray-200 text-gray-900 text-sm  rounded-t-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
          onChange={(e) => {
            const value = e.target.value;
            if (value === "open_claim") {
              setFilter("IN_PROGRESS");
            } else if (value === "closed_claim") {
              setFilter("SETTLED");
            } else {
              setFilter("");
            }
            setTabValue(value);
          }}
        >
          {tab?.map((item, index) => (
            <option value={item.value} key={index} className="font-bold">
              {item.label} &nbsp;&nbsp;count : {headerData[item.value]}
            </option>
          ))}
        </select>
      </div>

      <ul
        className="hidden text-sm font-medium text-center  divide-x divide-gray-200 rounded-lg sm:flex gap-2 rtl:divide-x-reverse"
        id="fullWidthTab"
        data-tabs-toggle="#fullWidthTabContent"
        role="tablist"
      >
        {tab?.map((item, index) => {
          return (
            <li
              className="w-full"
              key={index}
              onClick={() => {
                if (item.value === "open_claim") {
                  setFilter("IN_PROGRESS");
                } else if (item.value === "closed_claim") {
                  setFilter("SETTLED");
                } else {
                  setFilter("");
                }
                setTabValue(item.value);
              }}
            >
              <button
                id="stats-tab"
                data-tabs-target="#stats"
                type="button"
                role="tab"
                aria-controls="stats"
                aria-selected="true"
                style={{ border: "2px solid #DB2228", borderRadius: "5px" }}
                className={`inline-block w-full p-4 rounded-ss-lg font-bold ${
                  tabValue === item.value
                    ? "bg-primary-500 text-white"
                    : "bg-white-50 hover:bg-gray-100"
                }  focus:outline-none`}
              >
                {item.label}
                <br />
                <span>
                  {headerData[item.value] !== undefined &&
                  headerData[item.value] !== null
                    ? headerData[item.value]
                    : ""}
                </span>
              </button>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default HeaderCards;
