import React from "react";
import Terms from "../../screens/Terms";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppHeading } from "../AppComponents";

const TermsModal = ({ open, handleClose }) => {
  return (
    <React.Fragment>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h1>Terms & Conditions of Use</h1>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: "100%", padding: 0 }}>
          <Terms />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default TermsModal;
