import React, { useCallback, useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { InputTextArea } from "../../../components/form/InputTextArea";
import { DatePicker, Modal, Pagination, SelectPicker } from "rsuite";
import Button from "../../../components/Button";
import { BiTransferAlt } from "react-icons/bi";
import MedicineSearchPicker from "./MedicineSearchPicker";
import { PURCHASE_STOCK, TRANSFER_STOCK } from "../../../lib/constants";
import { getApiClaim, postApiClaim } from "../../../lib/AppHelper";
import debounce from "lodash.debounce";
import InputLabel from "../../../components/form/InputLabel";
import { Tooltip } from "@mui/material";
import { MAX_ROW, PRIMARY_COLOR, blockInvalidChar } from "./constant";
import { useSelector } from "react-redux";
import "./style.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import BranchSearchPicker from "./BranchSearchPicker";
import AddIcon from "@mui/icons-material/Add";
import AddVendor from "./AddVendor";
import { toast } from "react-toastify";

const IconStyle = {
  fontSize: "30px",
  color: PRIMARY_COLOR,
  cursor: "pointer",
};
const InventoryTable = ({
  data,
  handelDeleteRow,
  onchange,
  errors,
  handelSubmit,
  component,
  onMedicineClear,
  headerDate,
  setHeaderDate,
  medicineSearchUrlPrefix,
  vendorName,
  setVendorDetails,
  hoId,
  setHoId,
  disableDate = false,
  setVendorName,
  setVendorId,
  onRefesh,
  batchNo,
  setBatchNo,
  onAddMultiRow,
  setMultiRow,
  multiRow,
  windowSize,
  vendorId,
}) => {
  const state = useSelector((state) => state);
  const user = state.user;
  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [vendorList, setVendorList] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [branchPage, setBranchPage] = useState(0);
  const [count, setCount] = useState(0);
  const [branchInput, setBranchInput] = useState("");
  const [branchLoading, setBranchLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const getMedicine = async () => {
    setLoading(false);
    let data = {
      resellerId: user.id,
      page: 0,
      size: 20,
    };
    await postApiClaim(
      `${medicineSearchUrlPrefix}?medicineName=${searchKey}`,
      data
    )
      .then((response) => {
        if (response.code === 200) {
          const newArray = [];
          response?.data.map((obj) => {
            const exists = medicineList.some(
              (item) => item.value === obj.medicineId
            );
            if (!exists) {
              newArray.push({
                ...obj,
                label: obj.medicineName,
                value: obj.medicineId,
              });
            }
          });
          setMedicineList([...medicineList, ...newArray]);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const getVendor = async () => {
    setLoading(false);
    await getApiClaim(`get_vendor/${vendorName}`)
      .then((response) => {
        if (response.code === 200) {
          const newArray = response?.data.map((obj) => {
            return { ...obj, label: obj.name, value: obj.id }; // Creating a new object with modified property
          });
          setVendorList(newArray);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const getBranches = async (page) => {
    setBranchLoading(true);
    let data = {
      resellerId: user.id,
      page: page ? page : branchPage,
      size: 20,
    };
    await postApiClaim(`get_child_resellers?searchKey=${branchInput}`, data)
      .then((response) => {
        if (response?.code === 200) {
          const newArray = [];
          response?.data.map((obj) => {
            const exists = branchList.some((item) => item.value === obj.id);
            if (!exists) {
              newArray.push({
                ...obj,
                label: `${obj?.resellerName} (${obj?.mobile})`,
                value: obj?.id,
              });
            }
          });
          setBranchList(() => [...branchList, ...newArray]);
          setCount(response?.count);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setBranchLoading(false));
  };

  const getMedicineDebounceFunction = useCallback(debounce(getMedicine, 1000), [
    searchKey,
  ]);

  const getVendorDebounceFunction = useCallback(debounce(getVendor, 1000), [
    vendorName,
  ]);

  const getBranchDebounceFunction = useCallback(debounce(getBranches, 1000), [
    branchInput,
  ]);

  useEffect(() => {
    if (searchKey !== "" && searchKey?.length > 2)
      getMedicineDebounceFunction();
    return getMedicineDebounceFunction.cancel;
  }, [searchKey, getMedicineDebounceFunction]);

  useEffect(() => {
    if (vendorName && vendorName?.length > 2) getVendorDebounceFunction();
    return getVendorDebounceFunction.cancel;
  }, [vendorName, getVendorDebounceFunction]);

  useEffect(() => {
    getBranchDebounceFunction();
    return getBranchDebounceFunction.cancel;
  }, [branchInput]);

  useEffect(() => {
    setHoId(user.id);
  }, []);

  const onVendorSelect = (value, item) => {
    setVendorDetails(value, item);
  };

  const getBranchesScroll = () => {
    getBranches(branchPage + 1);
    setBranchPage(branchPage + 1);
  };

  const onBranchSearchHandler = (value) => {
    if (value?.length > 2 && /^[a-zA-Z0-9\s_]+$/.test(value))
      setBranchInput(value);
  };

  const onVendorInput = (value) => {
    if (/^[a-zA-Z0-9]+$/.test(value)) setVendorName(value);
  };

  const addVendorHandler = () => {
    setOpen(true);
  };

  const onAddVendor = (data) => {
    const newObject = { ...data, label: data.name, value: data.id }; // Creating a new object with modified property
    setVendorList([newObject]);
    setVendorId(data.id);
    setVendorName(data.name);
    setOpen(false);
  };

  const onVendorClear = () => {
    setVendorId("");
    setVendorName("");
  };

  return (
    <>
      <div
        className="flex item-center justify-between"
        style={{ flexDirection: windowSize[0] < 800 ? "column" : "" }}
      >
        <div className="flex flex-col md:flex-row items-center gap-4 flex-wrap ">
          {component === PURCHASE_STOCK && (
            <>
              {" "}
              <div
                style={{
                  width: windowSize[0] < 800 ? "300px" : "200px",
                  marginBottom: "1.25rem",
                }}
              >
                <InputLabel label="Vendor" requiredMark />
                <SelectPicker
                  data={vendorList || []}
                  value={vendorId}
                  placeholder="Select vendor"
                  style={{
                    width: 230,
                    marginTop: "8px",
                    border: errors ? "1px solid red" : "",
                    borderRadius: errors ? "14px" : "",
                  }}
                  onSearch={(searchKey, e) => onVendorInput(searchKey)}
                  searchBy={(searchValue, label, option) =>
                    option.label
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) ||
                    option.mobile?.includes(searchValue)
                  }
                  onSelect={(value, item) => {
                    setVendorName("");
                    onVendorSelect(value, item);
                  }}
                  onClean={onVendorClear}
                  renderMenuItem={(value, obj) => {
                    return (
                      <div className="border-b-2 p-1 text-xs space-y-2">
                        <div>
                          <span className="font-bold text-sm">{value}</span>{" "}
                          <span className="float-right">#{obj?.id}</span>
                        </div>
                        {obj?.mobile && <div>mobile : {obj?.mobile}</div>}
                        {obj?.address && <div>address : {obj?.address}</div>}
                      </div>
                    );
                  }}
                  renderExtraFooter={() => {
                    return (
                      <div className="w-full text-center">
                        <Button
                          attrs={{
                            onClick: addVendorHandler,
                          }}
                          title="+ Add new vendor"
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <span style={{ cursor: "pointer", paddingTop: "15px" }}>
                <BiTransferAlt fontSize={30} />
              </span>
            </>
          )}
          <div
            className="mb-3"
            style={{ width: windowSize[0] < 800 ? "300px" : "200px" }}
          >
            <InputLabel label={"Warehouse (HO)"} requiredMark />
            <div className="mt-2 mb-2">
              <SelectPicker
                data={[{ label: user.name, value: user.id }]}
                searchable={false}
                value={hoId}
                onChange={(value) => {
                  setHoId(value);
                }}
                defaultValue={user.id}
                style={{
                  width: windowSize[0] < 800 ? 300 : 224,
                  borderRadius: "12px",
                  border:
                    errors.header && errors.header.includes("HO_ID")
                      ? "1px solid #Db2228"
                      : "",
                }}
                placeholder="Select"
                renderMenuItem={(value, obj) => {
                  return (
                    <div
                      className="border-b-2 p-1 text-xs space-y-2"
                      style={{ width: "175px" }}
                    >
                      <div>
                        <span className="font-bold text-sm">{value}</span>{" "}
                        {obj?.value && (
                          <span className="float-right font-bold">
                            Id : {obj?.value}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }}
                className={`${true ? "border-primary" : ""}`}
              />
            </div>
          </div>
          <div className="mb-5">
            <InputLabel label={headerDate.title} requiredMark />
            <div className="mt-2 flex space-x-4">
              <DatePicker
                appearance="default"
                style={{
                  height: "43px",
                  width: windowSize[0] < 800 ? "300px" : "",
                  borderRadius: "11px",
                  borderBottom: "1px solid #DB2228",
                  border:
                    (errors?.header &&
                      errors?.header?.includes("receivingDate")) ||
                    errors?.header?.includes("sendingDate")
                      ? "1px solid #Db2228"
                      : "",
                }}
                name="headerDate"
                oneTap
                placeholder="DD-MM-YYYY"
                format="dd-MM-yyyy"
                value={headerDate.date ? new Date(headerDate.date) : null}
                onChange={(value) => {
                  setHeaderDate((prevState) => ({ ...prevState, date: value }));
                }}
                disabledDate={headerDate.disableDateFunc}
                className={`${true ? "border-primary" : ""}`}
              />
            </div>
          </div>
          {component === PURCHASE_STOCK && (
            <div
              style={{
                width: windowSize[0] < 800 ? "300px" : "200px",
                marginBottom: "1.25rem",
              }}
            >
              <InputLabel label={"Batch no / Bill no"} />
              <div className="">
                <input
                  type="text"
                  placeholder="Eg. xyz"
                  style={{ width: "100%", marginTop: "8px" }}
                  value={batchNo}
                  name="batchNo"
                  onChange={(e) => {
                    const reg = /^[a-zA-Z0-9]*$/;
                    const userInput = e.target.value;
                    if (userInput === "" || reg.test(userInput)) {
                      setBatchNo(e.target.value);
                    }
                  }}
                  maxLength={20}
                  className={[
                    `focus:ring-0 rounded-xl shadow-sm focus:border-primary focus:bg-primary-50 border-grey`,
                  ].join(" ")}
                />
              </div>
            </div>
          )}
          <Tooltip title="Refresh" placement="right-start" arrow>
            <div tabIndex={6}>
              <Button
                className="mt-2 px-4 py-2"
                title=""
                size="sm"
                attrs={{ onClick: () => onRefesh() }}
                paddingX="4"
                paddingY="1"
              >
                <RefreshIcon style={{ fontWeight: "600" }} />
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className="flex gap-4 mb-2 justify-end">
          <div className="mt-1 flex flex-col gap-2">
            <InputLabel label={"Add row"} />
            <div className="flex gap-1">
              <input
                type="Number"
                placeholder="eg.1"
                style={{ width: "60px" }}
                value={multiRow}
                name="batchNo"
                onChange={(e) => {
                  if (
                    (e.target.value > 0 && e.target.value <= MAX_ROW) ||
                    e.target.value === ""
                  ) {
                    setMultiRow(e.target.value);
                  } else {
                    setMultiRow(100);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.code === "Enter") onAddMultiRow();
                }}
                maxLength={20}
                className={[
                  `focus:ring-0 rounded-xl shadow-sm focus:border-primary focus:bg-primary-50 border-grey`,
                ].join(" ")}
              />
              <div style={{ display: "grid", placeItems: "center" }}>
                <Tooltip title="Add single row" placement="right-start" arrow>
                  <div className="">
                    <Button
                      className=" px-4 py-2"
                      paddingX="4"
                      paddingY="1"
                      title=""
                      size="sm"
                      outline
                      attrs={{
                        onClick: () => onAddMultiRow(),
                        disabled: !multiRow,
                      }}
                    >
                      <AddIcon style={{ fontWeight: "600" }} />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:block overflow-scroll no-scrollbar max-h-80">
        <table className="min-w-full shadow-lg " id="table-to-xls">
          <thead className="border-b-2 static">
            <tr style={{ backgroundColor: PRIMARY_COLOR, color: "#fff" }}>
              <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                #
              </th>

              {component === TRANSFER_STOCK && (
                <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                  Branch name *
                </th>
              )}
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                Medicine name *
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center">
                Quantity *
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                Expiry date *
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                Narration
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <tr className="border-b-2" key={index}>
                    <td
                      className=" text-gray-900 px-6 py-2 whitespace-nowrap text-center"
                      style={{ paddingBottom: "30px" }}
                    >
                      {index + 1}.
                    </td>
                    {component === TRANSFER_STOCK && (
                      <td className=" text-gray-900  px-6 py-2 whitespace-nowrap text-center ">
                        <div
                          style={{
                            width: "150px",
                            marginBottom: "10px",
                          }}
                        >
                          <BranchSearchPicker
                            data={branchList}
                            name="branchId"
                            value={item.branchId}
                            onChange={(value) =>
                              onchange(index, "branchId", value)
                            }
                            className={`${
                              errors.tableData[index] &&
                              errors.tableData[index].includes("branchId")
                                ? "border-primary"
                                : ""
                            }`}
                            request={getBranchesScroll}
                            count={count}
                            onSearch={onBranchSearchHandler}
                            loading={branchLoading}
                          />
                        </div>
                      </td>
                    )}
                    <td className="text-gray-900 px-6 py-2 whitespace-nowrap text-center ">
                      <MedicineSearchPicker
                        loading={loading}
                        setSearchKey={setSearchKey}
                        onchange={(value, item) =>
                          onchange(index, "medicineId", item)
                        }
                        value={item.medicineId}
                        data={medicineList}
                        onMedicineClear={() => onMedicineClear(index)}
                        errors={
                          errors.tableData[index] &&
                          errors.tableData[index].includes("medicineId")
                        }
                        component={component}
                      />
                    </td>
                    <td className=" text-gray-900  px-6 py-2 whitespace-nowrap text-center ">
                      <div className="mb-5">
                        <input
                          type="Number"
                          placeholder="Eg. 0"
                          style={{
                            width: "100px",
                            marginTop: "8px",
                            borderColor:
                              errors.tableData[index] &&
                              errors.tableData[index].includes("quantity")
                                ? "#DB2228"
                                : "#e5e5ea",

                            background: !item.medicineId ? "lightgrey" : "",
                          }}
                          value={item.quantity}
                          name="quantity"
                          disabled={!item.medicineId}
                          onKeyDown={blockInvalidChar}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length === 1 && value === "0") return;
                            if (value.includes("e")) return;
                            if (
                              parseInt(value) <=
                                parseInt(item.maxMedicineCapicity) ||
                              value === ""
                            ) {
                              onchange(index, e);
                            } else {
                              toast.info(
                                `you can only select up to max ${item.maxMedicineCapicity} `,
                                {
                                  toastId: "inventory1",
                                }
                              );
                            }
                          }}
                          className={[
                            `focus:ring-0 rounded-xl shadow-sm focus:border-primary focus:bg-primary-50 ${
                              errors.tableData[index] &&
                              errors.tableData[index].includes("quantity")
                                ? "border-primary"
                                : ""
                            }`,
                          ].join(" ")}
                        />
                      </div>
                    </td>
                    <td className=" text-gray-900  px-6 py-2 whitespace-nowrap text-center ">
                      <div className="mb-5">
                        <DatePicker
                          appearance="default"
                          style={{
                            marginTop: "8px",
                            border:
                              errors.tableData[index] &&
                              errors.tableData[index].includes("expiryDate")
                                ? "1px solid red"
                                : "",
                            borderRadius:
                              errors.tableData[index] &&
                              errors.tableData[index].includes("expiryDate")
                                ? "10px"
                                : "",
                          }}
                          name="expiryDate"
                          oneTap
                          disabled={disableDate}
                          placeholder="DD-MM-YYYY"
                          format="dd-MM-yyyy"
                          value={
                            item.expiryDate ? new Date(item.expiryDate) : null
                          }
                          onChange={(value) => onchange(index, "date", value)}
                          disabledDate={(date) => {
                            if (date < new Date(moment().add(15, "days"))) {
                              return true;
                            }
                          }}
                          calendarDefaultDate={moment()
                            .add(16, "days")
                            .toDate()}
                        />
                      </div>
                    </td>
                    <td className=" text-gray-900  px-6 py-2 whitespace-nowrap text-center ">
                      <InputTextArea
                        row={1}
                        name="narration"
                        placeholder="Eg. xyz"
                        attrs={{
                          maxLength: 256,
                          value: item.narration,
                          onChange: (e) => {
                            const reg = /^[a-zA-Z0-9 ]+$/;
                            const userInput = e.target.value;
                            if (userInput === "" || reg.test(userInput)) {
                              onchange(index, e);
                            }
                          },
                        }}
                        className={`min-w-max ${
                          errors.tableData[index] &&
                          errors.tableData[index].includes("narration")
                        }
                            ? "border-primary"
                            : ""`}
                      />
                    </td>
                    <td className=" text-gray-900  px-6 py-2 whitespace-nowrap text-center ">
                      <Tooltip title="Delete" placement="right-start" arrow>
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            margin: "auto",
                            marginBottom: 15,
                          }}
                        >
                          <MdDeleteForever
                            style={IconStyle}
                            onClick={() => handelDeleteRow(index)}
                          />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="9"
                  height="50"
                  className="text-red-500 font-light text-center border"
                >
                  No row found /Add a new row
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-between">
        <Pagination size="xs" layout={["total"]} total={data.length} />
        <div className="flex space-x-2 justify-end items-end mt-10">
          <Button
            className="mb-5"
            title="Submit"
            paddingX="4"
            paddingY="1"
            attrs={{ onClick: () => handelSubmit(), disabled: !data.length }}
          />
        </div>
      </div>
      <Modal size="xs" open={open} onClose={() => setOpen(false)}>
        <Modal.Header>Add new vendor</Modal.Header>
        <Modal.Body>
          <AddVendor
            callBack={onAddVendor}
            handleClose={(e) => {
              setOpen(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InventoryTable;
