import React, { useState } from "react";
import Button from "../../../components/Button";
import { AppHeading } from "../../../components/AppComponents";
import CommentList from "./CommentList";
import CommentHeader from "./CommentHeader";

const Comments = ({
  addComment,
  commentList,
  loading,
  documents,
  comment,
  setComment,
  onChangeFileHandeler,
  setViewImage,
  totalCount,
  setPage,
  buttonLoading,
  imageLoading,
  viewDocument,
  removeFile,
}) => {
  const [error, setError] = useState("");
  return (
    <div className="mb-10">
      <AppHeading className="mb-2 mt-6" size="md" font="medium">
        Comment:
      </AppHeading>
      <CommentHeader
        comment={comment}
        setComment={setComment}
        error={error}
        buttonLoading={buttonLoading}
        documents={documents}
        onChangeFileHandeler={onChangeFileHandeler}
        addComment={addComment}
        removeFile={removeFile}
        setViewImage={setViewImage}
        setError={setError}
      />
      <CommentList
        data={commentList}
        loading={loading}
        viewDocument={viewDocument}
        totalCount={totalCount}
      />

      <div className="flex items-center justify-center">
        {commentList?.length < totalCount && (
          <Button
            title="Load more"
            size="sm"
            outline={true}
            display="inline-block"
            attrs={{
              style: { marginTop: "-5px" },

              onClick: () => setPage((prev) => prev + 1),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Comments;
