import React, { useState } from "react";
import { InputGroup } from "../components/form/InputGroup";
import { InputRadio } from "../components/form/InputRadio";
import { toast } from "react-toastify";
import { InputSelect } from "../components/form/InputSelect";
import { Loader } from "rsuite";
import { ApiPost, validateAadhaar } from "../lib/AppHelper";
import {
  isValidDrivingLicense,
  isValidPassport,
  isValidVoterId,
} from "./govIdValidation";
import moment from "moment";

const AddMember = ({ application_id, user_id, callback }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    dob: "",
    gender: "",
    member_type: "",
    gov_id_number: "",
    gov_id_type: "",
  });

  const onSubmit = async () => {
    let validateFailed = false;
    Object.keys(initialValues).forEach((key) => {
      let stringWithSpaces = key.replace(/_/g, " ");
      if (!initialValues[key]) {
        setErrors((prev) => ({
          ...prev,
          [key]: [`${stringWithSpaces} is required`],
        }));
        validateFailed = true;
      } else if (key == "name" && initialValues[key].length < 3) {
        setErrors((prev) => ({
          ...prev,
          [key]: [`${stringWithSpaces} is too short`],
        }));
        validateFailed = true;
      } else {
        setErrors((prev) => ({ ...prev, [key]: "" }));
      }
      if (key === "dob") {
        let today = moment();
        let date = moment(initialValues[key], "YYYY-MM-DD");
        let date100YearsAgo = moment()
          .subtract(100, "years")
          .format("YYYY-MM-DD");
        if (date.isAfter(today, "day")) {
          setErrors((prev) => ({
            ...prev,
            dob: ["date of barth is not greater than today"],
          }));
          validateFailed = true;
        }
        if (date.isBefore(date100YearsAgo, "day")) {
          setErrors((prev) => ({
            ...prev,
            dob: ["date of barth is not older than 100 years ago"],
          }));
          validateFailed = true;
        }
      }
      if (key === "gov_id_type") {
        if (
          initialValues[key] === "aadhar" &&
          !validateAadhaar(initialValues.gov_id_number)
        ) {
          setErrors((prev) => ({
            ...prev,
            gov_id_number: ["aadhar is not valid"],
          }));
          validateFailed = true;
        } else if (
          initialValues[key] === "voter" &&
          !isValidVoterId(initialValues.gov_id_number)
        ) {
          setErrors((prev) => ({
            ...prev,
            gov_id_number: ["voter id is not valid"],
          }));
          validateFailed = true;
        } else if (
          initialValues[key] === "passport" &&
          !isValidPassport(initialValues.gov_id_number)
        ) {
          setErrors((prev) => ({
            ...prev,
            gov_id_number: ["passport id is not valid"],
          }));
          validateFailed = true;
        }
      }
    });

    if (validateFailed) {
      return false;
    }
    setLoading(true);
    let payload = {
      name: initialValues.name,
      dob: initialValues.dob,
      gender: initialValues.gender,
      patient_type: initialValues.member_type,
      gov_id_number: initialValues.gov_id_number,
      gov_id_type: initialValues.gov_id_type,
      application_id: application_id,
      user: user_id,
    };
    const data = await ApiPost("add-member", payload);
    if (data.status === true) {
      setLoading(false);
      toast.success(data.message);
      callback(data);
    } else {
      setLoading(false);
      if (data?.message) toast.error(data?.message);
      else toast.error("Problem to add member");
    }
  };

  return (
    <div className="px-1">
      <InputGroup
        label="Name"
        placeholder="Full Name"
        starMark={true}
        value={initialValues.name}
        error={errors.name}
        attrs={{
          maxLength: 64,
          value: initialValues.name,
          onChange: (e) => {
            setInitialValues({ ...initialValues, name: e.target.value });
            setErrors({ ...errors, name: "" });
          },
          autoFocus: true,
        }}
      />
      <InputRadio
        label="Gender"
        requiredMark={true}
        starMark={true}
        error={errors.gender}
        value={initialValues.gender}
        attrs={{
          onChange: (e) => {
            setInitialValues({ ...initialValues, gender: e.target.value });
            setErrors({ ...errors, gender: "" });
          },
        }}
        options={[
          { label: "Male", value: "male" },
          { label: "Female", value: "female" },
          { label: "Other", value: "other" },
        ]}
      />
      <InputGroup
        label="Date of birth"
        placeholder="Date of Birth (DD/MM/YYYY)"
        starMark={true}
        requiredMark={true}
        value={initialValues.dob}
        attrs={{
          value: initialValues.dob,
          onChange: (e) => {
            setInitialValues({ ...initialValues, dob: e.target.value });
            setErrors({ ...errors, dob: "" });
          },
          max: moment(new Date()).format("YYYY-MM-DD"),
          min: moment().subtract(100, "years").format("YYYY-MM-DD"),
          autoFocus: true,
        }}
        error={errors.dob}
        type="date"
      />
      <InputSelect
        label="Member type"
        requiredMark={true}
        starMark={true}
        attrs={{
          value: initialValues.member_type,
          onChange: (e) => {
            setInitialValues({
              ...initialValues,
              member_type: e.target.value,
            });
            setErrors({ ...errors, member_type: "" });
          },
          autoFocus: true,
        }}
        value={initialValues.member_type}
        error={errors.member_type}
        options={[
          {
            label: "Select Id Type",
            value: "",
          },
          {
            label: "Parent",
            value: "parent",
          },
          {
            label: "Spouse",
            value: "spouse",
          },
          {
            label: "Daughter",
            value: "daughter",
          },
          {
            label: "Son",
            value: "son",
          },
        ]}
      />
      <InputSelect
        label="Government ID type"
        requiredMark={true}
        starMark={true}
        value={initialValues.gov_id_type}
        error={errors.gov_id_type}
        attrs={{
          value: initialValues.gov_id_type,
          onChange: (e) => {
            setInitialValues({ ...initialValues, gov_id_type: e.target.value });
            setErrors({ ...errors, gov_id_type: "" });
          },
          autoFocus: true,
        }}
        options={[
          {
            label: "Select Id Type",
            value: "",
          },
          {
            label: "AADHAR",
            value: "aadhar",
          },
          {
            label: "Voter ID",
            value: "voter",
          },
          {
            label: "Passport",
            value: "passport",
          },
        ]}
      />
      <InputGroup
        label="Government ID number"
        starMark={true}
        value={initialValues.gov_id_number}
        error={errors.gov_id_number}
        placeholder="Govt ID Number (XXX-XX-XXXX)"
        attrs={{
          value: initialValues.gov_id_number,
          onChange: (e) => {
            setInitialValues({
              ...initialValues,
              gov_id_number: e.target.value,
            });
            setErrors({ ...errors, gov_id_number: "" });
          },
          autoFocus: true,
        }}
      />
      {loading ? (
        <Loader size="md" />
      ) : (
        <button
          className="bg-red text-white p-2 rounded-md"
          type="submit"
          onClick={onSubmit}
        >
          Add Member
        </button>
      )}
    </div>
  );
};

export default AddMember;
