import React from "react";
import { CheckPicker, Checkbox, Button } from "rsuite";

const footerStyles = {
  padding: "10px 2px",
  borderTop: "1px solid #e5e5e5",
};

const footerButtonStyle = {
  float: "right",
  marginRight: 10,
  marginTop: 2,
};

export const CheckBox = ({
  id,
  data = [],
  value,
  setValue,
  showMore = false,
  showItems,
  setShowItems,
  fetchData,
  noMore,
  onSubmit,
  handleDataChange,
  style,
}) => {
  const picker = React.useRef();
  const checkerData = data?.map((item) => ({
    label: item.resellerName,
    value: item.id,
  }));
  const allValue = checkerData?.map((item) => item.value);

  const handleChange = (item, checked) => {
    if (checked) {
      setValue([...value, item]);
    } else {
      let index = value?.indexOf(item);
      if (index > -1) {
        let arr = value?.filter((v) => {
          if (v != item) {
            return true;
          }
        });
        setValue(arr);
      }
    }
  };

  const handleCheckAll = (value, checked) => {
    setValue(checked ? allValue : []);
  };

  const handleShowMore = () => {
    setShowItems((showItems) => showItems + 1);
    fetchData(id);
  };

  return (
    <div className="example-item">
      <CheckPicker
        data={checkerData}
        placeholder="Select"
        value={value}
        ref={picker}
        style={style}
        menuMaxHeight={100}
        renderMenu={(props) => {
          return props?.props?.data?.map((i, index) => {
            return (
              <div {...props}>
                <Checkbox
                  value={i.value}
                  checked={value?.indexOf(i.value) > -1}
                  onChange={(value, checked) => {
                    handleChange(value, checked);
                  }}
                >
                  {i.label}
                </Checkbox>
                {index + 1 == data?.length && showMore && noMore ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginRight: "auto",
                      padding: "5px",
                      cursor: "pointer",
                      color: "blue",
                      fontWeight: "500",
                      fontSize: "12px",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      handleShowMore();
                    }}
                  >
                    Show More
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          });
        }}
        renderExtraFooter={(props) => (
          <div style={footerStyles}>
            <Checkbox
              indeterminate={
                value?.length > 0 && value?.length < allValue?.length
              }
              checked={value.length == allValue.length}
              value={value}
              onChange={(value, checked) => handleCheckAll(value, checked)}
            >
              Check all
            </Checkbox>
            <Button
              style={footerButtonStyle}
              appearance="primary"
              size="sm"
              onClick={() => {
                picker.current.close();
              }}
            >
              Ok
            </Button>
          </div>
        )}
      />
    </div>
  );
};
