import React, { useEffect, useState } from "react";
import TextInput from "../../../StepClaimForm/TextInput";
import { Button, CircularProgress } from "@mui/material";
import { JavaApiGet, JavaApiPost } from "../../../../lib/AppHelper";
import moment from "moment";

const VendorPolicyUpload = ({ rowData, handelError, handleSuccess }) => {
  const [vendorPolicyCount, setVendorPolicyCount] = useState("");
  const [loading, setLoading] = useState();
  const [historyDetails, setHistoryDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState();

  async function saveData() {
    setLoading(true);
    const data = {
      batchId: rowData?.batchId,
      policyNumberUploadCount: vendorPolicyCount,
      vendorName: rowData?.vendorCode,
      productSpecification: rowData?.productSpecification,
    };
    try {
      const response = await JavaApiPost(
        "enrollment-service/report/api/vendor_policy_upload",
        data
      );
      if (response.code === 200) {
        setLoading(false);
        handleSuccess(response.message);
      } else {
        handelError(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  const getHistoryDetails = async () => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/upload_policy_status?batchId=${rowData?.batchId}`
      );
      if (response.code === 200) {
        if (response.responseObject.length === 0) setIsOpen(true);
        else setHistoryDetails(response?.responseObject);
      } else {
        setIsOpen(true);
        console.log(response.message);
      }
    } catch (error) {}
  };
  const fetchProductSpecificationData = async () => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/get_batch_details_specification_wise?batchId=${rowData.batchId}`
      );
      if (response.code === 200) {
        let product = response?.responseObject.find(
          (item) => item?.productSpecification === rowData.productSpecification
        );
        setProduct(product);
      } else {
      }
    } catch (error) {}
  };
  useEffect(() => {
    getHistoryDetails();
    fetchProductSpecificationData();
  }, []);
  return (
    <>
      {historyDetails.length > 0 &&
        historyDetails?.map((details) => {
          return (
            <div
              style={{
                width: "100%",
                padding: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

                borderRadius: "12px",
                marginBottom: "10px",
              }}
            >
              <div>
                Batch Id:&nbsp;
                <span style={{ fontWeight: "600" }}>{details?.batchId}</span>
              </div>
              <div>
                <span>
                  Policy uploaded:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {details?.policyUploaded}
                  </span>
                </span>{" "}
                &nbsp;&nbsp;
                <span>
                  Payment Date:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {moment(details?.paymentDate).format("DD-MM-YYYY")}
                  </span>
                </span>
              </div>
              <div>
                <span>
                  Vendor name:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {details?.vendorName}
                  </span>
                </span>{" "}
                &nbsp;&nbsp;
                <span>
                  Product specification:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {details?.productSpecification}
                  </span>
                </span>
              </div>
            </div>
          );
        })}
      {historyDetails.length > 0 && (
        <button
          onClick={() => setIsOpen(!isOpen)}
          style={{
            width: "100%",
            background: "#db2228",
            color: "white",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {!isOpen ? "Add new policy details" : "Close policy details"}
        </button>
      )}
      <div
        style={{
          visibility: isOpen ? "visible" : "hidden",
          marginTop: "10px",
        }}
        className="grid grid-cols-2 gap-4"
      >
        <TextInput
          label="Product specification"
          requiredMark
          attrs={{
            maxLength: 20,
            disabled: true,
            value: product?.productSpecification,
          }}
        />
        <TextInput
          label="Expected Nop"
          requiredMark
          attrs={{
            maxLength: 20,
            disabled: true,
            value: product?.totalPolicyCount,
          }}
        />
        <TextInput
          label="Number of policies enforced"
          requiredMark
          attrs={{
            value: vendorPolicyCount,
            onChange: (e) => {
              const value = e.target.value;
              if (
                value === "" ||
                (/^[1-9]\d*$/.test(value) &&
                  value <= product?.totalPolicyCount &&
                  value > 0)
              ) {
                setVendorPolicyCount(value);
              }
            },
          }}
        />
      </div>
      {isOpen && (
        <div style={{ width: "100%" }}>
          <Button
            onClick={saveData}
            variant="contained"
            style={{
              background: !vendorPolicyCount ? "grey" : "#DB2228",
              width: "100%",
            }}
            disabled={!vendorPolicyCount}
          >
            {loading ? (
              <CircularProgress size="20px" style={{ color: "white" }} />
            ) : (
              "SAVE"
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default VendorPolicyUpload;
