import React, { Component } from "react";
import { ApiGet } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { InputGroup } from "./form/InputGroup";
import Button from "./Button";
import _ from "lodash";

const Pagination = ({ pagination, onPage }) => {
  const { total, from, to, current, last } = pagination;
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{from}</span> to{" "}
            <span className="font-medium">{to}</span> of{" "}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => onPage(current === 1 ? false : current - 1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => onPage(current === last ? false : current + 1)}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export class AppDataTable extends Component {
  state = {
    items: [],
    pagination: {},
    limit: 10,
    page: 1,
    params: { ...this.props.params, q: "" },
  };

  render() {
    const { items, pagination, params } = this.state;
    const { type, filters, header, render } = this.props;
    const itemContainerClassName =
      type === "card"
        ? "flex-wrap flex justify-between"
        : "bg-white divide-y divide-gray-200";
    return (
      <div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto lg:-mx-8">
            <div className=" align-middle inline-block min-w-full ">
              <div className="flex flex-wrap justify-between">
                <div>{filters}</div>
                <div className="flex space-x-2 justify-end items-end">
                  <InputGroup
                    label="Search"
                    placeholder=""
                    attrs={{
                      value: params.q,
                      onChange: (e) =>
                        this.onChangeHandler(e.target.value, "q"),
                    }}
                    onEnter={this.search}
                  />
                  <Button
                    className="mb-5"
                    title="Search"
                    attrs={{ onClick: () => this.search() }}
                  />
                  {params.q ? (
                    <Button
                      className="mb-5"
                      outline={true}
                      title="Clear"
                      attrs={{
                        onClick: () => {
                          this.onChangeHandler("", "q", () => {
                            this.search();
                          });
                        },
                      }}
                    />
                  ) : (
                    false
                  )}
                </div>
              </div>
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  {header && (
                    <thead className="bg-gray-100">
                      <tr>
                        {header.map((th) => {
                          if (_.isObject(th)) {
                            return (
                              <th
                                key={th.title}
                                scope="col"
                                className={[
                                  `px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`,
                                  th.className,
                                ].join(" ")}
                              >
                                {th.title}
                              </th>
                            );
                          }
                          return (
                            <th
                              key={th}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {th}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                  )}
                  <tbody className={itemContainerClassName}>
                    {items && items.length ? (
                      items.map((item) => {
                        return <tr key={item.id}>{render(item)}</tr>;
                      })
                    ) : (
                      <div
                        className={"flex text-center row justify-center w-full"}
                      >
                        <span>No Data Found</span>
                      </div>
                    )}
                  </tbody>
                </table>
                {items.length ? (
                  <Pagination
                    pagination={pagination}
                    onPage={this.onPageChange}
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getItems();
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ page: page }, () => {
      this.getItems();
    });
  };

  getItems = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(this.props.endpoint, {
      page: this.state.page,
      ...this.state.params,
    });

    if (response.status === "success") {
      this.setState({
        items: response.data.data,
        pagination: {
          from: response.data.from,
          to: response.data.to,
          total: response.data.total,
          links: response.data.links,
          current: response.data.current_page,
          last: response.data.last_page,
        },
      });
    } else {
      this.props.MessageRef.current.show(response.message, "error");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  onChangeHandler = (v, key, callback = () => {}) => {
    const params = this.state.params;
    params[key] = v;
    this.setState({ params: params }, () => callback());
  };

  search = () => {
    this.setState({ page: 1 }, () => {
      this.getItems();
    });
  };

  componentDidUpdate(prevProp) {
    if (!_.isEqual(prevProp.params, this.props.params)) {
      this.setState(
        { params: { ...this.props.params, q: this.state.params.q } },
        () => this.getItems()
      );
    }
  }
}

export default React.forwardRef((props, ref) => (
  <AppDataTable innerRef={ref} {...props} />
));
