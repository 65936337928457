import React, { Component } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import _ from "lodash";
import {
  DaughterIcon,
  FemaleIcon,
  MaleIcon,
  ParentsIcon,
  RingIcon,
  SonIcon,
} from "../../components/AppIcons";
import { InputGroup } from "../../components/form/InputGroup";
import { InputRadio } from "../../components/form/InputRadio";
import { InputSelect } from "../../components/form/InputSelect";
import moment from "moment";
import { validatePolicyPuchDate } from "../../lib/AppHelper";

export class FamilyDetails extends Component {
  constructor(props) {
    super(props);
    let objectState = [];
    const members = this.makeForm();
    if (members.length) {
      objectState = members
        .map((item) => item.key)
        .filter((item) => item != "self");
    }
    this.state = {
      members,
      open: objectState,
      params: null,
    };
  }

  render() {
    let coApplicantsOptions = [];
    const { params, members } = this.state;
    const { govt_ids, value, minAge, maxAge, showMinAge, showMaxAge } =
      this.props;
    if (params === null) {
      return false;
    } else if (showMinAge && showMaxAge) {
      const nameKeys = Object.keys(params);
      nameKeys.map((item) => {
        if (params[item]?.name) {
          coApplicantsOptions.push({
            label: params[item]?.name,
            value: item,
          });
        }
      });
    }
    return (
      <div className="w-full">
        {members?.map((member) => {
          return (
            <div key={member.key}>
              <div
                onClick={() => {
                  let data = this.state.open;
                  if (this.state.open?.indexOf(member.key) > -1) {
                    data = this.state.open.filter((item) => item != member.key);
                  } else {
                    data?.push(member.key);
                  }
                  this.setState({ open: data });
                }}
                style={{
                  backgroundColor: "rgb(248 242 242)",
                  marginBottom: 15,
                }}
                className="flex justify-between w-full px-4 py-5 border-b border-gray-100 border-dashed last:border-b-0 font-medium text-left focus:outline-none focus:ring-2 focus:ring-primary-200 focus:ring-offset-2"
              >
                <div className="flex">
                  <span>{member.icon}</span>
                  <span className="ml-2">{member.title}</span>
                </div>
                <ChevronRightIcon
                  className={`${
                    this.state.open?.indexOf(member.key) > -1
                      ? "transform rotate-90"
                      : ""
                  } p-1 rounded-full w-6 h-6 text-primary shadow`}
                />
              </div>
              <div
                className="px-4 pt-4 pb-2 text-sm"
                style={{
                  display:
                    this.state.open?.indexOf(member.key) > -1
                      ? "block"
                      : "none",
                }}
              >
                {member.relation === "self" ? (
                  <InputGroup
                    label="Name"
                    placeholder="Enter name..."
                    attrs={{
                      value: value.name,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                ) : (
                  <>
                    <InputGroup
                      label="Name"
                      placeholder="Enter name..."
                      starMark={true}
                      attrs={{
                        value: params[member.key]?.name,
                        onChange: (e) => {
                          if (
                            /^[a-zA-Z\s]+$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            this.onChangeHandler(e, `${member.key}.name`);
                          }
                        },
                        maxLength: 32,
                      }}
                      validateError={(value) => {
                        if (value) {
                          return false;
                        } else {
                          return "Please enter name";
                        }
                      }}
                    />
                  </>
                )}
                {member.relation === "self" && (
                  <InputGroup
                    label="Phone no"
                    prefix="+91"
                    placeholder="Enter phone number..."
                    attrs={{
                      value: value.phone,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                )}

                {member.relation === "self" && (
                  <InputGroup
                    label="Email"
                    placeholder="Enter email..."
                    attrs={{
                      value: value.email,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                )}

                {member.relation === "self" && (
                  <InputGroup
                    label="Gender"
                    attrs={{
                      value:
                        value.gender === "f"
                          ? "Female"
                          : value.gender === "m"
                          ? "Male"
                          : "Others",
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                )}

                {member.relation === "self" ? (
                  <>
                    <InputGroup
                      label="Date of Birth"
                      attrs={{
                        type: "date",
                        value: value.dob,
                        readOnly: true,
                        disabled: true,
                        onKeyPress: (e) => e.preventDefault(),
                      }}
                    />
                  </>
                ) : (
                  <>
                    {params[member.key]?.coApplicant === true ? (
                      <InputGroup
                        label={`Date of Birth ${
                          showMinAge && showMaxAge
                            ? `(min age ${showMinAge}, max age ${showMaxAge})`
                            : ""
                        }`}
                        placeholder="DOB"
                        starMark={true}
                        attrs={{
                          type: "date",
                          value: params[member.key]?.dob,
                          onChange: (e) =>
                            this.onChangeHandler(e, `${member.key}.dob`),
                          min: maxAge,
                          max: minAge ? minAge : moment().format("YYYY-MM-DD"),
                        }}
                        valdiateOnChange={true}
                        validateError={(value) => {
                          if (validatePolicyPuchDate(value, minAge, maxAge)) {
                            return validatePolicyPuchDate(
                              value,
                              minAge,
                              maxAge
                            );
                          } else {
                            return false;
                          }
                        }}
                      />
                    ) : (
                      <InputGroup
                        label="Date of Birth"
                        placeholder="DOB"
                        starMark={true}
                        valdiateOnChange={true}
                        attrs={{
                          type: "date",
                          value: params[member.key]?.dob,
                          onChange: (e) =>
                            this.onChangeHandler(e, `${member.key}.dob`),
                          max: moment().format("YYYY-MM-DD"),
                          // onKeyPress: (e) => e.preventDefault(),
                        }}
                        validateError={(value) => {
                          const minAgeValue = moment(new Date()).format(
                            "YYYY-MM-DD"
                          );
                          const maxAgeValue = moment()
                            .subtract(100, "years")
                            .format("YYYY-MM-DD");

                          if (
                            validatePolicyPuchDate(
                              value,
                              minAgeValue,
                              maxAgeValue
                            )
                          ) {
                            return validatePolicyPuchDate(
                              value,
                              minAgeValue,
                              maxAgeValue
                            );
                          } else {
                            return false;
                          }
                        }}
                      />
                    )}
                  </>
                )}

                {member.relation === "self" ? (
                  <>
                    <InputGroup
                      width="full"
                      label="Govt ID Type"
                      attrs={{
                        value:
                          value.governmentIdType == "driving_licence"
                            ? "Driving License"
                            : value.governmentIdType,
                        readOnly: true,
                        disabled: true,
                      }}
                    />
                    <InputGroup
                      label="Govt ID Number"
                      placeholder="Enter Gov ID Number..."
                      attrs={{
                        value: value.governmentId,
                        readOnly: true,
                        disabled: true,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <InputSelect
                      width="full"
                      label="Govt ID Type"
                      requiredMark="true"
                      attrs={{
                        value: params[member?.key]?.govtIDType,
                        onChange: (e) => {
                          this.onChangeHandler(
                            e,
                            `${member.key}.govtIDType`,
                            `${member.key}.govtIDNumber`
                          );
                        },
                      }}
                      options={govt_ids}
                    ></InputSelect>
                    {params[member.key]?.govtIDType ? (
                      <InputGroup
                        label="Govt ID Number"
                        placeholder="Enter Gov ID Number..."
                        starMark={true}
                        attrs={{
                          value: params[member.key]?.govtIDNumber,
                          onChange: (e) => {
                            if (
                              /^[a-zA-Z0-9-_]+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              if (params[member.key].govtIDType === "aadhar") {
                                if (e.target.value.length < 13) {
                                  e.target.value = e.target.value.replace(
                                    /\D/,
                                    ""
                                  );
                                  this.onChangeHandler(
                                    e,
                                    `${member.key}.govtIDNumber`
                                  );
                                }
                              } else if (
                                params[member.key].govtIDType === "voter"
                              ) {
                                if (e.target.value.length < 15) {
                                  this.onChangeHandler(
                                    e,
                                    `${member.key}.govtIDNumber`
                                  );
                                }
                              } else if (
                                params[member.key].govtIDType === "passport"
                              ) {
                                if (e.target.value.length < 15) {
                                  this.onChangeHandler(
                                    e,
                                    `${member.key}.govtIDNumber`
                                  );
                                }
                              } else if (
                                params[member.key].govtIDType ===
                                "driving_licence"
                              ) {
                                if (e.target.value.length < 18) {
                                  this.onChangeHandler(
                                    e,
                                    `${member.key}.govtIDNumber`
                                  );
                                }
                              } else {
                                if (e.target.value.length < 20) {
                                  this.onChangeHandler(
                                    e,
                                    `${member.key}.govtIDNumber`
                                  );
                                }
                              }
                            }
                          },
                          maxLength: 20,
                        }}
                      />
                    ) : (
                      false
                    )}
                  </>
                )}

                {_.indexOf(["self"], member.relation) === -1 ? (
                  <InputRadio
                    label="Gender"
                    starMark={true}
                    name={`${member.key}.gender`}
                    options={[
                      { label: "Male", value: "m" },
                      { label: "Female", value: "f" },
                      { label: "Other", value: "o" },
                    ]}
                    value={params[member.key]?.gender}
                    attrs={{
                      onChange: (e) =>
                        this.onChangeHandler(e, `${member.key}.gender`),
                    }}
                  />
                ) : null}

                {member.relation === "self" && (
                  <InputGroup
                    label="Pincode"
                    attrs={{
                      value: value.pincode,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                )}

                {member.relation === "self" && value.pincode && (
                  <InputGroup
                    label="Your State"
                    placeholder="Enter state..."
                    attrs={{
                      value: value.state,
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                )}
                {members.length == 2 && showMaxAge && showMinAge ? (
                  <p>This member will be marked as a co-applicant.</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
        {coApplicantsOptions?.length > 0 && members.length > 2 && (
          <>
            <div
              style={{
                backgroundColor: "rgb(248 242 242)",
                marginBottom: 15,
              }}
              className="flex justify-between w-full px-4 py-5 border-b border-gray-100 border-dashed last:border-b-0 font-medium text-left focus:outline-none focus:ring-2 focus:ring-primary-200 focus:ring-offset-2"
            >
              <div className="flex">
                <span className="ml-2">Select co-applicant</span>
              </div>
            </div>

            <InputRadio
              label={
                <span className="font-semibold">
                  Which member would you like to choose as the co-applicant?
                </span>
              }
              starMark={true}
              name="co-applicant"
              options={coApplicantsOptions}
              value={params?.["coApplicants"]}
              attrs={{
                onChange: (e) => this.isCoApplicantHandler(e, `coApplicants`),
              }}
              inline={false}
              radioPositionStart={true}
            />
          </>
        )}
      </div>
    );
  }

  componentDidMount() {
    const members = this.makeForm();

    let value = this.props.value.members_detail;
    _.forEach(members, (member) => {
      if (member.relation !== "self") {
        if (
          members.length == 2 &&
          this.props.showMinAge &&
          this.props.showMaxAge
        ) {
          _.set(
            value,
            `${member.key}.coApplicant`,
            _.get(value, `${member.key}.coApplicant`, true)
          );
        }
        _.set(
          value,
          `${member.key}.relation`,
          _.get(value, `${member.key}.relation`, member.relation)
        );
        _.set(
          value,
          `${member.key}.name`,
          _.get(value, `${member.key}.name`, ``)
        );
        _.set(
          value,
          `${member.key}.gender`,
          _.get(value, `${member.key}.gender`, ``)
        );
        _.set(
          value,
          `${member.key}.dob`,
          _.get(value, `${member.key}.dob`, ``)
        );
        _.set(
          value,
          `${member.key}.govtIDType`,
          _.get(value, `${member.key}.govtIDType`, ``)
        );
        _.set(
          value,
          `${member.key}.govtIDNumber`,
          _.get(value, `${member.key}.govtIDNumber`, ``)
        );
      }
      if (_.indexOf(["self", "daughter", "son"], member.relation) === -1) {
        _.set(
          value,
          `${member.key}.gender`,
          _.get(value, `${member.key}.gender`, `m`)
        );
      }
      _.set(
        value,
        `${member.key}.height`,
        _.get(value, `${member.key}.height`, ``)
      );
      _.set(
        value,
        `${member.key}.weight`,
        _.get(value, `${member.key}.weight`, ``)
      );
    });

    this.setState({ params: value }, () => {
      this.props.onChange(value);
    });
  }

  makeForm = () => {
    const { value } = this.props;
    const {
      spouse,
      daughter,
      son,
      parents,
      // daughter_in_law,
      // sibling,
      // sister,
      // father_in_law,
      // mother_in_law,
      // son_in_law,
      // sister_in_law,
      // brother_in_law,
    } = value.members;
    const count = value.members_count;
    const members = [];

    members.push({
      key: "self",
      relation: "self",
      title: `Primary Member (${value.name})`,
      icon:
        value.gender === "m" ? (
          <MaleIcon size={24} />
        ) : (
          <FemaleIcon size={24} />
        ),
    });

    if (spouse) {
      members.push({
        key: "spouse",
        relation: "spouse",
        title: "Spouse",
        icon: <RingIcon size={24} />,
      });
    }

    if (daughter) {
      for (let i = 1; i <= count.daughter; i++) {
        members.push({
          key: `daughter_${i}`,
          relation: "daughter",
          title: count.daughter > 1 ? `Daughter ${i}` : `Daughter`,
          icon: <DaughterIcon size={24} />,
        });
      }
    }

    if (son) {
      for (let i = 1; i <= count.son; i++) {
        members.push({
          key: `son_${i}`,
          relation: "son",
          title: count.son > 1 ? `Son ${i}` : `Son`,
          icon: <SonIcon size={24} />,
        });
      }
    }

    if (parents) {
      for (let i = 1; i <= count.parents?.self; i++) {
        members.push({
          key: `self_parent_${i}`,
          relation: "parent",
          title:
            count.parents.self > 1 ? `Parent ${i} (Self)` : `Parent (Self)`,
          icon: <ParentsIcon size={24} />,
        });
      }

      for (let i = 1; i <= count.parents?.spouse; i++) {
        members.push({
          key: `spouse_parent_${i}`,
          relation: "spouse_parent",
          title:
            count.parents.spouse > 1
              ? `Parent ${i} (Spouse)`
              : `Parent (Spouse)`,
          icon: <ParentsIcon />,
        });
      }
    }

    return members;
  };

  onChangeHandler = (e, key, para) => {
    const { params } = this.state;
    params["self"].applicant = true;
    if (para) {
      _.set(params, para, "");
    }
    _.set(params, key, e.target.value);
    this.setState({ params: params }, () => {
      this.props.onChange(this.state.params);
    });
  };

  isCoApplicantHandler = (e, key) => {
    const { params, open } = this.state;
    _.set(params, key, e.target.value);
    const keys = Object.keys(params);
    let newParams = {};
    let data = open;
    keys.forEach((item) => {
      newParams[item] = params[item];
      if (typeof newParams[item] === "object") {
        if (item === e.target.value) {
          newParams[item].coApplicant = true;
          if (newParams[item]?.dob) {
            let bool = moment(newParams[item].dob).isBetween(
              this.props.maxAge,
              this.props.minAge
            );
            if (!bool) {
              if (open?.indexOf(e.target.value) > -1) {
                data = open.filter((item) => item != e.target.value);
              } else {
                data?.push(e.target.value);
              }
              newParams[item].dob = "";
            }
          }
        } else {
          newParams[item].coApplicant = false;
        }
      }
    });

    this.setState({ params: { ...newParams }, data }, () => {
      this.props.onChange(this.state.params);
    });
  };
}

export default FamilyDetails;
