import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  MobileStyle: {
    marginTop: "25px", // Change this value to increase/decrease the height
  },
  WebStyle: {
    width: "200px",
    // height: "400px", // Change this value to increase/decrease the height of each step
  },
});

export default function VerticalStepper({ steps, activeStep, isMobile }) {
  const classes = useStyles();
  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={isMobile ? classes.MobileStyle : classes.WebStyle}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{isMobile ? null : step.title}</StepLabel>
            <StepContent>
              <Box sx={{ mb: 25 }}></Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
