import React, { useState } from "react";
import { useEffect } from "react";

const TimerFromStartDate = ({ startDate }) => {
  const [elapsedTime, setElapsedTime] = useState("");

  useEffect(() => {
    const calculateElapsedTime = () => {
      const now = new Date();
      const then = new Date(startDate);

      const timeDifference = now - then;

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      const formattedTime = `${days > 0 ? days + "d :" : ""}
        ${hours > 0 ? hours.toString().padStart(2, "0") + "h :" : ""}
        ${minutes.toString().padStart(2, "0") + "m "}:
        ${seconds.toString().padStart(2, "0") + "s "}`;

      setElapsedTime(formattedTime);
    };

    // Calculate elapsed time on component mount
    calculateElapsedTime();

    // Update elapsed time every second
    const timerId = setInterval(calculateElapsedTime, 1000);

    // Clean up timer on component unmount
    return () => clearInterval(timerId);
  }, [startDate]);

  return <div>{elapsedTime}</div>;
};

export default TimerFromStartDate;
