import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import {
  ApiGet,
  getResellerEncryptionToken,
  JavaApiGet,
  postApiForCommonDownloadedReportExcel,
} from "../../lib/AppHelper";
import moment from "moment";
import { InputSelect } from "../../components/form/InputSelect";
import Button from "../../components/Button";
import InputDate from "../../components/form/InputDate";
import { Pagination, Modal } from "rsuite";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Tooltip } from "@mui/material";
import CommonDownloadedReports from "./CommonDownloadedReports";
import { toast } from "react-toastify";
import { reimbursementReportStatusWiseHeader } from "../../lib/constants";

export class ResellerClaimReport extends Component {
  MessageRef = React.createRef();
  downloadRef = React.createRef();
  payloadRef = React.createRef();
  downloadPayloadRef = React.createRef();
  state = {
    activeTab: "report",
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      status: "All",
      page: 1,
      count: 10,
      employee_id: "",
    },
    employees: [],
    Claim_type: [
      {
        value: "All",
        label: "All",
      },
      {
        value: "IN_PROGRESS",
        label: "IN PROGRESS",
      },

      {
        value: "REJECTED",
        label: "REJECTED",
      },
      {
        value: "QUERY",
        label: "QUERY",
      },
      {
        value: "SETTLED",
        label: "SETTLED",
      },
      {
        value: "Approved",
        label: "APPROVED",
      },
    ],
    toMaxDate: new Date(),
    claimSpecification: [],
    showModal: false,
    modalSize: window.innerWidth <= 768 ? "xs" : "sm",
  };

  switchTab = () => {
    this.setState((prev) => ({
      ...prev,
      activeTab: prev.activeTab === "download" ? "report" : "download",
    }));
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    if (key === "status") {
      this.setState({ params: { ...params, page: 1 } }, () => {
        this.getReports({ ...params, page: 1 });
      });
    } else {
      this.setState({ params: params });
    }
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    params["status"] = "All";
    params["specification_type"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  getSpecification = async () => {
    await ApiGet("claim-specification").then((res) => {
      if (res.code === 200) {
        const tempData = res?.data?.specification?.map((item) => ({
          label: `${item}`?.charAt(0)?.toUpperCase() + `${item}`?.slice(1),
          value: item,
        }));
        this.setState({
          ...this.state,
          claimSpecification:
            [
              {
                label: "All",
                value: "",
              },
              ...tempData,
            ] || [],
        });
      } else {
        this.setState({
          ...this.state,
          claimSpecification: [],
        });
      }
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      params,
      reports,
      employees,
      toMaxDate,
      Claim_type,
      activeTab,
      showModal,
      modalSize,
    } = this.state;
    const { user, headerDownload } = this.props;
    const { permissions } = user;
    const headers = reimbursementReportStatusWiseHeader[params?.status];

    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.claim_report_view ? (
          <>
            {headerDownload && (
              <Modal
                open={showModal}
                onClose={this.handleCloseModal}
                backdrop="static"
                size={modalSize}
                className={`text-center ${modalSize ? "top-1/3" : "top-52"}`}
              >
                <Modal.Body>{showModal}</Modal.Body>
                <div className="w-20 mx-auto">
                  <Button
                    attrs={{
                      type: "button",
                      onClick: this.handleCloseModal,
                    }}
                    size="sm"
                    title={"Ok"}
                  />
                </div>
              </Modal>
            )}
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/">Back</AppBackButton>
              </div>
            </div>
            <div className="mb-6 flex justify-start space-x-4">
              <button
                className={`px-4 py-2 w-64 ${
                  activeTab === "report"
                    ? "border-b-4 border-green-900 text-green-900"
                    : "#000000"
                }`}
                onClick={this.switchTab}
              >
                Reimbursements Report
              </button>
              <button
                className={`px-4 py-2 w-64 ${
                  activeTab === "download"
                    ? "border-b-4 border-green-900 text-green-900"
                    : "#000000"
                }`}
                onClick={this.switchTab}
              >
                Downloaded Reports
              </button>
            </div>
            {activeTab === "report" ? (
              <div>
                <AppHeading
                  className="mb-10 text-center"
                  style={{ color: "#DB2228" }}
                >
                  Reimbursements Report
                </AppHeading>

                <div className="grid  md:grid-cols-5 gap-4 md:pb-4 pb-8">
                  <div>
                    <label className="flex text-sm text-gray-700 justify-left pb-2 mt-1">
                      Select team
                    </label>
                    <select
                      onChange={(e) => this.onChangeHandler(e, "employee_id")}
                      value={params.employee_id}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                    >
                      <option value="">Me</option>
                      {employees.map((option, index) => {
                        return (
                          <option value={option.id} key={option.id}>
                            {option.name} ({option.mobile})
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <InputSelect
                      requiredMark=""
                      value={params.status}
                      attrs={{
                        value: params.status,
                        onChange: (e) => this.onChangeHandler(e, "status"),
                      }}
                      name="status"
                      label="Reimbursement status"
                      options={Claim_type}
                    />
                  </div>
                  <div>
                    <InputSelect
                      requiredMark=""
                      value={params.specification_type}
                      attrs={{
                        value: params.specification_type,
                        onChange: (e) =>
                          this.onChangeHandler(e, "specification_type"),
                      }}
                      name="specification_type"
                      label="Reimbursement type"
                      options={this.state?.claimSpecification}
                      showSelectOption={false}
                    />
                  </div>
                  <div>
                    <InputDate
                      label="From"
                      value={params.from}
                      placeholder="from"
                      maxDate={new Date(params.to)}
                      attrs={{
                        onChange: (e) => this.onChangeHandlerDate(e, "from"),
                        value: new Date(params.from),
                      }}
                    />
                  </div>
                  <div>
                    <InputDate
                      label="To"
                      placeholder="To"
                      minDate={new Date(params.from)}
                      maxDate={new Date()}
                      attrs={{
                        onChange: (e) => this.onChangeHandlerDate(e, "to"),
                        value: new Date(params.to),
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-end items-center pb-8 gap-4">
                  <Tooltip
                    title={`${
                      headerDownload
                        ? "Can't refresh during downloading"
                        : "Click here to refresh"
                    }`}
                    arrow
                  >
                    <span>
                      <Button
                        size="sm"
                        title={""}
                        attrs={{
                          type: "button",
                          onClick: this.handleClickReset,
                          disabled: headerDownload,
                        }}
                        className={`${headerDownload ? "disabled-button" : ""}`}
                      >
                        <RefreshIcon
                          sx={{
                            color: "#ffffff",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip title={"Search"} arrow>
                    <span>
                      <Button
                        attrs={{ type: "button", onClick: this.handleClick }}
                        size="sm"
                        title={"Search"}
                      />
                    </span>
                  </Tooltip>

                  <Tooltip
                    title={`${
                      headerDownload
                        ? "Download already in progress"
                        : "Click here to download"
                    }`}
                    arrow
                  >
                    <span>
                      <Button
                        size="sm"
                        title={"Download"}
                        attrs={{
                          type: "button",
                          onClick: this.downloadClick,
                          disabled: headerDownload,
                        }}
                        className={`${headerDownload ? "disabled-button" : ""}`}
                      />
                    </span>
                  </Tooltip>
                </div>

                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-x-auto">
                        <table className="min-w-full border" id="table-to-xls">
                          <thead className="border-b">
                            <tr>
                              {headers.map((item, index) => (
                                <th
                                  key={index}
                                  className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left"
                                >
                                  {item?.label}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {reports?.map((record, idx) => {
                              return (
                                <tr
                                  className={`border-b ${
                                    reports["internal_status"] == "SETTELED" ||
                                    reports["actual_status"] == "SETTELED" ||
                                    reports["actual_status"] == "SETTELED"
                                      ? "bg-green-100"
                                      : ""
                                  }
                                ${
                                  reports["internal_status"] == "REJECT"
                                    ? "bg-red-100"
                                    : ""
                                }
                                ${
                                  reports["external_status"] == "QUERY"
                                    ? "bg-yellow-100"
                                    : ""
                                }`}
                                  key={idx}
                                >
                                  {headers.map((item, index) => (
                                    <td
                                      className="text-sm  font-bigger px-6 py-4 whitespace-nowrap"
                                      key={index}
                                    >
                                      {record[item?.value] ||
                                      record[item?.optional]
                                        ? item.type === "date"
                                          ? moment(record[item?.value]).format(
                                              "DD, MMM YYYY"
                                            )
                                          : record[item?.value] ||
                                            record[item?.optional]
                                        : ""}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                          {reports.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                height="50"
                                className="text-red-500 font-light text-center"
                              >
                                Your result is empty
                              </td>
                            </tr>
                          ) : (
                            false
                          )}
                        </table>
                        <div style={{ padding: 20 }}>
                          <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={2}
                            size="xs"
                            layout={this.state.layout}
                            total={this.state.total}
                            limit={this.state.limit}
                            activePage={this.state.params.page}
                            onChangePage={this.onPageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <CommonDownloadedReports
                title="Downloaded Reports"
                reportName="Reimbursements Report"
              />
            )}
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You do not have permission to view reimbursements report</h4>
            </div>
          </div>
        )}
      </Main>
    );
  }

  componentDidMount() {
    this.getReports();
    this.getTeam();
    this.getSpecification();
    if (this.props?.location?.state) {
      this.setState((prev) => ({
        ...prev,
        activeTab: this.props?.location?.state,
      }));
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize); // Clean up the event listener
  }

  handleClick = () => {
    this.onPageChange(1);
  };

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  downloadClick = () => {
    let payload = {
      startDate: this.state.params?.from,
      endDate: this.state.params?.to,
      reportType: "download",
      status:
        this.state?.params?.status === "All" ? "" : this.state?.params?.status,
      resellerId: this.state?.params?.employee_id,
      specificationType: this?.state?.params?.specification_type || "",
      reportName: "Reimbursements Report",
    };

    if (this.downloadPayloadRef.current !== JSON.stringify(payload)) {
      this.downloadPayloadRef.current = JSON.stringify(payload);
      this.DownloadReports(payload);
    }
  };

  checkDownloadStatus = async () => {
    const res = await JavaApiGet(
      "enrollment-service/excel_report/check_excel_record",
      false,
      false,
      getResellerEncryptionToken()
    );
    if (res?.responseObject?.status === "Complete") {
      clearInterval(this?.downloadRef?.current);
      toast.success(res?.message);
      AppStore.dispatch({ type: "IS_HEADER_DOWNLOAD", headerDownload: false });
      AppStore.dispatch({
        type: "UPDATE_DOWNLOAD_LIST",
        updateDownloadList: !this.props.updateDownloadList,
      });
    }
  };

  DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "IS_HEADER_DOWNLOAD", headerDownload: true });
    const response = await postApiForCommonDownloadedReportExcel(
      "enrollment-service/excel_report/reimbursementDownloadExcel",
      request,
      "reimbursementsReport.csv"
    );

    const contentType = response?.headers.get("Content-Type");
    if (contentType === "application/json") {
      const res = await response?.json();
      if (res?.code === 200 && res?.count === 0) {
        toast.success(res?.message);
        AppStore.dispatch({
          type: "IS_HEADER_DOWNLOAD",
          headerDownload: false,
        });
        return;
      }
      this.setState({ showModal: res?.message });
      this.downloadRef.current = setInterval(() => {
        this.checkDownloadStatus();
      }, 3000);
    } else {
      AppStore.dispatch({
        type: "IS_HEADER_DOWNLOAD",
        headerDownload: false,
      });
      const blob = await response.blob();
      if (blob) {
        // Create blob link to download
        const urls = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = urls;
        link.setAttribute("download", "reimbursementsReport.csv");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        toast.success("Report downloaded successfully");
      } else {
        toast.info("No data found");
      }
    }
  };

  getReports = async (request) => {
    const requestData = {
      ...request,
      status:
        this.state?.params?.status === "All" ? "" : this.state?.params?.status,
    };

    if (this.payloadRef.current !== JSON.stringify(requestData)) {
      this.payloadRef.current = JSON.stringify(requestData);
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("report/reseller-claims", requestData);
      if (response?.status === "success") {
        if (response?.data?.data) {
          const items = Object.values(response?.data?.data);
          this.setState({ reports: items });
          this.setState({ count: response?.data });
          this.setState({ total: response?.data?.total });
        } else {
          this.state.reports = [];
        }
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, user, headerDownload } = state;
  return { loading: loading, user: user, headerDownload: headerDownload };
};

const ResellerClaimReportConnect = connect(mapStateToProps)((props) => {
  return <ResellerClaimReport {...props} />;
});

export default withRouter(ResellerClaimReportConnect);
