import React, { memo } from "react";
import { XIcon } from "@heroicons/react/outline";

const FileSelector = ({
  file,
  filename,
  onChangeHandle,
  onDeleteHandler,
  label = "Upload File ",
  accept = "",
}) => {
  return (
    <div className="flex flex-col justify-center items-center mt-10">
      <label>
        {!file ? label : "File Name"}
        <code className="text-red">*</code>
      </label>
      <div className="flex items-center justify-center bg-grey-lighter mt-5">
        <label
          className={`relative flex pl-3 items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-red ${
            !filename ? "cursor-pointer" : ""
          } hover:bg-red hover:text-white`}
        >
          {!filename ? (
            <>
              <svg
                className="w-8 h-8 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span className="mt-1 mr-2 text-base leading-normal">
                Select a file
              </span>
              <input
                type="file"
                value={file}
                onChange={onChangeHandle}
                className="hidden"
                name="file"
                accept={accept}
              />
            </>
          ) : (
            <>
              <div className="m-1 mr-3 flex relative">
                {filename.length > 30 ? (
                  <span className="text-sm">
                    {filename.substring(0, 30)}...
                  </span>
                ) : (
                  <span className="text-sm">{filename}</span>
                )}
              </div>
            </>
          )}
        </label>
        {filename ? (
          <span>
            <XIcon
              className="h-6 w-6 ml-3 text-red cursor-pointer"
              onClick={onDeleteHandler}
            />
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default memo(FileSelector);
