import React, { Component, Fragment, useCallback } from "react";
import { AppBackButton, AppLabel } from "../../components/AppComponents";
import { AppDataTable } from "../../components/AppDataTable";
import { InputSelect } from "../../components/form/InputSelect";
import { ApiGet, ApiPost, setError, setMessage } from "../../lib/AppHelper";
import AdminMain from "./AdminMain";
import { Dialog, Transition } from "@headlessui/react";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { XIcon } from "@heroicons/react/outline";
import Button from "../../components/Button";
import _ from "lodash";

const RESELLER_TYPES = [
  {
    value: "",
    label: "All",
  },
  {
    value: 1,
    label: "Individual",
  },
  {
    value: 2,
    label: "MLM",
  },
  {
    value: 3,
    label: "MFI",
  },
];

let ItemAction = ({ products, reseller, updateProductStatus }) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [selected, setSelected] = React.useState(
    _.map(reseller.prods, "product_id")
  );

  const [saveDisable, setSaveDisable] = React.useState(false);

  const selprod = {};
  _.each(reseller.prods, (prod) => {
    _.set(selprod, `product_${prod.product_id}`, prod);
  });

  const [selproducts, setSelproducts] = React.useState(selprod);

  React.useEffect(() => {
    setSelected(_.map(reseller.prods, "product_id"));
    return () => { };
  }, [open]);

  const onChangeNotification = useCallback(
    (e, id) => {
      setSelproducts((prevValueNotification) => {
        const updatedNotificationValue = {
          ...prevValueNotification,
          [`product_${id}`]: {
            ...prevValueNotification[`product_${id}`],
            notification_on_reg: e.target.checked ? "Y" : "N",
          },
        };

        return updatedNotificationValue;
        // setSelproducts(
        //   updatedNotificationValue
        // );
      });
    },
    [setSelproducts, selproducts]
  );

  const updateSelection = (product_id, price = "") => {
    if (selected && selected.indexOf(product_id) > -1) {
      setSelected(selected.filter((p) => p !== product_id));
    } else {
      setSelected([...selected, product_id]);
    }
    setSelproducts((prevValueInvoice) => {
      const updatedInvoiceValue = {
        ...prevValueInvoice,
        [`product_${product_id}`]: {
          ...prevValueInvoice[`product_${product_id}`],
          product_id: product_id,
          reseller_id: reseller.id,
          selling_amount: price,
        },
      };
      return updatedInvoiceValue;
    });
  };

  const toggleAll = () => {
    if (selected.length === products.length) {
      setSelected([]);
    } else {
      setSelected(_.map(products, "id"));
    }
  };

  const buttonRef = React.createRef();

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {reseller.prods ? reseller.prods.length : 0} Product
        {reseller.prods === null || reseller.prods.length === 1 ? `` : `s`}
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          initialFocus={buttonRef}
          as="div"
          static
          className="fixed inset-0 overflow-hidden"
          open={open}
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6 py-6 bg-gray-50">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {reseller.name} | Products
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 overflow-hidden">
                      <div className="max-h-full overflow-y-auto">
                        <div>
                          <div className="flex items-center border-b border-gray-300">
                            <div className="flex-1">
                              <input
                                type="text"
                                className="w-full border-0 py-3 px-4 rounded-t-lg focus:outline-none focus:ring-0"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search"
                              />
                            </div>
                            <div className="px-2">
                              <input
                                checked={selected.length === products.length}
                                onChange={toggleAll}
                                type="checkbox"
                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                              />
                            </div>
                          </div>
                          <div className="px-2 border-gray-200 divide-y divide-gray-200">
                            {_.filter(
                              products,
                              (item) =>
                                item.name
                                  .toUpperCase()
                                  .indexOf(search.toUpperCase()) > -1
                            ).map((product) => {
                              var selprod =
                                _.get(selproducts, `product_${product.id}`, {
                                  product_id: product.id,
                                  selling_amount: product.price,
                                  payment_mode: product.payment_mode,
                                  notification_on_reg: "N",
                                  invoice_on_reg: "N",
                                }) || {};

                              return (
                                <div key={product.id}>
                                  <div className="relative flex items-center">
                                    <div className="min-w-0 flex-1 text-sm">
                                      <label
                                        htmlFor={`product-${product.id}`}
                                        className={`py-4 block font-medium select-none ${selected &&
                                          selected.indexOf(product.id) > -1
                                          ? "text-black"
                                          : "text-gray-400"
                                          }`}
                                      >
                                        {product.name}
                                        {product.payment_mode == "NA" ? (
                                          <small className="text-red-300">
                                            <br></br>
                                            This is free plan
                                          </small>
                                        ) : (
                                          false
                                        )}
                                      </label>
                                    </div>
                                    <div className="ml-3 flex items-center h-5">
                                      <input
                                        id={`product-${product.id}`}
                                        name={`product-${product.id}`}
                                        onChange={() =>
                                          updateSelection(
                                            product.id,
                                            product.payment_mode != "NA"
                                              ? product.price
                                              : 0
                                          )
                                        }
                                        checked={
                                          selected
                                            ? selected.indexOf(product.id) > -1
                                            : false
                                        }
                                        type="checkbox"
                                        className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-gray-300 rounded"
                                      />
                                    </div>
                                  </div>
                                  {selected &&
                                    selected.indexOf(product.id) > -1 ? (
                                    <>
                                      <div className="mb-3">
                                        <div className="space-y-6 sm:space-y-5">
                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Selling Amount
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                disabled={
                                                  (selprod.payment_mode ||
                                                    product.payment_mode) ==
                                                    "NA"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(en) => {
                                                  setSelproducts(
                                                    (prevValue) => {
                                                      const updatedValue = {
                                                        ...prevValue,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValue[
                                                          `product_${product.id}`
                                                          ],
                                                          selling_amount:
                                                            en.target.value,
                                                        },
                                                      };
                                                      return updatedValue;
                                                    }
                                                  );
                                                }}
                                                value={
                                                  (selprod.payment_mode ||
                                                    product.payment_mode) ==
                                                    "NA"
                                                    ? 0
                                                    : selprod.selling_amount
                                                      ? selprod.selling_amount
                                                      : null
                                                }
                                                type="text"
                                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                              />
                                            </div>
                                          </div>

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Notification On Reg
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(e) => {
                                                  onChangeNotification(
                                                    e,
                                                    product.id
                                                  );
                                                }}
                                                checked={
                                                  selprod.notification_on_reg ==
                                                  "Y"
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Invoice On Reg
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(en) => {
                                                  setSelproducts(
                                                    (prevValueInvoice) => {
                                                      const updatedInvoiceValue =
                                                      {
                                                        ...prevValueInvoice,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValueInvoice[
                                                          `product_${product.id}`
                                                          ],
                                                          invoice_on_reg: en
                                                            .target.checked
                                                            ? "Y"
                                                            : "N",
                                                        },
                                                      };
                                                      return updatedInvoiceValue;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  selprod.invoice_on_reg === "Y"
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Special Product
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(en) => {
                                                  setSelproducts(
                                                    (prevValueInvoice) => {
                                                      const updatedSpecialProductValue =
                                                      {
                                                        ...prevValueInvoice,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValueInvoice[
                                                          `product_${product.id}`
                                                          ],
                                                          special_product:
                                                            en.target
                                                              .checked
                                                              ? 1
                                                              : 0,
                                                        },
                                                      };
                                                      return updatedSpecialProductValue;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  selprod.special_product == 1
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Commission Applicable
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(en) => {
                                                  setSaveDisable(
                                                    en.target.checked
                                                      ? true
                                                      : false
                                                  );

                                                  setSelproducts(
                                                    (prevValue) => {
                                                      const updatedCommissionApplicableValue =
                                                      {
                                                        ...prevValue,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValue[
                                                          `product_${product.id}`
                                                          ],
                                                          commission_applicable:
                                                            en.target
                                                              .checked
                                                              ? "1"
                                                              : "0",
                                                          reseller_commission:
                                                            en.target
                                                              .checked
                                                              ? selprod.reseller_commission
                                                              : 0,
                                                        },
                                                      };
                                                      return updatedCommissionApplicableValue;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  selprod.commission_applicable ==
                                                  1
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                          {selprod.commission_applicable ==
                                            "1" ? (
                                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                              <label className="block text-sm font-medium text-gray-700 col-span-2">
                                                Reseller Commission
                                              </label>
                                              <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
                                                <input
                                                  onKeyDown={(evt) =>
                                                    evt.key === "e" &&
                                                    evt.preventDefault()
                                                  }
                                                  onChange={(en) => {
                                                    if (
                                                      en.target.value.length > 0
                                                    ) {
                                                      setSaveDisable(false);
                                                    } else {
                                                      setSaveDisable(true);
                                                    }
                                                    // accept only numbers
                                                    if (
                                                      en.target.value.length >
                                                      0 &&
                                                      isNaN(en.target.value)
                                                    ) {
                                                      en.target.value = "";
                                                    }
                                                    // not accept nagative value
                                                    if (en.target.value < 0) {
                                                      en.target.value = 0;
                                                    }

                                                    // not accepted value more then 100
                                                    if (
                                                      en.target.value >= 100
                                                    ) {
                                                      en.target.value =
                                                        en.target.value.slice(
                                                          0,
                                                          2
                                                        );
                                                    }

                                                    // not accepted more then two decimal places
                                                    const value =
                                                      en.target.value;
                                                    const newValue =
                                                      value.replace(
                                                        /[^0-9.]/g,
                                                        ""
                                                      );
                                                    const newValueArr =
                                                      newValue.split(".");
                                                    if (
                                                      newValueArr.length > 1
                                                    ) {
                                                      if (
                                                        newValueArr[1].length >
                                                        2
                                                      ) {
                                                        en.target.value =
                                                          newValueArr[0] +
                                                          "." +
                                                          newValueArr[1].slice(
                                                            0,
                                                            2
                                                          );
                                                      }
                                                    }

                                                    setSelproducts(
                                                      (prevValue) => {
                                                        const updatedValue = {
                                                          ...prevValue,
                                                          [`product_${product.id}`]:
                                                          {
                                                            ...prevValue[
                                                            `product_${product.id}`
                                                            ],
                                                            reseller_commission:
                                                              en.target.value,
                                                          },
                                                        };
                                                        return updatedValue;
                                                      }
                                                    );
                                                  }}
                                                  value={
                                                    selprod.reseller_commission
                                                  }
                                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md p-2 border-2"
                                                />
                                                <span
                                                  style={{
                                                    position: "relative",
                                                    zIndex: 1,
                                                    top: 9,
                                                    right: 17,
                                                  }}
                                                >
                                                  %
                                                </span>
                                              </div>
                                            </div>
                                          ) : null}

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Referral Applicable
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(en) => {
                                                  setSaveDisable(
                                                    en.target.checked
                                                      ? true
                                                      : false
                                                  );

                                                  setSelproducts(
                                                    (prevValue) => {
                                                      const updatedReferralApplicable =
                                                      {
                                                        ...prevValue,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValue[
                                                          `product_${product.id}`
                                                          ],
                                                          referral_applicable:
                                                            en.target
                                                              .checked
                                                              ? "1"
                                                              : "0",
                                                          discounted_amount:
                                                            en.target
                                                              .checked
                                                              ? selprod.discounted_amount
                                                              : 0,
                                                        },
                                                      };
                                                      return updatedReferralApplicable;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  selprod.referral_applicable ==
                                                  1
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                          {selprod.referral_applicable ==
                                            "1" ? (
                                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                              <label className="block text-sm font-medium text-gray-700 col-span-2">
                                                Discounted Amount
                                              </label>
                                              <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
                                                <input
                                                  onKeyDown={(evt) =>
                                                    evt.key === "e" &&
                                                    evt.preventDefault()
                                                  }
                                                  onChange={(en) => {
                                                    if (
                                                      en.target.value.length > 0
                                                    ) {
                                                      setSaveDisable(false);
                                                    } else {
                                                      setSaveDisable(true);
                                                    }
                                                    // accept only numbers
                                                    if (
                                                      en.target.value.length >
                                                      0 &&
                                                      isNaN(en.target.value)
                                                    ) {
                                                      en.target.value = "";
                                                    }
                                                    // not accept nagative value
                                                    if (en.target.value < 0) {
                                                      en.target.value = 0;
                                                    }

                                                    // not accepted value more then 100
                                                    if (
                                                      en.target.value >
                                                      selprod.selling_amount
                                                    ) {
                                                      en.target.value =
                                                        selprod.selling_amount;
                                                    }

                                                    // not accepted more then two decimal places
                                                    const value =
                                                      en.target.value;
                                                    const newValue =
                                                      value.replace(
                                                        /[^0-9.]/g,
                                                        ""
                                                      );
                                                    const newValueArr =
                                                      newValue.split(".");
                                                    if (
                                                      newValueArr.length > 1
                                                    ) {
                                                      if (
                                                        newValueArr[1].length >
                                                        2
                                                      ) {
                                                        en.target.value =
                                                          newValueArr[0] +
                                                          "." +
                                                          newValueArr[1].slice(
                                                            0,
                                                            2
                                                          );
                                                      }
                                                    }

                                                    setSelproducts(
                                                      (prevValue) => {
                                                        const updatedValue = {
                                                          ...prevValue,
                                                          [`product_${product.id}`]:
                                                          {
                                                            ...prevValue[
                                                            `product_${product.id}`
                                                            ],
                                                            discounted_amount:
                                                              en.target.value,
                                                          },
                                                        };
                                                        return updatedValue;
                                                      }
                                                    );
                                                  }}
                                                  value={
                                                    selprod.discounted_amount
                                                  }
                                                  className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md p-2 border-2"
                                                />
                                              </div>
                                            </div>
                                          ) : null}

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Lab Test Applicable
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(en) => {
                                                  setSaveDisable(
                                                    en.target.checked
                                                      ? true
                                                      : false
                                                  );

                                                  setSelproducts(
                                                    (prevValue) => {
                                                      const labTestBenefitApplicable =
                                                      {
                                                        ...prevValue,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValue[
                                                          `product_${product.id}`
                                                          ],
                                                          lab_test_benefit_applicable:
                                                            en.target
                                                              .checked
                                                              ? "1"
                                                              : "0",
                                                          discounted_amount:
                                                            en.target
                                                              .checked
                                                              ? selprod.discounted_amount
                                                              : 0,
                                                        },
                                                      };
                                                      return labTestBenefitApplicable;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  selprod.lab_test_benefit_applicable ==
                                                  1
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                          {selprod.lab_test_benefit_applicable ==
                                            "1" ? (
                                            <>
                                              <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                                <label className="block text-sm font-medium text-gray-700 col-span-2">
                                                  Lab Test Name
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
                                                  <input
                                                    onChange={(en) => {
                                                      //accept only string
                                                      // if (!/[a-z]$/.test(en.target.value)) {
                                                      //   console.log(en.target.value,'hhhhhhh');
                                                      //   en.target.value = en.target.value.slice(0, -1);
                                                      // }
                                                      setSelproducts(
                                                        (prevValue) => {
                                                          let updatedValue = {
                                                            ...prevValue,
                                                            [`product_${product.id}`]:
                                                            {
                                                              ...prevValue[
                                                              `product_${product.id}`
                                                              ],
                                                              lab_test_name:
                                                                en.target
                                                                  .value,
                                                            },
                                                          };
                                                          return updatedValue;
                                                        }
                                                      );
                                                      if (
                                                        en.target.value && selproducts[`product_${product.id}`].lab_test_limit && selproducts[`product_${product.id}`].lab_test_limit
                                                      ) {
                                                        setSaveDisable(false);
                                                      } else {
                                                        setSaveDisable(true);
                                                      }
                                                    }}
                                                    value={
                                                      selprod.lab_test_name
                                                    }
                                                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md p-2 border-2"
                                                  />
                                                </div>
                                              </div>
                                              <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                                <label className="block text-sm font-medium text-gray-700 col-span-2">
                                                  Lab Test Description
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
                                                  <textarea
                                                    onChange={(en) => {

                                                      //accept only string
                                                      // if (!/[a-z]$/.test(en.target.value)) {
                                                      //   en.target.value = en.target.value.slice(0, -1);
                                                      // }

                                                      setSelproducts(
                                                        (prevValue) => {
                                                          let updatedValue = {
                                                            ...prevValue,
                                                            [`product_${product.id}`]:
                                                            {
                                                              ...prevValue[
                                                              `product_${product.id}`
                                                              ],
                                                              lab_test_description:
                                                                en.target
                                                                  .value,
                                                            },
                                                          };
                                                          return updatedValue;
                                                        }
                                                      );
                                                      if (
                                                        en.target.value && selproducts[`product_${product.id}`].lab_test_limit && selproducts[`product_${product.id}`].lab_test_name
                                                      ) {
                                                        setSaveDisable(false);
                                                      } else {
                                                        setSaveDisable(true);
                                                      }
                                                    }}
                                                    value={
                                                      selprod.lab_test_description
                                                    }
                                                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md p-2 border-2"
                                                  />
                                                </div>
                                              </div>
                                              <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                                <label className="block text-sm font-medium text-gray-700 col-span-2">
                                                  Lab Test Limit
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
                                                  <input
                                                    onKeyDown={(evt) =>
                                                      evt.key === "e" &&
                                                      evt.preventDefault()
                                                    }
                                                    onChange={(en) => {
                                                      // accept only numbers
                                                      if (
                                                        en.target.value.length >
                                                        0 &&
                                                        isNaN(en.target.value)
                                                      ) {
                                                        en.target.value = "";
                                                      }
                                                      // not accept nagative value
                                                      if (en.target.value < 0) {
                                                        en.target.value = 0;
                                                      }

                                                      // not accepted value more then 100
                                                      if (
                                                        en.target.value > 10
                                                      ) {
                                                        en.target.value = 10;
                                                      }

                                                      // not accepted more then two decimal places
                                                      const value =
                                                        en.target.value;
                                                      const newValue =
                                                        value.replace(
                                                          /[^0-9.]/g,
                                                          ""
                                                        );
                                                      const newValueArr =
                                                        newValue.split(".");
                                                      if (
                                                        newValueArr.length > 1
                                                      ) {
                                                        if (
                                                          newValueArr[1].length >
                                                          2
                                                        ) {
                                                          en.target.value =
                                                            newValueArr[0] +
                                                            "." +
                                                            newValueArr[1].slice(
                                                              0,
                                                              2
                                                            );
                                                        }
                                                      }

                                                      if (
                                                        en.target.value && selproducts[`product_${product.id}`].lab_test_name && selproducts[`product_${product.id}`].lab_test_description
                                                      ) {
                                                        setSaveDisable(false);
                                                      } else {
                                                        setSaveDisable(true);
                                                      }
                                                      setSelproducts(
                                                        (prevValue) => {
                                                          let updatedValue = {
                                                            ...prevValue,
                                                            [`product_${product.id}`]:
                                                            {
                                                              ...prevValue[
                                                              `product_${product.id}`
                                                              ],
                                                              lab_test_limit:
                                                                en.target
                                                                  .value,
                                                            },
                                                          };
                                                          return updatedValue;
                                                        }
                                                      );
                                                    }}
                                                    value={
                                                      selprod.lab_test_limit
                                                    }
                                                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md p-2 border-2"
                                                  />
                                                </div>
                                              </div>
                                            </>
                                          ) : null}

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Tax Invoice Not Show
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(en) => {
                                                  setSelproducts(
                                                    (prevValueInvoice) => {
                                                      let updatedTaxInvoiceValue =
                                                      {
                                                        ...prevValueInvoice,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValueInvoice[
                                                          `product_${product.id}`
                                                          ],
                                                          tax_invoice: en
                                                            .target.checked
                                                            ? "N"
                                                            : "Y",
                                                        },
                                                      };
                                                      return updatedTaxInvoiceValue;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  selprod.tax_invoice === "N"
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center">
                                            <label className="block text-sm font-medium text-gray-700 col-span-2">
                                              Excel SMS Notification
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                              <input
                                                onChange={(en) => {
                                                  setSelproducts(
                                                    (prevValueInvoice) => {
                                                      let excelSmsNotificationValue =
                                                      {
                                                        ...prevValueInvoice,
                                                        [`product_${product.id}`]:
                                                        {
                                                          ...prevValueInvoice[
                                                          `product_${product.id}`
                                                          ],
                                                          excel_sms_notification: en
                                                            .target.checked
                                                            ? true
                                                            : false,
                                                        },
                                                      };
                                                      return excelSmsNotificationValue;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  selprod.excel_sms_notification === true
                                                }
                                                type="checkbox"
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                                              />
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    false
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 bg-gray-50 flex justify-end">
                      <Button
                        ref={buttonRef}
                        className="max-w-min"
                        attrs={{
                          disabled: saveDisable,
                          onClick: () => {
                            updateProductStatus(
                              reseller.id,
                              selected,
                              selproducts,
                              () => setOpen(false)
                            );
                          },
                        }}
                        title="Save"
                      />
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export class AdminResellerProducts extends Component {
  DataTable = React.createRef();
  MessageRef = React.createRef();

  state = { type: "", openPanel: false };

  render() {
    const { type } = this.state;
    const { products } = this.props;
    return (
      <AdminMain MessageRef={this.MessageRef}>
        <AppBackButton to="/">Back to Dashboard</AppBackButton>
        <div className="w-full  flex flex-col justify-between space-y-6">
          <div>
            <AppDataTable
              ref={this.DataTable}
              MessageRef={this.MessageRef}
              endpoint="reseller-products"
              header={["Reseller", { title: "Actions", className: "w-48" }]}
              render={(item) => {
                return (
                  <>
                    <td className="px-4 py-4 whitespace-normal max-w-xs">
                      <div className="flex items-center space-x-2">
                        <p>{item.name}</p>
                        <AppLabel
                          title={item.type_title}
                          color={item.type_color}
                        />
                      </div>
                      <p className="text-sm text-gray-500">{item.mobile}</p>
                    </td>
                    <td className="px-4">
                      <ItemAction
                        products={products}
                        reseller={item}
                        updateProductStatus={this.updateProductStatus}
                      />
                    </td>
                  </>
                );
              }}
              params={{ type: type }}
              filters={
                <>
                  <div className="w-48">
                    <InputSelect
                      label="Reseller Type"
                      attrs={{
                        value: type,
                        onChange: (e) =>
                          this.setState({ type: e.target.value }),
                      }}
                      options={RESELLER_TYPES}
                    />
                  </div>
                </>
              }
            />
          </div>
        </div>
      </AdminMain>
    );
  }

  updateProductStatus = async (
    id,
    selected,
    selproducts,
    callback = () => { }
  ) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("update-reseller-products", {
      selproducts,
      products: selected,
      id: id,
    });

    if (response.status === "success") {
      setMessage(response.message);
    } else {
      setError(response.message);
    }
    callback();
    AppStore.dispatch({ type: "LOADING", loading: false });
    this.DataTable.current.getItems();
  };

  componentDidMount() {
    this.getProducts();
  }

  getProducts = async () => {
    const { user, products } = this.props;
    if (user === null) return;
    if (user?.status !== 1 || products?.length > 0) return;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("admin/products");
    if (response.status === "success") {
      AppStore.dispatch({ type: "PRODUCTS", products: response.data });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  manageReseller = () => {
    this.setState({ openPanel: true });
  };
}

const mapStateToProps = (state) => {
  const { loading, products, user } = state;
  return { loading: loading, products: products, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <AdminResellerProducts {...props} />;
});
