export const headers = [
  {
    name: "Reseller_id",
    mandatory: true,
    type: "number",
    dataKey: "Reseller_id",
    columnColor: [],
  },
  {
    name: "Sku",
    mandatory: true,
    type: "string",
    dataKey: "sku",
    columnColor: [],
  },

  {
    name: "Product_name",
    mandatory: true,
    type: "string",
    dataKey: "Product_name",
    columnColor: [],
  },
  {
    name: "Stock_in",
    mandatory: true,
    type: "number",
    dataKey: "Stock_in",
    columnColor: [],
  },
  {
    name: "Expiry_date",
    mandatory: true,
    dataKey: "Expiry_date",
    type: "date",
    format: "DD/MM/YYYY",
    columnColor: [],
  },
  {
    name: "Narration",
    mandatory: false,
    type: "string",
    dataKey: "Narration",
    columnColor: [],
  },
  {
    name: "Transaction_date",
    mandatory: true,
    dataKey: "Transaction_date",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
];
