import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital } from "@fortawesome/free-solid-svg-icons";

const HospitalDetails = ({ claim }) => {
  // Helper function to truncate text
  const truncateText = (text, length = 20) => {
    return text && text.length > length
      ? text.substring(0, length) + "..."
      : text;
  };

  return (
    <div
      id="style-1"
      style={{
        width: "350px",
        height: "400px",
        borderRadius: "10px",
        padding: "20px 20px",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <span
        style={{
          fontSize: "14px",
          color: "#DB2228",
          fontWeight: "600",
          textAlign: "center",
          display: "block",
        }}
      >
        Hospital details
      </span>
      <hr />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faHospital}
          style={{ fontSize: "50px", color: "#Db2228" }}
        />
      </div>
      <div className="pt-4 text-sm">
        {claim?.hospital?.name && (
          <div className="pb-2 w-100">
            <span className="font-semibold">Hospital name : </span>
            <span title={claim?.hospital?.name || "---"}>
              {truncateText(claim?.hospital?.name) || "---"}
            </span>
          </div>
        )}
        {claim?.hospital?.hospital_address && (
          <div className="pb-2">
            <span className="font-semibold">Address :</span>{" "}
            <span title={claim?.hospital?.hospital_address || "---"}>
              {truncateText(claim?.hospital?.hospital_address) || "---"}
            </span>
          </div>
        )}
        {claim?.claim_reason && (
          <div className="pb-2">
            <span className="font-semibold">Reason for hospitalization :</span>{" "}
            <span title={claim?.claim_reason || "---"}>
              {truncateText(claim?.claim_reason) || "---"}
            </span>
          </div>
        )}
        {claim?.start_date && (
          <div className="pb-2">
            <span className="font-semibold">Admit on :</span>{" "}
            {claim?.start_date
              ? moment(claim.start_date).format("DD MMM YYYY")
              : "---"}
          </div>
        )}
        {claim?.end_date && (
          <div className="pb-2">
            <span className="font-semibold">Discharge on :</span>{" "}
            {claim?.end_date
              ? moment(claim?.end_date).format("DD MMM YYYY")
              : "---"}
          </div>
        )}
        {claim?.claim_register_date && (
          <div className="pb-2 w-100">
            <span className="font-semibold">Reimbursement register date: </span>
            <span title={claim?.claim_register_date || "---"}>
              {truncateText(claim?.claim_register_date) || "---"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HospitalDetails;
