import React from "react";
import { Placeholder } from "rsuite";
import SingleComment from "./SingleComment";
import { AppHeading } from "../../../components/AppComponents";

const CommentList = ({ loading, data, viewDocument, totalCount }) => {
  return (
    <div
      style={{
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <AppHeading className="mb-1" size="lg" font="medium">
        Previous comments :
      </AppHeading>
      {totalCount && (
        <div className="mb-2">
          <span className="text-sm">
            Total comment:&nbsp;
            <span className="font-medium">{totalCount}</span>
          </span>
        </div>
      )}
      {loading ? (
        <>
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
        </>
      ) : data?.length > 0 ? (
        data?.map((item, index) => {
          return (
            <>
              <SingleComment
                key={index}
                item={item}
                viewDocument={viewDocument}
              />
            </>
          );
        })
      ) : (
        <p style={{ textAlign: "center" }}>No comment found</p>
      )}
    </div>
  );
};

export default CommentList;
