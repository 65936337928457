import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ViewStatusPatnerPayment from "./paymentStatusPatnerToAiqa/ViewStatusPatnerPayment";
import ViewStatusInsuranceData from "./insuranceDataSentToVendor/ViewStatusInsuranceData";
import ViewStatusPaymentVendor from "./aiqaVendorPaymentStatus/ViewStatusPaymentVendor";
import ViewStatusPolicyEnforcement from "./policyEnforcementVendorStatus/ViewStatusPolicyEnforcement";
import ViewStatusPolicyUpload from "./VendorPolicyUpload/ViewStatus";

const tabLabelStyle = {
  fontWeight: "600",
  textTransform: "capitalize",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TabModal({ handleClose, open, data }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Dialog maxWidth={"xl"} open={open} onClose={handleClose}>
        <DialogTitle style={{ textAlign: "center" }}>View details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent style={{ width: "60rem" }}>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              bgcolor: "background.paper",
              height: "400px",
              overflow: "hidden",
            }}
          >
            {/* Sticky Tabs */}
            <Box
              sx={{
                position: "sticky",
                top: 0, // Ensures sticky behavior
                alignSelf: "flex-start",
                borderRight: 1,
                borderColor: "divider",
                backgroundColor: "background.paper", // Prevents transparency
                zIndex: 1, // Avoid overlap issues
              }}
            >
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab
                  label="Payment status Patner to aiqa"
                  {...a11yProps(0)}
                  style={tabLabelStyle}
                />
                <Tab
                  label="Insurance data sent to vendor"
                  {...a11yProps(1)}
                  style={tabLabelStyle}
                />
                <Tab
                  label="Payment status to vendor"
                  {...a11yProps(2)}
                  style={tabLabelStyle}
                />
                <Tab
                  label="Policy enforcement vendor status"
                  {...a11yProps(3)}
                  style={tabLabelStyle}
                />
                <Tab
                  label="Vendor policy upload status"
                  {...a11yProps(3)}
                  style={tabLabelStyle}
                />
              </Tabs>
            </Box>

            {/* Tab Panels */}
            <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
              <TabPanel value={value} index={0}>
                <ViewStatusPatnerPayment rowData={data} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ViewStatusInsuranceData rowData={data} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ViewStatusPaymentVendor rowData={data} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ViewStatusPolicyEnforcement rowData={data} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ViewStatusPolicyUpload rowData={data} />
              </TabPanel>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
