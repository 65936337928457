export function checkUID(uid) {
  if (uid.length != 12) {
    return false;
  }

  var Verhoeff = {
    d: [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
      [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
      [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
      [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
      [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
      [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
      [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
      [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
      [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
    ],
    p: [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
      [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
      [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
      [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
      [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
      [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
      [7, 0, 4, 6, 9, 1, 3, 2, 5, 8],
    ],
    j: [0, 4, 3, 2, 1, 5, 6, 7, 8, 9],
    check: function (str) {
      var c = 0;
      str
        .replace(/\D+/g, "")
        .split("")
        .reverse()
        .join("")
        .replace(/[\d]/g, function (u, i) {
          c = Verhoeff.d[c][Verhoeff.p[i % 8][parseInt(u, 10)]];
        });
      return c;
    },
    get: function (str) {
      var c = 0;
      str
        .replace(/\D+/g, "")
        .split("")
        .reverse()
        .join("")
        .replace(/[\d]/g, function (u, i) {
          c = Verhoeff.d[c][Verhoeff.p[(i + 1) % 8][parseInt(u, 10)]];
        });
      return Verhoeff.j[c];
    },
  };

  if (Verhoeff["check"](uid) === 0) {
    return true;
  } else {
    return false;
  }
}

export function isValidVoterId(voterId) {
  // Remove whitespace and convert to uppercase
  voterId = voterId.replace(/\s/g, "").toUpperCase();

  // Check if the voter ID length is exactly 10 characters
  if (voterId.length !== 10) {
    return false;
  }

  // Check if the first three characters are alphabets
  const firstThreeCharacters = voterId.substring(0, 3);
  if (!/^[A-Z]{3}$/.test(firstThreeCharacters)) {
    return false;
  }

  // Check if the next seven characters are digits
  const lastSevenCharacters = voterId.substring(3);
  if (!/^\d{7}$/.test(lastSevenCharacters)) {
    return false;
  }

  // Perform additional checks if needed, such as verifying the state code and checksum

  // If all checks pass, the voter ID is likely valid
  return true;
}

export function isValidPassport(passportNumber) {
  // Remove whitespace and convert to uppercase
  passportNumber = passportNumber.replace(/\s/g, "").toUpperCase();

  // Check if the passport number length is between 6 and 12 characters
  if (passportNumber.length < 6 || passportNumber.length > 12) {
    return false;
  }

  // Check if the passport number contains only alphanumeric characters
  if (!/^[a-zA-Z0-9]+$/.test(passportNumber)) {
    return false;
  }

  // Perform additional checks if needed, such as verifying specific country codes or formats

  // If all checks pass, the passport number is likely valid
  return true;
}

export function isValidDrivingLicense(drivingLicenseNumber) {
  // Remove whitespace and convert to uppercase
  drivingLicenseNumber = drivingLicenseNumber.replace(/\s/g, "").toUpperCase();

  // Check if the driving license number length is exactly 15 characters
  if (drivingLicenseNumber.length < 15) {
    return false;
  }

  // Check if the first two characters are alphabets (state code)
  const stateCode = drivingLicenseNumber.substring(0, 2);
  if (!/^[A-Z]{2}$/.test(stateCode)) {
    return false;
  }

  // Check if the next two characters are digits (district code)
  const districtCode = drivingLicenseNumber.substring(2, 4);
  if (!/^\d{2}$/.test(districtCode)) {
    return false;
  }

  // If all checks pass, the driving license number is likely valid
  return true;
}

export function isValidPan(pan) {
  // Regular expression to match the PAN format
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return panRegex.test(pan);
}
