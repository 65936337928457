import React from "react";
import Button from "../../../components/Button";
import { BULK_DATA, SINGLE_DATA } from "./constant";
import { FaCloudUploadAlt } from "react-icons/fa";

const ButtonHeader = ({ view, setView, windowSize }) => {
  return (
    <div className="flex space-x-4 justify-center md:justify-start items-end">
      <Button
        className="mb-5"
        title="Manual upload"
        attrs={{ onClick: () => setView(SINGLE_DATA) }}
        outline={view === SINGLE_DATA ? false : true}
        paddingX="4"
        paddingY="1"
      >
        <FaCloudUploadAlt size={20} style={{ marginRight: "10px" }} />
      </Button>
      <Button
        className="mb-5"
        title="Upload by excel"
        attrs={{ onClick: () => setView(BULK_DATA) }}
        outline={view === BULK_DATA ? false : true}
        paddingX="4"
        paddingY="1"
      >
        <FaCloudUploadAlt size={20} style={{ marginRight: "10px" }} />
      </Button>
    </div>
  );
};

export default ButtonHeader;
