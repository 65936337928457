import * as yup from "yup";

export const addProductValidation = yup.object().shape({
  productName: yup.string().required("product name field is required"),
  productDisplayName: yup.string().required("product display name field is required"),
  tenure: yup
    .number("only number accepted")
    .min(1, 'The minimum amount is one')
    .required("product tenor is required")
    .positive("positive value accepted")
    .integer(),
  productTenorType: yup.string()
    .required("product tenor type is required"),
  paymentMode: yup.string().required("payment mode field is required"),
  productBasePrice: yup
    .number("only number accepted")
    .required("product base price is required")
    .min(1, 'The minimum amount is one')
    .positive("positive value accepted")
    .integer()
    .test(
      'no-leading-zero',
      'Product Base Price not start with zero',
      (value, context) => {
        return context.originalValue && !context.originalValue.startsWith('0');
      }
    ),
  gst: yup
    .number("only number accepted")
    .nullable()
    .min(0, 'The minimum amount is zero')
    .max(50, "Not be grater then 50 %"),
  productMRP: yup
    .number("only number accepted")
    .required("product MRP is required")
    .positive("positive value accepted")
    .integer(),
  sellableToIndividual: yup.string().required("sellable to ind is required"),
  paymentPartner: yup.string().required("payment partner is required"),
  productCategory: yup.string().required("product category is required"),
  productDescription: yup.string().required("product description is required"),
  productBenefitText: yup.string().required("product Benefit is required"),
  isDoctorConsult: yup.boolean(),
  isAccidental: yup.boolean(),
  isHospitalCash: yup.boolean(),
  isHosSlab1: yup.boolean().when(["specification"], {
    is: (specification) => specification === "HCH",
    then: yup.boolean().required("Hospital Slab 1 is required").oneOf([true], "Hospital Slab 1 is required")
  }),
  isHosSlab2: yup.boolean(),
  isHosSlab3: yup.boolean(),
  isHosSlab4: yup.boolean(),
  isICUSlab1: yup.boolean().when(["specification"], {
    is: (specification) => specification === "HCH",
    then: yup.boolean().required("ICU Slab 1 is required").oneOf([true], "ICU Slab 1 is required")
  }),
  isICUSlab2: yup.boolean(),
  memberCoverd: yup.string().required("member covered is required"),
  partner: yup.string().required("partner is required"),
  sumAssuredCode: yup.string().when("partner", {
    is: (partner) => partner === "AC" || partner === "HCH",
    then: yup.string().required("sum assured code is required"),
  }),
  specification: yup.string().when(["productClasification"], {
    is: (productClasification) => productClasification === "Individual",
    then: yup.string().min(1).required("specification is required"),
  }),
  vendorPlanId: yup.string().when("partner", {
    is: (partner) => partner !== "LAC",
    then: yup.string().required("vendor plan id is required"),
  }),
  vendorPlanName: yup.string().when("partner", {
    is: (partner) => partner !== "LAC",
    then: yup.string().required("vendor plan name is required"),
  }),
  eligibleForClaim: yup.string().required("eligible for claim is required"),
  claimDoc: yup.array().when(["eligibleForClaim"], {
    is: (eligibleForClaim) => eligibleForClaim === "Y",
    then: yup.array().min(1).required("claim document is required"),
  }),
  claimAmount: yup
    .number()
    .when(["specification", "eligibleForClaim", "productClasification"], {
      is: (specification, eligibleForClaim, productClasification) =>
        (specification === "AC" || specification === "HCH") && eligibleForClaim === "Y" && productClasification === "Individual",
      then: yup.number().min(1).required("claim amount is required")
        .test(
          'no-leading-zero',
          'claim Amount not start with zero',
          (value, context) => {
            return context.originalValue && !context.originalValue.startsWith('0');
          }
        ),
    }),
  hospitalSlab1: yup
    .object()
    .when(["isHosSlab1", "specification", "productClasification"], {
      is: (isHosSlab1, specification, productClasification) =>
        isHosSlab1 === true &&
        specification === "HCH" &&
        productClasification === "Individual",
      then: yup.object().shape({
        days: yup.string().required("days field is required"),
        amount: yup
          .number("only number accepted")
          .required("amount field is required"),
      }),
    }),
  hospitalSlab2: yup
    .object()
    .when(["isHosSlab2", "specification", "productClasification"], {
      is: (isHosSlab2, specification, productClasification) =>
        isHosSlab2 === true &&
        specification === "HCH" &&
        productClasification === "Individual",
      then: yup.object().shape({
        days: yup.string().required("this field is required"),
        amount: yup
          .number("only number accepted")
          .required("this field is required"),
      }),
    }),
  hospitalSlab3: yup
    .object()
    .when(["isHosSlab3", "specification", "productClasification"], {
      is: (isHosSlab3, specification, productClasification) =>
        isHosSlab3 === true &&
        specification === "HCH" &&
        productClasification === "Individual",
      then: yup.object().shape({
        days: yup.string().required("this field is required"),
        amount: yup
          .number("only number accepted")
          .required("this field is required"),
      }),
    }),
  hospitalSlab4: yup
    .object()
    .when(["isHosSlab4", "specification", "productClasification"], {
      is: (isHosSlab4, specification, productClasification) =>
        isHosSlab4 === true &&
        specification === "HCH" &&
        productClasification === "Individual",
      then: yup.object().shape({
        days: yup.string().required("this field is required"),
        amount: yup
          .number("only number accepted")
          .required("this field is required"),
      }),
    }),
  icuSlab1: yup
    .object()
    .when(["isICUSlab1", "specification", "productClasification"], {
      is: (isICUSlab1, specification, productClasification) =>
        isICUSlab1 === true &&
        specification === "HCH" &&
        productClasification === "Individual",
      then: yup.object().shape({
        days: yup.string().required("this field is required"),
        amount: yup
          .number("only number accepted")
          .required("this field is required"),
      }),
    }),
  icuSlab2: yup
    .object()
    .when(["isICUSlab2", "specification", "productClasification"], {
      is: (isICUSlab2, specification, productClasification) =>
        isICUSlab2 === true &&
        specification === "HCH" &&
        productClasification === "Individual",
      then: yup.object().shape({
        days: yup.string().required("this field is required"),
        amount: yup
          .number("only number accepted")
          .required("this field is required"),
      }),
    }),
});
