import React from "react";
import { Link } from "react-router-dom";

const navigation = [
  // { title: 'Subscription', 'link': '/subscription' },
];

export default function AppMenu({ mobile = false, active = false }) {
  let _class =
    "px-3 py-2 rounded-xl text-sm focus:outline-none focus:ring-2 focus:ring-primary-200 cursor-pointer";

  if (mobile) {
    _class =
      "block px-3 py-2 rounded-xl text-base focus:outline-none focus:ring-2 focus:ring-primary-200";
  }

  return (
    <div
      className={
        mobile ? `px-2 pt-2 pb-3 space-y-1 sm:px-3` : `hidden md:block`
      }
    >
      <div className={mobile ? `` : `ml-10 flex items-baseline space-x-4`}>
        {navigation.map((item, index) => {
          let _active =
            active === item.link
              ? "bg-gray-900 text-white"
              : "hover:bg-primary hover:text-white";
          return (
            <React.Fragment key={index}>
              <Link to={item.link} className={[_class, _active].join(" ")}>
                {item.title}
              </Link>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
