import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Button from "../../components/Button";
import {
  ApiGet,
  JavaApiGet,
  getResellerEncryptionToken,
  postApiForCommonDownloadedReportExcel,
} from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { Pagination, Modal } from "rsuite";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommonDownloadedReports from "./CommonDownloadedReports";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

export class SalesReport extends Component {
  MessageRef = React.createRef();
  downloadRef = React.createRef();
  payloadRef = React.createRef();
  countRef = React.createRef();
  downloadPayloadRef = React.createRef();
  state = {
    activeTab: "report",
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    count: {
      policy_count: 0,
      lead_count: 0,
      policy_amount: 0,
      lead_amount: 0,
    },
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      page: 1,
      count: 10,
      employee_id: "",
      date_type: "uploaded_date",
      status: "all",
    },
    employees: [],
    showModal: false,
    modalSize: window.innerWidth <= 768 ? "xs" : "sm",
  };

  switchTab = () => {
    this.setState((prev) => ({
      ...prev,
      activeTab: prev.activeTab === "download" ? "report" : "download",
    }));
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    params["date_type"] = "uploaded_date";
    this.setState({ params: params });
    this.getReports();
    this.getTotalRecords();
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      activeTab,
      params,
      reports,
      count,
      employees,
      showModal,
      modalSize,
    } = this.state;

    const { user, headerDownload } = this.props;
    const { permissions } = user;
    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.sales_report ? (
          <>
            {headerDownload && (
              <Modal
                open={showModal}
                onClose={this.handleCloseModal}
                backdrop="static"
                size={modalSize}
                className={`text-center ${modalSize ? "top-1/3" : "top-52"}`}
              >
                <Modal.Body>{showModal}</Modal.Body>
                <div className="w-20 mx-auto">
                  <Button
                    attrs={{
                      type: "button",
                      onClick: this.handleCloseModal,
                    }}
                    size="sm"
                    title={"Ok"}
                  />
                </div>
              </Modal>
            )}

            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/">Back</AppBackButton>
              </div>
            </div>

            <div className="mb-6 flex justify-start space-x-4">
              <button
                className={`px-4 py-2 w-56 ${
                  activeTab === "report"
                    ? "border-b-4 border-green-900 text-green-900"
                    : "#000000"
                }`}
                onClick={this.switchTab}
              >
                Sales Report
              </button>
              <button
                className={`px-4 py-2 w-56 ${
                  activeTab === "download"
                    ? "border-b-4 border-green-900 text-green-900"
                    : "#000000"
                }`}
                onClick={this.switchTab}
              >
                Downloaded Reports
              </button>
            </div>

            {activeTab === "report" ? (
              <div>
                <AppHeading
                  className="mb-10 text-center"
                  style={{ color: "#DB2228" }}
                >
                  Sales Report
                </AppHeading>

                <div
                  className="grid md:grid-cols-5 gap-4"
                  style={{ margin: "10px" }}
                >
                  <div style={{ marginTop: "4px" }}>
                    <label className="flex text-sm text-gray-700 justify-left pb-2">
                      Select Team
                    </label>
                    <select
                      onChange={(e) => this.onChangeHandler(e, "employee_id")}
                      value={params.employee_id}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                    >
                      <option value="">Me</option>
                      {employees.map((option, index) => {
                        return (
                          <option value={option.id} key={index}>
                            {option.name} ({option.mobile})
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div style={{ marginTop: 4 }}>
                    <label className="flex text-sm text-gray-700 justify-left pb-2">
                      View By
                    </label>
                    <select
                      onChange={(e) => {
                        this.setState({
                          params: {
                            ...this.state.params,
                            date_type: e.target.value,
                          },
                        });
                      }}
                      value={this.state.params.date_type}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                    >
                      <option value="uploaded_date">Uploaded date</option>
                      <option value="disbursement_date">Disbursed date</option>
                    </select>
                  </div>
                  <div style={{ marginTop: 4 }}>
                    <label className="flex text-sm text-gray-700 justify-left pb-2">
                      Status
                    </label>
                    <select
                      onChange={(e) => {
                        this.setState({
                          params: {
                            ...this.state.params,
                            status: e.target.value,
                          },
                        });
                      }}
                      value={this.state.params.status}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                    >
                      <option value="all">All</option>

                      <option value="active">Active</option>
                      <option value="unpaid">Unpaid</option>
                    </select>
                  </div>
                  <div>
                    <InputDate
                      label="From"
                      value={params.from}
                      placeholder="from"
                      maxDate={new Date(params.to)}
                      attrs={{
                        onChange: (e) => this.onChangeHandlerDate(e, "from"),
                        value: new Date(params.from),
                      }}
                    />
                  </div>
                  <div>
                    <InputDate
                      label="To"
                      placeholder="To"
                      minDate={new Date(params.from)}
                      maxDate={new Date()}
                      attrs={{
                        onChange: (e) => this.onChangeHandlerDate(e, "to"),
                        value: new Date(params.to),
                      }}
                    />
                  </div>
                </div>

                <div
                  className="flex gap-4 justify-end"
                  style={{ margin: "20px 10px 10px 10px" }}
                >
                  <Tooltip
                    title={`${
                      headerDownload
                        ? "Can't refresh during downloading"
                        : "Click here to refresh"
                    }`}
                    arrow
                  >
                    <span>
                      <Button
                        size="sm"
                        title={""}
                        attrs={{
                          type: "button",
                          onClick: this.handleClickReset,
                          disabled: headerDownload,
                        }}
                        className={`${headerDownload ? "disabled-button" : ""}`}
                      >
                        <RefreshIcon
                          sx={{
                            color: "#ffffff",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip title={"Search"} arrow>
                    <span>
                      <Button
                        attrs={{ type: "button", onClick: this.handleClick }}
                        size="sm"
                        title={"Search"}
                      />
                    </span>
                  </Tooltip>

                  {user && (
                    <Tooltip
                      title={`${
                        headerDownload
                          ? "Download already in progress"
                          : "Click here to download"
                      }`}
                      arrow
                    >
                      <span>
                        <Button
                          size="sm"
                          title={"Download"}
                          attrs={{
                            type: "button",
                            onClick: this.downloadClick,
                            disabled: headerDownload,
                          }}
                          className={`${
                            headerDownload ? "disabled-button" : ""
                          }`}
                        />
                      </span>
                    </Tooltip>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-x-auto">
                        {reports ? (
                          <table
                            className="min-w-full table-auto"
                            id="table-to-xls"
                          >
                            <thead>
                              <tr>
                                <td>
                                  <table className="min-w-full border text-center">
                                    <thead className="border-b">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                                        >
                                          Active Members
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                                        >
                                          Unpaid Members
                                        </th>
                                        <th
                                          scope="col"
                                          className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                                        >
                                          Total Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="border-b">
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                          {count?.policy_count || 0}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                          {count?.lead_count || 0}
                                        </td>
                                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                          {count?.policy_amount +
                                            count?.lead_amount || 0}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {reports.map((item, index) => {
                                    return (
                                      <table
                                        className="min-w-full border"
                                        key={index}
                                      >
                                        <thead className="border-b border-r">
                                          <tr className="bg-red-300">
                                            <th
                                              colSpan="6"
                                              scope="col"
                                              className="text-sm font-large capitalize  text-white px-6 py-4 text-center border-r"
                                            >
                                              {item?.reseller?.reseller_name}
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr>
                                            <td>
                                              <table className="min-w-full">
                                                <thead className="border-b">
                                                  <tr>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Name
                                                    </th>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Mobile
                                                    </th>
                                                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Amount
                                                    </th>
                                                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Booking Date
                                                    </th>
                                                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Disbursed Date
                                                    </th>
                                                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Product
                                                    </th>

                                                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Member ID
                                                    </th>
                                                    <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                                      Payment Status
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item &&
                                                    item?.records &&
                                                    item?.records.map(
                                                      (record, index) => {
                                                        return (
                                                          <tr
                                                            className="border-b"
                                                            key={index}
                                                          >
                                                            <td
                                                              className="text-sm  font-bigger px-6 py-4 whitespace-nowrap"
                                                              style={{
                                                                fontWeight:
                                                                  "bold",
                                                                color: "blue",
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              <Link
                                                                to={`/subscription/${record.application_id}`}
                                                              >
                                                                {record.customer_name.toLowerCase()}
                                                              </Link>
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                              {record.mobile}
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                              {
                                                                record.policy_amount
                                                              }
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                              {record?.booking_date &&
                                                                moment(
                                                                  record.booking_date
                                                                ).format(
                                                                  "DD, MMM YYYY"
                                                                )}
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                              {record?.start_date &&
                                                                moment(
                                                                  record.start_date
                                                                ).format(
                                                                  "DD, MMM YYYY"
                                                                )}
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                              {
                                                                record?.product_display_name
                                                              }
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                              {
                                                                record?.insured_member_id
                                                              }
                                                            </td>
                                                            <td
                                                              className={`px-6 py-4 whitespace-nowrap ${
                                                                record?.r_type ===
                                                                "Policy"
                                                                  ? "text-green-600"
                                                                  : "text-red-600"
                                                              }`}
                                                            >
                                                              {record?.r_type ===
                                                              "Policy"
                                                                ? "Received"
                                                                : "Pending"}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    );
                                  })}
                                </td>
                              </tr>
                            </thead>
                          </table>
                        ) : (
                          false
                        )}
                        <div style={{ padding: 20 }}>
                          <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={2}
                            size="xs"
                            layout={this.state.layout}
                            total={this.state.total}
                            limit={this.state.limit}
                            activePage={this.state.params.page}
                            onChangePage={this.onPageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <CommonDownloadedReports
                title="Downloaded Reports"
                reportName="Sales Report"
              />
            )}
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You do not have permission to view sales report</h4>
            </div>
          </div>
        )}
      </Main>
    );
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  handleResize = () => {
    this.setState({
      modalSize: window.innerWidth <= 768 ? "xs" : "md",
    });
  };

  componentDidMount() {
    this.getReports();
    this.getTotalRecords();
    this.getTeam();
    if (this.props?.location?.state) {
      this.setState((prev) => ({
        ...prev,
        activeTab: this.props?.location?.state,
      }));
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize); // Clean up the event listener
  }

  handleClick = () => {
    this.onPageChange(1);
    this.getTotalRecords();
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  downloadClick = () => {
    let payload = {
      startDate: this.state.params.from,
      endDate: this.state.params.to,
      resellerId: this.state.params.employee_id,
      dateType: this.state.params.date_type,
      status: this.state.params.status,
      reportName: "Sales Report",
    };
    if (this.downloadPayloadRef.current !== JSON.stringify(payload)) {
      this.downloadPayloadRef.current = JSON.stringify(payload);
      this.DownloadReports(payload);
    }
  };

  checkDownloadStatus = async () => {
    const res = await JavaApiGet(
      "enrollment-service/excel_report/check_excel_record",
      false,
      false,
      getResellerEncryptionToken()
    );
    if (res?.responseObject?.status === "Complete") {
      clearInterval(this.downloadRef.current);
      toast.success(res?.message);
      AppStore.dispatch({ type: "IS_HEADER_DOWNLOAD", headerDownload: false });
      AppStore.dispatch({
        type: "UPDATE_DOWNLOAD_LIST",
        updateDownloadList: !this.props.updateDownloadList,
      });
    }
  };

  DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "IS_HEADER_DOWNLOAD", headerDownload: true });
    const response = await postApiForCommonDownloadedReportExcel(
      "enrollment-service/excel_report/download_excel",
      request,
      "salesReport.csv"
    );

    const contentType = response?.headers.get("Content-Type");
    if (contentType === "application/json") {
      const res = await response?.json();
      if (res?.code === 200 && res?.count === 0) {
        toast.success(res?.message);
        AppStore.dispatch({
          type: "IS_HEADER_DOWNLOAD",
          headerDownload: false,
        });
        return;
      }
      this.setState({ showModal: res?.message });
      this.downloadRef.current = setInterval(() => {
        this.checkDownloadStatus();
      }, 3000);
    } else {
      AppStore.dispatch({
        type: "IS_HEADER_DOWNLOAD",
        headerDownload: false,
      });
      const blob = await response.blob();
      if (blob) {
        // Create blob link to download
        const urls = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = urls;
        link.setAttribute("download", "SalesReport.csv");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        toast.success("Report downloaded successfully");
      } else {
        toast.info("No data found");
      }
    }
  };

  getReports = async () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      employee_id: this.state.params.employee_id,
      date_type: this.state.params.date_type,
      status: this.state.params.status,
      page: this.state.params.page,
    };

    if (this.payloadRef.current !== JSON.stringify(payload)) {
      this.payloadRef.current = JSON.stringify(payload);
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("report/sales", payload);
      if (response?.status === "success") {
        if (response?.data?.items) {
          const items = Object.values(response?.data?.items);
          this.modifyResponse(items);
          this.setState({ reports: items });
          this.setState({ total: response?.data?.total });
        } else {
          this.state.reports = [];
        }
      } else {
        // this.MessageRef.current.show(response.message);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  getTotalRecords = async () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      employee_id: this.state.params.employee_id,
      date_type: this.state.params.date_type,
      status: this.state.params.status,
    };
    if (this.countRef.current !== JSON.stringify(payload)) {
      this.countRef.current = JSON.stringify(payload);
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("report/salesReportCount", payload);
      if (response?.status === "success") {
        if (response?.data) {
          this.setState({ count: response?.data });
        }
      } else {
        // this.MessageRef.current.show(response.message);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  modifyResponse = (data) => {
    let items = [];
    for (let i = 0; i < data.length; i++) {
      items[i] = [(reseller_name) => data[i].reseller_name, (data) => data[i]];
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, user, headerDownload } = state;
  return { loading: loading, user: user, headerDownload: headerDownload };
};

const SalesReportConnect = connect(mapStateToProps)((props) => {
  return <SalesReport {...props} />;
});

export default withRouter(SalesReportConnect);
