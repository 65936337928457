import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton, AppHeading } from "../components/AppComponents";
import { ApiGet } from "../lib/AppHelper";
import Main from "./Main";
import PaginationData from "./admin/components/PaginationData";
import { Search } from "./Search";

export const Leads = ({ loading }) => {
  const MessageRef = React.useRef();
  const permissions = useSelector((state) => state?.user?.permissions);
  const [filterValues, setFilterValues] = useState({});

  return (
    <Main MessageRef={MessageRef}>
      <div className="w-full flex md:flex-row flex-col">
        <div className="w-96">
          <AppBackButton to="/">Back to Dashboard</AppBackButton>
        </div>
        <div className="w-full md:w-1/3 text-center mb-5">
          <AppHeading> Unpaid Subscriptions</AppHeading>
        </div>
        <div className="w-full md:w-1/3 none md:block"></div>
      </div>
      {permissions?.unpaid_policy_view ? (
        <div className="w-full mb-5">
          <Search setValue={setFilterValues} filterValues={filterValues} />
        </div>
      ) : (
        false
      )}
      {permissions?.unpaid_policy_view ? (
        <div className="w-full flex flex-col justify-between space-y-4">
          <div className="flex flex-wrap">
            <PaginationData
              request={ApiGet}
              endPoint={"leads"}
              filterValues={filterValues}
              dataType="DRAFT"
              pageType="leads"
              permissions={permissions}
            />
          </div>
        </div>
      ) : (
        <div className="p-10 text-red-500">
          <h4>You do not have permission to view unpaid Subscription</h4>
        </div>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

const LeadsConnect = connect(mapStateToProps)((props) => {
  return <Leads {...props} />;
});

export default withRouter(LeadsConnect);
