import React from "react";
import { Placeholder } from "rsuite";

const ShimmerLoader = ({
  rows = 2,
  rowHeight = 8,
  rowMargin = 10,
  active = true,
}) => {
  return (
    <Placeholder.Paragraph
      rows={rows}
      rowHeight={rowHeight}
      rowMargin={rowMargin}
      active={active}
    />
  );
};

export default ShimmerLoader;
