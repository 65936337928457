import { Field } from "formik";
import React from "react";
import InputLabel from "../form/InputLabel";

export default function InputGroup({
  type = "text",
  label,
  value,
  name,
  requiredMark = "",
  onBlur,
  disabled = false,
  placeHolder,
  onChange,
}) {
  return (
    <div className="mb-1">
      <InputLabel label={label} requiredMark={requiredMark} />
      <Field
        name={name}
        disabled={disabled}
        type={type}
        value={value}
        onBlur={onBlur}
        placeholder={placeHolder}
        render={({ field, form: { isSubmitting } }) => (
          <input
            {...field}
            disabled={isSubmitting}
            onChange={(e) => {
              if (e.target.value.length < 256) {
                onChange(e);
              }
            }}
            type={type}
            className={
              !disabled
                ? "focus:ring-0 rounded-xl shadow-sm focus:border-primary focus:bg-primary-50 block w-full"
                : "focus:ring-0 rounded-xl shadow-sm focus:border-primary focus:bg-primary-50 block w-full bg-gray-200"
            }
          />
        )}
      />
    </div>
  );
}
