import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SelectPicker, Loader } from "rsuite";

const BranchSearchPicker = ({
  data,
  name,
  value,
  onChange,
  className,
  request,
  count,
  onSearch = () => {},
  loading = false,
}) => {
  const customRenderMenuItem = (value, obj, menu) => {
    return (
      <div
        className="border-b-2 p-1 text-xs space-y-2 cursor-pointer hover:bg-red-500 hover:text-white"
        style={{ width: "200px" }}
        onClick={(e) => menu.props.onSelect(obj?.id, obj, e)}
      >
        <div>
          <span className="font-bold text-sm">{value}</span>
          <div className="mt-2">
            {obj?.value && (
              <span className="float-right font-bold">Id : {obj?.value}</span>
            )}
            {obj?.reportingToName && (
              <div>Reporting to : {obj.reportingToName}</div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <SelectPicker
      data={data || []}
      searchable={true}
      size="lg"
      name={name}
      value={value}
      onChange={(value) => onChange(value)}
      style={{
        width: 224,
        border: className ? "1px solid #Db2228" : "",
        borderRadius: className ? "12px" : "",
      }}
      placeholder="Select"
      onSelect={(value) => onChange(value)}
      onSearch={onSearch}
      renderMenu={(menu) => (
        <InfiniteScroll
          dataLength={data?.length}
          next={request}
          hasMore={count !== data?.length}
          loader={
            loading && (
              <h4 className="text-center">
                <Loader size="xs" speed="fast" />
              </h4>
            )
          }
          height={menu?.props?.data?.length ? 270 : 50}
          scrollableTarget="scrollableDiv"
          className="flex flex-col items-center"
        >
          {menu?.props?.data?.length ? (
            menu?.props?.data?.map((item, index) =>
              customRenderMenuItem(item.label, item, menu)
            )
          ) : (
            <div className="text-center">No data found</div>
          )}
        </InfiniteScroll>
      )}
    />
  );
};

export default BranchSearchPicker;
