import React, { Component } from 'react'
import { AppHeading } from '../../components/AppComponents'
import Button from '../../components/Button'
import { InputGroup } from '../../components/form/InputGroup'
import { ApiPost } from '../../lib/AppHelper'
import { AppStore } from '../../lib/AppStore'
import paymentImg from './../../payment-img.png'
import Countdown from 'react-countdown';
import moment from 'moment'
import _ from 'lodash'

export class VpaMandate extends Component {

   state = {
      upi: '',
      errors: {},
      vpa_registered: false,
      expiry: null,
      enquiry: null
   }

   enquiryInterval = null;

   isReady = () => {
      if (this.state.upi === '' || !(/^[\w.-]+@[\w.-]+$/.test(this.state.upi))) return false
      return true
   }

   render() {
      const { upi, errors, vpa_registered, expiry } = this.state;

      const ready = this.isReady();

      return (
         <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
            <div>
               <AppHeading>Payment</AppHeading>
               <h4 className="mt-1 text-md mb-6 mt-6">{vpa_registered ? "Open your payment app to authorize your mandate." : "Please register your UPI for mandate."}</h4>

               <InputGroup error={errors.upi} label="UPI ID" placeholder="Enter UPI ID.." attrs={{ value: upi, onChange: (e) => this.setState({ upi: e.target.value }), autoFocus: true, readOnly: vpa_registered }} checked={vpa_registered} onEnter={() => { if (ready) { this.mandate() } }} />

               {vpa_registered ? <>
                  <div className="flex mt-10 flex-col items-center">
                     <img alt="" className="w-40 h-40 rounded-full" src={paymentImg} />
                     <p className="text-gray-400 text-sm mb-2 mt-3">PAGE EXPIRE IN</p>
                     <p className="text-xl font-semibold"><Countdown onComplete={this.onCompleteCountdown} renderer={this.countdownRenderer} date={moment(expiry).toDate()} /></p>
                  </div>
               </> : <>
                  <Button attrs={{ disabled: !ready, type: 'button', onClick: this.mandate }} size="md" title={"Register Mandate"} />
               </>}
            </div>
         </div>
      )
   }

   mandate = async () => {
      AppStore.dispatch({ type: 'LOADING', loading: true })

      this.setState({ errors: {} });
      const response = await ApiPost('vpa/register', { applicationId: this.props.application.application_id, vpa: this.state.upi })

      if (response.status === "error") {
         this.setState({ errors: response.errors }, () => {
            if (_.isEmpty(response.errors)) {
               this.props.MessageRef.current.show(response.message, "error")
            }
            AppStore.dispatch({ type: 'LOADING', loading: false })
         })
      } else {
         this.setState({ vpa_registered: true, enquiry: response.data.enquiry, expiry: response.data.expiry }, () => {
            this.props.MessageRef.current.show(response.data.message, "success")
            AppStore.dispatch({ type: 'LOADING', loading: false })
         })
      }

   }

   onCompleteCountdown = () => {
      this.setState({
         vpa_registered: false,
         expiry: null,
         enquiry: null
      }, () => {
         this.props.MessageRef.current.show("Your upi mandate request has been expired. Please try again.");
      })
   }

   countdownRenderer = ({ hours, minutes, seconds }) => {
      return `${_.padStart(hours, 2, '0')}:${_.padStart(minutes, 2, '0')}:${_.padStart(seconds, 2, '0')}`;
   }

   componentDidMount() {
      this.enquiryInterval = setInterval(this.enquiry, 20000);
   }

   componentWillUnmount() {
      clearInterval(this.enquiryInterval);
   }

   enquiry = async () => {
      if (!this.state.vpa_registered) return false;
      const response = await ApiPost(`vpa/enquiry`, this.state.enquiry);

      if (response.status === 'success') {
         if (response.data.status === true) {
            this.props.onSuccess('activation' in response.data ? response.data.activation : response.data.message)
         }
      } else {
         if (response.code === 205) {
            this.setState({
               vpa_registered: false,
               expiry: null,
               enquiry: null,
               errors: response.errors
            })
         } else {
            this.props.onError(response.message)
         }
      }
   }
}

export default VpaMandate
