import React from "react";
import { DatePicker } from "rsuite";
import InputLabel from "../../components/form/InputLabel";

export const DateInput = ({
  label,
  error,
  requiredMark,
  setValue,
  state,
  attrs: { value, disabled },
  disabledDateFunc,
  formate = "yyyy-MM-dd",
}) => {
  let newValue = value;

  if (
    state.claimEndDate === "Invalid date" ||
    state.claimStartDate === "Invalid date"
  ) {
    newValue = "";
  } else if (newValue) {
    newValue = new Date(newValue);
  } else {
    newValue = "";
  }

  const disabledDate = (date) => {
    if (disabledDateFunc) {
      return disabledDateFunc(date, state);
    }
    return date > new Date();
  };
  return (
    <div className="mb-5">
      <InputLabel label={label} requiredMark={requiredMark} />
      <div className="mt-2 relative">
        <DatePicker
          style={{ width: "100%" }}
          autoSave
          format={formate}
          placeholder="DD-MM-YYYY"
          className="genderClass padding0"
          oneTap
          value={newValue || void 0}
          onChange={setValue}
          disabledDate={disabledDate}
          disabled={disabled}
        />
        {error ? <p className="mt-2 text-xs text-red-500">{error}</p> : false}
      </div>
    </div>
  );
};
