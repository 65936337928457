import {
  PencilIcon,
  XIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/outline";
import React, { Component } from "react";
import { AppRow } from "../../components/AppComponents";
import Button from "../../components/Button";
import Main from "./../Main";
import { InputGroup } from "../../components/form/InputGroup";
import ProfileSidebar from "./ProfileSidebar";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import {
  ApiPost,
  setError,
  setMessage,
  validatePassword,
} from "../../lib/AppHelper";
import _ from "lodash";
import { toast } from "react-toastify";
import PasswordPolicy from "../../components/form/passwordPolicy/PasswordPolicy";

export class ChangePassword extends Component {
  state = {
    edit: false,
    init_params: {
      email: this.props.user.email,
      oldPassword: this.props.user.basic.oldPassword,
      password: "",
      confirm_password: "",
    },
    params: {
      email: this.props.user.email,
      oldPassword: "",
      password: "",
      confirm_password: "",
    },
    errors: {},
    types: {
      oldPassword: "password",
      password: "password",
      confirm_password: "password",
    },
  };

  checked_password = this.props.user.basic.password;

  EmailInput = React.createRef();
  BasicBtn = React.createRef();

  passwordVisibleHandler(key, value) {
    const { types } = this.state;
    types[key] = value;
    this.setState({ types: types });
  }

  postfix(key) {
    const { types } = this.state;
    return types[key] === "password" ? (
      <EyeOffIcon
        onClick={() => this.passwordVisibleHandler(key, "text")}
        className="h-5 w-5 cursor-pointer"
      />
    ) : (
      <EyeIcon
        onClick={() => this.passwordVisibleHandler(key, "password")}
        className="h-5 w-5 cursor-pointer"
      />
    );
  }

  handlePaste = (event) => {
    event.preventDefault();
    return false;
  };

  render() {
    const { edit, params, errors, types } = this.state;
    const { user } = this.props;

    return (
      <Main>
        <AppRow>
          <div className="flex-1 max-w-min mr-5">
            <ProfileSidebar isDirty={edit} />
          </div>
          <div className="flex-auto">
            <div className="max-w-xl">
              <InputGroup
                error={errors.email}
                label="Email Address"
                attrs={{
                  value: params.email ? params.email : "",
                  maxLength: 64,
                  readOnly: !edit,
                  onChange: (e) => this.onChangeHandler(e, "email"),
                  ref: this.EmailInput,
                  disabled: true,
                }}
              />
              {user?.is_password ? (
                <>
                  <InputGroup
                    error={errors.oldPassword}
                    label="Old Password"
                    requiredMark={edit}
                    placeholder="Enter old password..."
                    valdiateOnChange={true}
                    isError={edit && ""}
                    attrs={{
                      maxLength: 32,
                      value: params.oldPassword ? params.oldPassword : "",
                      readOnly: !edit,
                      onChange: (e) => {
                        this.onChangeHandler(
                          {
                            target: { value: e.target.value.replace(" ", "") },
                          },
                          "oldPassword"
                        );
                      },
                      type: types.oldPassword,
                    }}
                    postfix={edit && this.postfix("oldPassword")}
                  />
                </>
              ) : (
                false
              )}

              <InputGroup
                error={errors?.password}
                requiredMark={edit}
                label="New Password"
                placeholder="Enter new password..."
                isError={edit && ""}
                attrs={{
                  maxLength: 20,
                  value: params.password ? params.password : "",
                  readOnly: !edit,
                  onChange: (e) => {
                    this.onChangeHandler(
                      {
                        target: { value: e.target.value.replace(" ", "") },
                      },
                      "password"
                    );
                  },
                  type: types.password,
                  onPaste: this.handlePaste,
                }}
                postfix={edit && this.postfix("password")}
              />
              <InputGroup
                error={errors.confirm_password}
                requiredMark={edit}
                label="Confirm Password"
                placeholder="Re enter password..."
                isError={edit && ""}
                attrs={{
                  maxLength: 20,
                  value: params.confirm_password ? params.confirm_password : "",
                  readOnly: !edit,
                  onChange: (e) => {
                    this.onChangeHandler(
                      {
                        target: { value: e.target.value.replace(" ", "") },
                      },
                      "confirm_password"
                    );
                  },
                  type: types.confirm_password,
                  onPaste: this.handlePaste,
                }}
                postfix={edit && this.postfix("confirm_password")}
              />
              <div className="mt-1 mb-2">
                <PasswordPolicy />
              </div>
              <div className="mt-8">
                <div className="flex">
                  <div>
                    <Button
                      attrs={{ onClick: this.toggleEdit }}
                      title={!edit ? "Edit password" : "Cancel edit"}
                      display="inline-block"
                      outline={true}
                    >
                      {edit ? (
                        <XIcon width={16} className="mr-1" />
                      ) : (
                        <PencilIcon width={16} className="mr-1" />
                      )}
                    </Button>
                  </div>
                  <div className="flex-1 pl-5">
                    {edit ? (
                      <Button
                        attrs={{
                          type: "button",
                          onClick: this.updatePassword,
                          ref: this.BasicBtn,
                          disabled: !this.isReady(),
                        }}
                        size="md"
                        title={"Update password"}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppRow>
      </Main>
    );
  }

  isReady = () => {
    let ready = true;
    const { oldPassword, password, confirm_password } = this.state.params;
    const { user } = this.props;
    if (user?.is_password && (!oldPassword || !password || !confirm_password)) {
      ready = false;
    } else if (!user?.is_password && (!password || !confirm_password)) {
      ready = false;
    } else if (password === oldPassword) {
      ready = false;
    } else if (
      password &&
      (!validatePassword(password) || password.length < 6)
    ) {
      return false;
    } else if (password !== confirm_password) {
      ready = false;
    }
    return ready;
  };

  onChangeHandler = (e, key) => {
    const { params, errors } = this.state;
    const { oldPassword, password, confirm_password } = params;
    const value = e.target.value;
    if (key === "password") {
      if (value === oldPassword) {
        errors[key] = ["New password must be different from old password"];
      } else if (value && !validatePassword(value)) {
        errors[key] = [
          "Password must contain at least 6 characters one lowercase letter, one uppercase letter, one digit, and one of the following special symbols: !@#$%^&*?",
        ];
      } else {
        errors[key] = null;
      }
      if (value === confirm_password) {
        errors["confirm_password"] = null;
      }
      if (value && confirm_password && value !== confirm_password) {
        errors["confirm_password"] = ["Passwords do not match"];
      }
    } else if (key === "confirm_password") {
      if (value && value !== params.password) {
        errors[key] = ["Passwords do not match"];
      } else {
        errors[key] = null;
      }
    } else if (key === "oldPassword") {
      errors[key] = null;
    }
    params[key] = e.target.value;
    this.setState({ params: params, errors: errors });
  };

  componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: false });
    AppStore.dispatch({ type: "PROGRESS", progress: 0 });
  }

  toggleEdit = () => {
    if (this.state.edit) {
      this.setState({
        edit: !this.state.edit,
        params: { ...this.state.init_params },
        errors: {},
        types: {
          oldPassword: "password",
          password: "password",
          confirm_password: "password",
        },
      });
      this.setState({ errors: {} });
    } else {
      this.EmailInput.current.focus();
      this.setState({
        ...this.state,
        edit: !this.state.edit,
      });
    }
  };

  updatePassword = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ errors: {} }, async () => {
      const { email, oldPassword, password, confirm_password } =
        this.state.params;
      const request = {
        email: email,
        oldPassword: oldPassword,
        password: password,
        confirm_password: confirm_password,
      };

      const response = await ApiPost("register/change-password", request);
      if (response?.status === "error") {
        if (response?.message) {
          toast.error(response?.message);
        }
        this.setState({ errors: response?.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        if (response?.status === "success") {
          await setMessage(response?.data?.message);
          const someProperty = { ...this.props };
          someProperty.user.is_password = true;
          this.setState(someProperty);
          toast.success(response.message);
          this.setState({
            edit: false,
            params: {
              ...this.state.params,
              confirm_password: "",
              password: "",
              oldPassword: "",
            },
            errors: {},
            types: {
              oldPassword: "password",
              password: "password",
              confirm_password: "password",
            },
          });
        } else {
          await setError(response.message);
        }
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ edit: false });
      }
    });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <ChangePassword {...props} />;
});
