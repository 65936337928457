import React, { useState, useEffect } from "react";
import { ErrorMessage, Field } from "formik";
import InputSelect from "../../components/formikForm/InputSelect";
import { AppHeading } from "../../components/AppComponents";
import InputGroup from "../../components/formikForm/InputGroup";
import InputLabel from "../../components/form/InputLabel";

const IndividualProduct = ({ values, setFieldValue, specificationData }) => {
  const [hospitalSlabOptions, setHospitalSlabOptions] = useState({
    slab2: [],
    slab3: [],
    slab4: [],
  });
  const [icuSlab, setIcuSlab] = useState([]);
  const [subProduct, setSubProduct] = useState([]);

  useEffect(() => {
    setSubProduct([]);
    setFieldValue("specification", "");
    if (specificationData.doc) {
      setSubProduct((pre) => [
        ...pre,
        { value: "DOC", label: "DoctorConsultation" },
      ]);
    }
    if (specificationData.hospital_cash) {
      setSubProduct((pre) => [...pre, { value: "HCH", label: "HospitalCash" }]);
    }
    if (specificationData.accidental) {
      setSubProduct((pre) => [...pre, { value: "AC", label: "Accidental" }]);
    }
    if (specificationData.insurance) {
      setSubProduct((pre) => [...pre, { value: "INC", label: "Insurance" }]);
    }
    if (specificationData.cloan) {
      setSubProduct((pre) => [...pre, { value: "CLOAN", label: "Credit Life Insurance" }]);
    }
  }, [specificationData]);

  const onSlabHandler = (label, e) => {
    const value = e.target.value;
    const newVal = parseInt(value.split("-")[1]);

    let data = [];
    for (let i = 1; i <= 6; i++) {
      data.push({
        value: `${newVal + 1}-${newVal + i * 5}`,
        label: `${newVal + 1}-${newVal + i * 5}`,
      });
    }

    setHospitalSlabOptions({ ...hospitalSlabOptions, [label]: data });
  };

  const onIcuSlabChange = (value) => {
    const newVal = parseInt(value.split("-")[1]);
    let data = [];
    for (let i = 1; i <= 6; i++) {
      data.push({
        value: `${newVal + 1}-${newVal + i * 5}`,
        label: `${newVal + 1}-${newVal + i * 5}`,
      });
    }
    setIcuSlab(data);
  };

  return (
    <>
      <div>
        <p className="text-lg mt-3">Specification</p>
        <hr />
        <div className="text-xs text-red-500">
          <ErrorMessage name="specification" />
        </div>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="flex justify-around mt-4"
        >
          {subProduct.map((item, index) => (
            <div key={index} className="mt-2">
              <div
                className="flex"
                onClick={() => setFieldValue("specification", item.value)}
              >
                <Field
                  type="radio"
                  name="specification"
                  value={item.value}
                  className="mr-3 cursor-pointer"
                  id={item.value}
                />
                <InputLabel label={item.label} for={item.value} />
              </div>
              {values.specification === item.value
                ? (item.value === "AC" || item.value === "HCH") && (
                  <div className="mt-3">
                    <InputGroup
                      label="Max Claim Amount"
                      name="claimAmount"
                      requiredMark="true"
                      placeholder=""
                      placeHolder="0"
                      value={values.claimAmount}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (/^[0-9\b]+$/.test(e.target.value) &&
                            /^(?:\d{0,7}|.*?\D.*)$/.test(e.target.value))
                        ) {
                          setFieldValue("claimAmount", e.target.value);
                        }
                      }}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="claimAmount" />
                    </div>
                  </div>
                )
                : false}
            </div>
          ))}
        </div>
      </div>

      {values.specification === "HCH" ? (
        <>
          <AppHeading className="mt-6">Hospital Slab</AppHeading>
          <hr />
          <div className="mt-4 grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex">
              <Field
                type="checkbox"
                name="isHosSlab1"
                id="isHosSlab1"
                className="mr-3 cursor-pointer"
                onChange={(e) => {
                  setFieldValue("isHosSlab1", e.target.checked);
                  setFieldValue("isHosSlab2", false);
                  setFieldValue("isHosSlab3", false);
                  setFieldValue("isHosSlab4", false);
                }}
              />
              <InputLabel label="Hospital Slab 1" requiredMark="true" for="isHosSlab1" />

              <div className="text-xs text-red-500">
                <ErrorMessage name="isHosSlab1" />
              </div>
            </div>
            {values.isHosSlab1 ? (
              <>
                <div>
                  <InputSelect
                    requiredMark="true"
                    label="Days"
                    name="hospitalSlab1.days"
                    onChange={(e) => {
                      setFieldValue("hospitalSlab1.days", e.target.value);
                      onSlabHandler("slab2", e);
                      setFieldValue("isHosSlab2", false);
                      setFieldValue("isHosSlab3", false);
                      setFieldValue("isHosSlab4", false);
                    }}
                    options={[
                      { value: "1-10", label: "1-10" },
                      { value: "1-15", label: "1-15" },
                      { value: "1-20", label: "1-20" },
                      { value: "1-25", label: "1-25" },
                      { value: "1-30", label: "1-30" },
                      { value: "1-40", label: "1-40" },
                      { value: "1-50", label: "1-50" },
                    ]}
                  />
                  <div className="text-xs text-red-500">
                    <ErrorMessage name="hospitalSlab1.days" />
                  </div>
                </div>
                <div>
                  <InputGroup
                    label="Amount"
                    name="hospitalSlab1.amount"
                    requiredMark="true"
                    placeholder="0"
                    value={values.hospitalSlab1.amount}
                    onChange={(e) => {
                      if (
                        e.target.value === "" ||
                        (/^[0-9\b]+$/.test(e.target.value) &&
                          /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                      ) {
                        setFieldValue("hospitalSlab1.amount", e.target.value);
                      }
                    }}
                  />
                  <div className="text-xs text-red-500">
                    <ErrorMessage name="hospitalSlab1.amount" />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {values.isHosSlab1 ? (
            <div className="mt-4 grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex">
                <Field
                  type="checkbox"
                  className="mr-3 cursor-pointer"
                  name="isHosSlab2"
                  onChange={(e) => {
                    setFieldValue("isHosSlab2", e.target.checked);
                    setFieldValue("isHosSlab3", false);
                    setFieldValue("isHosSlab4", false);
                  }}
                />
                <InputLabel label="Hospital Slab 2" />
              </div>

              {values.isHosSlab2 ? (
                <>
                  <div>
                    <InputSelect
                      requiredMark="true"
                      label="Days"
                      name="hospitalSlab2.days"
                      onChange={(e) => {
                        setFieldValue("hospitalSlab2.days", e.target.value);
                        onSlabHandler("slab3", e);
                        setFieldValue("isHosSlab3", false);
                        setFieldValue("isHosSlab4", false);
                      }}
                      options={hospitalSlabOptions?.slab2}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="hospitalSlab2.days" />
                    </div>
                  </div>
                  <div>
                    <InputGroup
                      label="Amount"
                      name="hospitalSlab2.amount"
                      requiredMark="true"
                      placeholder="0"
                      value={values.hospitalSlab2.amount}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (/^[0-9\b]+$/.test(e.target.value) &&
                            /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                        ) {
                          setFieldValue("hospitalSlab2.amount", e.target.value);
                        }
                      }}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="hospitalSlab2.amount" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {values.isHosSlab2 && values.isHosSlab1 ? (
            <div className="mt-4 grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex">
                <Field
                  type="checkbox"
                  className="mr-3 cursor-pointer"
                  name="isHosSlab3"
                  onChange={(e) => {
                    setFieldValue("isHosSlab3", e.target.checked);
                    setFieldValue("isHosSlab4", false);
                  }}
                />
                <InputLabel label="Hospital Slab 3" />
              </div>

              {values.isHosSlab3 ? (
                <>
                  <div>
                    <InputSelect
                      requiredMark="true"
                      label="Days"
                      name="hospitalSlab3.days"
                      onChange={(e) => {
                        setFieldValue("hospitalSlab3.days", e.target.value);
                        onSlabHandler("slab4", e);
                        setFieldValue("isHosSlab4", false);
                      }}
                      options={hospitalSlabOptions?.slab3}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="hospitalSlab3.days" />
                    </div>
                  </div>
                  <div>
                    <InputGroup
                      label="Amount"
                      name="hospitalSlab3.amount"
                      requiredMark="true"
                      placeholder="0"
                      value={values.hospitalSlab3.amount}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (/^[0-9\b]+$/.test(e.target.value) &&
                            /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                        ) {
                          setFieldValue("hospitalSlab3.amount", e.target.value);
                        }
                      }}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="hospitalSlab3.amount" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {values.isHosSlab3 && values.isHosSlab2 && values.isHosSlab1 ? (
            <div className="mt-4 grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex">
                <Field
                  type="checkbox"
                  className="mr-3 cursor-pointer"
                  name="isHosSlab4"
                />
                <InputLabel label="Hospital Slab 4" />
              </div>
              {values.isHosSlab4 ? (
                <>
                  <div>
                    <InputSelect
                      requiredMark="true"
                      label="Days"
                      name="hospitalSlab4.days"
                      onChange={(e) => {
                        setFieldValue("hospitalSlab4.days", e.target.value);
                      }}
                      options={hospitalSlabOptions?.slab4}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="hospitalSlab4.days" />
                    </div>
                  </div>
                  <div>
                    <InputGroup
                      label="Amount"
                      name="hospitalSlab4.amount"
                      requiredMark="true"
                      placeholder="0"
                      value={values.hospitalSlab4.amount}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (/^[0-9\b]+$/.test(e.target.value) &&
                            /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                        ) {
                          setFieldValue("hospitalSlab4.amount", e.target.value);
                        }
                      }}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="hospitalSlab4.amount" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          <div className="mt-10">
            <AppHeading className="mt-6">ICU SLAB</AppHeading>
            <hr />
            <div className="mt-4 grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex">
                <Field
                  type="checkbox"
                  className="mr-3 cursor-pointer"
                  name="isICUSlab1"
                  id="isICUSlab1"
                  onChange={(e) => {
                    setFieldValue("isICUSlab1", e.target.checked);
                    setFieldValue("isICUSlab2", false);
                  }}
                />
                <InputLabel label="ICU Slab 1 "
                  for="isICUSlab1"
                  requiredMark="true"
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="isICUSlab1" />
                </div>
              </div>
              {values.isICUSlab1 ? (
                <>
                  <div>
                    <InputSelect
                      requiredMark="true"
                      label="Days"
                      name="icuSlab1.days"
                      onChange={(e) => {
                        setFieldValue("icuSlab1.days", e.target.value);
                        onIcuSlabChange(e.target.value);
                      }}
                      options={[
                        { value: "1-10", label: "1-10" },
                        { value: "1-15", label: "1-15" },
                        { value: "1-20", label: "1-20" },
                        { value: "1-25", label: "1-25" },
                        { value: "1-30", label: "1-30" },
                      ]}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="icuSlab1.days" />
                    </div>
                  </div>

                  <div>
                    <InputGroup
                      label="Amount"
                      name="icuSlab1.amount"
                      requiredMark="true"
                      placeholder="0"
                      value={values.icuSlab1.amount}
                      onChange={(e) => {
                        if (
                          e.target.value === "" ||
                          (/^[0-9\b]+$/.test(e.target.value) &&
                            /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                        ) {
                          setFieldValue("icuSlab1.amount", e.target.value);
                        }
                      }}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="icuSlab1.amount" />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            {values.isICUSlab1 ? (
              <div className="mt-4 grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4">
                <div className="flex">
                  <Field
                    type="checkbox"
                    className="mr-3 cursor-pointer"
                    name="isICUSlab2"
                  />
                  <InputLabel label="ICU Slab 2" />
                </div>
                {values.isICUSlab2 ? (
                  <>
                    <div>
                      <InputSelect
                        requiredMark="true"
                        label="Days"
                        name="icuSlab2.days"
                        onChange={(e) => {
                          setFieldValue("icuSlab2.days", e.target.value);
                        }}
                        options={icuSlab}
                      />
                      <div className="text-xs text-red-500">
                        <ErrorMessage name="icuSlab2.days" />
                      </div>
                    </div>
                    <div>
                      <InputGroup
                        label="Amount"
                        name="icuSlab2.amount"
                        requiredMark="true"
                        placeholder="0"
                        value={values.icuSlab2.amount}
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            (/^[0-9\b]+$/.test(e.target.value) &&
                              /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                          ) {
                            setFieldValue("icuSlab2.amount", e.target.value);
                          }
                        }}
                      />
                      <div className="text-xs text-red-500">
                        <ErrorMessage name="icuSlab2.amount" />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        null
      )}
    </>
  );
};

export default IndividualProduct;
