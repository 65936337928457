import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import useMobileDetect from "../useMobileDetect";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

export default function PdfViewer({
  myFile,
  pdfData,
  height = "100%",
  width = "100%",
  scale,
}) {
  const [numPages, setNumPages] = useState(null);
  const { isMobile } = useMobileDetect();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div
      style={{
        height: height,
        width: width,
        overflow: "hidden",
        minHeight: "200px",
      }}
    >
      <Document
        file={pdfData ? { data: pdfData } : myFile}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div className="h-32 w-32 flex justify-center items-center">
            <CircularProgress />
          </div>
        }
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            scale={scale ? scale : isMobile() ? 0.4 : 1.4}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </div>
  );
}
