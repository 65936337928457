import React, { useState, useCallback, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SelectPicker, Loader } from "rsuite";
import InputLabel from "../../components/form/InputLabel";
import { debounce } from "lodash";
import Button from "@mui/material/Button";

const InfintyDropdown = ({
  attrs: { value, disabled = false, searchParam, searchId, payload },
  label,
  name,
  request,
  endpoint,
  formatter,
  valueField,
  labelField,
  options,
  requiredMark,
  error,
  setValue,
  field,
  customRenderMenu,
  isCleanable = true,
  handelAddNew,
  isAddNew = false,
  regex = /^[A-Za-z\s]*$/,
}) => {
  const [page, setPage] = useState(0);
  const [dropDownData, setDropdownData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const customRenderMenuItem = (value, obj, menu) => {
    return (
      <div
        className="p-4 text-xs space-y-2 border-b-2 cursor-pointer hover:bg-red-500 hover:text-white"
        style={{ width: "100%" }}
        onClick={(e) => menu.props.onSelect(obj?.id, obj, e)}
      >
        <div className="flex items-center justify-between">
          <div className="font-bold text-sm">{value}</div>
        </div>
        <div className="flex items-center justify-between">
          {obj?.state && (
            <div>
              State: <span className="font-medium">{obj.state}</span>
            </div>
          )}
          {obj?.city && (
            <div>
              City: <span className="font-medium">{obj.city}</span>
            </div>
          )}
        </div>
      </div>
    );
  };
  const defaultFormatter = useCallback(
    (option) => {
      if (!option) {
        return {};
      }
      if (formatter) {
        return formatter(option);
      }
      return {
        value: String(option[valueField]),
        label: String(option[labelField]),
        ...option,
      };
    },
    [valueField, labelField]
  );
  const fetchData = async (pg, id) => {
    const currentPage = pg !== undefined ? pg : page;
    let sid = id === "null" ? null : id;

    if (pg === 0) {
      setPage(0);
    }
    setLoading(true);
    try {
      let data;
      if (payload) {
        data = {
          page: currentPage,
          size: 15,
          [searchParam]: search,
          id: sid || "",
          ...payload,
        };
      } else {
        data = {
          page: currentPage,
          size: 15,
          [searchParam]: search,
          id: sid || "",
        };
      }

      const res = await request(endpoint, data);

      if (res?.data?.content) {
        let newArray = [];
        if (search !== "" || currentPage === 0) {
          newArray = res.data.content.map((obj) => defaultFormatter(obj));
          setDropdownData(newArray);
        } else {
          res.data.content.forEach((obj) => {
            const exists = dropDownData.some((item) => item.id === obj.id);
            if (!exists) {
              newArray.push(defaultFormatter(obj));
            }
          });
          setDropdownData([...dropDownData, ...newArray]);
        }
        setCount(res.data.totalElements ?? 0); // Ensure count is set to 0 if total is null/undefined
      } else if (search !== "" && res.data === null) {
        setDropdownData([]);
      } else {
        console.warn("No content data available");
      }
    } catch (err) {
      setDropdownData([]);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const getSearch = useCallback(debounce(fetchData, 1000), [search]);

  useEffect(() => {
    if (request && searchId) {
      fetchData(0, searchId);
    } else if (request) {
      fetchData(0);
    } else if (options) {
      const updated = options.map((item) => defaultFormatter(item));
      setDropdownData(updated);
    }
  }, [
    request,
    JSON.stringify(options),
    endpoint,
    searchId,
    JSON.stringify(payload),
  ]);

  useEffect(() => {
    if (page === 0) return;
    fetchData(page);
  }, [page]);

  useEffect(() => {
    if (search?.trim().length > 1) getSearch(0);

    return getSearch.cancel;
  }, [search]);
  return (
    <div className="mb-5 mt-1">
      <InputLabel label={label} requiredMark={requiredMark} />
      <div className="mt-2 relative">
        <SelectPicker
          open={open}
          onClick={() => setOpen(!open)}
          data={dropDownData || []}
          searchable={true}
          size="lg"
          name={name}
          searchBy={(data, item, items) => {
            return true;
          }}
          placement="autoVerticalEnd"
          value={`${value}`}
          style={{ width: "100%" }}
          placeholder="Select"
          onSelect={(value, item, event) => {
            setValue({ [field]: value }, item);
            setOpen(false);
          }}
          onClean={() => {
            setValue({ [field]: null });
            setSearch("");
            setOpen(false);
          }}
          cleanable={isCleanable}
          onSearch={(value, event) => {
            if (event !== undefined && regex.test(value)) setSearch(value);
          }}
          onExit={() => {
            fetchData(0, searchId ? searchId : "", false);
          }}
          renderMenu={(menu) => {
            return (
              <InfiniteScroll
                dataLength={dropDownData?.length || 0}
                next={() => {
                  if (dropDownData.length > 3 && count !== dropDownData?.length)
                    setPage((prev) => prev + 1);
                }}
                hasMore={count !== dropDownData?.length}
                loader={
                  loading && (
                    <h4 className="text-center">
                      <Loader size="xs" speed="fast" />
                    </h4>
                  )
                }
                height={menu?.props?.data?.length ? 270 : 80}
                scrollableTarget="scrollableDiv"
                className="flex flex-col items-center"
              >
                {dropDownData?.length ? (
                  menu?.props?.data?.map((item, index) =>
                    customRenderMenu
                      ? customRenderMenu(item.label, item, menu)
                      : customRenderMenuItem(item.label, item, menu)
                  )
                ) : loading ? (
                  ""
                ) : isAddNew ? (
                  <div>
                    <Button
                      variant="contained"
                      style={{
                        background: "#Db2228",
                        color: "white",
                        width: "100%",
                      }}
                      onClick={() => {
                        handelAddNew(search);
                        setOpen(false);
                        setSearch("");
                      }}
                    >
                      Add new
                    </Button>

                    <p style={{ fontSize: "12px", marginBottom: "5px" }}>
                      This record is not in the list. Please use the "Add New"
                      <br />
                      button to add it.
                    </p>
                  </div>
                ) : (
                  <div className="text-center">No data found</div>
                )}
              </InfiniteScroll>
            );
          }}
        />
        {error ? <p className="mt-2 text-xs text-red-500">{error}</p> : false}
      </div>
    </div>
  );
};

export default InfintyDropdown;
