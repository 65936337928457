import React, { memo, useEffect, useState } from "react";
import { getProduct } from "./getProduct";
import { InputRadio } from "../form/InputRadio";
import { useSelector } from "react-redux";
import ShimmerLoader from "../common/ShimmerLoader";
import { JavaApiGet, getResellerId } from "../../lib/AppHelper";
import { useLocation } from "react-router-dom";

const GetResellerAndProduct = ({
  current,
  onProductChange = () => {},
  product_id,
  loader,
  uploaderType,
  showProduct = true,
}) => {
  const location = useLocation();

  const resellerId = location?.state?.id || getResellerId();
  const { products } = useSelector((state) => state);
  const [loading, setLoading] = useState(loader);
  const [productList, setProductList] = useState([]);

  const getResellerProductList = async () => {
    if (location?.state?.products && uploaderType !== "group_uploader") {
      setProductList(location?.state?.products);
    } else if (uploaderType === "group_uploader") {
      getProductGroupId();
    } else {
      setProductList(products);
    }
  };

  const getProductGroupId = async (id = null) => {
    setLoading(true);
    JavaApiGet(
      `enrollment-service/uploader/api/product_group_details/${resellerId}`
    )
      .then((res) => {
        if (res.code === 200) {
          const data = res?.responseObject.map((elem) => {
            return { ...elem, name: elem.groupName };
          });
          setProductList(data);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getResellerProductList();
  }, []);

  const onProductChangeHandler = (e) => {
    onProductChange(e);
  };

  const items = getProduct(productList, current, product_id, uploaderType);

  return (
    <div>
      {showProduct && (
        <>
          {loading ? (
            <div className="mt-4">
              <ShimmerLoader rows={4} />
            </div>
          ) : items.length > 0 ? (
            <>
              <p
                style={{
                  fontSize: "18px",
                  marginLeft: "13px",
                  marginTop: "30px",
                }}
              >
                {current === 0 ? "Please select a " : "Selected "}
                product -
              </p>

              <InputRadio
                value={product_id}
                buttonHidden={true}
                attrs={{
                  onChange: onProductChangeHandler,
                }}
                inline={true}
                label={false}
                options={items}
              />
            </>
          ) : (
            <div className={`flex flex-col justify-center items-center`}>
              <p className="text-center text-gray-500">"Product's not found"</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(GetResellerAndProduct);
