import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";
import Button from "../../components/Button";
import { InputGroup } from "../../components/form/InputGroup";
import { InputDate } from "../../components/form/InputDate";
import { toast } from "react-toastify";
import { InputFile } from "../../components/form/InputFile";
import Compressor from "compressorjs";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { InputRadio } from "../../components/form/InputRadio";
import moment from "moment";
import { Loader } from "rsuite";
import { DRAFT, FINAL, ImageType } from "./constant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MemberAddDialog({
  open,
  setNewMember,
  newMember,
  onSubmit,
  handleClose,
  setDocument,
  document,
  loading,
  imageLoading,
}) {
  const [age, setAge] = React.useState();

  React.useEffect(() => {
    setAge(null);
  }, [open]);
  const validationData = () => {
    if (
      !newMember.name ||
      !newMember.phone ||
      !newMember.gender ||
      !newMember.dob
    ) {
      toast.warning("Please enter required field");
    } else if (newMember.phone.length < 10) {
      toast.warning("Please enter 10 digit phone number");
    } else if (document === null) toast.warning("Please add prescription");
    else onSubmit(FINAL);
  };
  const validationDataAsDraft = () => {
    if (
      !newMember.name ||
      !newMember.phone ||
      !newMember.gender ||
      !newMember.dob
    ) {
      toast.warning("Please enter required field");
    } else if (newMember.phone.length < 10) {
      toast.warning("Please enter 10 digit phone number");
    } else {
      onSubmit(DRAFT);
    }
  };

  const onChangeFileHandeler = (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      let mimeType = file?.["type"];
      if (mimeType !== "application/pdf" && !ImageType.includes(mimeType)) {
        toast.warning("Only images or PDFs are allowed");
        return;
      }
      const fileSize = file?.size;
      if (fileSize > 4100000 && mimeType == "application/pdf") {
        toast.warning("Pdf size should not be more than 5 MB");
        setDocument(null);
        return;
      } else if (ImageType.includes(mimeType)) {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            if (result?.size < 4100000) {
              setDocument(result);
            } else {
              toast.warning("Image size should not be more than 5 mb");
              return;
            }
          },
          error(err) {
            console.error(err.message);
          },
        });
      } else {
        setDocument(file);
      }
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Member
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <InputGroup
            label="Name"
            requiredMark
            placeholder=""
            attrs={{
              value: newMember.name,
              onChange: (e) => {
                const value = e.target.value;
                const alphabetAndSpaceOnlyValue = value.replace(
                  /[^a-zA-Z\s]/g,
                  ""
                );
                if (alphabetAndSpaceOnlyValue?.length < 40)
                  setNewMember({
                    ...newMember,
                    name: alphabetAndSpaceOnlyValue,
                  });
              },
            }}
            valdiateOnChange={true}
            validateError={(value) => {
              if (value?.length > 39) return "Maxium input length is 40";
            }}
          />

          <InputGroup
            label=" phone"
            requiredMark
            placeholder="0000000000"
            type="tel"
            prefix="+91"
            attrs={{
              maxLength: 10,
              value: newMember.phone,
              onChange: (e) => {
                const integerRegex = /^-?\d+$/;
                const isValidInteger = integerRegex.test(e.target.value);
                if (isValidInteger || e.target.value == "") {
                  setNewMember({ ...newMember, phone: e.target.value });
                }
              },
            }}
          />
          <InputGroup
            label="Loan code/Reference number"
            placeholder=""
            attrs={{
              value: newMember.loanCode,
              onChange: (e) => {
                if (e?.target?.value?.length < 30)
                  setNewMember({ ...newMember, loanCode: e.target.value });
              },
            }}
            valdiateOnChange={true}
            validateError={(value) => {
              if (value?.length > 29) return "Maxium input length is 30";
            }}
          />
          <InputGroup
            label="Adhaar card"
            type="tel"
            placeholder=""
            attrs={{
              value: newMember.governmentId,
              maxLength: 12,
              onChange: (e) => {
                const integerRegex = /^-?\d+$/;
                const isValidInteger = integerRegex.test(e.target.value);
                if (isValidInteger || e.target.value == "") {
                  setNewMember({ ...newMember, governmentId: e.target.value });
                }
              },
            }}
            valdiateOnChange={true}
            validateError={(value) => {
              if (value?.length > 29) return "Maxium input length is 30";
            }}
          />
          <div className="flex gap-2 flex-wrap">
            <InputGroup
              label="Age"
              placeholder="00"
              type="tel"
              attrs={{
                style: { width: "80px" },
                maxLength: 2,
                value: age,
                onChange: (e) => {
                  const value = e.target.value;
                  const integerRegex = /^-?\d+$/;
                  const isValidInteger = integerRegex.test(value);
                  if (isValidInteger || value == "") {
                    setAge(value);
                    if (value > 0 && value <= 70)
                      setNewMember({
                        ...newMember,
                        dob: moment().subtract(value, "years"),
                      });
                    else {
                      setNewMember({
                        ...newMember,
                        dob: "",
                      });
                    }
                  }
                },
              }}
            />
            <div className="flex-grow">
              <InputDate
                requiredMark
                label="D.O.B"
                placeholder="DD-MM-YYYY"
                maxDate={new Date(moment().format("YYYY-MM-DD"))}
                attrs={{
                  style: { marginBottom: "15px" },
                  value: newMember?.dob ? new Date(newMember.dob) : null,
                  onChange: (value) => {
                    setNewMember({ ...newMember, dob: value });
                    setAge(moment().diff(value, "years"));
                  },
                }}
                isError={newMember?.dob ? false : ""}
              />
            </div>
          </div>
          <InputRadio
            label="Gender"
            requiredMark="true"
            buttonHidden={false}
            options={[
              { label: "Male", value: "m" },
              { label: "Female", value: "f" },
              { label: "Others", value: "o" },
            ]}
            value={newMember.gender}
            attrs={{
              onChange: (e) =>
                setNewMember({ ...newMember, gender: e.target.value }),
            }}
          />
          <InputFile
            label="Upload prescription"
            starMark
            file={document || null}
            attrs={{
              onChange: (e) => onChangeFileHandeler(e),
              accept: "image/*,.pdf",
            }}
            onDocDelete={() => setDocument(null)}
            placeholder={
              !document && (
                <div className="text-center items-center flex-col inline-flex">
                  <PlusCircleIcon className="text-primary" width={24} />
                  <p className="mt-2 text-center text-sm text-primary">
                    Upload prescription.
                    <br />
                    (png, jpg, jpeg and pdf)
                    <br />
                    (max size 5MB)
                    <br />
                  </p>
                </div>
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            title=""
            display="inline-block"
            outline={true}
            attrs={{
              onClick: () => validationDataAsDraft(),
              disabled: document || loading,
            }}
          >
            {loading === DRAFT ? (
              <Loader size="sm" style={{ color: "white" }} />
            ) : (
              "Save as draft"
            )}
          </Button>

          <Button
            title=""
            attrs={{
              onClick: () => validationData(),
              disabled: loading || imageLoading,
            }}
          >
            {loading === FINAL || imageLoading ? (
              <Loader size="sm" style={{ color: "white" }} />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
