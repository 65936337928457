import React, { useEffect, useState } from "react";
import TextInput from "../../../StepClaimForm/TextInput";
import { InputDate } from "../../../../components/form/InputDate";
import { InputTextArea } from "../../../../components/form/InputTextArea";
import { Button, CircularProgress } from "@mui/material";
import moment from "moment";
import { JavaApiGet, JavaApiPost } from "../../../../lib/AppHelper";
import CustomSelect from "../../../../components/CustomSelect";

const AiqaVendorPaymentStatus = ({
  rowData,
  handleSuccess,
  handelError,
  vendorList,
}) => {
  const [values, setValues] = useState({
    expectedAmount: "",
    amount: "",
    paymentDate: new Date(),
    vendor: rowData?.vendorCode,
    remarks: "",
    productSpecification: rowData?.productSpecification,
  });
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function validateData() {
    const { expectedAmount, paymentDate, vendor } = values;
    return expectedAmount && paymentDate && vendor;
  }
  async function saveData() {
    setLoading(true);
    const data = {
      batchId: rowData?.batchId,
      expectedAmount: values?.expectedAmount,
      paymentDate: moment(values.paymentDate).format("YYYY-MM-DD"),
      remarks: values?.remarks,
      vendor: values?.vendor,
      amount: values?.amount,
      productSpecification: values?.productSpecification,
    };
    try {
      const response = await JavaApiPost(
        "enrollment-service/report/api/aiqa_to_vendor_payment",
        data
      );
      if (response.code === 200) {
        setLoading(false);
        handleSuccess(response.message);
      } else {
        handelError(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  const fetchProductSpecificationData = async () => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/get_batch_details_specification_wise?batchId=${rowData.batchId}`
      );
      if (response.code === 200) {
        let product = response?.responseObject.find(
          (item) => item?.productSpecification === rowData.productSpecification
        );
        setProduct(product);
        setValues({
          ...values,
          expectedAmount: product?.productAmount,
        });
      } else {
      }
    } catch (error) {}
  };
  const getHistoryDetails = async () => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/aiqa_to_vendor_payment_details?batchId=${rowData?.batchId}`
      );
      if (response.code === 200) {
        if (response.responseObject.length === 0) setIsOpen(true);
        else setHistoryDetails(response?.responseObject);
      } else {
        setIsOpen(true);
        console.log(response.message);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchProductSpecificationData();
    getHistoryDetails();
  }, []);

  return (
    <>
      {historyDetails.length > 0 &&
        historyDetails?.map((details) => {
          return (
            <div
              style={{
                width: "100%",
                padding: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

                borderRadius: "12px",
                marginBottom: "10px",
              }}
            >
              <div>
                Batch Id:&nbsp;
                <span style={{ fontWeight: "600" }}>{details?.batchId}</span>
              </div>
              <div>
                <span>
                  Amount:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {details?.amount} &#8377;/-
                  </span>
                </span>{" "}
                &nbsp;&nbsp;
                <span>
                  Payment Date:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {moment(details?.paymentDate).format("DD-MM-YYYY")}
                  </span>
                </span>
                &nbsp;&nbsp;
                <span>
                  Number of polices:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {details?.policyCount}
                  </span>
                </span>
              </div>
              <div>
                <span>
                  Vendor name:{" "}
                  <span style={{ fontWeight: "600" }}>{details?.vendor}</span>
                </span>{" "}
                &nbsp;&nbsp;
                <span>
                  Product specification:{" "}
                  <span style={{ fontWeight: "600" }}>
                    {details?.productSpecification}
                  </span>
                </span>
              </div>
            </div>
          );
        })}
      {historyDetails.length > 0 && (
        <button
          onClick={() => setIsOpen(!isOpen)}
          style={{
            width: "100%",
            background: "#db2228",
            color: "white",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {!isOpen ? "Add new payment details" : "Close payment details"}
        </button>
      )}
      <div
        style={{
          visibility: isOpen ? "visible" : "hidden",
          marginTop: "10px",
        }}
        className="grid grid-cols-2 gap-4"
      >
        <TextInput
          label="Product specification"
          requiredMark
          attrs={{
            maxLength: 20,
            disabled: true,
            value: values?.productSpecification,
          }}
        />
        <TextInput
          label="Expected amount"
          requiredMark
          attrs={{
            disabled: true,
            maxLength: 6,
            value: values?.expectedAmount,

            onChange: (e) => {
              const value = e.target.value;
              if (
                value === "" ||
                (/^\d+$/.test(value) &&
                  value <= product.productAmount &&
                  value > 0)
              )
                setValues({ ...values, expectedAmount: value });
            },
          }}
        />

        <TextInput
          label="Amount"
          requiredMark
          attrs={{
            maxLength: 6,
            value: values?.amount,
            onChange: (e) => {
              const value = e.target.value;
              if (
                value === "" ||
                (/^\d+$/.test(value) &&
                  value <= values?.expectedAmount &&
                  value > 0)
              )
                setValues({ ...values, amount: value });
            },
          }}
        />

        <InputDate
          label="Payment sent date"
          placeholder="payment date"
          maxDate={new Date()}
          attrs={{
            value: new Date(values?.paymentDate),
            onChange: (value) => {
              setValues({ ...values, paymentSentDate: value });
            },
          }}
        />

        <TextInput
          label="Vendor"
          requiredMark
          attrs={{
            disabled: true,
            value: values?.vendor,

            onChange: (e) => {
              const value = e.target.value;
              if (value === "" || /^\d+$/.test(value))
                setValues({ ...values, vendor: value });
            },
          }}
        />

        <InputTextArea
          label="Remarks"
          attrs={{
            maxLength: 256,
            value: values?.remarks,
            onChange: (e) => {
              setValues({ ...values, remarks: e.target.value });
            },
          }}
        />
      </div>
      {isOpen && (
        <div style={{ width: "100%", marginTop: "10px" }}>
          <Button
            onClick={saveData}
            variant="contained"
            style={{
              background: !validateData() ? "grey" : "#DB2228",
              width: "100%",
            }}
            disabled={!validateData()}
          >
            {loading ? (
              <CircularProgress size="20px" style={{ color: "white" }} />
            ) : (
              "SAVE"
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default AiqaVendorPaymentStatus;
