import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { InputFile } from "../../../components/form/InputFile";
import { Loader } from "rsuite";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ImageModal = ({ open, loading, file, onclose }) => {
  return (
    <BootstrapDialog
      open={open}
      onClose={() => {
        if (!loading) {
          onclose();
        }
      }}
    >
      <h5 className="text-md font-medium px-3 mt-2">Document</h5>
      <span className="text-sm font-medium px-4">{file?.name}</span>
      <IconButton
        aria-label="close"
        onClick={() => {
          if (!loading) {
            onclose();
          }
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        disabled={loading}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {loading ? (
          <div
            style={{
              height: "300px",
              width: "300px",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <InputFile
            isZoom={true}
            file={file}
            readOnly={true}
            paddingY="1"
            attrs={{
              accept: "image/*,.pdf",
              style: { marginBottom: 1 },
            }}
          />
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ImageModal;
