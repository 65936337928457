import { array } from "yup";

export const initialValues = {
  productName: "",
  productDisplayName: "",
  tenure: "",
  productTenorType: "",
  paymentMode: "",
  productBasePrice: "",
  gst: 0,
  productMRP: "",
  sellableToIndividual: "",
  productDescription: "",
  productCategory: "",
  memberCoverd: "",
  partner: "",
  claimAmount: 0,
  specification: "",
  paymentPartner: "",
  productClasification: "Individual",
  productBenefitText: "",
  vendorPlanId: "",
  vendorPlanName: "",
  eligibleForClaim: "",
  sumAssuredCode: "",
  claimDoc: [],
  hospitalSlab1: {
    days: "",
    amount: "",
  },
  hospitalSlab2: {
    days: "",
    amount: "",
  },
  hospitalSlab3: {
    days: "",
    amount: "",
  },
  hospitalSlab4: {
    days: "",
    amount: "",
  },
  icuSlab1: {
    days: "",
    amount: "",
  },
  icuSlab2: {
    days: "",
    amount: "",
  },
  selectedProduct: {
    doctorConsult: { id: "" },
    accidental: { id: "" },
    hospitalCash: { id: "" },
    creditLife: { id: "" },
  },
  productType: "",
  isHosSlab1: false,
  isHosSlab2: false,
  isHosSlab3: false,
  isHosSlab4: false,
  isICUSlab1: false,
  isICUSlab2: false,
};
