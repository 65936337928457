import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { setResellerId } from "./AppHelper";

const mapStateToProps = (state) => {
  const { token, user } = state;
  if (user) {
    setResellerId(user.id);
  }
  return { token: token, user: user };
};

export default connect(mapStateToProps)(({ children, ...rest }) => {
  let registered = null;

  registered = rest.user?.banking ? registered : 3;
  registered = rest.user?.professional ? registered : 2;
  registered = rest.user?.basic ? registered : 1;

  const is_register = useRouteMatch("/register")?.isExact;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (rest.token) {
          if (rest.user.is_admin) {
            return children;
          } else if (is_register && registered === null) {
            return <Redirect to={{ pathname: "/" }} />;
          } else if (registered === null) {
            return children;
          } else if (is_register && registered !== null) {
            return children;
          } else {
            return <Redirect to={{ pathname: "/register" }} />;
          }
        } else {
          return <Redirect to={{ pathname: "/login" }} />;
        }
      }}
    />
  );
});
