export const headers = [
  {
    name: "Reseller_id",
    mandatory: false,
    type: "number",
    dataKey: "Reseller_id",
    columnColor: [],
  },
  {
    name: "Product_id",
    mandatory: false,
    type: "number",
    dataKey: "Product_id",
    columnColor: [],
  },
  { name: "SrNo", mandatory: false, dataKey: "SrNo", columnColor: [] },
  {
    name: "Customer_Id",
    mandatory: true,
    dataKey: "Customer_Id",
    columnColor: [],
  },
  {
    name: "Branch_code",
    mandatory: false,
    dataKey: "Branch_code",
    columnColor: [],
  },
  {
    name: "Branch_name",
    mandatory: false,
    dataKey: "Branch_name",
    columnColor: [],
  },
  {
    name: "Loan_Code",
    mandatory: false,
    dataKey: "Loan_Code",
    columnColor: [],
  },
  {
    name: "Disbursementdate",
    mandatory: true,
    dataKey: "Disbursementdate",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "State", mandatory: false, dataKey: "State", columnColor: [] },
  {
    name: "Pincode",
    mandatory: true,
    type: "number",
    dataKey: "Pincode",
    columnColor: [],
  },
  {
    name: "Cust_Name",
    mandatory: true,
    type: "name",
    dataKey: "Cust_Name",
    columnColor: [],
  },
  {
    name: "Cust_Dob",
    mandatory: true,
    dataKey: "Cust_Dob",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  {
    name: "Cust_Gender",
    mandatory: true,
    dataKey: "Cust_Gender",
    columnColor: [],
  },
  {
    name: "Mobile",
    mandatory: true,
    type: "number",
    dataKey: "Mobile",
    columnColor: [],
  },
  { name: "Email_Id", mandatory: false, dataKey: "Email_Id", columnColor: [] },
  {
    name: "Cust_Id_Type",
    mandatory: true,
    dataKey: "Cust_Id_Type",
    columnColor: [],
  },
  {
    name: "Cust_Id_Number",
    mandatory: true,
    dataKey: "Cust_Id_Number",
    columnColor: [],
  },
  {
    name: "Insured_PMJAY_ID",
    mandatory: false,
    dataKey: "Insured_PMJAY_ID",
    columnColor: [],
  },
  {
    name: "Coinsured_Name",
    mandatory: false,
    dataKey: "Coinsured_Name",
    columnColor: [],
    type: "name",
  },
  {
    name: "Coinsured_Dob",
    mandatory: false,
    dataKey: "Coinsured_Dob",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  {
    name: "Coinsured_Gender",
    mandatory: false,
    dataKey: "Coinsured_Gender",
    columnColor: [],
  },
  {
    name: "Coinsured_Relation",
    mandatory: false,
    dataKey: "Coinsured_Relation",
    columnColor: [],
  },
  {
    name: "Coinsured_Id_Type",
    mandatory: false,
    dataKey: "Coinsured_Id_Type",
    columnColor: [],
  },
  {
    name: "Coinsured_Id_Number",
    mandatory: false,
    dataKey: "Coinsured_Id_Number",
    columnColor: [],
  },
  {
    name: "Coinsured_PMJAY_ID",
    mandatory: false,
    dataKey: "Coinsured_PMJAY_ID",
    columnColor: [],
  },
  {
    name: "Family_1_name",
    mandatory: false,
    dataKey: "Family_1_name",
    columnColor: [],
    type: "name",
  },
  {
    name: "Family_1_DOB",
    mandatory: false,
    dataKey: "Family_1_DOB",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  {
    name: "Family_1_Gender",
    mandatory: false,
    dataKey: "Family_1_Gender",
    columnColor: [],
  },
  {
    name: "Family_1_Relation",
    mandatory: false,
    dataKey: "Family_1_Relation",
    columnColor: [],
  },
  {
    name: "FAMILY_1_PMJAY_ID",
    mandatory: false,
    dataKey: "FAMILY_1_PMJAY_ID",
    columnColor: [],
  },
  {
    name: "Family_2_name",
    mandatory: false,
    dataKey: "Family_2_name",
    columnColor: [],
    type: "name",
  },
  {
    name: "Family_2_DOB",
    mandatory: false,
    dataKey: "Family_2_DOB",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  {
    name: "Family_2_Gender",
    mandatory: false,
    dataKey: "Family_2_Gender",
    columnColor: [],
  },
  {
    name: "Family_2_Relation",
    mandatory: false,
    dataKey: "Family_2_Relation",
    columnColor: [],
  },
  {
    name: "FAMILY_2_PMJAY_ID",
    mandatory: false,
    dataKey: "FAMILY_2_PMJAY_ID",
    columnColor: [],
  },
  {
    name: "Family_3_name",
    mandatory: false,
    dataKey: "Family_3_name",
    columnColor: [],
    type: "name",
  },
  {
    name: "Family_3_DOB",
    mandatory: false,
    dataKey: "Family_3_DOB",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  {
    name: "Family_3_Gender",
    mandatory: false,
    dataKey: "Family_3_Gender",
    columnColor: [],
  },
  {
    name: "Family_3_Relation",
    mandatory: false,
    dataKey: "Family_3_Relation",
    columnColor: [],
  },
  {
    name: "FAMILY_3_PMJAY_ID",
    mandatory: false,
    dataKey: "FAMILY_3_PMJAY_ID",
    columnColor: [],
  },
  {
    name: "Family_4_name",
    mandatory: false,
    dataKey: "Family_4_name",
    columnColor: [],
    type: "name",
  },
  {
    name: "Family_4_DOB",
    mandatory: false,
    dataKey: "Family_4_DOB",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  {
    name: "Family_4_Gender",
    mandatory: false,
    dataKey: "Family_4_Gender",
    columnColor: [],
  },
  {
    name: "Family_4_Relation",
    mandatory: false,
    dataKey: "Family_4_Relation",
    columnColor: [],
  },
  {
    name: "FAMILY_4_PMJAY_ID",
    mandatory: false,
    dataKey: "FAMILY_4_PMJAY_ID",
    columnColor: [],
  },
  {
    name: "Policy_Start_Date",
    mandatory: false,
    dataKey: "Policy_Start_Date",
    columnColor: [],
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "ABO_ID", mandatory: false, dataKey: "ABO_ID", columnColor: [] },
];
