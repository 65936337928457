import React, { useEffect, useState } from "react";
import { JavaApiGet } from "../../../../lib/AppHelper";
import { Placeholder } from "rsuite";
import moment from "moment";

const ViewStatusPolicyUpload = ({ rowData }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/upload_policy_status?batchId=${rowData?.batchId}`
      );
      if (response.code === 200) {
        setLoading(false);
        setDetails(response?.responseObject);
      } else {
        setLoading(false);
        console.log(response.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div>
      <div className="w-full  bg-white p-6 ">
        <div className="space-y-4">
          {loading ? (
            <div style={{ width: "300px" }}>
              <Placeholder.Paragraph />
              <Placeholder.Paragraph />
              <Placeholder.Paragraph />
              <Placeholder.Paragraph />
              <Placeholder.Paragraph />
            </div>
          ) : details?.length > 0 ? (
            details?.map((item) => {
              return (
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

                    borderRadius: "12px",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    Batch Id:&nbsp;
                    <span style={{ fontWeight: "600" }}>{item?.batchId}</span>
                  </div>
                  <div>
                    <span>
                      Policy uploaded:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item?.policyUploaded}
                      </span>
                    </span>{" "}
                    &nbsp;&nbsp;
                    <span>
                      Payment Date:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {moment(item?.paymentDate).format("DD-MM-YYYY")}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span>
                      Vendor name:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item?.vendorName}
                      </span>
                    </span>{" "}
                    &nbsp;&nbsp;
                    <span>
                      Product specification:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item?.productSpecification}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="grid place-items-center w-[300px]">
              {" "}
              No data found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewStatusPolicyUpload;
