import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextInput from "./TextInput";
import { InputTextArea } from "../../components/form/InputTextArea";

import { postApiClaim } from "../../lib/AppHelper";
import { toast } from "react-toastify";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function AddNewBank({
  open,
  label,
  handelAddBank,
  handleClose,
  state,
}) {
  const [bankDetails, setBankDetails] = useState({
    name: label,
    address: "",
  });
  const [loading, setLoading] = useState(false);

  async function saveData() {
    setLoading(true);
    const data = {
      bankName: bankDetails.name,
      address: bankDetails.address,
    };
    try {
      const response = await postApiClaim("addBank", data);
      if (response.code === 200) {
        toast.success("Bank added successfully");
        setLoading(false);
        handelAddBank(response?.data);
      } else {
        setLoading(false);
        toast.error(response?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div>
      <Dialog
        fullWidth="true"
        maxWidth="xs"
        open={open}
        onClose={loading ? null : handleClose}
        scroll={"paper"}
      >
        <DialogTitle> Add new bank</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={loading ? null : handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div>
            <TextInput
              label="Bank name"
              requiredMark
              attrs={{
                value: bankDetails.name,
                maxLength: 20,
                onChange: (e) => {
                  let value = e.target.value;
                  if (/^[A-Za-z\s]*$/.test(value)) {
                    setBankDetails({
                      ...bankDetails,
                      name: value,
                    });
                  }
                },
              }}
            />

            <InputTextArea
              label="Bank address"
              attrs={{
                maxLength: 64,
                value: bankDetails.address,
                onChange: (e) => {
                  setBankDetails({
                    ...bankDetails,
                    address: e.target.value,
                  });
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveData}
            variant="contained"
            style={{ background: !bankDetails.name ? "grey" : "#DB2228" }}
            disabled={!bankDetails.name && bankDetails.length > 10}
          >
            {loading ? (
              <CircularProgress size="20px" style={{ color: "white" }} />
            ) : (
              "SAVE"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
