import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { getData } from "../../lib/AppHelper";
import Main from "../Main";
import LabTestSubscriptionDataTable from "./LabTestSubscriptionDataTable";

export const LabTestSubscriptions = ({ loading }) => {
  const history = useHistory();
  const MessageRef = React.useRef();
  const [permissions, setPermissions] = useState();
  const data = history?.location?.state;

  useEffect(() => {
    (async () => {
      const permission = await getData("permissions");
      if (permission && permission.lab_test_subscription) {
        setPermissions(permission);
      }
    })();
  }, []);

  return (
    <Main MessageRef={MessageRef}>
      <div className="w-full flex md:flex-row flex-col">
        <div className="w-full md:w-1/3">
          <AppBackButton to="/">Back to Dashboard</AppBackButton>
        </div>
        <div className="w-full md:w-1/3 text-center mb-5">
          <AppHeading>Lab Test</AppHeading>
        </div>
        <div className="w-full md:w-1/3 none md:block"></div>
      </div>

      {permissions && permissions.lab_test_subscription ? (
        <div className="w-full flex flex-col">
          <div className="flex flex-wrap">
            <LabTestSubscriptionDataTable
              data={data}
              permissions={permissions}
            />
          </div>
        </div>
      ) : (
        <div className="p-10 text-red-500">
          <h4>You do not have permission to view members</h4>
        </div>
      )}
    </Main>
  );
};

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user };
};

const SubscriptionsConnect = connect(mapStateToProps)((props) => {
  return <LabTestSubscriptions {...props} />;
});

export default withRouter(SubscriptionsConnect);
