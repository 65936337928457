import moment from "moment";
export const DateFilter = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "This week",
    value: "thisWeek",
  },
  {
    label: "This month",
    value: "thisMonth",
  },
  {
    label: "Previous Week",
    value: "previousWeek",
  },
  {
    label: "Previous Month",
    value: "previousMonth",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const WeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label);

  const formattedStartDate = startDate.format("YYYY-MM-DD");
  return [formattedStartDate, currentDate.format("YYYY-MM-DD")];
};

export const LastWeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label).subtract(1, label);

  const endDate = currentDate.clone().startOf(label).subtract(1, "day");
  const formattedStartDate = startDate.format("YYYY-MM-DD");
  const formattedEndDate = endDate.format("YYYY-MM-DD");
  return [formattedStartDate, formattedEndDate];
};
