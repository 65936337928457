import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ImageUploader = ({ id, file, onDelete, onView }) => {
  return (
    <>
      {file !== null && (
        <div
          className="bg-blue-200 p-1 px-8 m-1"
          style={{
            borderRadius: "5px",
            height: "auto",
            display: "inline-block",
          }}
        >
          <span>
            {" "}
            <VisibilityIcon
              onClick={() => onView(id)}
              style={{ cursor: "pointer", marginRight: "5px" }}
            />
            {file.name}
          </span>
          <span>
            <CloseIcon
              style={{ cursor: "pointer", marginLeft: "5px" }}
              onClick={() => {
                onDelete(id);
              }}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default ImageUploader;
