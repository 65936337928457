import { Tooltip } from "@mui/material";
import React from "react";
import RangePicker from "../../../components/common/DateRangePicker";
import { RefreshIcon } from "@heroicons/react/solid";
import { Loader } from "rsuite";

const SalesHeader = ({
  dateRange,
  onSearch,
  handleDateChange,
  handleRefresh,
  onSearchHandle,
  request,
}) => {
  return (
    <div>
      <div
        className="flex flex-wrap justify-evenly md:justify-start"
        style={{
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: "10px",
            marginLeft: "10px",
            marginBottom: "10px",
          }}
        >
          <RangePicker dateRange={dateRange} onDateChange={handleDateChange} />
        </div>
        <Tooltip title="Reset Date" placement="bottom">
          <button
            className="text-white hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
            style={{ height: "30px", margin: "20px 10px 20px 10px" }}
            onClick={() => {
              handleRefresh();
              request(new Date(), new Date());
            }}
          >
            <RefreshIcon width={25} height={25} className="m-1" />
          </button>
        </Tooltip>
        <Tooltip title="Search" placement="bottom">
          <div style={{ height: "30px", margin: "20px 10px 20px 10px" }}>
            <button
              className="w-full shadow whitespace-nowrap flex items-center justify-center px-2 py-1
               border border-transparent text-sm rounded-xl text-white bg-primary hover:bg-primary-700
                active:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              onClick={() => onSearchHandle()}
              disabled={onSearch}
            >
              {"Search"}
            </button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default SalesHeader;
