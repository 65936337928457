import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AppStore } from "./lib/AppStore";
import Pusher from "pusher-js";
import swal from "sweetalert";
import Login from "./screens/Login";
import PrivateRoute from "./lib/PrivateRoute";
import { ApiGet, ApiPost, setData, getData, setError } from "./lib/AppHelper";
import AppRoutes from "./lib/AppRoutes";
import { useLocation } from "react-router";
import Terms from "./screens/Terms";
import PasswordlessLogin from "./screens/PasswordlessLogin";
import ForgotPassword from "./screens/ForgotPassword";
import { setResellerToken } from "./helpers/Helper";
import { HandleGetLocation } from "./lib/SessionInformation";
import CustomizedSnackbars from "./components/Snackbar";
import { setFcmToken } from "./utils/commom";
import {
  getToken,
  onMessage,
  getMessaging,
  isSupported,
} from "@firebase/messaging";
import { messaging } from "./firebase";
import { appointmentType } from "./lib/constants";

export const RouterContext = React.createContext();

const LoginRoute = () => {
  const token = useSelector((state) => state.token);
  if (token) return <Redirect to={{ pathname: "/" }} />;
  return <Login />;
};

const RouterProvider = ({ children }) => {
  const location = useLocation();

  const [route, setRoute] = useState({
    //--> It can be replaced with useRef or localStorage
    to: location.pathname,
    from: location.pathname, //--> previous pathname
  });

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }));
    HandleGetLocation();
  }, [location]);

  return (
    <RouterContext.Provider value={route}>{children}</RouterContext.Provider>
  );
};

function App() {
  // const messaging = getMessaging();
  const [loaded, setLoaded] = React.useState(false);
  const [resellerId, setResellerId] = React.useState(null);
  const [option, setOption] = useState(false);

  const postFCMTokenHandler = (fcmToken) => {
    const token = localStorage.getItem("fcmToken");
    const authToken = localStorage.getItem("auth_token");
    const data = {
      fcm_token_id: fcmToken,
    };
    if (fcmToken && !token && authToken)
      ApiPost("add-fcm-token", data).then((res) => {
        if (fcmToken && res?.status === "success")
          localStorage.setItem("fcmToken", fcmToken);
      });
  };

  const getRequestPermission = async () => {
    try {
      if (
        Notification.permission == "default" ||
        Notification.permission == "denied "
      ) {
        localStorage.removeItem("fcmToken");
      }
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(await messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        postFCMTokenHandler(token);
        if (token) {
          setFcmToken(token);
        }
      } else if (Notification.permission === "denied") {
        console.log("Permission is denied");
      }
    } catch (error) {
      console.error("Error getting FCM token", error);
    }
  };

  const onMessageListener = async () => {
    const token = localStorage.getItem("auth_token");
    HandleGetLocation();
    getRequestPermission();
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      onMessage(getMessaging(), (payload) => {
        if (token) {
          if (appointmentType.includes(payload?.data?.event_type)) {
            AppStore.dispatch({
              type: "REFRESH_TABLE",
              loading: true,
            });
          }
          setOption(payload);
        }
      });
    }
  };

  React.useEffect(() => {
    onMessageListener();
  }, []);

  React.useEffect(() => {
    async function bootstrap() {
      const token = await getData("access_token");
      const is_admin = await getData("is_admin");
      if (token) {
        const response = await ApiGet(`${is_admin ? "admin/" : ""}me`);
        setResellerToken(response.data?.reseller_id_by_token || null);
        if (response.status === "success") {
          await setData("permissions", response?.data?.permissions);
          setResellerId(response.data.id);
          AppStore.dispatch({
            type: "SIGN_IN",
            user: response.data,
            token: token,
          });
          AppStore.dispatch({
            type: "RESELLER_ID",
            reseller_id: response.data.id,
          });
          AppStore.dispatch({
            type: "SWITCH_PROFILE",
            activeProfile:
              response.data?.is_admin || response?.data?.is_super_admin
                ? "admin"
                : "reseller",
          });
          AppStore.dispatch({
            type: "IS_SUPER_ADMIN",
            isSuperAdmin: response?.data?.is_super_admin ? true : false,
          });
          await setData(
            "activeProfile",
            response.data?.is_admin ? "admin" : "reseller"
          );
        } else {
          // await removeData("access_token");
          await setError(response.message);
        }
        setLoaded(true);
      } else {
        setLoaded(true);
      }

      /* setTimeout(() => {
        setTimeLoaded(true)
      }, 2000) */
    }

    bootstrap();

    return () => {};
  }, []);

  if (!loaded) return false;

  /// pusher
  const pusher = new Pusher("6d501ac3db4df477a851", {
    cluster: "ap2",
  });
  const channel = pusher.subscribe(`${resellerId}`);
  channel.bind("success", function (data) {
    swal({
      title: data.title,
      text: data.message,
      icon: "info",
    });
  });

  return (
    <>
      <CustomizedSnackbars option={option} setOption={setOption} />
      <Provider store={AppStore}>
        <Router>
          <Switch>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/auth/:token">
              <PasswordlessLogin />
            </Route>
            <Route path="/login">
              <LoginRoute />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>

            <PrivateRoute>
              <RouterProvider>
                <AppRoutes />
              </RouterProvider>
            </PrivateRoute>

            <Route
              path="*"
              render={() => {
                return <Redirect to="/login" />;
              }}
            />
          </Switch>
        </Router>
      </Provider>
    </>
  );
}

export default App;
