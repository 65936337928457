import React, { useEffect, useState } from "react";
import { JavaApiGet } from "../../../../lib/AppHelper";
import { Placeholder } from "rsuite";
import moment from "moment";

const ViewStatusInsuranceData = ({ rowData }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/get_data_sent_to_vendor?batchId=${rowData?.batchId}`
      );
      if (response.code === 200) {
        setLoading(false);
        setDetails(response?.responseObject);
      } else {
        setLoading(false);
        console.log(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="flex items-center">
      <div className="w-full  bg-white p-6 ">
        {loading ? (
          <div style={{ width: "300px" }}>
            <Placeholder.Paragraph rows={5} />
          </div>
        ) : details?.length > 0 ? (
          details?.map((item) => {
            return (
              <div
                style={{
                  width: "100%",
                  padding: "10px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

                  borderRadius: "12px",
                  marginBottom: "10px",
                }}
              >
                <div>
                  Batch Id:&nbsp;
                  <span style={{ fontWeight: "600" }}>{item?.batchId}</span>
                </div>
                <div>
                  <span>
                    Lot number:{" "}
                    <span style={{ fontWeight: "600" }}>{item?.lotnumber}</span>
                  </span>{" "}
                  &nbsp;&nbsp;
                  <span>
                    Record sent date:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {moment(item?.sentDate).format("DD-MM-YYYY")}
                    </span>
                  </span>
                </div>
                <div>
                  <span>
                    Vendor name:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {item?.vendorName}
                    </span>
                  </span>{" "}
                  &nbsp;&nbsp;
                  <span>
                    Product specification:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {item?.productSpecification}
                    </span>
                  </span>
                </div>
                {item?.nop && (
                  <div>
                    Number of policies:&nbsp;
                    <span style={{ fontWeight: "600", wordWrap: "break-word" }}>
                      {item?.nop}
                    </span>
                  </div>
                )}

                {item?.remarks && (
                  <div>
                    Remarks:&nbsp;
                    <span style={{ fontWeight: "600", wordWrap: "break-word" }}>
                      {item?.remarks}
                    </span>
                  </div>
                )}
                {item?.subjectForMail && (
                  <div>
                    Subject:&nbsp;
                    <span style={{ fontWeight: "600", wordWrap: "break-word" }}>
                      {item?.subjectForMail}
                    </span>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="grid place-items-center w-[300px]">No data found</div>
        )}
      </div>
    </div>
  );
};

export default ViewStatusInsuranceData;
