import React from "react";

const Stepper = ({ step, steps = [] }) => {
  return (
    <div className="flex items-end max-w-screen-lg mx-auto w-full">
      {steps?.map((item, index) => {
        const lastNode = steps?.length - 1 === index;
        return (
          <div className={lastNode ? "" : "w-full"} key={index}>
            <p className="mb-2 mr-4 text-sm font-normal">{item}</p>
            <div className="flex items-center w-full ml-6">
              <div
                className={`w-6 h-6 shrink-0 mx-[-1px] border-2 ${
                  step >= index ? "border-primary-500" : "border-gray-300"
                }  p-1.5 flex items-center justify-center rounded-full`}
              >
                <span
                  className={`w-2 h-2 ${
                    step >= index ? "bg-primary-500" : "bg-gray-300"
                  }  rounded-full`}
                />
              </div>
              {!lastNode && (
                <div
                  className={`w-full h-1 ${
                    step > index ? "bg-primary-500" : "bg-gray-300"
                  } `}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
