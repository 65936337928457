import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import {
  ApiPost,
  debounce,
  validateName,
  validateNumber,
  validateSpecialChar,
} from "../../../lib/AppHelper";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowClose,
  faFilter,
  faPhoneAlt,
  faIdCard,
  faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Loader } from "rsuite";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

const FilterForm = ({
  values,
  onChangeHandler,
  onSubmit,
  onClose,
  errors,
  disable,
}) => {
  return (
    <form className="p-4" onChange={onChangeHandler} onSubmit={onSubmit}>
      <div className="flex gap-4 flex-col md:flex-row">
        <div className="w-full md:w-1/2">
          <label className="flex">Mobile</label>
          <input
            placeholder="Enter mobile number"
            name="mobile"
            className="border border-gray-300 rounded-lg  outline-none p-2 text-sm focus:border-primary ring-0 w-full"
            value={values?.mobile}
          />
          {errors?.mobile && (
            <p className="mt-2 text-xs text-red-500">{errors?.mobile}</p>
          )}
        </div>
        <div className="w-full md:w-1/2">
          <label className="flex">Aadhar</label>
          <input
            placeholder="Enter aadhar number"
            name="aadhaar_id"
            className="border border-gray-300 rounded-lg  outline-none p-2 text-sm focus:border-primary ring-0 w-full"
            value={values?.aadhaar_id}
          />
          {errors?.aadhaar_id && (
            <p className="mt-2 text-xs text-red-500">{errors?.aadhaar_id}</p>
          )}
        </div>
        <div className="w-full md:w-4/5">
          <label className="flex">Loan code/Reference number</label>
          <input
            placeholder="Enter Loan code/Reference number"
            name="loan_code"
            className="border border-gray-300 rounded-lg  outline-none p-2 text-sm focus:border-primary ring-0 w-full"
            value={values?.loan_code}
          />
          {errors?.loan_code && (
            <p className="mt-2 text-xs text-red-500">{errors?.loan_code}</p>
          )}
        </div>
        <div className="w-full md:w-1/2">
          <label className="flex">Member Id</label>
          <input
            placeholder="Enter member id"
            name="insured_member_id"
            className="border border-gray-300 rounded-lg  outline-none p-2 text-sm focus:border-primary ring-0 w-full"
            value={values?.insured_member_id}
          />
          {errors?.insured_member_id && (
            <p className="mt-2 text-xs text-red-500">
              {errors?.insured_member_id}
            </p>
          )}
        </div>
        <div className="w-full self-end">
          <button
            type="submit"
            className={`${
              disable ? "bg-gray-500" : "bg-red-500"
            } text-white p-1 px-2 float-right rounded-xl`}
            disabled={disable}
          >
            Search
          </button>
          <button
            type="button"
            className="bg-gray-500 text-white p-1 px-2 float-right rounded-xl mr-2"
            onClick={onClose}
          >
            Clear filter
          </button>
        </div>
      </div>
    </form>
  );
};

const UniversalSearch = () => {
  const initialState = {
    name: "",
    mobile: "",
    loan_code: "",
    insured_member_id: "",
    aadhaar_id: "",
  };
  const [query, setQuery] = useState(initialState);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState();
  const [filterToggle, setFilterToggle] = useState(false);
  const history = useHistory();
  const [errors, setErrors] = useState({
    mobile: "",
    loan_code: "",
    insured_member_id: "",
    aadhaar_id: "",
  });
  const [showValue, setShowValue] = useState(null);

  const validateFun = (query) => {
    let flag = false;
    if (query.name && query.name.length > 2) {
      flag = true;
    } else if (query.aadhaar_id && query.aadhaar_id.length === 12) {
      flag = true;
    } else if (query.mobile && query.mobile.length === 10) {
      flag = true;
    } else if (query.loan_code && query.loan_code.length > 3) {
      flag = true;
    } else if (query.insured_member_id && query.insured_member_id.length > 3) {
      flag = true;
    }
    return flag;
  };

  const fetchData = async (data) => {
    if (validateFun(data)) {
      setLoader(true);
      setOptions([]);
      let payload = {};
      Object.keys(data).map((item) => {
        if (data[item]) {
          payload[item] = data[item];
        }
      });
      await ApiPost("search-policy", {
        ...payload,
      })
        .then((res) => {
          if (res.status === "success") {
            setFilterToggle(false);
            setOptions(res?.data);
            if (res?.data.length == 0) {
              toast.error("No data found");
            }
            setIsOpen(true);
          }
        })
        .finally(() => setLoader(false));
    }
  };

  const divRef = useRef(null);
  const debouncedSearch = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    if (!filterToggle && query?.name?.length) {
      debouncedSearch(query);
    }
  }, [query]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onOutsideClick = () => {
    onClear();
  };

  const filterOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "aadhaar_id") {
      if (value && value.length < 12) {
        setErrors({ ...errors, aadhaar_id: "length must be 12" });
      } else {
        setErrors({ ...errors, aadhaar_id: "" });
      }
      if (value && (value.length > 12 || !validateNumber(value))) return;
    } else if (name === "mobile") {
      if (value && value.length < 10) {
        setErrors({ ...errors, mobile: "length must be 10" });
      } else {
        setErrors({ ...errors, mobile: "" });
      }
      if (value && (value.length > 10 || !validateNumber(value))) return;
    } else if (name === "loan_code") {
      if (value && value.length < 3) {
        setErrors({ ...errors, loan_code: "min length 3" });
      } else {
        setErrors({ ...errors, loan_code: "" });
      }
      if ((value && value.length > 30) || !validateSpecialChar(value)) return;
    } else if (name === "insured_member_id") {
      if (value && value.length < 3) {
        setErrors({ ...errors, insured_member_id: "min length 3" });
      } else {
        setErrors({ ...errors, insured_member_id: "" });
      }
      if (value.length > 30 || !validateSpecialChar(value)) return;
    }

    setQuery((prevQuery) => ({
      ...prevQuery,
      [name]: value,
    }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setShowValue(handleShowValue(query));
    setOptions([]);
    fetchData(query);
  };

  const onClear = () => {
    setQuery(initialState);
    setOptions([]);
    setShowValue(null);
  };

  const handleShowValue = (data) => {
    let str = "";
    if (data.name) {
      str = str + `name : ${data.name}`;
    }
    if (data.mobile) {
      str = str + ` | mobile : ${data.mobile}`;
    }
    if (data.aadhaar_id) {
      str = str + ` | aadhar : ${data.aadhaar_id}`;
    }
    if (data.loan_code) {
      str = str + ` | loan code : ${data.loan_code}`;
    }
    if (data.insured_member_id) {
      str = str + ` | member id : ${data.insured_member_id}`;
    }
    return str;
  };

  return (
    <div className="flex items-center mx-auto max-w-[20rem] relative mb-6">
      <div ref={divRef} className="w-full border-primary border rounded-xl">
        <div
          className={`w-full ${
            options?.length > 0 || filterToggle ? "border-b" : ""
          } p-1`}
        >
          <div className="absolute pl-2 rounded-r-full border-primary focus:border-primary top-4">
            <FiSearch className=" mr-1 border-collapse" />
          </div>
          <input
            type="text"
            className="py-2 pl-10 focus:ring-0 rounded-full w-full outline-0 border-none"
            placeholder="Search by name..."
            value={showValue ? showValue : query.name}
            onChange={(e) => {
              setShowValue("");
              if (e.target?.value?.length === 0) {
                onClear();
              }
              if (validateName(e.target.value)) {
                setQuery((prevQuery) =>
                  filterToggle
                    ? {
                        ...prevQuery,
                        name: e.target.value,
                      }
                    : { name: e.target.value }
                );
              }
            }}
          />

          {loader ? (
            <div className="absolute right-0 pr-4 rounded-r-full border-primary focus:border-primary top-3">
              <Loader size="sm" style={{ color: "white" }} />
            </div>
          ) : (
            <div className="absolute right-0 pr-4 flex gap-3 top-3">
              {(options?.length > 0 || query.name) && (
                <Tooltip title="Clear search" placement="top" arrow>
                  <div
                    className="rounded-r-full border-primary focus:border-primary "
                    onClick={() => onClear()}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      className="text-red-500 cursor-pointer"
                      icon={faWindowClose}
                    />
                  </div>
                </Tooltip>
              )}
              <Tooltip title="Show search options" placement="top" arrow>
                <div
                  className="rounded-r-full border-primary focus:border-primary "
                  onClick={() => setFilterToggle(!filterToggle)}
                >
                  <FontAwesomeIcon
                    size="sm"
                    className="text-red-500 cursor-pointer"
                    icon={faFilter}
                  />
                </div>
              </Tooltip>
            </div>
          )}
        </div>

        <div
          className={`bg-white border-primary w-full  overflow-auto mt-1 scrollbar-medium rounded-b-xl ${
            isOpen || filterToggle ? "" : "hidden"
          } `}
          style={{
            maxHeight: "400px",
          }}
        >
          {filterToggle ? (
            <FilterForm
              onChangeHandler={filterOnChange}
              onSubmit={handleSearch}
              values={query}
              onClose={onClear}
              errors={errors}
              disable={!validateFun(query)}
            />
          ) : (
            options &&
            options.map((item, index) => {
              return (
                <div
                  index={index}
                  className=" hover:bg-red-100 p-2 py-3 cursor-pointer border-b-2"
                  onClick={() => {
                    history.push(`/subscription/${item.application_id}`);
                  }}
                >
                  <div className="mb-2">
                    <FontAwesomeIcon
                      className="text-red-500 mr-2"
                      icon={faUser}
                    />
                    <span className="font-semibold capitalize">
                      {item?.customer_name?.toLowerCase()} &nbsp;&nbsp;
                    </span>
                    <span>
                      {item?.policy_status == "lead" ? (
                        <span className="bg-yellow-100 font-semibold text-xs p-1 bol rounded">
                          Unpaid
                        </span>
                      ) : item?.end_date && moment(item.end_date).isBefore() ? (
                        <span className="bg-red-200 font-semibold p-1 text-xs rounded">
                          Expired
                        </span>
                      ) : (
                        <span className="bg-green-100 font-semibold p-1 text-xs rounded">
                          Active
                        </span>
                      )}
                    </span>
                  </div>
                  <div>
                    <ul className="block">
                      <li className="block md:inline-block border-r-0 md:border-r border-gray-300 pr-0 md:pr-5">
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="mr-2 text-green-500"
                        />
                        <span>{item?.mobile}</span>
                      </li>
                      {item?.loan_code && (
                        <li className="block md:inline-block border-r-0 md:border-r border-gray-300 px-0 md:px-5">
                          <FontAwesomeIcon icon={faIdBadge} className="mr-2" />
                          Loan code {" : "}
                          <span>{item?.loan_code}</span>
                        </li>
                      )}
                      {item?.gov_id_type && item?.gov_id_number && (
                        <li className="block md:inline-block border-r-0 md:border-r border-gray-300 px-0 md:px-5">
                          <FontAwesomeIcon
                            icon={faIdCard}
                            className="mr-2 text-red-500"
                          />
                          <span>
                            {item?.gov_id_type && item?.gov_id_number && (
                              <>
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  &nbsp;
                                  {item?.gov_id_type ? item?.gov_id_type : ""}
                                </span>
                                &nbsp;
                                {typeof item?.gov_id_number === "string"
                                  ? item?.gov_id_number.length > 9
                                    ? new Array(
                                        item?.gov_id_number?.length - 3
                                      ).join("x") +
                                      item?.gov_id_number
                                        ?.substr(
                                          item?.gov_id_number?.length - 4,
                                          4
                                        )
                                        .toUpperCase()
                                    : item?.gov_id_number?.toUpperCase()
                                  : item?.gov_id_number}
                              </>
                            )}
                          </span>
                        </li>
                      )}
                      <li className="block md:inline-block border-r-0  border-gray-300 px-0 md:px-5">
                        <FontAwesomeIcon
                          icon={faIdCard}
                          className="mr-2 text-red-500"
                        />
                        Member id {" : "}
                        <span>{item?.insured_member_id}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default UniversalSearch;
