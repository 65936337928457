import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Button from "../../components/Button";
import InputGroup from "../../components/form/InputGroup";
import InputTextArea from "../../components/form/InputTextArea";
import { InputRadio } from "../../components/form/InputRadio";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import {
  ApiGet,
  ApiPost,
  setMessage,
  setError,
  validateEmail,
  validateMobile,
  validateName,
} from "../../lib/AppHelper";
import { withRouter } from "react-router-dom";

class ResellerLeadCapture extends Component {
  BasicBtn = React.createRef();
  MessageRef = React.createRef();

  state = {
    params: {
      state: "",
      name: "",
      mobile: "",
      email: "",
      gender: "m",
      interest: "",
      reason: "",
      comment: "",
      pin_code: "",
    },
  };

  steps = 2;

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  isReady = () => {
    const { params } = this.state;

    if (!params.name) {
      return false;
    } else if (!validateName(params.name)) {
      return false;
    }
    if (!params.interest) {
      return false;
    }
    if (!params.comment) {
      return false;
    }
    if (!params.mobile || params.mobile.length !== 10) return false;
    return true;
  };

  render() {
    const { params } = this.state;
    const ready = this.isReady();

    return (
      <Main MessageRef={this.MessageRef}>
        <AppBackButton to="/">Back </AppBackButton>

        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            <div>
              <AppHeading className="mb-3">Reseller Details</AppHeading>
              <InputGroup
                label="Member Name"
                requiredMark="true"
                placeholder="Enter member name..."
                attrs={{
                  maxLength: 32,
                  value: params.name,
                  onChange: (e) => {
                    // name validation ragx
                    if (
                      /^[a-zA-Z\s]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      this.onChangeHandler(e, "name");
                    }
                  },
                }}
                validateError={(value) => {
                  if (validateName(value)) {
                    return false;
                  } else {
                    return "Please enter valid name";
                  }
                }}
              />
              <InputGroup
                label="Phone no"
                placeholder="Enter phone no..."
                prefix="+91"
                requiredMark="true"
                attrs={{
                  value: params.mobile,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value)
                    if (isValidInteger || e.target.value == "") {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "mobile"
                      );
                    }
                  },
                  maxLength: 10,
                }}
                validateError={(value) => {
                  if (validateMobile(value)) {
                    return false;
                  } else {
                    return "Please enter valid mobile number";
                  }
                }}
              />

              <InputGroup
                label="Email"
                placeholder="Enter email..."
                attrs={{
                  maxLength: 64,
                  value: params.email,
                  onChange: (e) => this.onChangeHandler(e, "email"),
                }}
                validateError={(value) => {
                  if (value === "") return false;
                  if (validateEmail(value)) {
                    return false;
                  } else {
                    return "please enter correct email ID";
                  }
                }}
              />

              <InputRadio
                label="Gender"
                name="gender"
                buttonHidden={false}
                options={[
                  { label: "Male", value: "m" },
                  { label: "Female", value: "f" },
                  { label: "Others", value: "o" },
                ]}
                value={params.gender}
                attrs={{ onChange: (e) => this.onChangeHandler(e, "gender") }}
              />

              <InputGroup
                label="Pincode"
                placeholder="Enter pincode..."
                attrs={{
                  maxLength: 6,
                  value: params.pin_code,
                  onChange: (e) => {
                    this.onChangeHandler(
                      { target: { value: e.target.value.replace(/\D/, "") } },
                      "pin_code"
                    );
                    if (e.target.value.length === 6) {
                      this.checkPin();
                    }
                    if (e.target.value.length == 6) this.checkPin()
                  },
                  onBlur: this.checkPin,
                }}
                onEnter={this.checkPin}
              />
              {params.state && params.pin_code.length == 6 ? (
                <InputGroup
                  label="Your State"
                  placeholder="Enter state..."
                  attrs={{ value: params.state, readOnly: true }}
                />
              ) : (
                false
              )}

              <InputRadio
                requiredMark="true"
                label="Interest"
                name="interest"
                buttonHidden={false}
                options={[
                  { label: "Low", value: "low" },
                  { label: "Medium", value: "medium" },
                  { label: "High", value: "high" },
                ]}
                value={params.interest}
                attrs={{ onChange: (e) => this.onChangeHandler(e, "interest") }}
              />

              <InputTextArea
                requiredMark="true"
                label="Comment"
                placeholder="Enter Comment"
                attrs={{
                  maxLength: 256,
                  value: params.comment,
                  onChange: (e) => this.onChangeHandler(e, "comment"),
                }}
              />

            </div>
          </div>
          <div>
            <Button
              attrs={{
                ref: this.BasicBtn,
                disabled: !ready,
                type: "button",
                onClick: this.doSubmit,
              }}
              size="md"
              title={"Save and Continue"}
            />
          </div>
        </div>
      </Main>
    );
  }
  onChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params });
  };

  checkPin = async () => {
    if (!this.state.params.pin_code) return;
    if (this.state.params.pin_code === this.checked_pin) return;
    this.checked_pin = this.state.params.pin_code;

    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(`check-pin/${this.state.params.pin_code}`);

    if (response.status === "success" && response.data !== null) {
      this.onChangeHandler({ target: { value: response.data } }, "state");
      this.BasicBtn.current.focus();
    } else {
      this.MessageRef.current.show(response.message, "error");
      this.onChangeHandler({ target: { value: null } }, "state");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  doSubmit = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    await ApiPost("reseller/leads", this.state.params)
      .then((response) => {
        if (response.errors) {
          setError(response.message);
        } else {
          setMessage(response.message);
          const me = ApiGet("me");
          if (me.status === "success") {
            AppStore.dispatch({ type: "USER_UPDATED", user: me.data });
            this.props.history.push("/");
          }
          this.props.history.push("/");
        }
      })
      .catch((error) => {
        setError("somthing went wrong");
      });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, products } = state;
  return { loading: loading, products: products };
};

const LeadCaptureConnect = connect(mapStateToProps)((props) => {
  return <ResellerLeadCapture {...props} />;
});

export default withRouter(LeadCaptureConnect);
