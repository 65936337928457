import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export default function CustomModal({ children, setOpen, open }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <DialogContent style={{ height: "100%", padding: 0 }}>
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
