import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { ApiGet } from "../../lib/AppHelper";
import { UserCard } from "../UserCard";
import { AppStore } from "../../lib/AppStore";
import { useSelector } from "react-redux";

export const SelectSubPolicyForm = ({
  step,
  members,
  setStep,
  setValue,
  onSubmit,
  value,
  field,
  state,
  subValue,
  subField,
  isMobile,
}) => {
  const loading = useSelector((state) => state.loading);
  const [data, setData] = useState([]);
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (state.memberId && state.policyId) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      getSubPolicies();
    }
  }, [state.memberId, state.policyId]);

  useEffect(() => {
    if (data?.length === 1 && !hasRunRef?.current) {
      const singleItem = data[0];
      const checkedValue = Number(singleItem?.sub_policy_id);
      const subCheckedValue = Number(singleItem?.sub_product_id);
      const productSpecification = singleItem?.claim_type;
      const productSpecificationStatus =
        singleItem?.product_specification_status;
      const maxClaimAmount = singleItem?.max_claim_amount;
      let claimType = singleItem.claim_type;
      let specification_type = singleItem.product_specification;
      setValue({
        [field]: [checkedValue],
        [subField]: [subCheckedValue],
        productSpecification: productSpecification,
        productSpecificationStatus: productSpecificationStatus,
        maxClaimAmount: maxClaimAmount,
        claimType: claimType,
        specification_type: specification_type,
      });
      hasRunRef.current = true;
    }
  }, [data, setValue, field, subField]);

  const getSubPolicies = async () => {
    await ApiGet("member-subpolicy", {
      member_id: state.memberId,
      policy_id: state.policyId,
    })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
  };

  const [error, setError] = useState(null);
  const handleRadioChange = useCallback(
    (event) => {
      const item = event.target.value;
      let checkedValue = Number(item["sub_policy_id"]);
      let subCheckedValue = Number(item["sub_product_id"]);
      let productSpecification = item["claim_type"];
      let productSpecificationStatus = item["product_specification_status"];
      let max_claim_amount = item["max_claim_amount"];
      let claimType = item["claim_type"];

      setValue({
        [field]: [checkedValue],
        [subField]: [subCheckedValue],
        productSpecification: productSpecification,
        productSpecificationStatus: productSpecificationStatus,
        maxClaimAmount: max_claim_amount,
        specification_type: item["product_specification"],
        claimType: claimType,
      });
    },
    [setValue, value]
  );
  const onClick = () => {
    if (value.length) {
      setError(false);
      return onSubmit();
    } else {
      setError("Please select ");
    }
  };

  return loading ? (
    <div
      style={{ height: "200px", display: "grid", placeItems: "center" }}
    ></div>
  ) : data && data.length ? (
    <div style={{ width: isMobile ? "100%" : "40%" }}>
      <h4>Please select the Sub Subscription</h4>
      {data.map((item) => (
        <UserCard
          key={item}
          item={item}
          valueField={"sub_policy_id"}
          labelField={"name"}
          selectedValue={value}
          handleRadioChange={handleRadioChange}
        />
      ))}
      <div style={{ color: "red", marginLeft: 20, marginRight: 20 }}>
        {error}
      </div>

      <Button
        attrs={{
          //   ref: this.BasicBtn,
          type: "button",
          onClick,
        }}
        options={members}
        size="md"
        className={"mt-5"}
        title={"Continue"}
      />
    </div>
  ) : (
    <h4>
      Member don't have any sub subscription. Please avail any sub subscription
      first
    </h4>
  );
};
