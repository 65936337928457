import React, { useState } from "react";
import { InputGroup } from "../../../components/form/InputGroup";
import Button from "../../../components/Button";
import { postApiClaim } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import { InputTextArea } from "../../../components/form/InputTextArea";

const AddVendor = ({ callBack, handleClose }) => {
  const [data, setData] = useState({
    name: "",
    mobile: "",
    address: "",
    gstNumber: "",
  });
  const [loading, setLoading] = useState(false);

  const sendVendor = async () => {
    setLoading(true);
    await postApiClaim(`add_vendor`, data)
      .then((response) => {
        if (response?.code === 200) {
          toast.success("New vendor added successfully");
          callBack(response?.data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const onSubmitHandle = (e) => {
    e.preventDefault();
    sendVendor();
  };

  const onChangeHandler = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const validateGstNumber = (value) => {
    const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!regex.test(value)) {
      return "Please enter valid gst number";
    } else {
      return false;
    }
  };

  const handleDisable = () => {
    let valid = false;
    if (!data.name && !data.mobile) {
      valid = true;
    }
    if (data?.gstNumber && validateGstNumber(data?.gstNumber) !== false) {
      valid = true;
    }
    if (data?.mobile?.length < 10) {
      valid = true;
    }
    if (data?.name?.length < 3) {
      valid = true;
    }
    return valid;
  };

  return (
    <>
      <InputGroup
        requiredMark={true}
        label="Name"
        placeholder="e.g., xyz"
        attrs={{
          value: data?.name,
          maxLength: 48,
          onChange: (e) => {
            const value = e.target.value;
            value.trim();
            if (value === "" || /^[a-zA-Z 0-9]+$/.test(value))
              onChangeHandler("name", value);
          },
        }}
      />
      <InputGroup
        requiredMark={true}
        label="Mobile"
        placeholder="e.g., 1234567890"
        attrs={{
          value: data.mobile,
          maxLength: 10,
          onChange: (e) => {
            const value = e.target.value;
            value.trim();
            if (value === "" || /^\d+$/.test(value)) {
              onChangeHandler("mobile", value);
            }
          },
        }}
      />
      <InputGroup
        label="Gst number"
        placeholder="e.g., 22AAAAA0000A1Z5"
        attrs={{
          maxLength: 15,
          value: data.gstNumber,
          onChange: (e) => {
            const value = e.target.value;
            value.trim();
            onChangeHandler("gstNumber", value);
          },
        }}
        validateError={validateGstNumber}
        valdiateOnChange={true}
      />
      <InputTextArea
        label="Address"
        placeholder="e.g., 123 Main Street, City, State, Zip"
        attrs={{
          value: data.address,
          onChange: (e) => {
            const value = e.target.value;
            value.trim();
            onChangeHandler("address", value);
          },
        }}
      />
      <div className="flex gap-2 justify-center">
        <Button
          title={loading ? "Loading..." : "Save"}
          attrs={{
            disabled: loading || handleDisable(),
            onClick: onSubmitHandle,
          }}
        />
        <Button
          title="Cancel"
          attrs={{
            onClick: handleClose,
          }}
        />
      </div>
    </>
  );
};

export default AddVendor;
