import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  getApiClaim,
  getDocuments,
  getResellerId,
  postApiClaim,
} from "../../../lib/AppHelper";
import Comments from "./Comments";
import { toast } from "react-toastify";
import { ImageType } from "../../heathCamp/constant";
import Compressor from "compressorjs";
import { Placeholder } from "rsuite";
import { handleRemoveItem } from "./constant";
import ImageModal from "./ImageModal";
import TicketDetails from "./TicketDetails";
import { addCommentAPI } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";

function CommentsModal({ open, handleClose }) {
  const claimID = open?.sub_claims?.[0]?.claim_id;
  const subClaimId = open?.sub_claims?.[0]?.sub_claim_id;

  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState("");
  const [documents, setDocuments] = useState([]);
  const [viewFile, setViewFile] = useState(null);
  const [loading, setLoading] = useState({ ticket: false, comment: false });
  const [viewImage, setViewImage] = useState({ isOpen: false, id: null });
  const [masterId, setMasterId] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [page, setPage] = useState(0);

  const fetchTicket = async () => {
    setLoading({ ticket: true, comment: true });
    let data = {
      referenceId: claimID,
      subReferenceId: subClaimId,
      page: 0,
      size: 10,
    };
    try {
      const response = await postApiClaim("get-ticket", data);
      if (response.code === 200) {
        setLoading({ comment: true, ticket: false });
        setTicket(response?.data?.content?.[0]);
        setMasterId(response?.data?.content?.[0]?.id || null);
        fetchComment(response?.data?.content?.[0]?.id || null);
      }
    } catch (error) {
      console.log(error);
      setLoading({ comment: false, ticket: false });
    }
  };

  const fetchComment = async (masterId, prevComment = []) => {
    if (!masterId) return;
    setLoading({ ticket: false, comment: true });
    let data = {
      ticketMasterId: masterId,
      referenceId: claimID,
      subReferenceId: subClaimId,
      page: prevComment.length > 0 ? page : 0,
      size: 5,
    };
    try {
      const response = await postApiClaim("get-comment", data);
      if (response.code === 200) {
        if (prevComment.length > 0)
          setCommentList([...prevComment, ...response.data.content]);
        else setCommentList(response.data.content);

        setTotalCount(response.data.totalElements);
        setPage(0);
      }
    } catch (error) {
    } finally {
      setLoading({ ticket: false, comment: false });
    }
  };
  async function addComment() {
    if (!comment.trim()) {
      toast.warning("Please add a comment");
      return;
    }
    setButtonLoading(true);
    const data = {
      ticketMasterId: masterId,
      createdBy: getResellerId(),
      comments: comment.trim(),
      referenceId: claimID,
      subReferenceId: subClaimId,
      userApp: "RESELLER",
    };
    try {
      await addCommentAPI({
        data,
        documents,
        onSuccess: () => {
          toast.success("comment added successfully");
          fetchComment(masterId);
          setButtonLoading(false);
          setComment("");
          setDocuments([]);
        },
        onError: (err) => {
          toast.error(err);
          setButtonLoading(false);
          setComment("");
        },
      });
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while adding the comment");
      setButtonLoading(false);
      setComment("");
    }
  }

  const updateViewComment = async () => {
    try {
      const response = await getApiClaim(
        `update-view-comment/${claimID}/${subClaimId}`
      );
    } catch (error) {
      toast.error(error.message);
    }
  };
  const viewDocument = async (documentID, type) => {
    setImageLoading(true);
    setViewImage({ ...viewImage, isOpen: true });
    try {
      const blob = await getDocuments(documentID);
      if (blob.size > 0) {
        const fileName = `downloadedFile.${type}`;
        const lastModified = new Date().getTime();
        const changedFile = new File([blob], fileName, {
          type: blob.type,
          lastModified: lastModified,
        });
        setViewFile(changedFile);
        setViewImage({ isOpen: true, id: null });
      }
    } catch (error) {
      setViewImage({ isOpen: false, id: null });
      toast.error(error.message);
    } finally {
      // fetchComment(masterId);
      setImageLoading(false);
    }
  };
  const onChangeFileHandeler = (event) => {
    if (documents?.length > 4) {
      toast.warning("maximum file can be upto 5");
      return;
    }
    const currentFile = event?.target?.files?.[0];

    if (currentFile) {
      const mimeType = currentFile.type;
      const fileSize = currentFile.size;

      if (mimeType !== "application/pdf" && !ImageType.includes(mimeType)) {
        toast.warning("Only images or PDFs are allowed");
      } else if (mimeType === "application/pdf" && fileSize > 5000000) {
        toast.warning("PDF size should not be more than 5 MB");
      } else if (mimeType === "application/pdf" && fileSize <= 5000000) {
        setDocuments((prev) => [...prev, currentFile]);
      } else if (ImageType.includes(mimeType)) {
        new Compressor(currentFile, {
          quality: 0.6,
          success(result) {
            setDocuments((prev) => [...prev, result]);
          },
          error(err) {
            console.error(err.message);
          },
        });
      }
    }
    event.target.value = null;
  };
  function removeFile(id) {
    const items = handleRemoveItem(id, documents);
    setDocuments(items);
  }

  useEffect(() => {
    if (open) fetchTicket();
  }, [open]);
  useEffect(() => {
    if (page) fetchComment(masterId, commentList);
  }, [page]);
  useEffect(() => {
    if (claimID && subClaimId) updateViewComment();
  }, []);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => !buttonLoading && handleClose()}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <FontAwesomeIcon icon={faTicketAlt} style={{ color: "#Db2228" }} />
          &nbsp; Ticket &nbsp;
          <span className="font-medium">
            {ticket?.id ? (
              `#${ticket?.id}`
            ) : (
              <Placeholder.Grid rows={1} columns={1} active />
            )}
          </span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => !buttonLoading && handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={buttonLoading}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={"paper"}>
          <TicketDetails loading={loading.ticket} ticket={ticket} />
          <Comments
            addComment={addComment}
            commentList={commentList}
            loading={loading.comment}
            comment={comment}
            setComment={setComment}
            documents={documents}
            setDocuments={setDocuments}
            onChangeFileHandeler={onChangeFileHandeler}
            setViewImage={setViewImage}
            totalCount={totalCount}
            page={page}
            setPage={setPage}
            buttonLoading={buttonLoading}
            imageLoading={imageLoading}
            viewDocument={viewDocument}
            removeFile={removeFile}
          />
        </DialogContent>
      </Dialog>

      <ImageModal
        file={viewFile || documents[viewImage?.id] || null}
        open={viewImage.isOpen}
        onclose={() => {
          setViewImage({ isOpen: false, id: null });
          setViewFile(null);
        }}
        loading={imageLoading}
      />
    </React.Fragment>
  );
}

export default CommentsModal;
