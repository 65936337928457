import moment from "moment";
import React from "react";
import { Placeholder } from "rsuite";
import { AppHeading } from "../../../components/AppComponents";

const TicketDetails = ({ loading, ticket }) => {
  return (
    <>
      <AppHeading className="mb-2" size="md" font="medium">
        Details :
      </AppHeading>
      {loading ? (
        <>
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
          <Placeholder.Paragraph rows={2} rowHeight={8} active />
        </>
      ) : ticket ? (
        <div
          className="flex flex-col gap-2 px-4 py-3"
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "10px",
            boxShadow:
              "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
          }}
        >
          <div>
            <span className="font-medium">Title:</span>&nbsp;
            {ticket?.ticketTitle}
          </div>
          <div>
            <span className="font-medium">Description:</span>&nbsp;
            {ticket?.description}
          </div>
          <div>
            <span className="font-medium">Created by:</span>&nbsp;
            {ticket?.createdDate
              ? moment(ticket?.createdDate).format("LLL")
              : "---"}
          </div>
          <div>
            <span className="font-medium">Raised by:</span>&nbsp;
            {ticket?.createdBy}
          </div>
        </div>
      ) : (
        <p className="text-center">No ticket found</p>
      )}
    </>
  );
};

export default TicketDetails;
