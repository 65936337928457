import React, { memo, useEffect, useRef, useState } from "react";
import "rsuite-table/lib/less/index.less";
import { Pagination } from "rsuite";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppStore } from "../../../lib/AppStore";
import { truncateString } from "../utils";
import useMobileDetect from "../../../utils/useMobileDetect";
import CustomSelect from "../../../components/CustomSelect";
import { InputDate } from "../../../components/form/InputDate";
import Button from "../../../components/Button";
import {
  DateFilter,
  WeekDates,
  LastWeekDates,
  LastMonths,
  LastDays,
} from "./constant";
import { downloadExcelFile } from "../../../lib/AppHelper";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const SubscriptionsPaginationData = memo(
  ({
    request,
    data,
    limit = 15,
    pagination = true,
    filterValues = {},
    loading,
    renderItem,
    endPoint,
    handleGetSearch,
    permissions,
  }) => {
    if (limit < 1) {
      limit = 1;
    }

    const { isMobile } = useMobileDetect();
    const lastFilter = useRef(null);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [tableData, setTableData] = useState(data);
    const [errorMessage, setErrorMessage] = useState();

    const [filter, setFilter] = useState({
      duration: "thisMonth",
      from: WeekDates("month")[0],
      to: WeekDates("month")[1],
    });

    const { from, to } = filter || {};
    const formattedFrom = from && moment(from)?.format("YYYY-MM-DD");
    const formattedTo = to && moment(to)?.format("YYYY-MM-DD");

    const handelChangePage = (pg) => {
      if (page === pg) return;
      setPage(pg);
      fetchData(pg);
    };

    const fetchData = async (pg = null) => {
      const start = limit * (pg ? pg - 1 : page - 1);
      if (pg) setPage(pg);
      if (!data) {
        try {
          AppStore.dispatch({ type: "LOADING", loading: true });
          const res = await request(endPoint, {
            limit,
            from: formattedFrom,
            to: formattedTo,
            page: pg ? pg : page,
            ...filterValues,
          });

          if (res.status == "error") {
            setErrorMessage(res.message);
          } else {
            if (res?.data?.total) {
              setTotalCount(res?.data?.total);
            }
            handleGetSearch && handleGetSearch(res.data.total);
            if (res.data.results) {
              setTableData(res?.data?.results);
            } else {
              setTableData(res?.data);
            }
          }
        } catch (err) {
        } finally {
          AppStore.dispatch({ type: "LOADING", loading: false });
          lastFilter.current = filterValues;
        }
      } else {
        const newData = [...data];
        const update = newData?.slice(start, limit * page);
        setTotalCount(data?.length);
        setTableData(update);
      }
    };

    const downloadClick = () => {
      let payload = {
        limit,
        from: formattedFrom,
        to: formattedTo,
        page,
        download: true,
      };
      DownloadReports(payload);
    };

    const DownloadReports = async (request = "") => {
      AppStore.dispatch({ type: "LOADING", loading: true });
      await downloadExcelFile(
        "subscription-details",
        request,
        "ActivePolicyReport.xlsx"
      );
      AppStore.dispatch({ type: "LOADING", loading: false });
    };

    useEffect(() => {
      if (JSON.stringify(lastFilter.current) !== JSON.stringify(filterValues))
        fetchData(1);
      else {
        fetchData();
      }
    }, [setTableData, setTotalCount, request, filterValues, filter]);

    return (
      <div className="w-full">
        <div className="flex justify-between lg:flex-row flex-col lg:gap-0 gap-5 mb-5">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-5">
            <div>
              <CustomSelect
                label="Date Filter"
                value={filter?.duration}
                onChange={(e) => {
                  let value = e?.target?.value;
                  let dates;
                  if (value === "custom") {
                    setFilter((state) => ({ ...state, duration: value }));
                    return;
                  }
                  if (value === "last7Days") dates = LastDays(7);
                  if (value === "thisMonth") dates = WeekDates("month");
                  if (value === "last1Month") dates = LastWeekDates("month");
                  if (value === "last3Months") dates = LastMonths(3);
                  if (value === "last6Months") dates = LastMonths(6);
                  if (value === "thisYear") dates = WeekDates("year");
                  if (value === "last1Year") dates = LastWeekDates("year");
                  setFilter((state) => ({
                    ...state,
                    from: new Date(dates[0]),
                    to: new Date(dates[1]),
                    duration: value,
                  }));
                  setPage(1);
                }}
                data={DateFilter}
                style={{ width: isMobile() ? "300px" : "230px" }}
              />
            </div>
            {filter.duration === "custom" && (
              <>
                <div>
                  <InputDate
                    label="From"
                    value={filter.from}
                    placeholder="from"
                    maxDate={new Date(moment().format("YYYY-MM-DD"))}
                    attrs={{
                      onChange: (e) => {
                        const newFromDate = new Date(e);
                        const currentDate = new Date(
                          moment().format("YYYY-MM-DD")
                        );
                        // If the selected 'from' date is greater than the current 'to' date, update 'to' to the current date
                        if (newFromDate > new Date(filter.to)) {
                          setFilter({
                            ...filter,
                            from: newFromDate,
                            to: currentDate,
                          });
                          setPage(1);
                        } else {
                          setFilter({ ...filter, from: newFromDate });
                          setPage(1);
                        }
                      },
                      value: new Date(filter.from),
                    }}
                  />
                </div>
                <div>
                  <InputDate
                    label="To"
                    placeholder="To"
                    maxDate={new Date(moment().format("YYYY-MM-DD"))}
                    minDate={new Date(filter.from)}
                    attrs={{
                      onChange: (e) =>
                        setFilter({ ...filter, to: new Date(e) }),
                      value: new Date(filter.to),
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex md:items-end">
            <Button
              title=""
              className={`mr-5 ${
                tableData?.length === 0 ? "disabled-button" : ""
              }`}
              attrs={{
                onClick: downloadClick,
                disabled: tableData?.length === 0,
              }}
            >
              <CloudDownloadIcon />
              &nbsp;<span style={{ fontWeight: "600" }}> Download</span>
            </Button>
          </div>
        </div>

        {tableData && tableData?.length === 0 ? (
          <div className="text-center">
            <p className="text-xl">You do not have any item</p>
          </div>
        ) : (
          <div>
            {!isMobile() ? (
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <table className="min-w-full border" id="table-to-xls">
                    <thead className="border-b bg-red-500 text-white">
                      <th className="text-sm font-sm px-6 py-4 whitespace-nowrap text-left">
                        Customer Name
                      </th>
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                        Loan Code/Reference Number  
                      </th>
                      {permissions &&
                        permissions?.show_reseller_name_on_member_card && (
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            Partner Name
                          </th>
                        )}
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                        Product Name
                      </th>
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                        Phone
                      </th>
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                        Subscription Start Date
                      </th>
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                        Subscription End Date
                      </th>
                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                        Product Specification
                      </th>
                    </thead>
                    <tbody>
                      {tableData && tableData.length ? (
                        tableData.map((item, index) => {
                          if (renderItem) {
                            return renderItem(item);
                          }
                          return (
                            <tr className="border-b" key={index}>
                              <td
                                className="text-left border-r px-4 py-2 text-sm dark:border-neutral-500 capitalize"
                                style={{ color: "blue", fontWeight: 600 }}
                              >
                                <Link
                                  to={`/subscription/${item.application_id}`}
                                >
                                  {truncateString(
                                    item?.customer_name
                                      ? item?.customer_name?.toLowerCase()
                                      : item?.name?.toLowerCase(),
                                    16,
                                    "Member Empty"
                                  )}
                                </Link>
                              </td>
                              <td className="text-center border-r px-4 py-2 text-sm dark:border-neutral-500">
                                {item?.loan_code}
                              </td>
                              {permissions &&
                              permissions?.show_reseller_name_on_member_card
                                ? item?.reseller_name && (
                                    <td className="text-center border-r px-4 py-2 text-sm dark:border-neutral-500 capitalize">
                                      {truncateString(
                                        item?.reseller_name?.toLowerCase(),
                                        16,
                                        "Reseller name empty"
                                      )}
                                    </td>
                                  )
                                : null}
                              <td className="text-center border-r px-4 py-2 text-sm dark:border-neutral-500">
                                {truncateString(
                                  item.product_display_name
                                    ? item.product_display_name
                                    : item.product_name,
                                  28,
                                  "Product Empty"
                                )}
                              </td>
                              <td className="text-center border-r px-4 py-2 text-sm dark:border-neutral-500">
                                {item?.mobile}
                              </td>
                              <td className="text-center border-r px-4 py-2 text-sm dark:border-neutral-500">
                                {(item?.start_date || item.application_date) &&
                                  moment(
                                    item.start_date
                                      ? item.start_date
                                      : item.application_date
                                  ).format("DD/MM/YYYY")}
                              </td>
                              <td className="text-center border-r px-4 py-2 text-sm dark:border-neutral-500">
                                {(item?.end_date || item.application_date) &&
                                  moment(
                                    item.end_date
                                      ? item.end_date
                                      : item.application_date
                                  ).format("DD/MM/YYYY")}
                              </td>
                              <td className="text-center border-r px-4 py-2 text-sm dark:border-neutral-500">
                                {item
                                  ? item?.product_specification?.map(
                                      (subProduct, index) => {
                                        return (
                                          <div style={{ paddingBottom: "4px" }}>
                                            <span
                                              key={index}
                                              style={{
                                                fontWeight: 600,
                                              }}
                                            >
                                              {subProduct}
                                              {index <
                                                item?.product_specification
                                                  .length -
                                                  1 && ", "}
                                            </span>
                                          </div>
                                        );
                                      }
                                    )
                                  : "N/A"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan="8"
                            height="150"
                            className="text-red-500 font-large text-center"
                          >
                            No Data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="flex flex-wrap justify-center md:justify-between lg:justify-center">
                {tableData &&
                  tableData.length >= 0 &&
                  tableData.map((item, index) => {
                    if (renderItem) {
                      return renderItem(item);
                    }
                    return (
                      <div
                        className="my-4 px-1 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 sm:w-full"
                        key={index}
                      >
                        <article
                          className={`overflow-hidden rounded-lg shadow-lg ${
                            item?.end_date && moment(item.end_date).isBefore()
                              ? "bg-red-100"
                              : ""
                          }`}
                        >
                          <Link to={`/subscription/${item.application_id}`}>
                            <div>
                              <header className="flex items-center justify-between leading-tight pl-4">
                                <div>
                                  <p className="text-lg font-bold capitalize pt-2">
                                    {truncateString(
                                      item.customer_name
                                        ? item.customer_name?.toLowerCase()
                                        : item.name?.toLowerCase(),
                                      20,
                                      "Member Empty"
                                    )}
                                  </p>
                                  <p className="text-sm font-bold pt-2">
                                    {truncateString(
                                      item.product_display_name
                                        ? item.product_display_name
                                        : item.product_name,
                                      28,
                                      "Product Empty"
                                    )}
                                  </p>
                                </div>
                                <p className="text-grey-darker text-sm pt-1">
                                  <div className="pr-3">
                                    <span className="text-green-600">
                                      {moment(
                                        item.start_date
                                          ? item.start_date
                                          : item.application_date
                                      ).format("DD, MMM YYYY")}
                                    </span>{" "}
                                    <br></br>
                                    <span className="text-red-600">
                                      {" "}
                                      {moment(
                                        item.end_date
                                          ? item.end_date
                                          : item.application_date
                                      ).format("DD, MMM YYYY")}
                                    </span>
                                  </div>
                                </p>
                              </header>
                              <body
                                className={`flex items-center justify-between leading-tight p-4  ${
                                  item.stage != "DRAFT" ? "pt-4" : "md:p-3"
                                }`}
                              >
                                <div className="text-sm text-clip overflow-hidden ...">
                                  {item?.gov_id_type && item?.gov_id_number && (
                                    <p className="flex items-center">
                                      <span
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item?.gov_id_type
                                          ? item?.gov_id_type
                                          : ""}
                                      </span>
                                      &nbsp;:&nbsp;
                                      {typeof item?.gov_id_number === "string"
                                        ? item?.gov_id_number.length > 9
                                          ? new Array(
                                              item?.gov_id_number?.length - 3
                                            ).join("x") +
                                            item?.gov_id_number
                                              ?.substr(
                                                item?.gov_id_number?.length - 4,
                                                4
                                              )
                                              .toUpperCase()
                                          : item?.gov_id_number?.toUpperCase()
                                        : item?.gov_id_number}
                                    </p>
                                  )}
                                  {item?.mobile && (
                                    <p className="pt-2 flex items-center">
                                      Phone :&nbsp;
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          width: "250px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        <a
                                          className="no-underline text-black"
                                          href={`tel:+91${item?.mobile}`}
                                        >
                                          {item?.mobile}
                                        </a>
                                      </span>
                                    </p>
                                  )}
                                  {item?.loan_code && (
                                    <p className="pt-2 flex items-center">
                                      Loan&nbsp;Code&nbsp;:&nbsp;
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          width: "250px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {truncateString(item?.loan_code, 20)}
                                      </span>
                                    </p>
                                  )}
                                  {permissions &&
                                  permissions?.show_reseller_name_on_member_card
                                    ? item?.reseller_name && (
                                        <p className="pt-2 flex items-center capitalize">
                                          Partner Name :&nbsp;
                                          {truncateString(
                                            item?.reseller_name?.toLowerCase(),
                                            18,
                                            "Reseller name empty"
                                          )}
                                        </p>
                                      )
                                    : null}
                                </div>
                              </body>
                            </div>
                          </Link>
                        </article>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        )}

        {pagination && tableData && tableData?.length > 0 ? (
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={totalCount}
              limit={limit}
              activePage={page}
              onChangePage={(page) => handelChangePage(page)}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default SubscriptionsPaginationData;
