import React, { Component } from 'react'
import marked from 'marked'
import _ from 'lodash';

export class AppMessage extends Component {

   messageRef = React.createRef();

   state = {
      open: false,
      message: 'A simple alert with text and a right icon',
      type: 'error'
   }

   show = (message, type = 'error') => {
      this.setState({
         type: type,
         message: message,
         open: true
      }, () => {
         this.messageRef.current.focus();
      })
   }

   hide = () => {
      this.setState({
         open: false
      })
   }

   render() {
      let { open, message, type } = this.state;

      if (!open) return false

      let color;
      switch (type) {
         case 'success':
            color = 'green'
            break;
         default:
            color = 'primary'
      }

      message = _.isString(message) ? message : '';

      return (
         <div ref={this.messageRef} tabIndex={0} className={`focus:outline-none focus:ring-0 relative py-3 pl-4 pr-10 leading-normal ${color === 'green' ? 'text-green-700' : 'text-primary-700'} ${color === 'green' ? 'bg-green-100' : 'bg-primary-100'}`} role="alert">
            <p dangerouslySetInnerHTML={{ __html: marked(message) }}></p>
            <span onClick={this.hide} className="absolute inset-y-0 right-0 flex items-center mr-4">
               <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                     fillRule="evenodd"
                     d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                     clipRule="evenodd"
                  ></path>
               </svg>
            </span>
         </div>
      )
   }
}

export default React.forwardRef((props, ref) => <AppMessage innerRef={ref} {...props} />);