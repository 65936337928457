import React from "react";
import { SelectPicker } from "rsuite";

const InputSearchableDropdown = ({
  value,
  data,
  width = 224,
  placeholder = "Select",
  loading = false,
  onSearch,
  onSelect,
  onClean,
  onChange,
}) => {
  return (
    <SelectPicker
      value={value}
      onChange={onChange}
      data={data}
      style={{ width: width }}
      placeholder={placeholder}
      loading={loading}
      onSearch={onSearch}
      onSelect={onSelect}
      // onClean={onClean}
    />
  );
};

export default InputSearchableDropdown;
