import React, { useState } from "react";
import moment from "moment";
import InventoryTable from "../component/InventoryTable";
import ExcelUploader from "../component/Uploader";
import { AppStore } from "../../../lib/AppStore";
import { postApiClaim } from "../../../lib/AppHelper";
import { useSelector } from "react-redux";
import SampleFile from "./StockOutSampleFile.xlsx";
import { headers } from "./Headers";
import { toast } from "react-toastify";
import ButtonHeader from "../component/ButtonHeader";
import { SINGLE_DATA, BULK_DATA } from "../component/constant";

const INITIAL_STATE = {
  branchId: "",
  medicineId: "",
  medicineName: "",
  sku: "",
  quantity: "",
  expiryDate: "",
  narration: "",
  maxMedicineCapicity: 0,
  batchNumber: "",
};

const PurchaseStock = ({ component, windowSize }) => {
  const state = useSelector((state) => state);
  const user = state.user;
  const [transferData, setTransferdata] = useState([{ ...INITIAL_STATE }]);
  const [view, setView] = useState(SINGLE_DATA);
  const [errors, setErrors] = useState({
    tableData: [],
    header: [],
  });
  const [sendingDate, setSendingDate] = useState({
    title: "Dispatch date",
    date: "",
    disableDateFunc: (date) => {
      const today = moment().startOf("day");
      const inputDate = moment(date).startOf("day");
      if (inputDate.isBefore(today, "day")) {
        return true;
      }
      return false;
    },
  });
  const [hoId, setHoId] = useState(user.id);
  const [multiRow, setMultiRow] = useState(1);

  const validateRows = () => {
    const newErrors = [];
    transferData.forEach((row, index) => {
      const rowErrors = [];
      for (const key in row) {
        if (row[key] === "" && key !== "narration") {
          rowErrors.push(key);
        }
      }
      if (rowErrors.length > 0) {
        newErrors[index] = rowErrors;
      }
    });
    setErrors({ ...errors, tableData: newErrors });
    return newErrors.length === 0;
  };

  const handelAddRow = () => {
    if (transferData.length < 10)
      setTransferdata((prevData) => [...prevData, { ...INITIAL_STATE }]);
    else
      toast.info(
        "you can add upto 10 row only for adding more row kindly use bulk uploader",
        {
          toastId: "warning1",
        }
      );
  };
  const handelDeleteRow = (rowIndex) => {
    toast.info("1 row deleted");
    setTransferdata((prevData) =>
      prevData.filter((_, index) => index !== rowIndex)
    );
  };

  const handelChangeStockIn = (index, e, value) => {
    const data = [...transferData];
    if (e === "branchId") {
      data[index]["branchId"] = value;
    } else if (e === "date") {
      data[index]["expiryDate"] = value
        ? moment(new Date(value)).format("YYYY-MM-DD")
        : "";
    } else if (e === "medicineId") {
      if (value?.runningBalance === 0) {
        toast.info("Quantity is not available");
      }
      data[index]["medicineId"] = value?.medicineId ? value.medicineId : null;

      data[index]["quantity"] = value?.runningBalance;
      data[index]["maxMedicineCapicity"] = value?.runningBalance;
      data[index]["expiryDate"] = new Date(value?.expiryDate);
      data[index]["medicineName"] = value?.medicineName
        ? value.medicineName
        : null;
      data[index]["sku"] = value?.sku ? value.sku : null;
      data[index]["batchNumber"] = value?.batchNo || "";
    } else {
      const { name, value } = e.target;
      data[index][name] = value;
    }

    setTransferdata(data);
    setErrors((prevErrors) => {
      const newErrors = [...errors.tableData];
      if (newErrors[index]) {
        if (e === "date") delete newErrors[index]["expiryDate"];
        else delete newErrors[index][e.target.name];
      }
      return { ...prevErrors, tableData: newErrors };
    });
  };

  const onMedicineClear = (index) => {
    const data = [...transferData];
    data[index]["medicineId"] = "";
    data[index]["medicineName"] = "";
    data[index]["sku"] = "";
    data[index]["quantity"] = "";
    data[index]["expiryDate"] = "";
    data[index]["maxMedicineCapicity"] = "";
    data[index]["batchNumber"] = "";
    setTransferdata(data);
  };

  const onAddMultiRow = () => {
    const numberOfRowsToAdd = parseInt(multiRow);

    if (!isNaN(numberOfRowsToAdd) && numberOfRowsToAdd > 0) {
      const newRows = Array.from({ length: numberOfRowsToAdd }, () => ({
        ...INITIAL_STATE,
      }));
      setTransferdata((prevState) => [...prevState, ...newRows]);
    }
  };
  const checkHeader = (id) => {
    let headerErr = [];

    if (!sendingDate.date) {
      headerErr.push("sendingDate");
    }
    if (!hoId) {
      headerErr.push("HO_ID");
    }
    setErrors({ ...errors, header: headerErr });
    return headerErr.length === 0;
  };

  const handelSubmit = async (id) => {
    if (!checkHeader(id)) {
      toast.warning("Please fill mandatory field", {
        toastId: "error1",
      });
      return;
    }

    if (!validateRows()) {
      toast.warning("Please fill mandatory field", {
        toastId: "error2",
      });
    } else {
      AppStore.dispatch({ type: "LOADING", loading: true });
      let data = {
        sendingResellerId: hoId,
        sendingDate: sendingDate.date
          ? moment(new Date(sendingDate.date)).format("YYYY-MM-DD")
          : "",
        batchNumber: "",
        subStockOutInventoryRequests: transferData.map((item) => {
          return {
            ...item,
            receivingResellerId: item.branchId,
            stockOut: item.quantity,
            expiryDate: moment(item.expiryDate).format("YYYY-MM-DD"),
          };
        }),
      };
      await postApiClaim(`send_inventory_ho_to_branch`, data)
        .then((response) => {
          if (response.code === 200) {
            if (response?.data?.length > 0) {
              toast.error(
                `${response.data.length} out of ${transferData.length} Stock transfered failed `
              );

              let tempData = response?.data.map((item) => {
                return {
                  ...item,
                  branchId: item.receivingResellerId.toString(),
                  quantity: item.stockOut,
                };
              });
              tempData.forEach((item) => toast.error(item.errorMessage));
              setTransferdata(tempData);
            } else {
              toast.success("Stock transfered successfully ");
              setTransferdata([{ ...INITIAL_STATE }]);
              setSendingDate({
                title: "Dispatch Date",
                date: "",
              });
            }
          } else {
            toast.error(response?.message);
          }
        })
        .catch((error) => toast.error(error))
        .finally(() => AppStore.dispatch({ type: "LOADING", loading: false }));
    }
  };
  const onRefesh = () => {
    setTransferdata([{ ...INITIAL_STATE }]);
    setSendingDate({
      title: "Dispatch date",
      date: "",
      disableDateFunc: (date) => {
        const today = moment().startOf("day");
        const inputDate = moment(date).startOf("day");
        if (inputDate.isBefore(today, "day")) {
          return true;
        }
        return false;
      },
    });
  };

  return (
    <>
      <p
        className="text-center font-semibold text-lg"
        style={{ color: "#DB2228" }}
      >
        Transfer medicine
      </p>
      <ButtonHeader view={view} setView={setView} />
      {view === SINGLE_DATA && (
        <InventoryTable
          data={transferData}
          handelAddRow={handelAddRow}
          handelDeleteRow={(rowIndex) => handelDeleteRow(rowIndex)}
          onchange={(index, e, value) => handelChangeStockIn(index, e, value)}
          errors={errors}
          handelSubmit={handelSubmit}
          component={component}
          onMedicineClear={onMedicineClear}
          onAddMultiRow={onAddMultiRow}
          headerDate={sendingDate}
          setHeaderDate={setSendingDate}
          medicineSearchUrlPrefix="transfer_medicine"
          setHoId={setHoId}
          hoId={hoId}
          disableDate={true}
          onRefesh={onRefesh}
          checkHeader={checkHeader}
          setMultiRow={setMultiRow}
          multiRow={multiRow}
          windowSize={windowSize}
        />
      )}
      {view === BULK_DATA && (
        <ExcelUploader
          sampleFile={SampleFile}
          fileName="StockOutSampleFile.xlsx"
          headers={headers}
          request="uploadStockTransactionData"
          transactionType="STOCK_OUT"
        />
      )}
    </>
  );
};

export default PurchaseStock;
