import React from "react";
import InputLabel from "../../components/form/InputLabel";
import { InputFile } from "../../components/form/InputFile";

const ImageView = ({ doc, index, onRemove, id }) => {
  return (
    <div className="mb-5 ml-5 flex flex-col " key={index}>
      <InputLabel label={`${doc?.name}`} />

      <div className="mt-2 relative">
        <InputFile
          isZoom={true}
          file={doc}
          readOnly={true}
          paddingY="1"
          attrs={{
            accept: "image/*,.pdf",
            disable: true,
            style: { marginBottom: 1 },
          }}
          customHeight="200px"
          customWidth="200px"
        />

        {true && (
          <div
            onClick={() => onRemove && onRemove(index, id)}
            style={{
              cursor: "pointer",
              position: "absolute",
              backgroundColor: "#f8f8f8",
              width: 25,
              height: 25,
              borderRadius: 15,
              right: 0,
              top: -5,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
            }}
          >
            X
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageView;
