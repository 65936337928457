import { AmountText } from "./AppComponents";

export const PlanOption = ({ title = "", features = [], amount = "1,123" }) => {
  return (
    <div className="bg-primary-50 border border-warning rounded-xl p-5">
      <p className="font-semibold">{title}</p>
      <ul className="list-disc pl-4 text-xs my-3">
        {features.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
      <div className="font-semibold">
        <AmountText amount={amount} />
      </div>
    </div>
  );
};

export const GroupOption = ({
  groupName = "",
  description = "",
  features = [],
}) => {
  return (
    <div
      className="bg-primary-50 border border-warning rounded-xl p-5"
      style={{ height: "150px" }}
    >
      <p className="font-semibold">{groupName}</p>
      <p className="text-xs my-3">{description}</p>
      <ul className="list-disc pl-4 text-xs my-3">
        {features?.map((item, index) => {
          return <li key={index}>{item.descriptionText}</li>;
        })}
      </ul>
    </div>
  );
};
