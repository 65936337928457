function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export default function setColor(hexCode) {
  var { r, g, b } = hexToRgb(hexCode);
  var rgbValue = [r, g, b];
  var color = Math.round(
    (parseInt(rgbValue[0]) * 299 +
      parseInt(rgbValue[1]) * 587 +
      parseInt(rgbValue[2]) * 114) /
      1000
  );
  var textColor = color > 125 ? "black" : "white";
  // var backColor =
  //   "rgb(" + rgbValue[0] + ", " + rgbValue[1] + ", " + rgbValue[2] + ")";
  return textColor;
}
