import React from "react";
import { Steps } from "rsuite";

function Stepper({ activeStep = 0, steps = [] }) {
  return (
    <>
      <Steps small current={activeStep}>
        {steps?.length > 0 &&
          steps?.map((item) => <Steps.Item title={item.title} />)}
      </Steps>
    </>
  );
}

export default Stepper;
