import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { Modal } from "rsuite";
import {
  GetPmjayApiData,
  getDocumentJava,
  getResellerId,
  pmjayDataUpdate,
} from "../../lib/AppHelper";
import { toast } from "react-toastify";
import { AppStore } from "../../lib/AppStore";
import { RefreshIcon } from "@heroicons/react/outline";
import { InputGroup } from "../../components/form/InputGroup";
import Main from "../Main";
import { Pagination } from "rsuite";
import moment from "moment";
import { InputTextArea } from "../../components/form/InputTextArea";
import { FileUploader } from "../../components/form/FileUploader";

export class UpdateCustomerInfo extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    data: [],
    search: {
      page: 0,
      size: 10,
      aadhaarNumber: "",
      pmjayId: "",
      loanCode: "",
      mobile: "",
      resellerId: getResellerId(),
      getChildData: true,
    },
    params: {
      id: "",
      aadhaarNumber: "",
      aadhaarImage: "",
      pmjayId: "",
      pmjayImage: "",
      loanCode: "",
      loanCodeImage: "",
      mobile: "",
      consentImage: "",
      consentText: "",
      aadhaarImageShow: null,
      pmjayImageShow: null,
      loanCodeImageShow: null,
      consentImageShow: null,
      aadhaarImagePdf: null,
      pmjayImagePdf: null,
      loanCodeImagePdf: null,
      consentImagePdf: null,
    },
    errors: {
      pmjayId: "",
      aadhaarImage: "",
      loanCodeImage: "",
      pmjayImage: "",
      consentImage: "",
    },
    open: false,
    loading: false,
  };

  handleClickReset = () => {
    const { search } = this.state;
    search["page"] = 0;
    search["aadhaarNumber"] = "";
    search["pmjayId"] = "";
    search["loanCode"] = "";
    search["mobile"] = "";
    this.setState({ search: search });
    this.getPmJayData(this.state.search);
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onSearchHandler = (e, key) => {
    const { search } = this.state;
    search[key] = e.target.value;
    this.setState({ search: search });
    if (e.target.value.length === 0) {
      this.getPmJayData(this.state.search);
    }
  };

  openModal = (item) => {
    this.setState({ open: true, openedModalItem: item });
    const { params } = this.state;
    params["id"] = item?.id;
    params["pmjayId"] = item?.pmjayId;
    params["aadhaarNumber"] = item?.aadhaarNumber;
    params["consentText"] = item?.consentText;
    params["loanCode"] = item?.loanCode;
    params["mobile"] = item?.mobile;
    this.setState({ params: params });
  };

  handleClose = () => {
    this.setState({ open: false });
    const { params } = this.state;
    this.emptyParams(params);
  };

  update = () => {
    this.setState({ loading: true });
    this.setState({ open: false });
    AppStore.dispatch({ type: "LOADING", loading: true });
    pmjayDataUpdate(this.state.params)
      .then((res) => {
        if (res?.code == 200) {
          this.setState({ open: false });
          const { params } = this.state;
          toast.success("Data Update Successfully");
          this.emptyParams(params);
          this.getPmJayData(this.state.search);
          this.setState({ loading: false });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          this.setState({ loading: false });
          AppStore.dispatch({ type: "LOADING", loading: false });
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        AppStore.dispatch({ type: "LOADING", loading: false });
        console.log(err);
      });
  };

  emptyParams = (params) => {
    params["aadhaarNumber"] = "";
    params["aadhaarImage"] = "";
    params["pmjayId"] = "";
    params["pmjayImage"] = "";
    params["loanCode"] = "";
    params["loanCodeImage"] = "";
    params["mobile"] = "";
    params["consentImage"] = "";
    params["consentText"] = "";
    params["aadhaarImageShow"] = null;
    params["pmjayImageShow"] = null;
    params["loanCodeImageShow"] = null;
    params["consentImageShow"] = null;
    params["aadhaarImagePdf"] = null;
    params["pmjayImagePdf"] = null;
    params["loanCodeImagePdf"] = null;
    params["consentImagePdf"] = null;
    setTimeout("", 2000);
    this.setState({ params: params });
  };

  filterMobile = (e) => {
    const { search } = this.state;
    search["mobile"] = e.target.value;
    search["page"] = 0;
    this.setState({ search: search });
    if (e.target.value.length === 10) {
      this.getPmJayData(this.state.search);
    }
    if (e.target.value.length === 0) {
      this.getPmJayData(this.state.search);
    }
  };

  isReady = () => {
    const { params } = this.state;

    if (!params.loanCodeImage) return false;
    if (!params.consentImage) return false;
    if (!params.aadhaarImage) return false;
    if (params.aadhaarNumber && params.aadhaarNumber.length < 12) return false;
    if (params.pmjayId && params.pmjayId.length < 12) return false;
    if (!params.loanCode || params.loanCode.length < 5) return false;
    return true;
  };

  setParams = (field, value) => {
    this.setState((state) => {
      let params = state.params;
      return {
        ...state,
        params: { ...params, [field]: value },
      };
    });
  };

  setErrors = (field, errorMessage) => {
    this.setState((state) => {
      let errors = state.errors;
      return {
        ...state,
        errors: { ...errors, [field]: errorMessage },
      };
    });
  };

  onPageChange = (currentPage) => {
    if (currentPage === false) return false;
    let page = currentPage >= 1 ? currentPage - 1 : currentPage;
    this.setState({ search: { ...this.state.search, page } }, () => {
      this.getPmJayData(this.state.search);
    });
  };

  componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.getPmJayData(this.state.search);
  }

  getPmJayData = async (request = "") => {
    const response = await GetPmjayApiData(
      "enrollment-service/pmjay_reseller_mapping/fetchAllLoanCodePmjayIdDetails",
      request
    );
    if (response.code === 200) {
      if (response?.responseObject) {
        const items = Object.values(response?.responseObject);
        this.setState({ data: items });
        this.setState({ total: response?.count });
      } else {
        this.setState({ data: [] });
        this.setState({ total: 0 });
        let page = 0;
        this.setState({ search: { ...this.state.search, page } });
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else {
      AppStore.dispatch({ type: "LOADING", loading: false });
      // this.MessageRef.current.show(response?.message);
      this.setState({ data: [] });
      this.setState({ total: 0 });
      let page = 0;
      this.setState({ search: { ...this.state.search, page } });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  render() {
    const { params, search, data, open, errors, openedModalItem } = this.state;
    const { user } = this.props;
    const { permissions } = user;
    const ready = this.isReady();
    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.update_pm_jay_information ? (
          <>
            <div className="w-full flex md:flex-row flex-col">
              <div className="w-96">
                <AppBackButton to="/">Back</AppBackButton>
              </div>
            </div>
            <div className="flex items-center flex-wrap justify-between">
              <InputGroup
                label="Mobile"
                placeholder="mobile number..."
                attrs={{
                  maxLength: 10,
                  minLength: 10,
                  value: search.mobile,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value);
                    if (isValidInteger || e.target.value == "") {
                      this.filterMobile({
                        target: {
                          value: e.target.value.replace(/\D/, ""),
                        },
                      });
                    }
                  },
                }}
              />
              <InputGroup
                label="Loan code/Reference number"
                placeholder="Loan code/Reference number..."
                attrs={{
                  maxLength: 20,
                  minLength: 12,
                  value: search.loanCode,
                  onChange: (e) => {
                    this.onSearchHandler(e, "loanCode");
                  },
                }}
                onEnter={() => {
                  if (search.loanCode) {
                    const { search } = this.state;
                    search["page"] = 0;
                    this.setState({ search: search });
                    this.getPmJayData(this.state.search);
                  }
                }}
              />
              <InputGroup
                label="PM-Jay"
                placeholder="pm-jay number..."
                attrs={{
                  maxLength: 12,
                  minLength: 12,
                  value: search.pmjayId,
                  onChange: (e) => {
                    this.onSearchHandler(e, "pmjayId");
                  },
                }}
                onEnter={() => {
                  if (search.pmjayId) {
                    const { search } = this.state;
                    search["page"] = 0;
                    this.setState({ search: search });
                    this.getPmJayData(this.state.search);
                  }
                }}
              />
              <InputGroup
                label="Aadhaar"
                placeholder="aadhaar number..."
                attrs={{
                  maxLength: 12,
                  minLength: 12,
                  value: search.aadhaarNumber,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value);
                    if (isValidInteger || e.target.value == "") {
                      this.onSearchHandler(
                        {
                          target: {
                            value: e.target.value.replace(/\D/, ""),
                          },
                        },
                        "aadhaarNumber"
                      );
                    }
                  },
                }}
                onEnter={() => {
                  if (search.aadhaarNumber.length > 11) {
                    const { search } = this.state;
                    search["page"] = 0;
                    this.setState({ search: search });
                    this.getPmJayData(this.state.search);
                  }
                }}
              />
              <button
                onClick={this.handleClickReset}
                className="text-white mt-2 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
              >
                <RefreshIcon width={25} className="m-1" />
              </button>
              <Button
                className="mt-2"
                attrs={{
                  type: "button",
                  onClick: () => {
                    this.getPmJayData(this.state.search);
                  },
                }}
                size="md"
                title={"Search"}
              />
            </div>
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full shadow-lg" id="table-to-xls">
                      <thead className="border-b-2">
                        <tr
                          style={{ backgroundColor: "#db2228", color: "#fff" }}
                        >
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            Mobile
                          </th>
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            Loan Code/Reference Number
                          </th>
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            PM-Jay
                          </th>
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            Aadhaar Number
                          </th>
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            Created By
                          </th>
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            Created At
                          </th>
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => {
                          return (
                            <tr className="border-b-2" key={index}>
                              <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                                {item?.mobile ? item.mobile : "---"}
                              </td>
                              <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                                {item?.loanCode ? item.loanCode : "---"}
                              </td>
                              <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                                {item?.pmjayId ? item.pmjayId : "---"}
                              </td>
                              <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                                {item?.aadhaarNumber
                                  ? item.aadhaarNumber
                                  : "---"}
                              </td>
                              <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center capitalize">
                                {item?.resellerName ? item.resellerName : "---"}
                              </td>
                              <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center ">
                                {item.createdAt
                                  ? moment(item.createdAt).format(
                                      "DD,MMM YYYY  h:mm:ss A"
                                    )
                                  : "---"}
                              </td>
                              <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                                <Button
                                  attrs={{
                                    ref: this.BasicBtn,
                                    type: "button",
                                    onClick: () => this.openModal(item),
                                  }}
                                  size="md"
                                  title={"Edit"}
                                />
                              </td>
                            </tr>
                          );
                        })}
                        {data.length === 0 ? (
                          <tr>
                            <td
                              colSpan="10"
                              height="50"
                              className="text-red-500 font-light text-center"
                            >
                              Your result is empty
                            </td>
                          </tr>
                        ) : (
                          false
                        )}
                      </tbody>
                    </table>
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={2}
                        size="xs"
                        layout={this.state.layout}
                        total={this.state.total}
                        limit={this.state.limit}
                        activePage={this.state.search.page + 1}
                        onChangePage={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              open={open}
              onClose={this.handleClose}
              className="w-96 sm:w-2/3 md:w-1/2 lg:w-1/3"
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title>Update Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form action={this.update}>
                  <FileUploader
                    field={"loanCodeImage"}
                    params={params}
                    setState={this.setParams}
                    error={errors.loanCodeImage}
                    setErrors={this.setErrors}
                    label="Loan code/Reference number Attachment"
                    required={true}
                    request={getDocumentJava}
                    valuePath={openedModalItem?.loanCodeImagePath}
                    isRequest={openedModalItem?.loanCodeImagePath}
                  />
                  <div className="w-96">
                    <InputGroup
                      className="w-96"
                      label="Loan code/Reference number"
                      requiredMark="true"
                      placeholder="Loan code/Reference number..."
                      attrs={{
                        maxLength: 20,
                        minLength: 12,
                        value: params.loanCode,
                        onChange: (e) => {
                          const integerStringRegex = /^[a-zA-Z0-9]+$/;
                          const isValidIntegerString = integerStringRegex.test(
                            e.target.value
                          );
                          if (isValidIntegerString || e.target.value == "") {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: e.target.value,
                                },
                              },
                              "loanCode"
                            );
                          }
                        },
                      }}
                    />
                  </div>
                  <hr className="mr-5" />
                  <FileUploader
                    field={"pmjayImage"}
                    params={params}
                    setState={this.setParams}
                    error={errors.pmjayImage}
                    setErrors={this.setErrors}
                    label="PM-JAY Attachment"
                    // required={true}
                    request={getDocumentJava}
                    valuePath={openedModalItem?.pmjayImagePath}
                    isRequest={openedModalItem?.pmjayImagePath}
                  />
                  <div className="w-96">
                    <InputGroup
                      label="PM-JAY Id"
                      placeholder="Enter PM-JAY ID"
                      attrs={{
                        maxLength: 12,
                        minLength: 12,
                        value: params.pmjayId,
                        onChange: (e) => {
                          const integerStringRegex = /^[a-zA-Z0-9]+$/;
                          const isValidIntegerString = integerStringRegex.test(
                            e.target.value
                          );
                          if (isValidIntegerString || e.target.value == "") {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: e.target.value,
                                },
                              },
                              "pmjayId"
                            );
                          }
                        },
                      }}
                      validateError={(value) => {
                        if (value && value.length < 12) {
                          return "Please enter 12 length number";
                        }
                      }}
                    />
                  </div>
                  <hr className="mr-5" />
                  <FileUploader
                    field={"aadhaarImage"}
                    params={params}
                    setState={this.setParams}
                    error={errors.aadhaarImage}
                    setErrors={this.setErrors}
                    label="Aadhaar Attachment"
                    required={true}
                    request={getDocumentJava}
                    valuePath={openedModalItem?.aadhaarImagePath}
                    isRequest={openedModalItem?.aadhaarImagePath}
                  />
                  <div className="w-96">
                    <InputGroup
                      label="Aadhaar Number"
                      placeholder="Aadhaar Number..."
                      attrs={{
                        maxLength: 12,
                        minLength: 12,
                        value: params.aadhaarNumber,
                        onChange: (e) => {
                          const integerRegex = /^-?\d+$/;
                          const isValidInteger = integerRegex.test(
                            e.target.value
                          );
                          if (isValidInteger || e.target.value == "") {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: e.target.value.replace(/\D/, ""),
                                },
                              },
                              "aadhaarNumber"
                            );
                          }
                        },
                      }}
                      validateError={(value) => {
                        if (value && value.length < 12) {
                          return "Please enter 12 digits number";
                        }
                      }}
                    />
                  </div>

                  <hr className="mr-5" />
                  <FileUploader
                    field={"consentImage"}
                    params={params}
                    setState={this.setParams}
                    error={errors.consentImage}
                    setErrors={this.setErrors}
                    label="Consent Attachment"
                    required={true}
                    request={getDocumentJava}
                    valuePath={openedModalItem?.consentPath}
                    isRequest={openedModalItem?.consentPath}
                  />
                  <div className="w-96">
                    <InputTextArea
                      label="Consent Text"
                      // requiredMark="true"
                      placeholder="Consent Text ..."
                      attrs={{
                        maxLength: 64,
                        value: params.consentText,
                        onChange: (e) => {
                          this.onChangeHandler(e, "consentText");
                        },
                      }}
                    />
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer className="mt-2">
                <Button
                  className="float-left"
                  attrs={{
                    ref: this.BasicBtn,
                    disabled: !ready,
                    type: "submit",
                    onClick: this.update,
                  }}
                  size="md"
                  title="Update"
                />
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>
                You do not have permission to view update PM Jay Information
              </h4>
            </div>
          </div>
        )}
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const UpdateCustomerInfoConnect = connect(mapStateToProps)((props) => {
  return <UpdateCustomerInfo {...props} />;
});

export default withRouter(UpdateCustomerInfoConnect);
