import React from "react";
import InputTextArea from "../../../components/form/InputTextArea";
import Button from "../../../components/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IconButton, Tooltip } from "@mui/material";
import ImageUploader from "../../../components/ImageUploader";
import CircularProgress from "@mui/material/CircularProgress";

const CommentHeader = ({
  comment,
  setComment,
  error,
  buttonLoading,
  documents,
  onChangeFileHandeler,
  addComment,
  removeFile,
  setViewImage,
  setError,
}) => {
  return (
    <div>
      <div className="w-full flex gap-3">
        <InputTextArea
          fullWidth={true}
          placeholder="Write your comment here less than 526 charcter..."
          name="description"
          disabled={buttonLoading}
          error={error?.discription}
          row={5}
          attrs={{
            maxLength: 526,
            style: { flexGrow: 1 },
            value: comment,
            onChange: (e) => {
              if (e.target.value.length > 526)
                setError({ discription: ["Maximum character limit is 526"] });
              else {
                setError({ discription: "" });
                setComment(e.target.value);
              }
            },
          }}
        />
      </div>
      <div className="mb-4">
        {documents?.length > 0 &&
          documents?.map((document, index) => (
            <ImageUploader
              key={index}
              id={index}
              file={document}
              onDelete={(id) => {
                if (!buttonLoading) removeFile(id);
              }}
              onView={(id) => {
                if (!buttonLoading) setViewImage({ isOpen: true, id });
              }}
            />
          ))}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <input
          accept="*"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          disabled={buttonLoading}
          onChange={(e) => {
            if (!buttonLoading) onChangeFileHandeler(e);
          }}
        />
        <label htmlFor="raised-button-file">
          <Tooltip title="Upload document" placement="top" arrow>
            <IconButton component="span">
              <AttachFileIcon style={{ color: "#db2228" }} fontSize="40px" />
            </IconButton>
          </Tooltip>
        </label>
        <Button
          title=""
          display="inline-block"
          attrs={{
            onClick: () => {
              if (!buttonLoading) addComment();
            },
          }}
        >
          {buttonLoading ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            "SAVE"
          )}
        </Button>
      </div>
    </div>
  );
};

export default CommentHeader;
