import React from "react";
import "./passwordPolicy.css";

const PasswordPolicy = () => {
  return (
    <div className="bg-orange-200 flex flex-col justify-between rounded-xl p-4">
      <p className="text-md font-medium text-orange-800 mb-2">
        Password guidelines:
      </p>
      <ul className="text-orange-800 mb-2 font-medium text-sm list-disc ml-4">
        <li>Be between 6 and 20 characters. </li>
        <li>At least one uppercase letter. </li>
        <li>At least one lowercase letter</li>
        <li>At least one digit. </li>
        <li>Special characters allowed: !@#$%^&*?</li>
      </ul>
    </div>
  );
};

export default PasswordPolicy;
