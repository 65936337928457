import React from "react";
import Cards from "./Cards";
import { Placeholder } from "rsuite";

const CardFilter = ({
  cardFilterArray,
  setFilter,
  filter,
  windowSize,
  tabValue,
  loading,
}) => {
  if (loading) return <Placeholder.Graph active width={2000} height={60} />;
  return cardFilterArray.map((item) => {
    const { status, status_count, new_status_count, type } = item;
    if (!type?.includes(tabValue)) return null;
    return (
      <Cards
        label={status}
        totalCount={status_count}
        newCount={new_status_count}
        setFilter={setFilter}
        filter={filter}
        windowSize={windowSize}
      />
    );
  });
};

export default CardFilter;
