import _ from "lodash";
import React, { Component } from "react";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import { PencilIcon } from "@heroicons/react/outline";

export default class OtpInputGroup extends Component {
  CountdownRef = React.createRef();
  state = {
    can_resend: false,
  };

  render() {
    const { onEdit, mobile, onEnter, value, autoFocus = true } = this.props;
    const { can_resend } = this.state;
    return (
      <div>
        {mobile && (
          <label className="block text-sm text-gray-700 mb-8">
            Please enter OTP sent to{" "}
            <span className="cursor-pointer" onClick={onEdit}>
              <span className="underline">{mobile}</span>
              <PencilIcon
                width={15}
                style={{
                  display: "inline-block",
                  marginLeft: 8,
                  position: "relative",
                  top: -2,
                }}
              />
            </span>
          </label>
        )}
        <div className="relative">
          <form onSubmit={onEnter}>
            <OtpInput
              isInputNum={true}
              shouldAutoFocus={autoFocus}
              containerStyle="justify-between"
              inputStyle="focus:ring-0 focus:border-primary focus:bg-primary-50 text-center w-full text-base border-gray-300 rounded-xl disabled:bg-gray-100 min-w-4rem px-2 py-3"
              value={value}
              onChange={this.props.onChange}
            />
            <input type="submit" className="hidden" />
          </form>
        </div>
        <label
          className={`block mt-2 text-right text-sm text-primary ${
            can_resend ? "hidden" : ""
          }`}
        >
          Resend OTP in{" "}
          <strong className="font-bold">
            <Countdown
              onComplete={this.onCompleteCountdown}
              renderer={this.countdownRenderer}
              autoStart={false}
              ref={this.CountdownRef}
              date={Date.now() + 30000}
            />
          </strong>
        </label>
        {can_resend ? (
          <label
            onClick={this.resend}
            className="block underline cursor-pointer mt-2 text-right text-sm text-primary"
          >
            Resend OTP
          </label>
        ) : (
          false
        )}
      </div>
    );
  }

  countdownRenderer = ({ minutes, seconds }) => {
    return `${_.padStart(minutes, 2, "0")}:${_.padStart(seconds, 2, "0")}`;
  };

  componentDidMount() {
    this.CountdownRef.current.start();
  }

  onCompleteCountdown = () => {
    this.setState({ can_resend: true });
  };

  resend = () => {
    this.setState({ can_resend: false }, () => {
      this.CountdownRef.current.start();
      this.props.onResend();
    });
  };
}
