import AckoSampleFile from "./AckoAbclSampleFile.xlsx";
import CareSampleFile from "./CareSampleFile.xlsx";
import LibertySampleFile from "./LibertySampleFile.xlsx";

export const AbclHeaders = [
  {
    name: "INSURED_MEMBER_ID",
    mandatory: true,
    type: "number",
    dataKey: "INSURED_MEMBER_ID",
  },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];

export const AckoHeaders = [
  {
    name: "INSURED_MEMBER_ID",
    mandatory: true,
    type: "number",
    dataKey: "INSURED_MEMBER_ID",
  },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];

export const CareHeaders = [
  {
    name: "LOAN_CODE",
    mandatory: true,
    type: "string",
    dataKey: "LOAN_CODE",
  },
  { name: "RELATION", mandatory: true, type: "string", dataKey: "RELATION" },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];

export const LibertyHeaders = [
  {
    name: "UNIQUE_REF_NUMBER",
    mandatory: true,
    type: "string",
    dataKey: "UNIQUE_REF_NUMBER",
  },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];

export const vendorPolicyMaping = {
  ACKO: {
    sampleFile: AckoSampleFile,
    uniqueHeaders: AckoHeaders,
  },
  LIBERTY: {
    sampleFile: LibertySampleFile,
    uniqueHeaders: LibertyHeaders,
  },
  ABCL: {
    sampleFile: AckoSampleFile,
    uniqueHeaders: AbclHeaders,
  },
  FEDERAL: {
    sampleFile: CareSampleFile,
    uniqueHeaders: CareHeaders,
  },
  PRAMERICA: {
    sampleFile: CareSampleFile,
    uniqueHeaders: CareHeaders,
  },
  CARE: {
    sampleFile: CareSampleFile,
    uniqueHeaders: CareHeaders,
  },
};
