import { Field } from "formik";
import React from "react";
import InputLabel from "../form/InputLabel";

export default function InputSelect({
  label,
  labelLink,
  requiredMark,
  name,
  value,
  options,
  onBlur,
  onChange,
}) {
  return (
    <div className="mb-1">
      <InputLabel
        label={label}
        labelLink={labelLink}
        requiredMark={requiredMark}
      />
      <div className="flex space-x-4">
        <Field
          className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl  disabled:bg-gray-100"
          name={name}
          value={value}
          onBlur={onBlur}
          as="select"
          onChange={onChange}
        >
          <option value="">Select</option>
          {options?.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Field>
      </div>
    </div>
  );
}
