import React, { useEffect, useRef, useState } from "react";
import Main from "../Main";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import CardFilter from "./component/CardFilter";
import { DatePicker, SelectPicker } from "rsuite";
import {
  AllFilterValue,
  DateFilter,
  Label,
  handleRemoveItem,
  setFormateDate,
} from "./component/constant";
import moment from "moment";
import {
  ApiGet,
  downloadCSVFileForClaimPoint,
  getResellerId,
  postApiClaim,
} from "../../lib/AppHelper";
import { ClaimCard } from "./component/ClaimsCard";
import { AppStore } from "../../lib/AppStore";
import { toast } from "react-toastify";
import { Divider } from "@mui/material";
import CommentsModal from "./component/CommentsModal";
import { ClaimsFilters } from "./component/ClaimsFilters";
import CustomModal from "./component/CustomModal";
import DataTable from "./component/DataTable";
import Button from "../../components/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import HeaderCards from "./component/HeaderCards";
import { useLocation } from "react-router-dom";
import { ImageType } from "../heathCamp/constant";
import Compressor from "compressorjs";
import { addCommentAPI } from "./api";

const tab = [
  { label: "All", value: "all" },
  { label: "Open ", value: "open_claim" },
  { label: "Closed ", value: "closed_claim" },
];

const Index = () => {
  const location = useLocation();
  const { state } = location;

  const user = useSelector((state) => state.user);
  const permissions = user.permissions;
  const [filter, setFilter] = useState(
    localStorage.getItem("card_Filter") || ""
  );
  const [cardFilterArray, setCardFilterArray] = useState([]);
  const [dateFilter, setDateFilter] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [ticketDetails, setTicketDetails] = React.useState({
    title: "",
    description: "",
    status: "In_Progress",
    priority: "High",
  });
  const [ticketDocuments, setTicketDocuments] = React.useState([]);
  const [open, setOpen] = useState(null);
  const [tabValue, setTabValue] = useState("all");
  const [headerData, setHeaderData] = useState({
    all: null,
    closed_claim: null,
    open_claim: null,
  });
  const [ticketLoading, setTicketLoading] = useState(false);
  const MessageRef = useRef();
  const FilterChildRef = useRef(null);
  const fetchAPIData = React.useRef(null);
  const [claimSpecification, setClaimSpecification] = useState([]);

  const fetchCardsCounts = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    try {
      const response = await ApiGet(`claims-status-count`);
      if (response?.status === "success") {
        const allObject = {
          status: "ALL",
          status_count: response.data.total,
          new_status_count: 0,
        };

        setCardFilterArray([allObject, ...response?.data?.notification]);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };
  const fetchCounts = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    try {
      const response = await ApiGet(`claim-all-open-close-count`);
      if (response?.status === "success") {
        setHeaderData({
          all: response.data.total || 0,
          closed_claim: response.data.total_closed_claims || 0,
          open_claim: response.data.total_open_claims || 0,
        });
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (windowSize[0] > 800)
        window.scrollTo({ top: 600, behavior: "smooth" });
    }
  };

  const handleFetch = () => {
    if (fetchAPIData.current) {
      fetchAPIData.current();
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    fetchCardsCounts();
    fetchCounts();
    getSpecification();
  }, []);

  const onChangeFileHandeler = (event) => {
    if (ticketDocuments.length > 4) {
      toast.warning("maximum file can be upto 5");
      return;
    }
    const currentFile = event?.target?.files?.[0];

    if (currentFile) {
      const mimeType = currentFile.type;
      const fileSize = currentFile.size;

      if (mimeType !== "application/pdf" && !ImageType.includes(mimeType)) {
        toast.warning("Only images or PDFs are allowed");
      } else if (mimeType === "application/pdf" && fileSize > 5000000) {
        toast.warning("PDF size should not be more than 5 MB");
      } else if (mimeType === "application/pdf" && fileSize <= 5000000) {
        setTicketDocuments((prev) => [...prev, currentFile]);
      } else if (ImageType.includes(mimeType)) {
        new Compressor(currentFile, {
          quality: 0.6,
          success(result) {
            setTicketDocuments((prev) => [...prev, result]);
          },
          error(err) {
            console.error(err.message);
          },
        });
      }
    }
    event.target.value = null;
  };

  const handelSubmitRaiseTicket = async (data) => {
    if (!data) return;

    setTicketLoading(true);
    try {
      await postApiClaim("add-ticket", data)
        .then(async (res) => {
          if (res.code === 200) {
            const commentData = {
              ticketMasterId: res?.data?.id,
              createdBy: getResellerId(),
              comments: data?.ticketTitle,
              referenceId: data?.referenceId,
              subReferenceId: data?.subReferenceId,
              userApp: "RESELLER",
            };
            try {
              await addCommentAPI({
                data: commentData,
                documents: ticketDocuments,

                onSuccess: () => {
                  setTicketDetails({
                    title: "",
                    description: "",
                    status: "In_Progress",
                    priority: "High",
                  });
                  setOpen(null);
                  toast.success("Ticket raise successfully");
                  fetchCardsCounts();
                  handleFetch();
                  setTicketLoading(false);
                  setTicketDocuments([]);
                },
                onError: (message) => {
                  setTicketLoading(false);
                  toast.error(message);
                },
              });
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FilterChildRef?.current?.handelInitalValue();
    setFilterValues({});
    if (filter === "SETTLED" || filter === "REJECTED") {
      setDateFilter({
        ...dateFilter,
        dateType: "1_month",
        from: setFormateDate("1_month")[0],
        to: setFormateDate("1_month")[1],
      });
    } else {
      setDateFilter({
        ...dateFilter,
        dateType: "All",
        from: "",
        to: "",
      });
    }
  }, [filter]);

  const downloadExcel = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const data = {
      statusList: filter ? [filter] : AllFilterValue(tabValue, cardFilterArray),
      resellerId: getResellerId(),
      from: dateFilter.from,
      to: dateFilter.to,
      specification_type: dateFilter.specificationType,
    };
    try {
      await downloadCSVFileForClaimPoint(
        "claimReport/claim-reseller-dashboard-report",
        data,
        "report.csv"
      );
    } catch (error) {
      toast.error(error?.message || "something went wrong");
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };
  function removeFile(id) {
    const items = handleRemoveItem(id, ticketDocuments);
    setTicketDocuments(items);
  }
  const handleClose = () => {
    setOpen(null);
    handleFetch();
  };

  useEffect(() => {
    if (state?.item) setOpen(state?.item);
  }, [state?.item]);

  const getSpecification = async () => {
    await ApiGet("claim-specification").then((res) => {
      if (res.code === 200) {
        const tempData = res?.data?.specification?.map((item) => ({
          label: `${item}`?.charAt(0)?.toUpperCase() + `${item}`?.slice(1),
          value: item,
        }));
        setClaimSpecification(
          [
            {
              label: "All",
              value: "",
            },
            ...tempData,
          ] || []
        );
      } else {
        setClaimSpecification([]);
      }
    });
  };
  return (
    <Main MessageRef={MessageRef}>
      <AppBackButton to="/" label="Back to Dashboard" />
      <AppHeading
        className="pl-4 text-center font-medium self-end "
        style={{ color: "#DB2228" }}
      >
        Reimbursements
      </AppHeading>
      <div className="mt-8 flex items-center justify-end">
        {permissions?.claim_add ? (
          <>
            <Link to="subscriptions" className="self-end mb-2">
              <button
                title="Add Claim"
                className="w-72 float-right bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded flex items-center"
              >
                <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
                <p className="px-2">Raise reimbursement</p>
              </button>
            </Link>
            {permissions?.reimbursement_uploader && (
              <Link to="/reimbursement-history" className="self-end mb-2 ml-2">
                <button
                  title="Upload bulk data of reimbursement"
                  className="w-72 float-right bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded flex items-center"
                >
                  <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
                  <p className="px-2">Raise bulk reimbursement</p>
                </button>
              </Link>
            )}
          </>
        ) : (
          false
        )}
      </div>

      <div className="w-full">
        <HeaderCards
          tab={tab}
          headerData={headerData}
          setTabValue={setTabValue}
          setFilter={setFilter}
          tabValue={tabValue}
        />

        {/* content */}
        <div className="p-4 mb-2">
          <div className="w-full flex flex-wrap gap-4 mt-5 mb-4">
            <CardFilter
              cardFilterArray={cardFilterArray}
              setFilter={setFilter}
              filter={filter}
              windowSize={windowSize}
              tabValue={tabValue}
            />
          </div>

          <Divider />
          <div
            className="flex justify-between items-center mt-4 mb-4 "
            style={{
              flexDirection: windowSize[0] < 800 && "column",
              alignItems: "center",
              justifyContent: windowSize[0] < 800 ? "center" : "space-between",
              gap: "5px",
            }}
          >
            <div
              className="flex gap-4 items-center"
              style={{
                flexDirection: windowSize[0] < 800 && "column",
              }}
            >
              <div className="flex flex-col">
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Date range
                </label>
                <SelectPicker
                  placeholder={"--Select Date--"}
                  data={DateFilter}
                  value={dateFilter.dateType}
                  cleanable={false}
                  onChange={(value) => {
                    if (value === "custom_date" || value === null) {
                      setDateFilter({
                        ...dateFilter,
                        dateType: value,
                        from: null,
                        to: null,
                      });
                    } else {
                      setDateFilter({
                        ...dateFilter,
                        dateType: value,
                        from: setFormateDate(value)[0],
                        to: setFormateDate(value)[1],
                      });
                    }
                  }}
                  searchable={false}
                  style={{
                    width: windowSize[0] < 800 ? "250px" : "200px",
                    color: "black",
                    opacity: "0 !important",
                    backgroundColor: "white",
                    border:
                      filter === "SETTLED" || filter === "REJECTED"
                        ? "2px solid #DB2228"
                        : "",
                    borderRadius: "12px",
                  }}
                />
              </div>
              {dateFilter.dateType === "custom_date" && (
                <div
                  style={{
                    display: "flex",
                    marginTop: "27px",
                    flexDirection: windowSize[0] < 800 && "column",
                    gap: "10px",
                  }}
                >
                  <DatePicker
                    format="dd/MM/yyyy"
                    placeholder="From"
                    style={{ width: windowSize[0] < 800 ? "250px" : "150px" }}
                    oneTap
                    cleanable={false}
                    onChange={(value) =>
                      setDateFilter({
                        ...dateFilter,
                        from: value ? moment(value).format("YYYY-MM-DD") : null,
                      })
                    }
                    disabledDate={(date) => {
                      if (date > new Date()) {
                        return true;
                      }
                    }}
                  />
                  <DatePicker
                    format="dd/MM/yyyy"
                    placeholder="To"
                    oneTap
                    disabled={!dateFilter.from}
                    cleanable={false}
                    style={{ width: windowSize[0] < 800 ? "250px" : "150px" }}
                    onChange={(value) =>
                      setDateFilter({
                        ...dateFilter,
                        to: value ? moment(value).format("YYYY-MM-DD") : null,
                      })
                    }
                    disabledDate={(date) => {
                      if (
                        date > new Date() ||
                        date < new Date(dateFilter.from)
                      ) {
                        return true;
                      }
                    }}
                  />
                </div>
              )}
              <div className="flex flex-col">
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Reimbursement specification type
                </label>
                <SelectPicker
                  data={claimSpecification}
                  value={dateFilter?.specificationType || ""}
                  cleanable={false}
                  onChange={(value) => {
                    setDateFilter({
                      ...dateFilter,
                      specificationType: value,
                    });
                  }}
                  searchable={false}
                  style={{
                    width: windowSize[0] < 800 ? "250px" : "250px",
                    color: "black",
                    opacity: "0 !important",
                    backgroundColor: "white",
                    borderRadius: "12px",
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              <Button
                title=""
                attrs={{
                  style: { marginTop: "4px" },
                  onClick: () => downloadExcel(),
                }}
              >
                <CloudDownloadIcon />
                &nbsp;<span style={{ fontWeight: "600" }}> Download</span>
              </Button>
            </div>
          </div>
          <div
            className="w-full mt-4 mb-4"
            style={{ flexDirection: windowSize[0] < 800 && "column" }}
          >
            <div style={{ width: "100%", margin: "auto" }}>
              <ClaimsFilters
                setValue={setFilterValues}
                filterValues={filterValues}
                ref={FilterChildRef}
              />
            </div>
          </div>
          <div style={{ fontSize: "14px", fontWeight: "600" }}>
            {dateFilter.from && dateFilter.to
              ? `Data from ${dateFilter.from} to ${dateFilter.to}`
              : "Data from Lifetime"}
            &nbsp;
            {filter && `| Filter: ${Label[filter]}`}
          </div>
          <div>
            <DataTable
              request={ApiGet}
              renderItem={(item) => (
                <ClaimCard
                  item={item}
                  filter={filter}
                  setOpen={setOpen}
                  windowSize={windowSize}
                />
              )}
              endPoint={"claims"}
              filterValues={filterValues}
              dateFilter={dateFilter}
              filter={filter}
              setOpen={setOpen}
              fetchAPIData={fetchAPIData}
            />
          </div>
        </div>
      </div>

      {open && open?.sub_claims[0]?.raised_ticket === "1" ? (
        <CommentsModal open={open} handleClose={handleClose} />
      ) : (
        <CustomModal
          open={open}
          setOpen={setOpen}
          ticketDetails={ticketDetails}
          setTicketDetails={setTicketDetails}
          handelSubmitRaiseTicket={handelSubmitRaiseTicket}
          ticketLoading={ticketLoading}
          onChangeFileHandeler={onChangeFileHandeler}
          ticketDocuments={ticketDocuments}
          removeFile={removeFile}
        />
      )}
    </Main>
  );
};

export default Index;
