import moment from "moment";
export const DateFilter = [
  {
    label: "Last 7 Days",
    value: "last7Days",
  },
  {
    label: "This month",
    value: "thisMonth",
  },
  {
    label: "Last 1 Month",
    value: "last1Month",
  },
  {
    label: "Last 3 Months",
    value: "last3Months",
  },
  {
    label: "Last 6 Months",
    value: "last6Months",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Last 1 Year",
    value: "last1Year",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const LastDays = (days) => {
  const today = new Date();
  const fromDate = new Date(today);
  fromDate.setDate(today.getDate() - days);
  return [fromDate, today];
};

export const WeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label);

  const formattedStartDate = startDate.format("YYYY-MM-DD");
  return [formattedStartDate, currentDate.format("YYYY-MM-DD")];
};

export const LastWeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label).subtract(1, label);

  const endDate = currentDate.clone().startOf(label).subtract(1, "day");
  const formattedStartDate = startDate.format("YYYY-MM-DD");
  const formattedEndDate = endDate.format("YYYY-MM-DD");
  return [formattedStartDate, formattedEndDate];
};

export const LastMonths = (months) => {
  const currentDate = moment();
  const startDate = currentDate
    .clone()
    .subtract(months, "months")
    .startOf("month");
  const endDate = currentDate.clone().startOf("month").subtract(1, "day");

  const formattedStartDate = startDate.format("YYYY-MM-DD");
  const formattedEndDate = endDate.format("YYYY-MM-DD");

  return [formattedStartDate, formattedEndDate];
};
