import React, { useState } from "react";
import Button from "../../components/Button";
import { getMedicineData, postMedicineData } from "../../lib/AppHelper";
import { useHistory } from "react-router-dom";
import { Loader } from "rsuite";
import { toast } from "react-toastify";
import { AppBackButton } from "../../components/AppComponents";

const MedicineAllocate = () => {
  const [searchInput, setsearchInput] = useState(null);
  const [inputvalues, setinputvalues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [appointmentId, setappointmentId] = useState(null);
  let navigate = useHistory();
  const handleAppointment = () => {
    navigate.push("/subscriptions");
  };
  const getMedicationList = () => {
    setLoading(true);
    getMedicineData(
      `enrollment-service/api/prescription/medications/${searchInput}`
    ).then((res) => {
      if (res.code == 200) {
        setData(res.responseObject);
        setLoading(false);
        setappointmentId(res?.responseObject?.[0]?.appointmentId);
      } else {
        setLoading(false);
        setData(null);
        toast.error(res.message);
      }
    });
  };
  const onChangeHandler = (e, index, item) => {
    const value = parseInt(e.target.value);
    let previousData = [...inputvalues];
    if (!value || value < 0) {
      let data = {
        medicationName: item.medicationName,
        handOverQuantity: "",
        resellerId: 1,
      };
      previousData[index] = data;
    } else {
      let data = {
        medicationName: item.medicationName,
        handOverQuantity: value,
        resellerId: 1,
      };
      previousData[index] = data;
    }
    setinputvalues(previousData);
  };
  const sendMedicationList = () => {
    let data = inputvalues.filter((elem) => elem && elem != "undefined");
    data = data.filter((elem) => elem.handOverQuantity > 0);
    postMedicineData(appointmentId, data).then((res) => {
      if (res.code == 200) {
        toast.success(res.message);
        setinputvalues([]);
      } else {
        toast.error(res.message);
        setinputvalues([]);
      }
      setData(null);
    });
  };

  return (
    <div className="space-y-4" style={{ marginTop: "-25px" }}>
      <div className="flex justify-between">
        <AppBackButton to="/" />
        <Button
          attrs={{ type: "button", onClick: () => handleAppointment() }}
          size="sm"
          title={"Book Appointment"}
        />
      </div>
      <div className="grid space-y-4">
        <div className="flex justify-between">
          <div className="mr-2 text-lg font-medium text-gray-900">
            Medicine Allocation
          </div>
          <div className="flex space-x-4">
            <input
              className="h-8 rounded-md"
              type="number"
              name="Search"
              placeholder="Appointment No..."
              onChange={(e) => {
                setsearchInput(e.target.value);
              }}
              onKeyPress={(e) => e.key === "Enter" && getMedicationList()}
            />
            <Button
              attrs={{
                type: "submit",
                onClick: getMedicationList,
                disabled: !searchInput || loading,
              }}
              size="sm"
              title={"Search"}
            />
          </div>
        </div>
        <table className="min-w-full border">
          <thead className="border-b">
            <tr className="bg-primary">
              <th
                className="text-m font-medium text-gray-900 px-6 py-4 border-r text-center"
                style={{ color: "white" }}
              >
                Name Of Medicine
              </th>
              {/* <th
                className="text-m font-medium text-gray-900 px-6 py-4 border-r  text-center"
                style={{ color: "white" }}
              >
                Prescribed Quantity
              </th> */}
              <th
                className="text-m font-medium text-gray-900 px-6 py-4 border-r  text-center"
                style={{ color: "white" }}
              >
                Handover Quantity
              </th>
            </tr>
          </thead>
          <tbody colSpan={2} className="text-center h-64 overflow-scroll">
            {loading ? (
              <Loader center="true" size="md" />
            ) : data ? (
              data?.map((med, i) => {
                return (
                  <tr className="border-b transition duration-300 ease-in-out hover:bg-gray-200 dark:border-gray-500 dark:hover:bg-gray-600">
                    <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap border-r text-center">
                      {med.medicationName}
                    </td>
                    {/* <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap border-r text-center">
                      {med.totalQuantity}
                    </td> */}
                    <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap border-r text-center">
                      <input
                        className={`${
                          med.isGenericMedicine == 0 &&
                          "cursor-not-allowed backdrop-grayscale bg-gray-200"
                        } h-6 w-12  rounded-md `}
                        type="number"
                        disabled={med.isGenericMedicine == 0}
                        onChange={(e) => onChangeHandler(e, i, med)}
                        value={
                          inputvalues[i]?.handOverQuantity
                            ? inputvalues[i]?.handOverQuantity
                            : ""
                        }
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={2} className="text-center h-64">
                  No Medicine
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row-reverse">
        <Button
          attrs={{
            type: "submit",
            onClick: sendMedicationList,
            disabled: !data,
          }}
          size="sm"
          title={"Submit"}
        />
      </div>
    </div>
  );
};

export default MedicineAllocate;
