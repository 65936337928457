import React, { memo, useState } from "react";
import Stepper from "./Stepper";
import file from "./assets/file.png";

const InProcessRecord = ({ data }) => {
  const [record, setRecord] = useState(data[0]);
  return (
    <div className="flex justify-center mt-7">
      <div className="border-2 w-full md:w-2/4  p-4 rounded-xl flex items-center flex-col gap-4">
        <Stepper step={1} steps={["Select file", "In progress", "Processed"]} />
        <img src={file} alt="img" className="h-20 " />
        <p>We are processing file {record?.fileName || "----"}</p>
        <div className="flex justify-around w-full text-sm">
          <p className="text-blue-500">Total Record : {record.totalRecord}</p>
          <p className="text-green-500">
            Success Record : {record.successRecord}
          </p>
          <p className="text-primary-500">
            Failed Record : {record.failRecord}
          </p>
        </div>
        <div class="h-2 w-1/2 bg-red-100 overflow-hidden rounded-md">
          <div class="progress w-full h-full bg-red-500 left-right" />
        </div>
        {data && data.length > 1 && (
          <ul className="flex gap-2">
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => (
                <li
                  key={index}
                  className={`h-2 w-2 ${
                    record.id === item.id ? "bg-gray-500" : "bg-gray-200"
                  }  rounded-full cursor-pointer`}
                  onClick={() => setRecord(item)}
                />
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default memo(InProcessRecord);
