export const headers = [
  {
    name: "Reseller_id",
    mandatory: true,
    type: "number",
    dataKey: "Reseller_id",
    columnColor: [],
  },
  {
    name: "Sku",
    mandatory: true,
    type: "string",
    dataKey: "sku",
    columnColor: [],
  },
  {
    name: "Product_name",
    mandatory: true,
    type: "string",
    dataKey: "Product_name",
    columnColor: [],
  },
  {
    name: "Stock_out",
    mandatory: true,
    type: "number",
    dataKey: "Stock_out",
    columnColor: [],
  },
  {
    name: "Expiry_date",
    mandatory: true,
    dataKey: "Expiry_date",
    columnColor: [],
    type: "date",
  },
  {
    name: "Narration",
    mandatory: false,
    type: "string",
    dataKey: "Narration",
    columnColor: [],
  },
  {
    name: "Transaction_date",
    mandatory: true,
    dataKey: "Transaction_date",
    columnColor: [],
    type: "date",
  },
  {
    name: "Batch_number",
    mandatory: true,
    dataKey: "Batch_number",
    columnColor: [],
  },
];
