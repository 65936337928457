import moment from "moment";
import React from "react";
import { SelectPicker } from "rsuite";
import { TRANSFER_STOCK } from "../../../lib/constants";
const MedicineSearchPicker = ({
  loading,
  setSearchKey,
  onchange,
  value,
  data,
  onMedicineClear,
  errors = "",
  component,
}) => {
  return (
    <div className="mb-5">
      <SelectPicker
        data={data || []}
        loading={loading}
        value={value}
        placeholder="Select Medicine"
        style={{
          width: 230,
          marginTop: "8px",
          border: errors ? "1px solid red" : "",
          borderRadius: errors ? "10px" : "",
        }}
        onSearch={(searchKey, e) => setSearchKey(searchKey)}
        onSelect={(value, item) => {
          setSearchKey("");
          onchange(value, item);
        }}
        onClean={onMedicineClear}
        renderMenuItem={(value, obj) => {
          return (
            <div className="border-b-2 p-1 text-xs space-y-2">
              <div>
                <span className="font-bold text-sm">{value}</span>{" "}
              </div>
              {component === TRANSFER_STOCK && (
                <div>Qty: {obj?.runningBalance} </div>
              )}
              {obj?.expiryDate && (
                <div>
                  expiry :{" "}
                  {obj?.expiryDate &&
                    moment(obj?.expiryDate).format("DD-MM-YYYY")}
                </div>
              )}
              {obj?.sku && <div>Medicine code : {obj?.sku && obj.sku}</div>}
              {obj.batchNo && (
                <div>Batch No : {obj?.batchNo && obj.batchNo}</div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default MedicineSearchPicker;
