import { Table } from "rsuite";
import "./style.css";
import React from "react";

const { Column, HeaderCell, Cell } = Table;

const headerStyle = {
  background: "#Db2228",
  color: "white",
  fontWeight: "bold",
  fontSize: "14px",
  textAlign: "center",
};
const CustomTable = ({ data }) => {
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.replace(/\s/g, "").toLowerCase();
        }
        if (typeof y === "string") {
          y = y.replace(/\s/g, "").toLowerCase();
        }
        if (typeof x === "string" && typeof y === "string")
          return sortType === "asc" ? x.localeCompare(y) : y.localeCompare(x);
        else {
          if (sortType === "asc") {
            return x - y;
          } else {
            return y - x;
          }
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
  return (
    <Table
      height={400}
      data={getData}
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}
    >
      <Column width={100} align="center">
        <HeaderCell style={headerStyle}>Sr.No</HeaderCell>
        <Cell>{(rowData, index) => `${index + 1}`}</Cell>
      </Column>
      <Column width={220} align="left" sortable resizable>
        <HeaderCell style={headerStyle}>Reseller name</HeaderCell>
        <Cell dataKey="resellerName">
          {(rowData) => (rowData.resellerName ? rowData.resellerName : "---")}
        </Cell>
      </Column>
      <Column width={220} align="center">
        <HeaderCell style={headerStyle}>Product</HeaderCell>
        <Cell dataKey="productCategoryGrouping">
          {(rowData) =>
            rowData.productCategoryGrouping
              ? rowData.productCategoryGrouping
              : "---"
          }
        </Cell>
      </Column>
      <Column width={220} align="center">
        <HeaderCell style={headerStyle}>Reporting manager</HeaderCell>
        <Cell dataKey="reportingToReseller">
          {(rowData) =>
            rowData.reportingToReseller ? rowData.reportingToReseller : "---"
          }
        </Cell>
      </Column>

      <Column width={185} sortable align="right">
        <HeaderCell style={headerStyle}>Subscription count</HeaderCell>
        <Cell dataKey="totalPolicyCount">
          {(rowData) =>
            rowData.totalPolicyCount
              ? `${rowData?.totalPolicyCount?.toLocaleString("en-IN")}`
              : 0
          }
        </Cell>
      </Column>

      <Column width={255} sortable align="right">
        <HeaderCell style={headerStyle}>Subscription amount (Rs/-)</HeaderCell>
        <Cell dataKey="totalPolicyAmount">
          {(rowData) =>
            rowData.totalPolicyAmount
              ? `${rowData?.totalPolicyAmount?.toLocaleString("en-IN")}`
              : 0
          }
        </Cell>
      </Column>
    </Table>
  );
};

export default CustomTable;
