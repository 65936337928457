import React from "react";
import { InputDate } from "../form/InputDate";

const RangePicker = ({ dateRange, onDateChange, hideLabel = false }) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "10px" }}>
        <InputDate
          label={hideLabel ? "" : "From"}
          placeholder="From"
          value={dateRange.startDate}
          maxDate={dateRange.endDate ? new Date(dateRange.endDate) : new Date()}
          attrs={{
            onChange: (e) => onDateChange(e, "from"),
            value: dateRange.startDate
              ? new Date(dateRange.startDate)
              : new Date(),
          }}
        />
      </div>
      <div>
        <InputDate
          label={hideLabel ? "" : "To"}
          placeholder="To"
          minDate={new Date(dateRange.startDate)}
          maxDate={new Date()}
          attrs={{
            onChange: (e) => onDateChange(e, "to"),
            value: dateRange.endDate ? new Date(dateRange.endDate) : new Date(),
          }}
        />
      </div>
    </div>
  );
};

export default RangePicker;
