import React, { useEffect, useRef, useState } from "react";
import { InputFile } from "../../components/form/InputFile";
import { toast } from "react-toastify";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { ImageType, SAVED_MEMBER, memberIcon } from "./constant";
import moment from "moment";
import ImageViewModal from "./ImageViewModal";
import PhoneIcon from "@mui/icons-material/Phone";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Compressor from "compressorjs";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import { enrollmentDocumentGet } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import AddCardIcon from "@mui/icons-material/AddCard";
const Card = ({ item, addDocument, screen, imageLoading }) => {
  const ImageRef = useRef();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const onChangeFileHandeler = (event) => {
    const file = event?.target?.files?.[0];

    if (file) {
      let mimeType = file?.["type"];
      if (mimeType !== "application/pdf" && !ImageType.includes(mimeType)) {
        toast.warning("Only images or PDFs are allowed");
        return;
      }
      const fileSize = file?.size;
      if (fileSize > 4100000 && mimeType == "application/pdf") {
        toast.warning("Pdf size should not be more than 5 MB");
        setFile(null);
        return;
      } else if (ImageType.includes(mimeType)) {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            if (result?.size < 4100000) {
              setOpen(true);
              setFile(result);
            } else {
              toast.warning("Image size should not be more than 5 mb");
              return;
            }
          },
          error(err) {
            console.error(err.message);
          },
        });
      } else {
        setFile(file);
        setOpen(true);
      }
      setOpen(true);
      setFile(file);
    }
  };

  const onConfirm = () => {
    if (file !== null) addDocument(file, item);
  };
  const handleClose = () => {
    if (ImageRef?.current) ImageRef.current.value = "";
    setFile(null);
    setOpen(false);
  };
  const fetchDocument = async (endpoint) => {
    enrollmentDocumentGet(
      `enrollment-service/api/health-camp/get-docs/${item?.documentId}/${endpoint}`
    )
      .then((blob) => {
        if (endpoint === "DOWNLOAD") {
          const newBlob = new Blob([blob]);
          const link = document.createElement("a");
          const url = URL.createObjectURL(newBlob);
          link.href = url;
          link.download = `downloadedFile.${item?.fileType}`;
          document.body.appendChild(link);
          link.click();
          link.remove();
          URL.revokeObjectURL(url);
        } else {
          const fileName = `downloadedFile.${item?.fileType}`;
          const lastModified = new Date().getTime();
          const changedFile = new File([blob], fileName, {
            type: blob.type,
            lastModified: lastModified,
          });

          setFile(changedFile);
          setOpen(true);
        }
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    return () => setFile(null);
  }, []);

  return (
    <>
      <div
        className="w-full md:w-96"
        style={{
          height: "auto",
          border: "1px solid #Db2228",
          borderRadius: "10px",
          padding: "10px 10px",
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          position: "relative",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span style={{ color: "#Db2228", fontWeight: "600" }}>
            #{item?.policyId}
          </span>
          <div>
            {memberIcon(item?.gender)}
            <p
              className="pl-8 text-medium text-md capitalize break-words"
              style={{ width: "220px" }}
            >
              {item?.name.toLowerCase()}
            </p>
            <p className="pl-8 text-xs">
              {item?.gender} | {moment(item?.dob).format("DD-MM-YYYY")}
            </p>
            <p className="pl-8 text-xs">
              <PhoneIcon
                style={{
                  color: "green",
                  position: "absolute",
                  left: 15,
                  fontSize: "15px",
                }}
              />
              {item?.mobile}
            </p>
            <p className="pl-8 text-xs">
              <CreditScoreIcon
                style={{
                  color: "#Db2228",
                  position: "absolute",
                  left: 15,
                  fontSize: "15px",
                }}
              />
              {item?.loanCode}
            </p>
            {item.govtIdNumber && item?.govtIdNumber !== "dummy_voter" && (
              <p className="pl-8 text-xs">
                <AddCardIcon
                  style={{
                    color: "grey",
                    position: "absolute",
                    left: 15,
                    fontSize: "15px",
                  }}
                />
                {item?.govtIdNumber}
              </p>
            )}
          </div>
        </div>
        <div>
          {screen !== SAVED_MEMBER ? (
            <div className="flex justify-center">
              <InputFile
                inputRef={ImageRef}
                readOnly={screen !== SAVED_MEMBER ? true : false}
                file={null}
                paddingY="1"
                attrs={{
                  onChange: (e) => onChangeFileHandeler(e),
                  accept: "image/*,.pdf",
                  style: { marginBottom: 1 },
                }}
                onDocDelete={() => setFile(null)}
                placeholder={
                  !file && (
                    <div className="text-center items-center flex-row inline-flex gap-1">
                      <PlusCircleIcon className="text-primary" width={24} />
                      <p className=" text-sm text-primary">Upload</p>
                    </div>
                  )
                }
              />
            </div>
          ) : (
            <div>
              <IconButton onClick={() => fetchDocument("DOWNLOAD")}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={() => fetchDocument("PREVIEW")}>
                <RemoveRedEyeIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <ImageViewModal
        open={open}
        onConfirm={onConfirm}
        handleClose={handleClose}
        imageLoading={imageLoading}
        isDisabledButton={screen !== SAVED_MEMBER ? false : true}
      >
        <InputFile
          file={file || null}
          inputRef={ImageRef}
          readOnly={screen !== SAVED_MEMBER ? false : true}
          paddingY="1"
          attrs={{
            onChange: (e) => onChangeFileHandeler(e),
            accept: "image/*,.pdf",
            style: { marginBottom: 1 },
            disabled: screen !== SAVED_MEMBER ? false : true,
          }}
          onDocDelete={() => setFile(null)}
          placeholder={
            !file && (
              <div className="text-center items-center flex-row inline-flex gap-1">
                <PlusCircleIcon className="text-primary" width={24} />
                <p className=" text-sm text-primary">Upload</p>
              </div>
            )
          }
        />
      </ImageViewModal>
    </>
  );
};

export default Card;
