import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import React from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";

export function InputCounter({
  loading = false,
  label = "",
  value = 0,
  attrs = {},
  children,
  onMinus,
  onPlus,
  min = 0,
  max = 999999,
  mini = true,
  className = "",
}) {
  return (
    <React.Fragment>
      <InputLabel label={label} />
      <div className={[`w-full flex items-center`, className].join(" ")}>
        <button
          onClick={onMinus}
          className={[
            `flex-1 flex items-center justify-center px-${mini ? 2 : 3} py-${
              mini ? 1 : 2
            } border border-transparent text-base rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-500 disabled:opacity-50`,
          ].join(" ")}
          disabled={loading ? true : value <= min}
        >
          <MinusIcon width={16} />
        </button>
        <div className="flex-auto w-full text-center">{value}</div>
        <button
          onClick={onPlus}
          className={[
            `flex-1 flex items-center justify-center px-${mini ? 2 : 3} py-${
              mini ? 1 : 2
            } border border-transparent text-base rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-500 disabled:opacity-50`,
          ].join(" ")}
          disabled={loading ? true : value >= max}
        >
          <PlusIcon width={16} />
        </button>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
  return <InputCounter {...props} />;
});
