import React from "react";
import {
  AppHeading,
  AppSidebarMenu,
  AppSidebarMenuItem,
} from "../../components/AppComponents";
import { AiOutlineBank } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { BiKey } from "react-icons/bi";
import { MdDevices } from "react-icons/md";
import { useSelector } from "react-redux";
import RouteLeavingGuard from "../../lib/RouteLeavingGuard";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function ProfileSidebar({ isDirty }) {
  const history = useHistory();
  const user = useSelector((state) => state?.user);
  const permissions = useSelector((state) => state?.user?.permissions);
  return (
    <div className="w-80">
      <RouteLeavingGuard
        when={isDirty}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(location) => {
          if (isDirty) {
            return true;
          }
          return false;
        }}
      />
      <AppHeading className="mb-6">Profile</AppHeading>
      <AppSidebarMenu>
        <div>
          <AppSidebarMenuItem link="/profile" title="Basic Profile">
            <FiUser size={20} />
          </AppSidebarMenuItem>
          {permissions?.hide_reseller_banking_section ? null : (
            <AppSidebarMenuItem last={true} link="/banking" title="Banking">
              <AiOutlineBank size={20} />
            </AppSidebarMenuItem>
          )}

          {/* <AppSidebarMenuItem last={true} link="/manage-tokens" title="Manage Tokens">
           */}
          {user?.email && (
            <AppSidebarMenuItem
              last={true}
              link="/change-password"
              title="Change Password"
            >
              <BiKey size={20} />
            </AppSidebarMenuItem>
          )}

          {/* <AppSidebarMenuItem
            last={true}
            link="/active-session"
            title="Active Session"
          >
            <MdDevices size={20} />
          </AppSidebarMenuItem> */}
        </div>
      </AppSidebarMenu>
    </div>
  );
}
