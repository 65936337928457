export const ASC = "ascending";
export const DESC = "descending";

function calculateAndFormat(value) {
  let result;
  result = value % 1 === 0 ? value : value.toFixed(2);
  return result;
}

export default function computeValue(data, item) {
  if (data.length > 0 && item.count > 0) {
    if (data[0]?.activationData === 0) return 0;
    let value = (data[0]?.eligibleData / data[0]?.activationData) * 100;
    return calculateAndFormat(value);
  }
  return "N/A";
}
function getColor(percentage) {
  if (percentage < 100) {
    return "#DB2228";
  } else return "green";
}

export const ExampleComponent = ({ data, item }) => {
  const percentage = computeValue(data, item);

  return (
    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border">
      <span
        style={{
          color: getColor(percentage),
          fontWeight: "600",
          fontSize: "20px",
        }}
      >
        {data[0]?.activationData}
      </span>{" "}
      ({typeof percentage === "number" ? `${percentage} %` : percentage})
      {/* {`${percentage} %`} */}
    </td>
  );
};

export const getNestedValue = (obj, path) => {
  console.log("obj", obj, path);
  return path.split(".").reduce((acc, part) => {
    if (Array.isArray(acc)) {
      const [arrayKey, arrayIndex] = part.split(/[\[\]]/).filter(Boolean);
      return acc[Number(arrayIndex)][arrayKey];
    }
    return acc ? acc[part] : undefined;
  }, obj);
};
