import React from "react";
import { Container } from "rsuite";
import Card from "./Card";
import { AppHeading } from "../../components/AppComponents";
import { NEW_MEMBER, headingLabel } from "./constant";
import Pagination from "./Pagination";

const CardsContainer = ({
  data,
  addDocument,
  count,
  screen,
  getMembers,
  page,
  imageLoading,
}) => {
  return (
    <>
      <AppHeading>{headingLabel[screen]}</AppHeading>
      <Container className="flex flex-col justify-center md:flex-row flex-wrap items-center p-3 gap-5">
        {data?.map((item, index) => {
          return (
            <Card
              key={index}
              item={item}
              addDocument={addDocument}
              imageLoading={imageLoading}
              screen={screen}
            />
          );
        })}
      </Container>
    </>
  );
};

export default CardsContainer;
