import React from "react";
import InputLabel from "./InputLabel";
import { Toggle } from "rsuite";

const CustomToggle = ({
  label,
  labelLink,
  requiredMark,
  starMark,
  leftLabel,
  rightLabel,
  size = "lg",
  attrs = {},
  showError,
  error,
  disabled,
}) => {
  const handelOnChange = (e) => {
    attrs.onChange && attrs.onChange(e);
  };

  return (
    <>
      <div className="mb-5">
        <InputLabel
          label={label}
          labelLink={labelLink}
          requiredMark={requiredMark}
          starMark={starMark}
        />
        <div className="mt-2 pt-2 relative">
          <label className="text-sm text-gray-700 mr-2">{leftLabel}</label>
          <Toggle
            size={size}
            checked={attrs.value}
            onChange={handelOnChange}
            disabled={disabled}
          />
          <label className="text-sm text-gray-700 ml-2">{rightLabel}</label>
        </div>
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-500">{error.join(" ")}</p>
      ) : (
        false
      )}
      {showError ? (
        <p className="mt-2 text-xs text-red-500">
          {typeof showError === "string" ? showError : `${label} is required`}
        </p>
      ) : (
        false
      )}
    </>
  );
};

export default CustomToggle;
