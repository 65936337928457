import React from "react";

export const Footer = () => {
  return (
    <div className="border-2 m-3 mt-10">
      <span className="flex m-2 text-red-500">Notes -</span>
      <ul className="list-disc list-inside m-2">
        <li>
          <span className="text-sm justify-center">
            The file should be in xlsx format.
          </span>
        </li>
        <li>
          <span className="text-sm text-right">
            The file should have the same structure as the sample file.
          </span>
        </li>
        <li>
          <span className="text-sm justify-center">
            The file should have the same date format as dd/mm/yyyy.
          </span>
        </li>
        <li>
          <span className="text-sm justify-center">
            Customer id should contain numeric values only.
          </span>
        </li>
        <li>
          <span className="text-sm justify-center">
            Family Relation Only Be (SELF, SPOUSE, DAUGHTER, SON, PARENT,
            MOTHER, FATHER, BROTHER, SIBLING, HUSBAND, SPOUSE_PARENT,
            SPOUSE_PARENT_1, SPOUSE_PARENT_2, SISTER)
          </span>
        </li>
      </ul>
    </div>
  );
};
