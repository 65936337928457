import _ from "lodash";
import { GroupOption, PlanOption } from "../PlanOption";
import { ApiGet } from "../../lib/AppHelper";

export const getProduct = (products, step, productId, uploaderType) => {
  let items = [];
  if (step === 0) {
    _.each(products, (prod) => {
      items.push({
        value: prod?.id ? prod?.id : prod?.groupId,
        label:
          uploaderType === "group_uploader" ? (
            <GroupOption
              groupName={prod.name}
              description={
                prod.product?.description
                  ? prod.product?.description
                  : prod?.description
              }
              features={prod.dtoList}
            />
          ) : (
            <PlanOption
              amount={prod.price}
              title={prod.name}
              features={[
                `${
                  prod.product?.max_members
                    ? prod.product?.max_members
                    : prod?.max_members
                } Members`,
                `${
                  prod.product?.description
                    ? prod.product?.description
                    : prod?.description
                }`,
                ..._.map(prod.inclusions, "title"),
              ]}
            />
          ),
      });
    });
  } else {
    _.each(products, (prod) => {
      (prod?.id || prod?.groupId) == productId &&
        items.push({
          value: prod?.id ? prod?.id : prod?.groupId,
          label:
            uploaderType === "group_uploader" ? (
              <GroupOption
                groupName={prod.name}
                description={
                  prod.product?.description
                    ? prod.product?.description
                    : prod?.description
                }
                features={prod.dtoList}
              />
            ) : (
              <PlanOption
                amount={prod?.price}
                title={prod?.name}
                features={[
                  `${
                    prod?.product?.max_members
                      ? prod?.product?.max_members
                      : prod?.max_members
                  } Members`,
                  `${
                    prod?.product?.description
                      ? prod?.product?.description
                      : prod?.description
                  }`,
                  ..._.map(prod?.inclusions, "title"),
                ]}
              />
            ),
        });
    });
  }
  return items;
};

export const getResellerProduct = async (
  role = "upload_excel",
  mobile,
  name
) => {
  const response = await ApiGet(
    `admin/excel-reseller?role_name=${role}&mobile=${
      mobile ? mobile : ""
    }&reseller_name=${name ? name : ""}`
  );
  return response?.data;
};
