import { useState, useEffect } from "react";
import { getApiClaimSales, getResellerId } from "../../lib/AppHelper";

export const useApiFilter = (filter) => {
  const [salesRequestFilterData, setSalesRequestFilterData] = useState(null);
  const [tenureList, setTenureList] = useState(null);
  const [salesPerson, setSalesPerson] = useState(null);
  const [mockReseller, setMockReseller] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const salesRequestFilterData = await getApiClaimSales(
          "getSalesRequestFilter"
        );
        const tenureList = await getApiClaimSales("getProductTenure");
        const salesPerson = await getApiClaimSales(
          `getSalesPerson/${getResellerId()}`
        );
        const Resellers = await getApiClaimSales(
          `mock_sales_resellers/${getResellerId()}`
        );

        setSalesRequestFilterData(salesRequestFilterData);
        setTenureList(tenureList);
        setSalesPerson(salesPerson);
        setMockReseller(Resellers.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchResellerId = async (id) => {
      const salesPerson = await getApiClaimSales(`getSalesPerson/${id}`);
      setSalesPerson(salesPerson);
    };
    if (filter.mockSalesResellerId) {
      fetchResellerId(filter.mockSalesResellerId);
    }
  }, [filter.mockSalesResellerId]);
  return { salesRequestFilterData, tenureList, salesPerson, mockReseller };
};
