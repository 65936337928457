import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { pmjayDataSave, validateMobile } from "../../lib/AppHelper";
import Main from "../Main";
import { toast } from "react-toastify";
import { InputGroup } from "../../components/form/InputGroup";
import { AppStore } from "../../lib/AppStore";
import { InputTextArea } from "../../components/form/InputTextArea";
import { FileUploader } from "../../components/form/FileUploader";
export class CustomerInformation extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    encResellerId: "",
    params: {
      aadhaarImage: "",
      pmjayImage: "",
      loanCodeImage: "",
      consentImage: "",
      aadhaarNumber: "",
      pmjayId: "",
      loanCode: "",
      mobile: "",
      consentText: "",
      aadhaarImageShow: null,
      pmjayImageShow: null,
      loanCodeImageShow: null,
      consentImageShow: null,
      aadhaarImagePdf: null,
      pmjayImagePdf: null,
      loanCodeImagePdf: null,
      consentImagePdf: null,
    },
    errors: {
      aadhaarNumber: "",
      pmjayId: "",
      loanCode: "",
      mobile: "",
      aadhaarImage: "",
      loanCodeImage: "",
      pmjayImage: "",
      consentImage: "",
    },
    step: 1,
    loading: false,
  };
  steps = 5;

  next = (e) => {
    e.preventDefault();
    if (this.state.step < this.steps) {
      this.setState({ step: this.state.step + 1 });
    } else {
      this.doSubmit();
    }
  };

  previous = () => {
    this.setState({ step: this.state.step - 1 });
  };

  doSubmit = () => {
    this.setState({ loading: true });
    AppStore.dispatch({ type: "LOADING", loading: true });
    pmjayDataSave(this.state.params)
      .then((res) => {
        if (res?.code == 200) {
          toast.success("Data Added Successfully");
          this.setState({ step: 1 });
          const { params } = this.state;
          params["aadhaarNumber"] = "";
          params["aadhaarImage"] = "";
          params["pmjayId"] = "";
          params["pmjayImage"] = "";
          params["loanCode"] = "";
          params["loanCodeImage"] = "";
          params["mobile"] = "";
          params["consentImage"] = "";
          params["consentText"] = "";
          params["aadhaarImageShow"] = null;
          params["pmjayImageShow"] = null;
          params["loanCodeImageShow"] = null;
          params["consentImageShow"] = null;
          params["aadhaarImagePdf"] = null;
          params["pmjayImagePdf"] = null;
          params["loanCodeImagePdf"] = null;
          params["consentImagePdf"] = null;
          this.setState({ params: params });
          this.setState({ loading: false });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          toast.error(res?.message);
          this.setState({ loading: false });
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  isReady = () => {
    const { step, params, loading } = this.state;
    if (step === 1 && !params.mobile) return false;
    if (step === 2 && !params.loanCodeImage) return false;
    if (step === 2 && !params.loanCode) return false;
    if (step === 2 && params.loanCode.length < 5) return false;
    if (step === 3 && params?.pmjayId && params.pmjayId.length < 12)
      return false;
    if (step === 4 && !params.aadhaarImage) return false;
    if (step === 5 && !params.consentImage) return false;
    if (params.mobile.length < 10 || params.mobile == "0000000000")
      return false;
    if (step === 4 && params?.aadhaarNumber && params.aadhaarNumber.length < 12)
      return false;

    if (step === 4 && params.aadhaarNumber == "000000000000") return false;
    if (loading) return false;
    return true;
  };

  setParams = (field, value) => {
    this.setState((state) => {
      let params = state.params;
      return {
        ...state,
        params: { ...params, [field]: value },
      };
    });
  };

  setErrors = (field, errorMessage) => {
    this.setState((state) => {
      let errors = state.errors;
      return {
        ...state,
        errors: { ...errors, [field]: errorMessage },
      };
    });
  };

  render() {
    const { params, step, loading, errors } = this.state;
    const { user } = this.props;
    const { permissions } = user;
    const ready = this.isReady();
    return (
      <Main MessageRef={this.MessageRef}>
        <AppBackButton to="/">Back</AppBackButton>
        {permissions && permissions?.add_pm_jay_information ? (
          <>
            <p className="text-2xl">Check Beneficiary</p>
            <a
              className="text-blue-500"
              href="https://bis.pmjay.gov.in/BIS/mobileverify?logout"
              target="blank"
            >
              https://bis.pmjay.gov.in/BIS/mobileverify?logout
            </a>
            <div className="py-4">
              <div className="flex items-center">
                <div className="flex items-center text-primary relative">
                  <div
                    className={`rounded-full ${
                      step > 1 ? "bg-red-600" : ""
                    } transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-red-600`}
                  >
                    <strong>
                      <p
                        className={`ml-4  ${
                          step > 1 ? "text-white" : "text-primary"
                        }`}
                      >
                        1
                      </p>
                    </strong>
                  </div>
                </div>
                {step > 1 ? (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-600"></div>
                ) : (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-300"></div>
                )}
                <div className="flex items-center text-primary relative">
                  <div
                    className={`rounded-full ${
                      step > 2 ? "bg-red-600" : ""
                    } transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-red-600`}
                  >
                    <strong>
                      <p
                        className={`ml-4  ${
                          step > 2 ? "text-white" : "text-primary"
                        }`}
                      >
                        2
                      </p>
                    </strong>
                  </div>
                </div>
                {step > 2 ? (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-600"></div>
                ) : (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-300"></div>
                )}
                <div className="flex items-center text-primary relative">
                  <div
                    className={`rounded-full ${
                      step > 3 ? "bg-red-600" : ""
                    } transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-red-600`}
                  >
                    <strong>
                      <p
                        className={`ml-4  ${
                          step > 3 ? "text-white" : "text-primary"
                        }`}
                      >
                        3
                      </p>
                    </strong>
                  </div>
                </div>
                {step > 3 ? (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-600"></div>
                ) : (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-300"></div>
                )}
                <div className="flex items-center text-primary relative">
                  <div
                    className={`rounded-full ${
                      step > 4 ? "bg-red-600" : ""
                    } transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-red-600`}
                  >
                    <strong>
                      <p
                        className={`ml-4  ${
                          step > 4 ? "text-white" : "text-primary"
                        }`}
                      >
                        4
                      </p>
                    </strong>
                  </div>
                </div>
                {step > 4 ? (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-600"></div>
                ) : (
                  <div className="flex-auto mx-1 border-t-8 rounded-full transition duration-500 ease-in-out border-red-300"></div>
                )}
                <div className="flex items-center text-primary relative">
                  <div
                    className={`rounded-full ${
                      loading ? "bg-red-600" : ""
                    } transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-red-600`}
                  >
                    <strong>
                      <p
                        className={`ml-4  ${
                          loading ? "text-white" : "text-primary"
                        }`}
                      >
                        5
                      </p>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-7xl mx-auto py-6 sm:px-1 lg:px-8">
              <form action={this.next}>
                <div className="py-6 sm:w-full lg:w-96 md:w-96">
                  {step == 1 && (
                    <>
                      {/* <p className="text-lg">
                        Mobile Number{" "}
                        <span className="text-lg text-red-500">*</span>
                      </p> */}
                      <InputGroup
                        prefix="+91 -"
                        label="Mobile Number"
                        placeholder="0000000000"
                        requiredMark="true"
                        attrs={{
                          value: params.mobile,
                          onChange: (e) => {
                            const integerRegex = /^-?\d+$/;
                            const isValidInteger = integerRegex.test(
                              e.target.value
                            );
                            if (isValidInteger || e.target.value == "") {
                              this.onChangeHandler(
                                {
                                  target: {
                                    value: e.target.value.replace(/\D/, ""),
                                  },
                                },
                                "mobile"
                              );
                            }
                          },
                          maxLength: 10,
                        }}
                        validateError={(value) => {
                          if (value == "0000000000")
                            return "Please enter valid mobile number";
                          if (validateMobile(value)) {
                            return false;
                          } else {
                            return "Please enter valid mobile number";
                          }
                        }}
                      />
                    </>
                  )}
                  {step == 2 && (
                    <>
                      <p className="py-2 text-lg">Loan Card Form</p>
                      <FileUploader
                        label="Attachments"
                        field={"loanCodeImage"}
                        params={params}
                        setState={this.setParams}
                        error={errors.loanCodeImage}
                        setErrors={this.setErrors}
                        required={true}
                      />
                      <InputGroup
                        label="Number"
                        requiredMark="true"
                        placeholder="Loan code/Reference number..."
                        attrs={{
                          maxLength: 20,
                          minLength: 12,
                          value: params.loanCode,
                          onChange: (e) => {
                            const integerStringRegex = /^[a-zA-Z0-9]+$/;
                            const isValidIntegerString =
                              integerStringRegex.test(e.target.value);
                            if (isValidIntegerString || e.target.value == "") {
                              this.onChangeHandler(
                                {
                                  target: {
                                    value: e.target.value,
                                  },
                                },
                                "loanCode"
                              );
                            }
                          },
                        }}
                      />
                    </>
                  )}
                  {step == 3 && (
                    <>
                      <p className="py-2 text-lg">PM-JAY Form</p>
                      <FileUploader
                        label="Attachments"
                        field={"pmjayImage"}
                        params={params}
                        setState={this.setParams}
                        error={errors.pmjayImage}
                        setErrors={this.setErrors}
                        // required={true}
                      />
                      <InputGroup
                        label="Number"
                        // requiredMark="true"
                        placeholder="Enter PM-JAY Number"
                        attrs={{
                          maxLength: 12,
                          minLength: 12,
                          value: params.pmjayId,
                          onChange: (e) => {
                            const integerStringRegex = /^[a-zA-Z0-9]+$/;
                            const isValidIntegerString =
                              integerStringRegex.test(e.target.value);
                            if (isValidIntegerString || e.target.value == "") {
                              this.onChangeHandler(
                                {
                                  target: {
                                    value: e.target.value,
                                  },
                                },
                                "pmjayId"
                              );
                            }
                          },
                        }}
                        validateError={(value) => {
                          if (value && value.length < 12) {
                            return "Please enter 12 length number";
                          }
                        }}
                      />
                    </>
                  )}
                  {step == 4 && (
                    <>
                      <p className="py-2 text-lg">Aadhaar Form</p>
                      <FileUploader
                        label="Attachments"
                        field={"aadhaarImage"}
                        params={params}
                        setState={this.setParams}
                        error={errors.aadhaarImage}
                        setErrors={this.setErrors}
                        required={true}
                      />
                      <InputGroup
                        label="Number"
                        // requiredMark="true"
                        placeholder="Aadhaar Id number..."
                        attrs={{
                          maxLength: 12,
                          minLength: 12,
                          value: params.aadhaarNumber,
                          onChange: (e) => {
                            const integerRegex = /^-?\d+$/;
                            const isValidInteger = integerRegex.test(
                              e.target.value
                            );
                            if (isValidInteger || e.target.value == "") {
                              this.onChangeHandler(
                                {
                                  target: {
                                    value: e.target.value.replace(/\D/, ""),
                                  },
                                },
                                "aadhaarNumber"
                              );
                            }
                          },
                        }}
                        validateError={(value) => {
                          if (value && value.length > 11) {
                            return false;
                          } else {
                            return "Please enter 12 digits number";
                          }
                        }}
                      />
                    </>
                  )}
                  {step == 5 && (
                    <>
                      <p className="py-2 text-lg">Consent Form</p>
                      <FileUploader
                        label="Attachments"
                        field={"consentImage"}
                        params={params}
                        setState={this.setParams}
                        error={errors.consentImage}
                        setErrors={this.setErrors}
                        required={true}
                      />
                      <InputTextArea
                        label="Text"
                        // requiredMark="true"
                        placeholder="Consent Text..."
                        attrs={{
                          maxLength: 64,
                          value: params.consentText,
                          onChange: (e) => {
                            this.onChangeHandler(e, "consentText");
                          },
                        }}
                      />
                    </>
                  )}
                  <div className="flex  mt-4 justify-between">
                    {step > 1 && (
                      <Button
                        attrs={{
                          ref: this.BasicBtn,
                          type: "button",
                          onClick: this.previous,
                        }}
                        size="md"
                        title={"Previous"}
                      />
                    )}
                    <Button
                      attrs={{
                        ref: this.BasicBtn,
                        disabled: !ready,
                        type: "submit",
                        onClick: this.next,
                      }}
                      size="md"
                      title={step === 5 ? "Save and Continue" : "Continue"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You do not have permission to view Pm Jay Information</h4>
            </div>
          </div>
        )}
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const CustomerInformationConnect = connect(mapStateToProps)((props) => {
  return <CustomerInformation {...props} />;
});

export default withRouter(CustomerInformationConnect);
