import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCheck,
  faRupeeSign,
  faTimes,
  faUser,
  faUserMd,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ProductInfoBlock = ({ product, permissions }) => {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const description = product?.product?.description;
  const cleanDescription = description
    ? description
        .replace(/<\/?li>/g, "")
        .split("\n")
        .map((item) => item.trim())
    : [];

  return (
    <>
      <div className="bg-orange-200 flex flex-col justify-between rounded-xl p-4">
        <div>
          <h5 className="text-md font-medium text-orange-800 mb-2">
            {product.name}
          </h5>
          <ul className="text-orange-800 mb-2 font-medium text-sm list-disc ml-4">
            {product.product?.max_members > 1 ? (
              <li>{product.product?.max_members} Members</li>
            ) : (
              !(
                product?.product?.max_members === 1 &&
                product?.product?.product_lab_test?.auto_book
              ) && <li>{product.product?.max_members} Member</li>
            )}

            {cleanDescription?.length > 0 &&
              cleanDescription?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}

            <li>&#x20B9; {product?.price}</li>
          </ul>
        </div>
        <div className="flex justify-between">
          <Link to={`/product/${product.id}`}>
            <button className="px-5 py-1.5 rounded-xl bg-white text-primary-500">
              Learn More
            </button>
          </Link>
          {permissions?.application_add &&
            permissions?.lab_test_subscription && (
              <Link
                to={{
                  pathname: "/subscribe",
                  state: {
                    productId: product.id,
                  },
                }}
              >
                <Button
                  className="px-6"
                  size="sm"
                  display="inline-block"
                  title="Buy Now"
                />
              </Link>
            )}
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {product.name}
                </Dialog.Title>
                <div className="mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Plan Benifits
                  </Dialog.Title>

                  <hr className="mt-4" />
                  <Dialog.Title
                    as="h4"
                    className="text-md font-medium leading-5 text-gray-900 pt-3"
                  >
                    <FontAwesomeIcon
                      className="text-primary text-lg mr-1"
                      icon={faUser}
                    />
                    Insurance Benifits
                  </Dialog.Title>
                  <ul className="mt-2 text-sm">
                    <li>
                      <FontAwesomeIcon
                        className="text-green-500"
                        icon={faCheck}
                      />{" "}
                      {product.product?.description}
                    </li>
                  </ul>
                </div>
                <hr className="mt-4" />

                <div className="pt-4">
                  <FontAwesomeIcon
                    className="text-green-800 text-lg mr-1 mt-2"
                    icon={faUserMd}
                  />
                  Doctor Type
                  <ul className="mt-2 ul-height">
                    <li>
                      <FontAwesomeIcon
                        className="text-green-500 text-sm"
                        icon={faCheck}
                      />{" "}
                      General Physicians
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="text-primary text-sm"
                        icon={faTimes}
                      />{" "}
                      Surgeon
                    </li>
                  </ul>
                </div>
                <hr className="mt-4" />

                <div className="pt-4">
                  <FontAwesomeIcon
                    className="text-blue-800 text-lg mr-1 mt-2"
                    icon={faCalendarAlt}
                  />
                  Duration of plan & timing
                  <ul className="mt-2 ul-height">
                    <li>
                      <FontAwesomeIcon
                        className="text-green-500 text-sm"
                        icon={faCheck}
                      />{" "}
                      1 Year (24*7 doctor available)
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="text-green-500 text-sm"
                        icon={faCheck}
                      />{" "}
                      1.5 Years (24*7 doctor available)
                    </li>
                    <li>
                      <FontAwesomeIcon
                        className="text-green-500 text-sm"
                        icon={faCheck}
                      />{" "}
                      2 Years (24*7 doctor available)
                    </li>
                  </ul>
                </div>
                <hr className="mt-4" />

                <div className="pt-4">
                  <FontAwesomeIcon
                    className="text-green-800 text-lg mr-1 mt-2"
                    icon={faRupeeSign}
                  />
                  Price
                  <ul className="mt-2 ul-height">
                    <li>
                      {product.product?.tenure} {product.product?.tenure_type} -{" "}
                      {product?.price} inr
                    </li>
                  </ul>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default ProductInfoBlock;
