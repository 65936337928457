import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ApiGet } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import { ChevronRightIcon } from "@heroicons/react/outline";

const ComboProduct = ({ values, setFieldValue }) => {
  const [toggle, setToggle] = React.useState("");
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("admin/products");
    if (response.status === "success") {
      AppStore.dispatch({ type: "PRODUCTS", products: response.data });
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const productName = [
    { name: "Doctor Consult Product", type: "DOC", label: "doctorConsult" },
    { name: "Accidental Product", type: "AC", label: "accidental" },
    { name: "HospitalCash Product", type: "HCH", label: "hospitalCash" },
    { name: "Credit life Product", type: "CLOAN", label: "creditLife" },
  ];

  const products = useSelector((state) => state.products);

  const toggleHandler = (value) => {
    toggle === value ? setToggle("") : setToggle(value);
  };
  const value = values.selectedProduct;

  const onProductChange = (prod, product) => {
    if (values.selectedProduct[prod.label].id === product.id) {
      setFieldValue(`selectedProduct.${prod.label}.id`, "");
    } else {
      setFieldValue(`selectedProduct.${prod.label}.id`, product.id);
    }
  };

  return (
    <>
      {productName.map((prod, index) => {
        return (
          <div className="mb-5" key={index}>
            <div
              onClick={() => toggleHandler(prod.type)}
              className="flex justify-between mt-4 items-center p-1 pr-2 pl-2 rounded-md cursor-pointer shadow-md"
            >
              <p className="text-lg">{prod.name}</p>
              <ChevronRightIcon
                className={`h-5 w-6 pt-1 cursor-pointer text-red ${prod.type == toggle
                  ? "transform rotate-90 duration-200"
                  : "transform rotate-0 duration-200"
                  }`}
              />
            </div>
            {toggle == prod.type && (
              <div className="flex-1">
                <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 mt-2">
                  {products.map((product, index) => {
                    if (
                      product.specification === prod.type &&
                      product.product_clasification === "Individual"
                    ) {
                      return (
                        <div key={index}
                          onClick={() => {
                            onProductChange(prod, product);
                          }}
                          className={`bg-orange-200 flex flex-col justify-between rounded-xl p-4 cursor-pointer ${value[prod.label].id === product.id
                            ? "border-2 border-red-600 "
                            : "border-2 border-gray-300"
                            }`}
                        >
                          <h5 className="text-md font-medium text-orange-800 mb-2">
                            {product.name}
                          </h5>
                          <ul className="text-orange-800 mb-2 font-medium text-sm list-disc ml-4">
                            <li>Price : &#x20B9;{product.price}</li>
                            {prod.type === "DOC" ? (
                              ""
                            ) : (
                              <li>
                                Max Claim Amount : {product.max_claim_amount}
                              </li>
                            )}
                            <li>Members Covered: {product.max_members}</li>
                            <li>Tenure : {product.tenure} months</li>
                          </ul>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
export default ComboProduct;
