import React, { memo, useEffect, useRef, useState } from "react";
import "rsuite-table/lib/less/index.less";
import { Pagination } from "rsuite";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppStore } from "../../lib/AppStore";
import { truncateString } from "../admin/utils";
import useMobileDetect from "../../utils/useMobileDetect";
import CustomSelect from "../../components/CustomSelect";
import { InputDate } from "../../components/form/InputDate";
import Button from "../../components/Button";
import {
  DateFilter,
  WeekDates,
  LastWeekDates,
  LastMonths,
  LastDays,
} from "../admin/components/constant";
import {
  enrollmentDocumentGet,
  getLabTestReport,
  getResellerId,
  LabTestPostApi,
} from "../../lib/AppHelper";
import SearchIcon from "@rsuite/icons/Search";
import { Close, CloseOutlined } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import { PRIMARY_COLOR } from "../../utils/constants";
import CustomPdfViewer from "../../utils/view_downloadPrescription/CustomPdfViewer";
import unCheck from "../../assets/png/unCheck.png";
import check from "../../assets/png/check.png";

const LabTestSubscriptionDataTable = memo(() => {
  let limit = 10;
  if (limit < 1) {
    limit = 1;
  }

  const initialValue = {
    duration: "thisMonth",
    startDate: moment(WeekDates("month")[0]).format("YYYY-MM-DD"),
    endDate: moment(WeekDates("month")[1]).format("YYYY-MM-DD"),
    sortOrder: "dsc",
    sortBy: "createdAt",
    resellerId: getResellerId(),
  };
  const { isMobile } = useMobileDetect();
  const lastFilter = useRef(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(initialValue);
  const [searchValue, setSearchValue] = useState("");
  const [reportUrl, setReportUrl] = useState(null);
  const [prescription, setPrescription] = useState(null);

  const handelChangePage = (pg) => {
    if (page === pg) return;
    setPage(pg);
    fetchData(pg);
  };

  const fetchData = async (pg = null, payloadData, forceReload) => {
    let payload;
    const startIndex = ((pg || page) - 1) * 10;
    const endIndex = 10;
    if (payloadData) {
      payload = {
        startIndex: startIndex,
        endIndex: endIndex,
        name: searchValue,
        ...payloadData,
      };
    } else {
      payload = {
        startIndex: startIndex,
        endIndex: endIndex,
        name: searchValue,
        ...filter,
      };
    }

    if (lastFilter.current !== JSON.stringify(payload) || forceReload) {
      try {
        AppStore.dispatch({ type: "LOADING", loading: true });
        const res = await LabTestPostApi(`labtest/ALL`, payload);

        if (res?.code === 200) {
          if (res?.count) {
            setTotalCount(res?.count);
          }
          if (res?.data) {
            setTableData(res?.data);
          }
        }
      } catch (err) {
      } finally {
        AppStore.dispatch({ type: "LOADING", loading: false });
        lastFilter.current = JSON.stringify(payload);
      }
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [filter]);

  const onSearchHandle = () => {
    fetchData(1);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const onResetHandle = () => {
    const payload = {
      ...initialValue,
      name: "",
    };
    setFilter(initialValue);
    setSearchValue("");
    setPage(1);
    fetchData(1, payload);
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      onSearchHandle();
    }
  };

  const getReport = async (endPoint, reportName, data) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const res = await getLabTestReport(endPoint);
    if (res instanceof Blob) {
      const blob = new Blob([res], { type: "text/plain" });
      const file = new File([blob], reportName, {
        type: "application/pdf",
        lastModified: Date.now(),
      });
      setReportUrl(file);
      visibilityMark(data, "LabTest");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const getPrescription = async (item) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { appoinmentId, patientId } = item;
    if (appoinmentId && patientId) {
      const res = await enrollmentDocumentGet(
        `enrollment-service/prescription/${appoinmentId}/${patientId}`
      );
      if (res instanceof Blob) {
        const blob = new Blob([res], { type: "text/plain" });
        const file = new File([blob], `${item.name}_prescription.pdf`, {
          type: "application/pdf",
          lastModified: Date.now(),
        });
        setPrescription(file);
        visibilityMark(item, "Prescription");
      }
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const onDocHide = () => {
    setReportUrl(null);
  };

  const visibilityMark = async (item, type) => {
    const payload = {
      labTestId: item.id,
      visibleReport: type,
    };
    const res = await LabTestPostApi("labtest/visibleReports", payload);

    if (res.code === 200) {
      fetchData(null, null, true);
    }
  };

  const onPrescriptionClose = () => {
    setPrescription(null);
  };

  return (
    <div className="w-full">
      <div className="w-full mb-5">
        <div className="">
          <label className="flex text-sm text-gray-700 justify-left pt-1 mb-2">
            Search
          </label>

          <div className="relative w-full self-end gap-2">
            <input
              className="block p-2.5 w-full z-20 text-sm text-gray-900  rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 rounded-xl"
              placeholder="Search by customer name ..."
              onChange={handleChange}
              value={searchValue}
              onKeyDown={handleEnterKey}
            />
            <button
              onClick={onSearchHandle}
              className="absolute top-0 end-0 right-0 p-2.5 text-sm font-medium h-full text-gray-900"
            >
              <SearchIcon />
            </button>
            {searchValue && (
              <button
                onClick={onResetHandle}
                className="absolute top-0 end-0 right-6 p-2.5 text-sm font-medium h-full text-gray-900"
              >
                <Close />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between lg:flex-row flex-col lg:gap-0 gap-5 mb-5">
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 gap-5">
          <div>
            <CustomSelect
              label="Date Filter"
              value={filter?.duration}
              onChange={(e) => {
                let value = e?.target?.value;
                let dates;
                if (value === "custom") {
                  setFilter((state) => ({ ...state, duration: value }));
                  return;
                }
                if (value === "last7Days") dates = LastDays(7);
                if (value === "thisMonth") dates = WeekDates("month");
                if (value === "last1Month") dates = LastWeekDates("month");
                if (value === "last3Months") dates = LastMonths(3);
                if (value === "last6Months") dates = LastMonths(6);
                if (value === "thisYear") dates = WeekDates("year");
                if (value === "last1Year") dates = LastWeekDates("year");
                setFilter((state) => ({
                  ...state,
                  startDate: dates[0],
                  endDate: dates[1],
                  duration: value,
                }));
                setPage(1);
              }}
              data={DateFilter}
              style={{ width: isMobile() ? "300px" : "230px" }}
            />
          </div>
          {filter.duration === "custom" && (
            <>
              <div>
                <InputDate
                  label="From"
                  placeholder="startDate"
                  maxDate={new Date(moment().format("YYYY-MM-DD"))}
                  attrs={{
                    onChange: (e) => {
                      const newFromDate = new Date(e);
                      const currentDate = new Date(
                        moment().format("YYYY-MM-DD")
                      );
                      // If the selected 'from' date is greater than the current 'to' date, update 'to' to the current date
                      if (newFromDate > new Date(filter.to)) {
                        setFilter({
                          ...filter,
                          startDate: moment(newFromDate).format("YYYY-MM-DD"),
                          endDate: moment(currentDate).format("YYYY-MM-DD"),
                        });
                        setPage(1);
                      } else {
                        setFilter({
                          ...filter,
                          startDate: moment(newFromDate).format("YYYY-MM-DD"),
                        });
                        setPage(1);
                      }
                    },
                    value: new Date(filter.startDate),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  maxDate={moment(moment().format("YYYY-MM-DD")).toDate()}
                  minDate={new Date(filter.startDate)}
                  attrs={{
                    onChange: (e) =>
                      setFilter({
                        ...filter,
                        endDate: moment(new Date(e)).format("YYYY-MM-DD"),
                      }),
                    value: new Date(filter.endDate),
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {tableData && tableData?.length === 0 ? (
        <div className="text-center">
          <p className="text-xl">You do not have any item</p>
        </div>
      ) : (
        <div>
          {!isMobile() ? (
            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <table className="min-w-full border" id="table-to-xls">
                  <thead className="bg-gray2 text-white h-8">
                    <th className="text-sm font-sm px-6 py-4 whitespace-nowrap text-left">
                      Date
                    </th>
                    <th className="text-sm font-sm px-6 py-4 whitespace-nowrap text-left">
                      Customer Name
                    </th>
                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                      Phone number
                    </th>
                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                      Lab test
                    </th>
                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                      Sample collection
                    </th>
                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                      Report
                    </th>
                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                      Prescription
                    </th>
                  </thead>
                  <tbody>
                    {tableData && tableData.length ? (
                      tableData.map((item, index) => {
                        return (
                          <tr
                            className={`${
                              index % 2 === 0 ? "" : "bg-tableColumn"
                            } h-16`}
                            key={index}
                          >
                            <td className="text-center px-4 py-2 text-sm">
                              {item?.createdAt
                                ? moment(item?.createdAt).format("DD/MM/YYYY")
                                : "---"}
                            </td>
                            <td
                              className="text-left px-4 py-2 text-sm capitalize break-words"
                              style={{ color: "blue", fontWeight: 600 }}
                            >
                              <Link
                                to={{
                                  pathname: "/customer-lab-test-details",
                                  state: item,
                                }}
                              >
                                {truncateString(
                                  item?.name?.toLowerCase(),
                                  30,
                                  "Member Empty"
                                )}
                              </Link>
                            </td>
                            <td className="text-center px-4 py-2 text-sm">
                              {item?.phoneNumber || "---"}
                            </td>
                            <td className="text-center px-4 py-2 text-sm">
                              {item?.labTestName || "---"}
                            </td>
                            <td className="text-center px-4 py-2 text-sm">
                              {item?.sampleCollectionDate ? (
                                <div>
                                  <p className="text-green-500 flex justify-center items-center gap-1">
                                    Done
                                  </p>
                                  {moment(item?.sampleCollectionDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              ) : (
                                <p className="text-red-500 flex justify-center items-center gap-1">
                                  Waiting
                                </p>
                              )}
                            </td>
                            <td className="text-center px-4 py-2 text-sm">
                              {item?.reportAdded ? (
                                <div>
                                  <p
                                    onClick={() =>
                                      getReport(
                                        `labtest/download_lab_report/${item.id}/download`,
                                        `${item.name}_lab_test_report.pdf`,
                                        item
                                      )
                                    }
                                    className="text-green-500 flex justify-center items-center gap-1"
                                  >
                                    <img
                                      style={{ height: "22px" }}
                                      alt=""
                                      src={
                                        item.labTestReport === "true"
                                          ? check
                                          : unCheck
                                      }
                                    />

                                    <span className="underline cursor-pointer">
                                      View
                                    </span>
                                  </p>
                                  {moment(item?.reportAdded).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              ) : (
                                <p className="text-red-500 flex justify-center items-center gap-1">
                                  Waiting
                                </p>
                              )}
                            </td>
                            <td className="text-center px-4 py-2 text-sm">
                              {item?.prescriptionCreationDate ? (
                                <div>
                                  <p
                                    onClick={() => getPrescription(item)}
                                    className="flex justify-center items-center gap-1 text-green-500"
                                  >
                                    <img
                                      style={{ height: "22px" }}
                                      alt=""
                                      src={
                                        item.prescriptionReport === "true"
                                          ? check
                                          : unCheck
                                      }
                                    />

                                    <span className="underline cursor-pointer">
                                      View prescription
                                    </span>
                                  </p>
                                  {moment(
                                    item?.prescriptionCreationDate
                                  ).format("DD/MM/YYYY")}
                                </div>
                              ) : (
                                <p className="text-red-500 flex justify-center items-center gap-1">
                                  Waiting
                                </p>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="8"
                          height="150"
                          className="text-red-500 font-large text-center"
                        >
                          No Data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap justify-center md:justify-between lg:justify-center">
              {tableData &&
                tableData.length >= 0 &&
                tableData.map((item, index) => {
                  return (
                    <div className="w-full mb-2" key={index}>
                      <article
                        className={`overflow-hidden rounded-lg border-2`}
                      >
                        <div>
                          <header className="items-center justify-between leading-tight p-2 pb-4 border-b-2">
                            <div className="w-full flex justify-between items-center">
                              <Link
                                to={{
                                  pathname: "/customer-lab-test-details",
                                  state: item,
                                }}
                              >
                                <p className="text-lg font-bold text-blue-500 capitalize pt-2">
                                  {truncateString(
                                    item.customer_name
                                      ? item.customer_name?.toLowerCase()
                                      : item.name?.toLowerCase(),
                                    20,
                                    "Member Empty"
                                  )}
                                </p>
                              </Link>
                              <p className="text-sm font-bold pt-2">
                                {item.createdAt
                                  ? moment(item.createdAt).format("DD/MM/YYYY")
                                  : ""}
                              </p>
                            </div>
                            <div className="w-full flex justify-between items-center mt-2">
                              <p className="text-sm">Phone number</p>
                              <p>{item?.phoneNumber}</p>
                            </div>
                          </header>

                          <body className="flex flex-col gap-2 leading-tight p-4">
                            <div className="w-full flex justify-between">
                              <p className="text-base">Sample collection</p>

                              {item?.sampleCollectionDate ? (
                                <div>
                                  <p className="text-green-500 flex justify-center items-center gap-1">
                                    Done
                                  </p>
                                </div>
                              ) : (
                                <p className="text-red-500 flex justify-center items-center gap-1">
                                  Waiting
                                </p>
                              )}
                            </div>
                            <div className="w-full flex justify-between">
                              <p className="text-base">Report</p>

                              {item?.reportAdded ? (
                                <div>
                                  <p
                                    onClick={() =>
                                      getReport(
                                        `labtest/download_lab_report/${item.id}/download`,
                                        `${item.name}_lab_test_report.pdf`,
                                        item
                                      )
                                    }
                                    className="text-green-500 flex justify-center items-center gap-1"
                                  >
                                    <img
                                      style={{ height: "22px" }}
                                      alt=""
                                      src={
                                        item.labTestReport === "true"
                                          ? check
                                          : unCheck
                                      }
                                    />
                                    <span className="underline cursor-pointer">
                                      View
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <p className="text-red-500 flex justify-center items-center gap-1">
                                  Waiting
                                </p>
                              )}
                            </div>
                            <div className="w-full flex justify-between">
                              <p className="text-base">Prescription</p>

                              {item?.prescriptionCreationDate ? (
                                <div>
                                  <p
                                    onClick={() => getPrescription(item)}
                                    className="text-green-500 flex justify-center items-center gap-1"
                                  >
                                    <img
                                      style={{ height: "22px" }}
                                      alt=""
                                      src={
                                        item.prescriptionReport === "true"
                                          ? check
                                          : unCheck
                                      }
                                    />
                                    <span className="underline cursor-pointer">
                                      View
                                    </span>
                                  </p>
                                </div>
                              ) : (
                                <p className="text-red-500 flex justify-center items-center gap-1">
                                  Waiting
                                </p>
                              )}
                            </div>
                          </body>
                        </div>
                      </article>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}

      {tableData && tableData?.length > 0 ? (
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={4}
            size="xs"
            layout={["total", "-", "pager", "skip"]}
            total={totalCount}
            limit={limit}
            activePage={page}
            onChangePage={(page) => handelChangePage(page)}
          />
        </div>
      ) : null}

      <Dialog
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={reportUrl}
        onClose={onDocHide}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: "111",
            backgroundColor: PRIMARY_COLOR,
          }}
        >
          <p>
            <span className="text-white m-4 float-left">Preview</span>
            <CloseOutlined
              className="text-white m-4 cursor-pointer float-right"
              onClick={onDocHide}
            />
          </p>
        </div>
        <div>
          {reportUrl ? (
            <CustomPdfViewer scale={isMobile() ? 0.7 : ""} myFile={reportUrl} />
          ) : (
            false
          )}
        </div>
      </Dialog>

      <Dialog
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={prescription}
        onClose={onPrescriptionClose}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: "111",
            backgroundColor: PRIMARY_COLOR,
          }}
        >
          <p>
            <span className="text-white m-4 float-left">Preview</span>
            <CloseOutlined
              className="text-white m-4 cursor-pointer float-right"
              onClick={onPrescriptionClose}
            />
          </p>
        </div>
        <div>
          {prescription ? (
            <CustomPdfViewer
              scale={isMobile() ? 0.7 : ""}
              myFile={prescription}
            />
          ) : (
            false
          )}
        </div>
      </Dialog>
    </div>
  );
});

export default LabTestSubscriptionDataTable;
