import React from "react";
import image from "./assets/image.png";
import fileImg from "./assets/file.png";
import sampleFile from "./assets/ReimbursementUploaderSampleFile.xlsx";
import Stepper from "./Stepper";

const ZipFileUploader = ({
  file,
  filename,
  onChangeHandle,
  handleUploadFile,
}) => {
  return (
    <div className="flex flex-col gap-6">
      <Stepper step={0} steps={["Select file", "In progress", "Processed"]} />
      <div className="flex items-center flex-col">
        <div className="border-2 border-gray-200 p-10 flex flex-col items-center w-full md:w-4/5 justify-center rounded-3xl border-dashed gap-5">
          <a
            href={sampleFile}
            download="ReimbursementUploaderSampleFile.xlsx"
            className="border-2 text-xs p-1 rounded-xl border-black self-end flex gap-1 items-center hover:bg-gray-100"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
            Reimbursement sample file
          </a>
          <p className="text-sm self-start">
            Download the reimbursement sample file and compare it with your file
            to ensure it matches the required format for upload.
          </p>
          <div className="flex flex-col items-center overflow-hidden gap-3 w-full border-2 border-primary-200 border-dashed rounded-3xl p-6 bg-gray-100">
            <img
              src={file ? fileImg : image}
              alt="img"
              className="h-14 md:h-14"
              style={{
                userSelect: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
              }}
            />
            <div>
              {filename ? (
                <p>
                  Are you sure to upload{" "}
                  <span className="font-medium">{filename}</span>
                </p>
              ) : (
                <div className="flex flex-col items-center gap-1">
                  <p className="font-medium">Browse file</p>
                  <span className="text-xs font-normal text-secondary">
                    Please upload a zip file containing the master excel data
                    file & a folder containing supporting documents.
                  </span>
                  <p className="font-medium mt-3">Drag and drop files or </p>
                </div>
              )}
            </div>

            {file ? (
              <button
                className="bg-primary-500 p-2 rounded-lg text-white"
                onClick={handleUploadFile}
              >
                Upload
              </button>
            ) : (
              <label
                className="relative flex items-center rounded-xl shadow-lg tracking-wide border border-red
           cursor-pointer
           bg-red text-white"
              >
                <span className="p-2 md:p-3 md:px-10  leading-normal">
                  Select file
                </span>
                <input
                  type="file"
                  onChange={onChangeHandle}
                  className="hidden"
                  name="file"
                  accept=".zip"
                />
              </label>
            )}
            {file && (
              <div className="flex flex-col text-center text-gray-500 gap-3 font-medium">
                <p>OR</p>
                <label>
                  <p className="underline cursor-pointer">
                    Select another file
                  </p>
                  <input
                    type="file"
                    onChange={onChangeHandle}
                    className="hidden"
                    name="file"
                    accept=".zip"
                  />
                </label>
              </div>
            )}
          </div>
          <div className="m-2">
            <div className="font-semibold">Note:-</div>
            <div className="text-secondary text-xs font-normal">
              <p className="flex gap-1">
                <code className="text-primary-500">* </code>{" "}
                <p>
                  The supporting documents should be in a folder named "Docs".
                </p>
              </p>

              <p className="flex gap-1">
                <code className="text-primary-500">* </code>{" "}
                <p>
                  There should be a single supporting document for each
                  reimbursement.
                </p>
              </p>
              <p className="flex gap-1">
                <code className="text-primary-500">* </code>{" "}
                <p>
                  Each supporting document within the ZIP file must not exceed
                  500 MB in size.
                </p>
              </p>
              <p className="flex gap-1">
                <code className="text-primary-500">* </code>{" "}
                <p>
                  Family relation can only be one of the following: self,
                  spouse, daughter, son, parent, mother, father, brother,
                  sibling, husband, spouse_parent, spouse_parent_1,
                  spouse_parent_2, sister.
                </p>
              </p>
              <p className="flex gap-1">
                <code className="text-primary-500">* </code>{" "}
                <p>All columns in the Excel file are mandatory.</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZipFileUploader;
