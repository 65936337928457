import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import Ringtone from "../assets/ringtone/notification.mp3";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function CustomizedSnackbars({ option, setOption }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOption(false);
  };

  React.useEffect(() => {
    let audio;
    if (option) {
      audio = new Audio(Ringtone);
      try {
        audio.play();
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }

    return () => {
      // Cleanup: Stop and release the audio element
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [option]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={option}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={SlideTransition}
      >
        <Alert onClose={handleClose} severity={"info"} sx={{ width: "100%" }}>
          {option?.notification?.body}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
