export const excelUploaderLimit = 20001;
export const creditExcelUploaderLimit = 20001;
export const policyExcelUploaderLimit = 50001;
export const groupExcelUploaderLimit = 20001;
export const groupExcelUploaderFileSize = 4194304; // 4MB
export const inventoryExcelUploaderFileSize = 4194304; // 4MB
export const RESELLER_ENCRYPTION_KEY = "1234567887654321";
export const inventoryStockExcelUploaderLimit = 20001;
export const MAX_REIMBURSEMENT_ZIP_FILE_SIZE = 524288000; // 500MB in bytes

export const PURCHASE_STOCK = "purchase_stock";
export const TRANSFER_STOCK = "transfer_stock";

export const appointmentType = [
  "appointment_created",
  "pharmacist_joined_call",
  "doctor_joined_call",
  "aiqa_center_joined_call",
];

export const addTeamDocSize = 5 * 1024 * 1024;

export function getFileExtension(file) {
  return file?.name?.split(".").pop().toLowerCase();
}

export const extensions = ["pdf", "jpeg", "jpg", "png"];

export const profileExtension = ["jpeg", "jpg", "png"];

export const reimbursementReportStatusWiseHeader = {
  All: [
    { label: "Loan Code", value: "loan_code", type: "text" },
    { label: "Mobile", value: "mobile", type: "text" },
    { label: "Name", value: "customer_name", type: "text" },
    {
      label: "Subscription Start Date",
      value: "policy_start_date",
      type: "date",
    },
    {
      label: "Reimbursement Register Date",
      value: "claim_register_date",
      type: "date",
    },
    {
      label: "Plan Name",
      value: "product_display_name",
      type: "text",
      optional: "product_name",
    },

    {
      label: "Status",
      value: "external_status",
      type: "text",
      optional: "internal_status",
    },

    { label: "Total Days", value: "eligible_days", type: "text" },
    { label: "Reimbursement Amount", value: "claim_amount", type: "text" },
    { label: "Approved Amount", value: "approved_amount", type: "text" },
    {
      label: "Reimbursement specification type",
      value: "specification_type",
      type: "text",
    },
    { label: "UTR Number", value: "transaction_number", type: "text" },
  ],

  IN_PROGRESS: [
    { label: "Loan Code", value: "loan_code", type: "text" },
    { label: "Mobile", value: "mobile", type: "text" },
    { label: "Name", value: "customer_name", type: "text" },
    {
      label: "Status",
      value: "external_status",
      type: "text",
      optional: "internal_status",
    },
    { label: "Reimbursement Amount", value: "claim_amount", type: "text" },
    {
      label: "Reimbursement Register Date",
      value: "claim_register_date",
      type: "date",
    },
    {
      label: "Reimbursement specification type",
      value: "specification_type",
      type: "text",
    },
    {
      label: "Plan Name",
      value: "product_display_name",
      type: "text",
      optional: "product_name",
    },
    { label: "Total Days", value: "eligible_days", type: "text" },
  ],
  REJECTED: [
    { label: "Loan Code", value: "loan_code", type: "text" },
    { label: "Mobile", value: "mobile", type: "text" },
    { label: "Name", value: "customer_name", type: "text" },
    {
      label: "Status",
      value: "external_status",
      type: "text",
      optional: "internal_status",
    },
    { label: "Reimbursement Amount", value: "claim_amount", type: "text" },
    {
      label: "Reimbursement reject reason",
      value: "claim_reject_reason",
      type: "text",
    },
    {
      label: "Reimbursement Register Date",
      value: "claim_register_date",
      type: "date",
    },
    {
      label: "Reimbursement specification type",
      value: "specification_type",
      type: "text",
    },
    {
      label: "Plan Name",
      value: "product_display_name",
      type: "text",
      optional: "product_name",
    },
    { label: "Total Days", value: "eligible_days", type: "text" },
  ],
  QUERY: [
    { label: "Loan Code", value: "loan_code", type: "text" },
    { label: "Mobile", value: "mobile", type: "text" },
    { label: "Name", value: "customer_name", type: "text" },
    {
      label: "Status",
      value: "external_status",
      type: "text",
      optional: "internal_status",
    },
    { label: "Reimbursement Amount", value: "claim_amount", type: "text" },
    { label: "Remarks", value: "remarks", type: "text" },

    {
      label: "Reimbursement Register Date",
      value: "claim_register_date",
      type: "date",
    },
    {
      label: "Reimbursement specification type",
      value: "specification_type",
      type: "text",
    },
    {
      label: "Plan Name",
      value: "product_display_name",
      type: "text",
      optional: "product_name",
    },
    { label: "Total Days", value: "eligible_days", type: "text" },
  ],
  SETTLED: [
    { label: "Loan Code", value: "loan_code", type: "text" },
    { label: "Mobile", value: "mobile", type: "text" },
    { label: "Name", value: "customer_name", type: "text" },
    {
      label: "Status",
      value: "external_status",
      type: "text",
      optional: "internal_status",
    },
    { label: "Reimbursement Amount", value: "claim_amount", type: "text" },
    { label: "Settled Amount", value: "approved_amount", type: "text" },
    { label: "Settlement Date", value: "settlement_date", type: "text" },
    {
      label: "Reimbursement Register Date",
      value: "claim_register_date",
      type: "date",
    },
    { label: "UTR Date", value: "transaction_date", type: "text" },
    {
      label: "Reimbursement specification type",
      value: "specification_type",
      type: "text",
    },
    {
      label: "Plan Name",
      value: "product_display_name",
      type: "text",
      optional: "product_name",
    },
    { label: "Total Days", value: "eligible_days", type: "text" },
  ],
  Approved: [
    { label: "Loan Code", value: "loan_code", type: "text" },
    { label: "Mobile", value: "mobile", type: "text" },
    { label: "Name", value: "customer_name", type: "text" },
    {
      label: "Status",
      value: "external_status",
      type: "text",
      optional: "internal_status",
    },
    { label: "Reimbursement Amount", value: "claim_amount", type: "text" },
    { label: "Approved Amount", value: "approved_amount", type: "text" },
    { label: "Approved Date", value: "settlement_date", type: "text" },
    {
      label: "Reimbursement Register Date",
      value: "claim_register_date",
      type: "date",
    },
    {
      label: "Reimbursement specification type",
      value: "specification_type",
      type: "text",
    },
    {
      label: "Plan Name",
      value: "product_display_name",
      type: "text",
      optional: "product_name",
    },
    { label: "Total Days", value: "eligible_days", type: "text" },
  ],
};
