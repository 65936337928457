import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Button from "../../components/Button";
import InputGroup from "../../components/form/InputGroup";
import { InputRadio } from "../../components/form/InputRadio";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import {
  ApiGet,
  ApiPost,
  setMessage,
  setError,
  validateEmail,
  validateMobile,
  validateName,
} from "../../lib/AppHelper";
import { withRouter } from "react-router-dom";
import { PlanOption } from "../../components/PlanOption";

class LeadCapture extends Component {
  BasicBtn = React.createRef();
  MessageRef = React.createRef();

  state = {
    params: {
      product_id: "",
      product_type: "",
      name: "",
      mobile: "",
      email: "",
      gender: "m",
      pin_code: "",
      state: "",
    },
    max: "",
    min: "",
    step: 1,
    questions: [],
    answers: {},
  };

  steps = 2;

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  isReady = () => {
    const { step, params, answers } = this.state;
    const selected_product = _.find(
      this.props.products,
      (item) => params.product_id == item.id
    );
    params.product_type = selected_product
      ? selected_product.product.product_type
      : "";

    if (step === 1 && !params.product_id) return false;

    if (step === 2) {
      if (!params.name) {
        return false;
      } else if (!validateName(params.name)) {
        return false;
      }
      if (!params.mobile || params.mobile.length !== 10) return false;
      //   if (!params.email || !this.validateEmail(params.email)) return false;
      //   if (!params.pin_code || params.pin_code.length !== 6) return false;
      //   if (!params.state) return false;
    }

    return true;
  };

  render() {
    const { products } = this.props;
    const { params, step, questions, answers } = this.state;
    const selected_product = _.find(
      products,
      (item) => params.product_id === item.id
    );
    const ready = this.isReady();
    const items = [];
    _.each(products, (prod) => {
      items.push({
        value: prod.id,
        label: (
          <PlanOption
            amount={prod.price}
            title={prod.name}
            features={[
              `${prod.product.max_members} Members`,
              `${prod.product.description ? prod.product.description : ""}`,
              ..._.map(prod.inclusions, "title"),
            ]}
          />
        ),
      });
    });
    return (
      <Main MessageRef={this.MessageRef}>
        {step > 1 ? (
          <div
            onClick={this.prev}
            className="flex items-center text-primary pb-3 cursor-pointer"
          >
            <ArrowLeftIcon width={17} className="mr-3" />
            <p>{"Back"}</p>
          </div>
        ) : (
          <AppBackButton to="/">Back </AppBackButton>
        )}

        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            {step === 1 ? (
              <div>
                <AppHeading style={{ fontSize: "24px" }}>
                  Customer Lead Capture
                </AppHeading>
                <p className="text-lg mt-2">
                  Select the product you want to capture the lead for{" "}
                </p>
                {/* <h4 className="text-md mb-6 mt-6">Please select the product</h4> */}
                <InputRadio
                  value={params.product_id}
                  buttonHidden={true}
                  attrs={{
                    onChange: (e) => this.onChangeHandler(e, "product_id"),
                  }}
                  inline={false}
                  label={false}
                  options={items}
                />
              </div>
            ) : (
              false
            )}

            {step === 2 ? (
              <div>
                <AppHeading className="mb-3">Member Details</AppHeading>
                <InputGroup
                  label="Member Name"
                  requiredMark="true"
                  placeholder="Enter member name..."
                  attrs={{
                    maxLength: 32,
                    value: params.name,
                    onChange: (e) => {
                      // name validation ragx
                      if (
                        /^[a-zA-Z\s]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        this.onChangeHandler(e, "name");
                      }
                    },
                  }}
                  validateError={(value) => {
                    if (validateName(value)) {
                      return false;
                    } else {
                      return "Please enter valid name";
                    }
                  }}
                />
                <InputGroup
                  label="Phone no"
                  placeholder="Enter phone no..."
                  prefix="+91"
                  requiredMark="true"
                  attrs={{
                    value: params.mobile,
                    onChange: (e) => {
                      const integerRegex = /^-?\d+$/;
                      const isValidInteger = integerRegex.test(e.target.value);
                      if (isValidInteger || e.target.value == "") {
                        this.onChangeHandler(
                          {
                            target: { value: e.target.value.replace(/\D/, "") },
                          },
                          "mobile"
                        );
                      }
                    },
                    maxLength: 10,
                  }}
                  validateError={(value) => {
                    if (validateMobile(value)) {
                      return false;
                    } else {
                      return "Please enter valid mobile number";
                    }
                  }}
                />
                <InputGroup
                  label="Email"
                  placeholder="Enter email..."
                  attrs={{
                    maxLength: 64,
                    value: params.email,
                    onChange: (e) => this.onChangeHandler(e, "email"),
                  }}
                  validateError={(value) => {
                    if (value === "") return false;
                    if (validateEmail(value)) {
                      return false;
                    } else {
                      return "please enter correct email ID";
                    }
                  }}
                />
                <InputRadio
                  label="Gender"
                  name="gender"
                  buttonHidden={false}
                  options={[
                    { label: "Male", value: "m" },
                    { label: "Female", value: "f" },
                    { label: "Others", value: "o" },
                  ]}
                  value={params.gender}
                  attrs={{ onChange: (e) => this.onChangeHandler(e, "gender") }}
                />

                <InputGroup
                  label="Pincode"
                  placeholder="Enter pincode..."
                  attrs={{
                    maxLength: 6,
                    value: params.pin_code,
                    onChange: (e) => {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "pin_code"
                      );
                      if (e.target.value.length === 6) {
                        this.checkPin();
                      }
                      if (e.target.value.length == 6) this.checkPin();
                    },
                    onBlur: this.checkPin,
                  }}
                  onEnter={this.checkPin}
                />
                {params.state && params.pin_code.length == 6 ? (
                  <InputGroup
                    label="Your State"
                    placeholder="Enter state..."
                    attrs={{ value: params.state, readOnly: true }}
                  />
                ) : (
                  false
                )}
              </div>
            ) : (
              false
            )}
          </div>
          <div>
            <Button
              attrs={{
                ref: this.BasicBtn,
                disabled: !ready,
                type: "button",
                onClick: this.next,
              }}
              size="md"
              title={step === this.steps ? "Save and Continue" : "Continue"}
            />
          </div>
        </div>
      </Main>
    );
  }
  onChangeHandler = (e, key) => {
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params }, () => {
      if (key === "product_id") {
        const selected_product = _.find(
          this.props.products,
          (item) => this.state.params.product_id === item.id
        );
      }
    });
  };

  next = () => {
    if (this.state.step < this.steps) {
      this.setState({ step: this.state.step + 1 });
    } else {
      this.doSubmit();
    }
  };

  prev = () => {
    if (this.state.step > 1) {
      this.setState({ cancelPayment: false, step: this.state.step - 1 });
    }
  };

  checkPin = async () => {
    if (!this.state.params.pin_code) return;
    if (this.state.params.pin_code === this.checked_pin) return;
    this.checked_pin = this.state.params.pin_code;

    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(`check-pin/${this.state.params.pin_code}`);

    if (response.status === "success" && response.data !== null) {
      this.onChangeHandler({ target: { value: response.data } }, "state");
      this.BasicBtn.current.focus();
    } else {
      this.MessageRef.current.show(response.message, "error");
      this.onChangeHandler({ target: { value: null } }, "state");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  doSubmit = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    await ApiPost("reseller/customer-leads", this.state.params)
      .then((response) => {
        setMessage(response.message);
        const me = ApiGet("me");
        if (me.status === "success") {
          AppStore.dispatch({ type: "USER_UPDATED", user: me.data });
          this.props.history.push("/");
        }
        this.props.history.push("/");
      })
      .catch((error) => {
        setError("somthing went wrong");
      });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, products } = state;
  return { loading: loading, products: products };
};

const LeadCaptureConnect = connect(mapStateToProps)((props) => {
  return <LeadCapture {...props} />;
});

export default withRouter(LeadCaptureConnect);
