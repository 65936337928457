import React, { useState } from "react";
import Main from "../Main";
import { AppBackButton } from "../../components/AppComponents";
import { InputDate } from "../../components/form/InputDate";
import AppointmentTable from "../aiqaCenter/AppointmentTable";
import { RefreshIcon } from "@heroicons/react/outline";

const Appointment = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterValues, setFilterValues] = useState(null);

  const onChangeHandlerDate = (value, type) => {
    if (type == "from") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const handleClickReset = () => {
    setStartDate(new Date());
    setEndDate(new Date());
  };

  return (
    <Main>
      <div className="w-full flex md:flex-row flex-col">
        <div className="w-96">
          <AppBackButton to="/">Back to Dashboard</AppBackButton>
        </div>
      </div>

      <div className="flex items-center flex-wrap gap-4 justify-between ">
        <div className="flex w-full md:w-2/5 lg:w-1/3 gap-4 mr-2">
          <div className="w-full">
            <InputDate
              label="From"
              placeholder="from"
              maxDate={startDate}
              attrs={{
                onChange: (e) => onChangeHandlerDate(e, "from"),
                value: new Date(startDate),
              }}
            />
          </div>
          <div className="w-full">
            <InputDate
              label="To"
              placeholder="To"
              minDate={new Date(startDate)}
              maxDate={new Date()}
              attrs={{
                onChange: (e) => onChangeHandlerDate(e, "to"),
                value: endDate,
              }}
            />
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleClickReset}
              className="text-white hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
              style={{
                width: "30px",
                height: "35px",
                alignItems: "center",
                marginTop: "35px",
              }}
            >
              <RefreshIcon width={30} />
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-2">
        <AppointmentTable startDate={startDate} endDate={endDate} />
      </div>
    </Main>
  );
};

export default Appointment;
