import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { InputGroup } from "../../../components/form/InputGroup";
import { InputTextArea } from "../../../components/form/InputTextArea";
import Button from "@mui/material/Button";
import { getResellerId } from "../../../lib/AppHelper";
import { Loader } from "rsuite";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tooltip } from "@mui/material";
import ImageUploader from "../../../components/ImageUploader";
import ImageModal from "./ImageModal";

function CustomModal({
  open,
  setOpen,
  ticketDetails,
  setTicketDetails,
  handelSubmitRaiseTicket,
  ticketLoading,
  onChangeFileHandeler,
  ticketDocuments,
  removeFile,
}) {
  const [error, setError] = React.useState({});
  const [viewImage, setViewImage] = React.useState({ isOpen: false, id: null });
  const [imageLoading, setImageLoading] = React.useState(false);
  const handleClose = () => {
    setTicketDetails({
      title: "",
      description: "",
      status: "In_Progress",
      priority: "High",
    });
    setOpen(null);
  };

  const handelSubmit = async () => {
    const isError = validateError();
    if (isError !== 0) return;
    const data = {
      currentOwner: getResellerId(),
      ownerType: getResellerId(),
      referenceId: open?.claim_id,
      referenceType: "claim",
      status: ticketDetails.status,
      reportedBy: getResellerId(),
      description: ticketDetails.description,
      ticketTitle: ticketDetails.title,
      ticketPriority: ticketDetails.priority,
      subReferenceId: open?.sub_claims?.[0]?.sub_claim_id,
      createdBy: getResellerId(),
    };
    handelSubmitRaiseTicket(data);
  };
  const handelChange = async (e) => {
    const { name, value } = e.target;
    setTicketDetails({ ...ticketDetails, [name]: value });
  };
  const validateError = () => {
    let errors = {};
    if (ticketDetails.title.trim() === "")
      errors["title"] = "This field is required";
    setError(errors);
    return Object.values(errors)?.length;
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => {
          if (!ticketLoading) {
            handleClose();
          }
        }}
        fullWidth={true}
        maxWidth="lg"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Ticket</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            if (!ticketLoading) {
              handleClose();
            }
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={ticketLoading}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={"paper"}>
          <InputGroup
            label="Title"
            placeholder="Title"
            requiredMark={true}
            name="title"
            attrs={{
              value: ticketDetails.title,
              maxLength: 256,
              onChange: (e) => {
                if (e.target.value.length < 256) {
                  handelChange(e);
                  setError({ title: "" });
                } else setError({ title: "maximum charcter can be upto 256" });
              },
              disabled: ticketLoading,
            }}
            isError={error?.title}
          />

          <InputTextArea
            label="Description"
            placeholder="Here comes the comment...maximum character upto 526"
            name="description"
            attrs={{
              maxLength: 526,
              value: ticketDetails.description,
              onChange: (e) => handelChange(e),
              disabled: ticketLoading,
            }}
          />
          <div className="mb-4">
            {ticketDocuments?.length > 0 &&
              ticketDocuments?.map((document, index) => {
                return (
                  <ImageUploader
                    key={index}
                    id={index}
                    file={document}
                    onDelete={(id) => {
                      if (!ticketLoading) {
                        removeFile(id);
                      }
                    }}
                    onView={(id) => {
                      if (!ticketLoading) {
                        setViewImage({ isOpen: true, id: id });
                      }
                    }}
                  />
                );
              })}
          </div>
          <div className="flex justify-end">
            <input
              accept="*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              disabled={ticketLoading}
              onChange={(e) => {
                if (!ticketLoading) onChangeFileHandeler(e);
              }}
            />
            <label htmlFor="raised-button-file">
              <Tooltip title="Upload document" placement="top" arrow>
                <IconButton component="span">
                  <AttachFileIcon
                    style={{ color: "#db2228" }}
                    fontSize="40px"
                  />
                  {/* <FilePresentOutlinedIcon style={{ color: "#DB2228" }} /> */}
                </IconButton>
              </Tooltip>
            </label>
            <Button
              variant="contained"
              style={{ background: "#DB2228", borderRadius: "12px" }}
              onClick={() => {
                if (!ticketLoading) {
                  handelSubmit();
                }
              }}
            >
              {ticketLoading ? <Loader /> : "Save"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <ImageModal
        file={ticketDocuments[viewImage?.id] || null}
        open={viewImage.isOpen}
        onclose={() => setViewImage({ isOpen: false, id: null })}
        loading={imageLoading}
      />
    </React.Fragment>
  );
}

export default CustomModal;
