import { Tooltip } from "@mui/material";
import moment from "moment";

const CLAIM_SETTLE_TOTAL_DAY = 14;

export const AMOUNT_TOGGLE = ["PARTIAL_SETTLE", "SETTLED"];
export const ACTION_TOGGLE = ["QUERY", "REJECTED", "DISPUTE", "DRAFT"];
export const DateFilter = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Last 7 days",
    value: "7_days",
  },
  {
    label: "Last 1 month",
    value: "1_month",
  },
  {
    label: "Last 3 months",
    value: "3_months",
  },

  {
    label: "Last 6 months",
    value: "6_months",
  },
  {
    label: "Custom Date",
    value: "custom_date",
  },
];

export const setFormateDate = (value) => {
  if (!value) return;
  switch (value) {
    case "All":
      return ["", ""];
    case "7_days":
      return [
        moment().subtract(7, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
    case "1_month":
      return [
        moment().subtract(1, "months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];

    case "3_months":
      return [
        moment().subtract(3, "months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
    case "6_months":
      return [
        moment().subtract(6, "months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
  }
};

export const TimerCount = (date) => {
  if (date === null) return;

  const lastDay = moment(date).add(CLAIM_SETTLE_TOTAL_DAY, "days").endOf("day");
  const currentDate = moment();

  const remainingDays = lastDay.diff(currentDate, "days");
  const remainingHours = lastDay.diff(currentDate, "hours");
  const remainingMonths = lastDay.diff(currentDate, "months");
  const remainingYears = lastDay.diff(currentDate, "years");

  let timeArray = [];

  if (remainingDays <= -365) {
    timeArray = [
      remainingYears,
      remainingYears === 1 ? "Year past due date" : "Years past due date",
    ];
  } else if (remainingDays <= -30) {
    timeArray = [
      remainingMonths,
      remainingMonths === 1 ? "Month past due date" : "Months past due date",
    ];
  } else if (remainingDays <= -1) {
    timeArray = [remainingDays, "Days past due date"];
  } else if (remainingDays <= 0) {
    timeArray = [remainingHours, "Hours left"];
  } else if (remainingDays > 365) {
    timeArray = [
      remainingYears,
      remainingYears === 1 ? "Year left" : "Years left",
    ];
  } else if (remainingDays > 30) {
    timeArray = [
      remainingMonths,
      remainingMonths === 1 ? "Month left" : "Months left",
    ];
  } else {
    timeArray = [remainingDays, "Days left "];
  }

  const numericValue = timeArray.slice(0, 1);
  const descriptiveText = timeArray.slice(1)[0].split(" ");

  return [numericValue, descriptiveText];
};

export const OueryTimerCount = (modified_on) => {
  if (!modified_on) return;

  const endClaimDate = moment(modified_on);
  const currentDate = moment();

  const remainingDays = endClaimDate.diff(currentDate, "days");
  const remainingHours = endClaimDate.diff(currentDate, "hours");

  let timeArray = [];

  const dayLabel = remainingDays === 1 ? "day" : "days";
  const hourLabel = remainingHours === 1 ? "hour" : "hours";

  if (remainingDays !== 0) {
    if (remainingDays < 0) {
      timeArray = ["In query since", remainingDays, dayLabel];
    } else {
      timeArray = ["In query since", remainingDays, dayLabel];
    }
  } else if (remainingHours !== 0) {
    if (remainingHours < 0) {
      timeArray = ["In query since", remainingHours, hourLabel];
    } else {
      timeArray = ["In query since", remainingHours, hourLabel];
    }
  } else {
    timeArray = ["In query since", remainingDays, "day"];
  }

  const beforeDescriptiveText = timeArray.slice(0, 1);
  const numericValue = timeArray.slice(1, 2);
  const afterDescriptiveText = timeArray.slice(2);

  return [beforeDescriptiveText, numericValue, afterDescriptiveText];
};

export const OtherTimerCount = (
  claim_register_date,
  modified_on,
  ClaimStatus
) => {
  if (!claim_register_date || !modified_on || !ClaimStatus) return;

  const ClaimRegisterDateWithTat = moment(claim_register_date).add(
    CLAIM_SETTLE_TOTAL_DAY,
    "days"
  );
  const assumeClaimRegisterDate = moment(modified_on);

  const remainingDays = ClaimRegisterDateWithTat.diff(
    assumeClaimRegisterDate,
    "days"
  );
  const remainingHours = ClaimRegisterDateWithTat.diff(
    assumeClaimRegisterDate,
    "hours"
  );

  let timeArray = [];

  if (ClaimStatus === "SETTLED") {
    const dayLabel = remainingDays === 1 ? "day" : "days";
    const hourLabel = remainingHours === 1 ? "hour" : "hours";

    if (remainingDays !== 0) {
      if (remainingDays < 0) {
        timeArray = ["Settled late in", remainingDays, dayLabel];
      } else {
        timeArray = ["Settled early in", remainingDays, dayLabel];
      }
    } else if (remainingHours !== 0) {
      if (remainingHours < 0) {
        timeArray = ["Settled late in", remainingHours, hourLabel];
      } else {
        timeArray = ["Settled early in", remainingHours, hourLabel];
      }
    } else {
      timeArray = ["Settled early in", remainingDays, "day"];
    }
  } else {
    const dayLabel = remainingDays === 1 ? "day" : "days";
    const hourLabel = remainingHours === 1 ? "hour" : "hours";

    if (remainingDays !== 0) {
      if (remainingDays < 0) {
        timeArray = ["Partial Settled late in", remainingDays, dayLabel];
      } else {
        timeArray = ["Partial Settled early in", remainingDays, dayLabel];
      }
    } else if (remainingHours !== 0) {
      if (remainingHours < 0) {
        timeArray = ["Partial Settled late in", remainingHours, hourLabel];
      } else {
        timeArray = ["Partial Settled early in", remainingHours, hourLabel];
      }
    } else {
      timeArray = ["Partial Settled early in", remainingDays, "day"];
    }
  }

  const beforeDescriptiveText = timeArray.slice(0, 1);
  const numericValue = timeArray.slice(1, 2);
  const afterDescriptiveText = timeArray.slice(2);

  return [beforeDescriptiveText, numericValue, afterDescriptiveText];
};

export const formatMoney = (amount) => {
  if (!amount) return;
  amount = Math.floor(amount);
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const showLabels = (arr, label) => {
  const matchedItem = arr.find(
    (item) => item.value.toLowerCase() === label.toLowerCase()
  );
  return matchedItem ? matchedItem?.name?.toLowerCase() : "";
};

export const getMessage = (daysLeft) => {
  if (daysLeft > 0) {
    return `Standard TAT: 14 days of which ${daysLeft} days left.`;
  } else if (daysLeft === 0) {
    return "Today is the last day of the standard TAT (14 days).";
  } else {
    return `The standard TAT of 14 days has passed. We apologize for the delay. Your claim is being processed, and we will update you shortly.`;
  }
};

export const truncateString = (str, charLength = 17) => {
  if (!str) return;
  else if (str.length <= charLength)
    return <span>{str ? str : "Name empty"}</span>;
  else {
    const newString = str.substring(0, charLength) + "...";
    return (
      <Tooltip title={str} placement="left" arrow>
        <span>{newString ? newString : "Name empty"}</span>
      </Tooltip>
    );
  }
};

export const AllFilterValue = (value, arr) => {
  const filteredStatuses = [];

  arr.forEach((item) => {
    const { status, type } = item;

    // Check if the condition is satisfied
    if (type?.includes(value)) {
      filteredStatuses.push(status);
    }
  });

  return filteredStatuses;
};
export const Label = {
  ALL: "All",
  SETTLED: "Settled",
  IN_PROGRESS: "In progress",
  DRAFT: "Draft",
  APPROVED: "Approved",
  QUERY: "Query",
  REJECTED: "Rejected",
  DISPUTE: "Dispute",
  PARTIAL_SETTLE: "Partially settled",
};

export const formatNumber = (input) => {
  if (!input) return "---";
  if (input?.includes("e") || input?.includes("E")) {
    return Number(input).toFixed(0);
  } else {
    return input;
  }
};

export const handleRemoveItem = (id, documents) => {
  return documents.filter((_, index) => index !== id);
};
export const getInitials = (name) => {
  const parts = name?.split(" ");

  const firstNameInitial = parts[0].charAt(0);
  const lastName = parts[parts.length - 1];

  if (lastName) {
    return firstNameInitial + lastName.charAt(0);
  } else {
    return firstNameInitial;
  }
};
