import CryptoJS from "crypto-js";
import * as XLSX from "xlsx";

export const Encryption = (data, ivKey) => {
  var data = `${data}`;
  var key = CryptoJS.enc.Latin1.parse(ivKey);
  var iv = CryptoJS.enc.Latin1.parse(ivKey);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  });
  return encrypted.toString();
};
let resellerToken = void 0;
let authToken = void 0;
export const ImageUrl = (image) => {
  return `${window.BASE_URL}/images/${image}`;
};

export const setResellerToken = (token) => {
  resellerToken = token;
};
export const getResellerToken = () => {
  return resellerToken;
};

export const setAuthToken = (token) => {
  authToken = token;
};

export const getAuthToken = () => {
  return authToken;
};

export const convertToAMPM = (time) => {
  const [hour, minute] = time.split(":");
  const hourNum = parseInt(hour, 10);
  const period = hourNum >= 12 ? "PM" : "AM";
  const hour12 = hourNum > 12 ? hourNum - 12 : hourNum;
  const formattedHour = hour12 < 10 ? "0" + hour12 : hour12.toString();
  const formattedMinute = minute < 10 ? "0" + minute : minute;
  const timeAMPM = `${formattedHour}:${formattedMinute} ${period}`;

  return timeAMPM;
};

export const formatedDate = (date) => {
  return `${date?.split("-")[2]}-${date?.split("-")[1]}-${date?.split("-")[0]}`;
};

//convert 24 time in to 12 hour
export const timeConvert = (time) => {
  if (time == null || time == "") {
    return "";
  }
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time.pop();
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    // time[4] = "";
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

// array of an object to xlsx file \

export const objectToXlsx = (data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  const wbout = XLSX.write(wb, {
    bookSST: true,
    type: "binary",
    compression: true,
  });
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const file1 = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });
  return file1;
};
