import {
  getResellerId,
  postApiClaim,
  putCommentDocuments,
} from "../../../lib/AppHelper";

export const addCommentAPI = async ({
  data,
  documents,
  onSuccess,
  onError,
}) => {
  try {
    const response = await postApiClaim("add-comment", data);
    if (response?.code === 200) {
      if (documents?.length > 0) {
        addDocument(
          response?.data?.commentId,
          data.referenceId,
          data.subReferenceId,
          data.ticketMasterId,
          documents,
          onSuccess,
          onError
        );
      } else {
        onSuccess();
      }
    } else onError(response.message);
  } catch (error) {
    console.log(error.message);
    onError(error);
  }
};

export const addDocument = async (
  documentID,
  claimID,
  subClaimId,
  masterId,
  documents,
  onSuccess,
  onError
) => {
  try {
    const response = await putCommentDocuments(
      getResellerId(),
      documentID,
      claimID,
      subClaimId,
      masterId,
      documents
    );
    if (response.code === 500) {
      onError();
    } else {
      onSuccess();
    }
  } catch (error) {
    onError();
  }
};
