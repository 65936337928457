import React, { Component } from "react";
import Button from "../../../components/Button";
import {
  XIcon,
  TrashIcon,
  ChevronRightIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import * as XLSX from "xlsx";
import { AppStore } from "../../../lib/AppStore";
import FileSaver from "file-saver";
import {
  setError,
  setMessage,
  validateEmail,
  ApiGet,
  getReportingEmail,
  inventoryStockInOutUploader,
  getApiClaim,
  postApiClaim,
} from "../../../lib/AppHelper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Encryption } from "../../../helpers/Helper";
import { InputGroup } from "../../../components/form/InputGroup";
import { Footer } from "../../../components/common/Footer";
import SampleHeader from "../../../components/common/SampleHeader";
import { InputFile } from "../../../components/common/InputFile";
import VerticalStepper from "../../../components/common/VerticalStepper";
import ErrorDataTable from "../../../components/common/ErrorDataTable";
import {
  RESELLER_ENCRYPTION_KEY,
  inventoryStockExcelUploaderLimit,
  inventoryExcelUploaderFileSize,
} from "../../../lib/constants";
import moment from "moment";
import { AutoComplete, Modal, SelectPicker } from "rsuite";
import debounce from "lodash.debounce";
import InputLabel from "../../../components/form/InputLabel";
import AddVendor from "./AddVendor";

export class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        group_id: null,
        file: "",
        uploadExcelDtoList: "",
        emailRecipients: "",
      },
      email: "",
      groupData: [],
      file: "",
      filename: "",
      errorDataRow: [],
      errorData: [],
      parseErrorData: [],
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
      validate: false,
      errMsg: "",
      current: 1,
      totalStep: 2,
      toggleErrData: false,
      encResellerId: "",
      userEmail: "",
      reseller_id: "",
      tenure: [],
      reportingEmail: getReportingEmail(),
      uploadHistoryToggle: "excel-upload",
      vendorList: "",
      vendorID: null,
      vendorName: null,
      open: false,
    };
    this.fileRender = this.fileRender.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.validateHeader = this.validateHeader.bind(this);
    this.validateExcelData = this.validateExcelData.bind(this);
    this.onSubmitHandle = this.onSubmitHandle.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.onRadioHandler = this.onRadioHandler.bind(this);
    this.onVendorSelect = this.onVendorSelect.bind(this);
    this.debouncedFetchData = debounce(this.getVendor, 1000);
    this.addVendorHandler = this.addVendorHandler.bind(this);
    this.onAddVendor = this.onAddVendor.bind(this);
    this.onAddVendor = this.onAddVendor.bind(this);
    this.onVendorClear = this.onVendorClear.bind(this);
  }

  getResellerId() {
    return ApiGet(`me`).then((res) => {
      if (res.status === "success") {
        this.setState({
          reseller_id: res.data.basic.reseller_id,
          userEmail: res.data.email,
          params: {
            ...this.state.params,
            emailRecipients: res.data.email,
          },
        });
        const encData = Encryption(
          this.state.reseller_id,
          RESELLER_ENCRYPTION_KEY
        );
        this.setState({ encResellerId: encData });
      }
      return res;
    });
  }

  // submit handle
  onSubmitHandle() {
    const {
      params,
      filename,
      encResellerId,
      parseErrorData,
      reseller_id,
      vendorID,
    } = this.state;
    const { request, transactionType } = this.props;
    AppStore.dispatch({ type: "LOADING", loading: true });
    if (params.uploadExcelDtoList?.length > 0) {
      // json to xlsx
      const ws = XLSX.utils.json_to_sheet([
        ...params.uploadExcelDtoList,
        ...parseErrorData,
      ]);
      // remove blank row

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      const wbout = XLSX.write(wb, {
        bookSST: true,
        type: "binary",
        compression: true,
      });

      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };
      const file1 = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // blob to xlsx file
      const file = new File([file1], filename, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const resellerID =
        transactionType === "STOCK_IN" ? vendorID : reseller_id;

      const data = {
        file: file,
        token: encResellerId,
        stockType: transactionType,
        resellerId: resellerID,
        emailRecipients: params.emailRecipients,
      };

      inventoryStockInOutUploader(data, request)
        .then((res) => {
          if (res.code === 200) {
            setMessage("Excel file uploaded successfully", () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              this.props.history.push("/inventory");
            });
          } else {
            setError(res.message, () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              this.props.history?.push("/inventory");
            });
          }
        })
        .catch(() => {
          setError("Something went wrong", () => {
            AppStore.dispatch({ type: "LOADING", loading: false });
            this.props.history.push("/inventory");
          });
        });
    } else {
      setError("no data found for the upload", () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  }

  // header match validation
  validateHeader = async () => {
    const { data, cols } = this.state;
    const { headers } = this.props;
    const header = data[0];
    let myHeaders = headers?.filter((item) => item.name !== "Error Message");
    const headerLength = header.length;
    const headersLength = myHeaders.length;
    if (headerLength === headersLength) {
      for (let i = 0; i < headerLength; i++) {
        const headerName = header[i];
        const headerObj = myHeaders[i].name;
        if (headerName.toUpperCase() === headerObj.toUpperCase()) {
          cols.push(headerObj);
        } else {
          return `${headerName} is not match with ${headerObj}`;
        }
      }
      return true;
    } else {
      return "headers not match please download sample excel file for reference";
    }
  };

  validateDateCheck = (dateString, format = "DD/MM/YYYY") =>
    moment(dateString, format, true).isValid();

  expiryCheckDate = (dateString, format = "DD/MM/YYYY") => {
    const date = moment(dateString, format);
    return date.isBefore(moment(), "day");
  };

  validateExcelData = async () => {
    const { headers, transactionType } = this.props;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { data } = this.state;
    data[0].push("Error_Message");
    const errDataRow = [data[0]];
    const succData = [];
    let errMessage = [];
    let tenureData = [];
    let parseErrorData = [];
    let errCount = 0;
    let errorCountIndex = 0;
    headers?.filter((item) => (item.columnColor = []));
    data?.map((row, index) => {
      if (index > 0) {
        let count = 0;
        let obj = {};
        headers?.map((header, i) => {
          if (header.name === "Reseller_id" && transactionType === "STOCK_IN") {
            row[i] = this.state.reseller_id;
          }
          if (header.mandatory) {
            if (row[i] === undefined || row[i] === "") {
              errCount = 1;
              errMessage.push("Please fill " + header.name);
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else if (
              header.type &&
              header.type === "number" &&
              /^[0-9]+$/.test(row[i]) === false
            ) {
              errCount = 1;
              errMessage.push("Please fill valid " + header.name);
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else if (
              header.type &&
              header.type === "date" &&
              !this.validateDateCheck(row[i])
            ) {
              errCount = 1;
              errMessage.push(header.name + " is invalid");
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else if (
              header.name === "Expiry_date" &&
              this.expiryCheckDate(row[i])
            ) {
              errCount = 1;
              errMessage.push("Expiry date can not be previous date");
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else if (
              header.name === "STOCK_IN" &&
              row[i] < 1 &&
              row[i] > 20000
            ) {
              errCount = 1;
              errMessage.push(
                "Stock should be greater than 0 and less than 20000"
              );
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else {
              count++;
              Object.assign(obj, { [header.name]: row[i] });
            }
          } else {
            count++;
            Object.assign(obj, { [header.name]: row[i] });
          }
        });
        if (count === headers.length) {
          Object.assign(obj, { ["Error_Message"]: "" });
          succData.push(obj);
        }

        if (errCount === 1) {
          row[headers.length] = errMessage
            .map((err) => {
              return err;
            })
            .join(", ");
          errDataRow.push(row);
          errCount = 0;
          errMessage = [];
          Object.assign(obj, { ["Error_Message"]: row[headers.length] });
          parseErrorData.push(obj);
          errorCountIndex = errorCountIndex + 1;
        }
      }
      // count = 0;
    });
    const { params } = this.state;
    this.setState({
      parseErrorData: parseErrorData,
      errorDataRow: errDataRow,
      tenure: tenureData,
    });
    this.setState({ params: { ...params, uploadExcelDtoList: succData } });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  //  xls file validation

  fileRender = async (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    // return promise function
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellText: false,
          cellDates: true,
          dateNF: "dd/mm/yyyy;@",
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
          raw: false,
          dateNF: "dd/mm/yyyy",
        });

        /* Update state */
        this.setState({ data: data });
        if (data.length > inventoryStockExcelUploaderLimit + 1) {
          setError(
            `Excel file should not be more than ${inventoryStockExcelUploaderLimit} rows`,
            () => {}
          );
          return resolve(false);
        } else if (data.length === 0) {
          setError("Excel file should not be empty", () => {});
          return resolve(false);
        } else {
          return resolve(true);
        }
      };
    });
  };

  onChangeHandle(e) {
    this.setState({ data: [], parseErrorData: [], errorDataRow: [] });
    const { params } = this.state;
    AppStore.dispatch({ type: "BACKDROP", loading: false });
    AppStore.dispatch({ type: "LOADING", loading: true });
    const file = e?.dataTransfer?.files[0]
      ? e?.dataTransfer?.files[0]
      : e?.target?.files[0];
    // get file size
    const fileSize = file.size;

    const ext = file.name.split(".").pop();

    if (fileSize > inventoryExcelUploaderFileSize) {
      setError("File size should not be more than 4 MB", () => {});
      // this.setState({ errMsg: "File size should be less than 4MB" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else if (ext === "xlsx") {
      this.fileRender(file).then((res) => {
        if (res) {
          this.setState({
            params: { ...params, file: file },
            filename: file.name,
            errMsg: "",
          });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
      });
    } else {
      setError("Please select only xlsx file", () => {});
      // this.setState({ errMsg: "Please select only xlsx file" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  }

  handleButtonTitle() {
    const { current } = this.state;
    if (current === 1) {
      return "Next";
    } else if (current === 2) {
      if (this.state.parseErrorData?.length > 0) {
        return "Continue With Error";
      }
      return "Next";
    } else if (current === 3) {
      return "Upload";
    }
  }
  //Drag and Drop
  handleDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  }

  handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.onChangeHandle(event);
  }

  handleDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    // AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  }

  // create and download xlsx file from array of data
  downloadFile = () => {
    const { errorDataRow } = this.state;
    const ws = XLSX.utils.json_to_sheet(errorDataRow, {
      skipHeader: true,
      cellStyles: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    FileSaver.saveAs(data, "MismatchDataFile.xlsx");
  };

  componentDidMount() {
    this.getResellerId();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // this.getProductGroupId();
  }

  resize() {
    if (window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  onRadioHandler(value) {
    this.setState({ uploadHistoryToggle: value });
  }

  onVendorSelect(value, item) {
    this.setState({ vendorID: value, vendorName: "" });
  }

  getVendor = async () => {
    if (this.state?.vendorName.length > 2)
      await getApiClaim(`get_vendor/${this.state?.vendorName}`)
        .then((response) => {
          if (response.code === 200) {
            const newArray = response?.data.map((obj) => {
              return { ...obj, label: obj.name, value: obj.id }; // Creating a new object with modified property
            });
            this.setState({ vendorList: newArray });
          }
        })
        .catch((error) => console.log(error));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.vendorName !== this.state.vendorName) {
      this.debouncedFetchData();
    }
  }

  onVendorInput(value) {
    const reg = /^[a-zA-Z 0-9]+$/;
    const userInput = value;
    if (userInput === "" || (reg.test(userInput) && userInput?.length <= 32)) {
      this.setState({
        vendorName: value,
      });
    }
  }

  onVendorClear() {
    this.setState({ vendorID: "", vendorName: "" });
  }

  addVendorHandler() {
    this.setState({ open: true });
  }

  onAddVendor(data) {
    const newObject = { ...data, label: data.name, value: data.id };
    this.setState({
      open: false,
      vendorList: [newObject],
      vendorID: data.id,
      vendorName: data.name,
    });
  }

  render() {
    const { params } = this.state;
    const { sampleFile, fileName, headers, transactionType } = this.props;

    console.log(this.state, "ssssss");

    const steps = [
      { title: "Upload Excel File" },
      { title: "Preparation" },
      { title: "Upload" },
    ];

    return (
      <>
        <SampleHeader samplefile={sampleFile} fileName={fileName} />
        <div className="flex h-80">
          <div className="mb-14 w-1/5">
            {this.state.isMobile ? (
              <VerticalStepper
                activeStep={this.state.current - 1}
                steps={steps}
                isMobile={true}
              />
            ) : (
              <VerticalStepper
                steps={steps}
                activeStep={this.state.current - 1}
                isMobile={false}
              />
            )}
          </div>
          <div className="w-4/5">
            <div>
              {this.state.current === 1 && (
                <>
                  {transactionType === "STOCK_IN" && (
                    <div className="flex justify-center items-center flex-col">
                      <InputLabel label="Vendor name" requiredMark />

                      <SelectPicker
                        data={this.state.vendorList || []}
                        value={this.state.vendorID}
                        placeholder="Select vendor"
                        style={{
                          width: 230,
                          marginTop: "8px",
                        }}
                        onSearch={(searchKey, e) =>
                          this.onVendorInput(searchKey)
                        }
                        onSelect={(value, item) => {
                          this.onVendorSelect(value, item);
                        }}
                        onClean={this.onVendorClear}
                        renderMenuItem={(value, obj) => {
                          return (
                            <div className="border-b-2 p-1 text-xs space-y-2">
                              <div>
                                <span className="font-bold text-sm">
                                  {value}
                                </span>{" "}
                                <span className="float-right">#{obj?.id}</span>
                              </div>
                              {obj?.mobile && <div>mobile : {obj?.mobile}</div>}
                              {obj?.address && (
                                <div>address : {obj?.address}</div>
                              )}
                            </div>
                          );
                        }}
                        renderExtraFooter={() => {
                          return (
                            <div className="w-full text-center">
                              <Button
                                attrs={{
                                  onClick: this.addVendorHandler,
                                }}
                                title="+ Add new vendor"
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <div className="flex flex-col justify-center items-center mt-10">
                      <label>
                        {!this.state.params.file ? "Upload File " : "File Name"}
                        <code className="text-red">*</code>
                      </label>
                      <div className="flex items-center justify-center bg-grey-lighter mt-5">
                        <label
                          className={`relative flex pl-3 items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-red ${
                            !this.state.filename ? "cursor-pointer" : ""
                          } hover:bg-red hover:text-white`}
                        >
                          {!this.state.filename ? (
                            <>
                              <InputFile
                                file={this.state.params.file}
                                onChangeHandler={this.onChangeHandle}
                              />
                            </>
                          ) : (
                            <>
                              <div className="m-1 mr-3 flex relative">
                                {this.state.filename.length > 30 ? (
                                  <span className="text-sm">
                                    {this.state.filename.substring(0, 30)}
                                    ...
                                  </span>
                                ) : (
                                  <span className="text-sm">
                                    {this.state.filename}
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                        </label>

                        {this.state.filename ? (
                          <span>
                            <XIcon
                              className="h-6 w-6 ml-3 text-red cursor-pointer"
                              onClick={() => {
                                this.setState({
                                  filename: "",
                                  params: {
                                    ...params,
                                    file: "",
                                  },
                                  errMsg: "",
                                  data: [],
                                });
                              }}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className="flex justify-center mt-2 "
                      style={{ fontWeight: "600" }}
                    >
                      Or Drop Excel here
                    </div>
                    <div className="flex justify-center mt-2">
                      {this.state?.errMsg ? (
                        <span>{this.state?.errMsg}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}

              {this.state.current === 2 && (
                <div className="flex flex-col space-y-6 border-2 p-3">
                  <div className="md:flex justify-between block">
                    <p className="ml-4">
                      Total data :{" "}
                      <span className="text-green-500">
                        {this.state.params.uploadExcelDtoList.length +
                          this.state?.parseErrorData?.length}
                      </span>
                    </p>
                    <p className="ml-4">
                      Total data ready to be imported :{" "}
                      <span className="text-green-500">
                        {this.state.params.uploadExcelDtoList.length}
                      </span>
                    </p>

                    <p className="ml-4">
                      Unmatched data count :{" "}
                      <span className="text-red-500">
                        {this.state?.parseErrorData?.length}
                      </span>
                    </p>
                  </div>

                  {this.state.errorDataRow.length > 1 ? (
                    <>
                      <hr />
                      <div className="flex flex-wrap justify-between mt-3 items-center p-1 pr-2 pl-2 rounded-md">
                        <div
                          className="flex cursor-pointer"
                          onClick={() =>
                            this.setState({
                              toggleErrData: !this.state.toggleErrData,
                            })
                          }
                        >
                          <ChevronRightIcon
                            className={`h-5 w-6 pt-1 cursor-pointer text-red ${
                              this.state.toggleErrData
                                ? "transform rotate-90 duration-200"
                                : "transform rotate-0 duration-200"
                            }`}
                          />

                          <p>
                            Total error count in row :{" "}
                            <span className="text-yellow-400">
                              {this.state?.parseErrorData?.length}
                            </span>
                          </p>
                        </div>
                        <div>
                          <span className="flex">
                            Download unmatched data excel file{" "}
                            <DownloadIcon
                              onClick={this.downloadFile}
                              className=" h-5 w-5 text-red ml-2 cursor-pointer"
                            />
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="relative overflow-hidden">
                    {this.state.toggleErrData ? (
                      <ErrorDataTable
                        header={headers}
                        data={this.state?.parseErrorData}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>

            {this.state.current === 3 && (
              <>
                By default messages are sent to the users email{" "}
                {<span className="text-blue-500">{this.state.userEmail}</span>}.
                You can also send them to a specific email address.
                <div className="flex mt-4">
                  <InputGroup
                    label="Email"
                    placeholder="Enter email..."
                    attrs={{
                      maxLength: 64,
                      value: this.state.email,
                      onChange: (e) => {
                        this.setState({
                          email: e.target.value,
                        });
                      },
                    }}
                    validateError={(value) => {
                      if (validateEmail(value)) {
                        return false;
                      } else {
                        return "please enter correct email ID";
                      }
                    }}
                  />
                  <Button
                    className="m-2 mt-7"
                    outline={true}
                    title={"Add"}
                    attrs={{
                      onClick: this.emailChangeHandler,
                    }}
                  />
                </div>
                {this.state.params.emailRecipients.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      flexWrap: "wrap",
                    }}
                  >
                    {
                      // comma separated list of email recipients
                      this.state.params.emailRecipients
                        .split(",")
                        .map((item, index) => (
                          <div className="flex" key={index}>
                            <span className="text-sm">{item}</span>
                            <TrashIcon
                              className="h-5 w-6 text-red cursor-pointer"
                              onClick={() => {
                                this.removeEmail(index);
                              }}
                            />
                          </div>
                        ))
                    }
                  </div>
                )}
                {this.state.reportingEmail &&
                  this.state.reportingEmail.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        flexWrap: "wrap",
                      }}
                    >
                      {" "}
                      <span className="text-sm">
                        {this.state.reportingEmail}
                      </span>
                    </div>
                  )}
              </>
            )}

            <div className="relative md:mr-10 flex justify-around mt-10 bottom-0 mb-6 right-0">
              {this.state.current > 1 ? (
                <Button
                  title="Previous"
                  outline={true}
                  attrs={{
                    onClick: () => {
                      if (this.state.current > 1) {
                        this.setState({
                          current: this.state.current - 1,
                        });
                      }
                      if (this.state.current === 2) {
                        this.setState({
                          filename: "",
                          file: "",
                          params: {
                            ...params,
                            file: "",
                          },
                          current: this.state.current - 1,
                        });
                      } else if (this.state.current === 3) {
                        this.setState({
                          current: this.state.current - 1,
                        });
                      }
                    },
                  }}
                />
              ) : (
                <div></div>
              )}

              <Button
                outline={true}
                title={this.handleButtonTitle()}
                attrs={{
                  onClick: this.onClickHandler,
                  disabled: this.disabledHandler(),
                }}
              />
            </div>
            {this.state.current === 1 ? (
              <div className="border-2 m-3 mt-10">
                <span className="flex m-2 text-red-500">Notes -</span>
                <ul className="list-disc list-inside m-2">
                  <li>
                    <span className="text-sm justify-center">
                      The file should be in xlsx format.
                    </span>
                  </li>
                  <li>
                    <span className="text-sm text-right">
                      The file should have the same structure as the sample
                      file.
                    </span>
                  </li>
                  <li>
                    <span className="text-sm justify-center">
                      The file should have the same date format as dd/mm/yyyy.
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal
          size="xs"
          open={this.state.open}
          onClose={() =>
            this.setState({
              open: false,
            })
          }
        >
          <Modal.Header>Add new vendor</Modal.Header>
          <Modal.Body>
            <AddVendor
              callBack={this.onAddVendor}
              handleClose={(e) => {
                this.setState({
                  open: false,
                });
              }}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }

  disabledHandler = () => {
    const { current, params, vendorID, vendorName } = this.state;
    const { transactionType } = this.props;

    if (
      transactionType === "STOCK_IN" &&
      current === 1 &&
      vendorID &&
      params.file
    ) {
      return false;
    } else if (
      transactionType === "STOCK_OUT" &&
      current === 1 &&
      params.file
    ) {
      return false;
    } else if (
      current === 2 &&
      params.file &&
      params.uploadExcelDtoList.length > 0
    ) {
      return false;
    } else if (
      current === 3 &&
      params.file &&
      params.emailRecipients.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  onClickHandler = (e) => {
    const { current } = this.state;
    if (current == 1) {
      this.validateHeader().then((res) => {
        if (res == true) {
          this.validateExcelData().then((res) => {
            this.setState({ current: this.state.current + 1 });
          });
        } else {
          setError(res, () => {});
        }
      });
    } else if (current == 2) {
      this.setState({ current: this.state.current + 1 });
    } else if (current == 3) {
      this.onSubmitHandle();
    }
  };

  emailChangeHandler = () => {
    if (validateEmail(this.state.email)) {
      // check if email is already present in the list
      if (
        this.state.params.emailRecipients
          .split(",")
          .indexOf(this.state.email) === -1
      ) {
        let email = this.state.params.emailRecipients;
        if (email.length > 0) {
          email = email + "," + this.state.email;
        } else {
          email = this.state.email;
        }
        this.setState({
          params: {
            ...this.state.params,
            emailRecipients: email,
          },
          email: "",
        });
      } else {
        this.setState({
          email: "",
        });
      }
    }
  };

  removeEmail = (index) => {
    let email = this.state.params.emailRecipients;
    let emailArray = email.split(",");
    emailArray.splice(index, 1);
    email = emailArray.join(",");
    this.setState({
      params: {
        ...this.state.params,
        emailRecipients: email,
      },
    });
  };
}

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    reseller_id: state.reseller_id,
    products: state.products,
    user: user,
  };
};

export default withRouter(connect(mapStateToProps)(Uploader));
