import React from "react";
import "./style.css";
import { Pagination, Table } from "rsuite";

import {
  convertNumberToShortFormat,
  customHeader,
  percentageCalculation,
  primaryColor,
} from "./constant";
import { Tooltip, Zoom } from "@mui/material";
const { Column, ColumnGroup, HeaderCell, Cell } = Table;
const headerStyle = {
  background: primaryColor,
  color: "white",
  fontWeight: "bold",
  fontSize: "14px",
  textAlign: "center",
};

const ComparisonTable = ({ comparisonData, filter }) => {
  const Headers = comparisonData?.header;
  const TableData = comparisonData?.data;
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const getData = (data) => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === "string") {
          x = x.replace(/\s/g, "").toLowerCase();
        }
        if (typeof y === "string") {
          y = y.replace(/\s/g, "").toLowerCase();
        }
        if (typeof x === "string" && typeof y === "string")
          return sortType === "asc" ? x.localeCompare(y) : y.localeCompare(x);
        else {
          if (sortType === "asc") {
            return x - y;
          } else {
            return y - x;
          }
        }
      });
    }
    return data;
  };
  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  if (TableData?.length === 0)
    return (
      <div
        style={{
          textAlign: "center",
          fontSize: "20px",
          marginBottom: "10px",
          marginTop: "5px",
        }}
      >
        <label>No data found</label>
      </div>
    );
  return (
    <>
      <Table
        bordered
        cellBordered
        height={420}
        headerHeight={80}
        data={() => getData(TableData || [])}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
      >
        <Column width={70} align="center" fixed>
          <HeaderCell style={headerStyle}>Sr.No</HeaderCell>
          <Cell>{(rowData, index) => `${index + 1}`}</Cell>
        </Column>

        <Column width={180} colSpan={2} align="center" sortable fixed resizable>
          <HeaderCell style={headerStyle}>Reseller Name</HeaderCell>
          <Cell dataKey="resellerName" />
        </Column>

        <Column width={180} colSpan={2} align="center" sortable fixed resizable>
          <HeaderCell style={headerStyle}>Reporting To</HeaderCell>
          <Cell dataKey="reportingTo" />
        </Column>
        <Column width={180} colSpan={2} align="center" sortable fixed resizable>
          <HeaderCell style={headerStyle}>Product Type</HeaderCell>
          <Cell dataKey="productType" />
        </Column>
        <ColumnGroup header={"Growth"} align="center">
          <Column width={200} colSpan={1} sortable align="center">
            <HeaderCell
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "black",
              }}
            >
              Total amount (%)
            </HeaderCell>
            <Cell dataKey="policyAmount">
              {(rowData) => {
                let oldSale =
                  rowData?.comparisonData?.[Headers?.[0]]?.policyAmount ?? 0;
                let newSale =
                  rowData?.comparisonData?.[Headers[Headers.length - 1]]
                    ?.policyAmount ?? 0;
                return percentageCalculation(oldSale, newSale);
              }}
            </Cell>
          </Column>
          <Column width={170} colSpan={2} sortable align="center">
            <HeaderCell style={{ fontWeight: "600", fontSize: "14px" }}>
              Total count (%)
            </HeaderCell>
            <Cell dataKey="policyCount">
              {(rowData) => {
                let oldSale =
                  rowData?.comparisonData?.[Headers?.[0]]?.policyCount ?? 0;
                let newSale =
                  rowData?.comparisonData?.[Headers[Headers.length - 1]]
                    ?.policyCount ?? 0;
                return percentageCalculation(oldSale, newSale);
              }}
            </Cell>
          </Column>
        </ColumnGroup>
        {Headers?.map((header, index) => {
          return (
            <ColumnGroup
              header={customHeader(header)}
              align="center"
              key={index}
            >
              <Column width={170} colSpan={2} align="right">
                <HeaderCell
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Total amount (Rs/-)
                </HeaderCell>
                <Cell dataKey="policyAmount">
                  {(rowData) => {
                    let value = rowData?.comparisonData?.[header]?.policyAmount;
                    return value ? (
                      <>
                        {" "}
                        <Tooltip
                          title={value.toLocaleString("en-IN")}
                          placement="top"
                          arrow
                          TransitionComponent={Zoom}
                        >
                          {convertNumberToShortFormat(value)}
                        </Tooltip>
                      </>
                    ) : (
                      0
                    );
                  }}
                </Cell>
              </Column>
              <Column width={170} colSpan={2} sortable align="right">
                <HeaderCell style={{ fontWeight: "600", fontSize: "14px" }}>
                  Total count
                </HeaderCell>
                <Cell dataKey="policyCount">
                  {(rowData) => {
                    let value = rowData?.comparisonData?.[header]?.policyCount;
                    return value ? value : 0;
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
          );
        })}
      </Table>
      <div className="mt-2">
        <Pagination
          size="xs"
          layout={["total"]}
          total={TableData?.length || 0}
        />
      </div>
    </>
  );
};

export default ComparisonTable;
