import React from "react";
import { Modal } from "rsuite";
import Button from "../../components/Button";

const CustomModal = ({ open, handleClose, handleSuccess }) => {
  return (
    <Modal
      maxWidth="md"
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal.Header>
        <Modal.Title style={{ fontWeight: "600" }}>
          {open?.blob?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this document?</Modal.Body>
      <Modal.Footer className="flex justify-between">
        <Button
          title=""
          display="inline-block"
          style={{ backgroundColor: "#db2228", color: "white" }}
          attrs={{
            onClick: () => handleSuccess(),
          }}
        >
          Yes
        </Button>
        <Button
          outline={true}
          title=""
          display="inline-block"
          style={{ backgroundColor: "lightGray", color: "black" }}
          attrs={{
            onClick: handleClose,
          }}
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
