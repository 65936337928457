import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageViewModal({
  open,
  onConfirm,
  handleClose,
  children,
  imageLoading,
  isDisabledButton,
}) {
  return (
    <React.Fragment>
      <BootstrapDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {isDisabledButton
            ? "Uploaded Document"
            : "Are you sure want to upload ?"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>{children}</DialogContent>
        {!isDisabledButton && (
          <DialogActions>
            <Button
              onClick={onConfirm}
              style={{
                background: isDisabledButton ? "darkgrey" : "green",
                color: "white",
                fontWeight: "500",
              }}
              disabled={isDisabledButton}
            >
              Confirm
            </Button>
            <Button
              onClick={handleClose}
              style={{
                background: isDisabledButton ? "darkgrey" : "#DB2228",
                color: "white",
                fontWeight: "500",
              }}
              disabled={isDisabledButton}
            >
              Cancel
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </React.Fragment>
  );
}
