import React from "react";
import "./style.css";

const CustomGif = () => {
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">Oops! Something went wrong </h1>
              </div>

              <div className="contant_box_404">
                <h3 className="text-2xl font-medium text-black ">
                  Look like you're lost
                </h3>
                <p className="text-xl font-medium text-black mb-6">
                  The page you are looking for not available!
                </p>
                <a href="/" className="link_404">
                  Go to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomGif;
