import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Table from "../../components/common/Table";
import Main from "../Main";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { DatePicker, Placeholder, SelectPicker } from "rsuite";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UserIcon from "../../images/user.png";
import { getApiClaim, postApiClaim } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import InputLabel from "../../components/form/InputLabel";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import Button from "../../components/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import BranchSearchPicker from "../InventoryStock/component/BranchSearchPicker";
import { debounce } from "lodash";

const TableDataHeaders = [
  { label: "Product Name", dataKey: "productName" },
  { label: "Stock In", dataKey: "stockIn" },
  { label: "Stock Out", dataKey: "stockOut" },
  { label: "Batch Number", dataKey: "batchNumber" },
  {
    label: "Transaction No.",
    dataKey: "transactionNo",
  },
  {
    label: "Sku",
    dataKey: "sku",
  },
  {
    label: "Batch Number",
    dataKey: "batchNumber",
  },
  {
    label: "Expiry Date",
    dataKey: "expiryDate",
  },
  {
    label: "Credit Name",
    dataKey: "creditName",
  },
];

const ExcelUploadPolicyReport = (props) => {
  const state = useSelector((state) => state);
  const user = state.user;
  const location = useLocation();
  const renderComponent = location?.state && location?.state?.componentTo;
  const MessageRef = React.useRef();
  const history = useHistory();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [branchId, setBranchId] = useState("");
  const [branchList, setBranchList] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [transactionDataCount, setTransactionDataCount] = useState(null);
  const [transactionDataDataPagination, setTransactionDataPagination] =
    useState(1);
  const [tableData, setTableData] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [singleId, setSingleId] = useState(null);
  const [tableDataCount, setTableDataCount] = useState(null);
  const [tableDataLoader, setTableDataLoader] = useState(false);
  const [tableDataPagination, setTableDataPagination] = useState({
    size: 5,
    page: 1,
  });
  const [branchInput, setBranchInput] = useState("");
  const [branchLoading, setBranchLoading] = useState(false);
  const [branchPage, setBranchPage] = useState(0);
  const [count, setCount] = useState(0);

  const onAccordClick = (isExpanded, id) => {
    if (isExpanded) {
      setExpanded(id);
      setSingleId(id);
    } else {
      setExpanded("");
    }
  };
  const fetchTransactionData = async () => {
    if (branchId === null || branchId === "") return;
    AppStore.dispatch({ type: "LOADING", loading: true });
    let data = {
      page: transactionDataDataPagination - 1,
      size: 5,
      vendorResellerId: branchId,
      startDate: moment(fromDate).format("YYYY-MM-DD"),
      endDate: moment(toDate).format("YYYY-MM-DD"),
    };
    await postApiClaim("ho_to_branch_view_inventory", data)
      .then((response) => {
        if (response?.code === 200) {
          setTransactionData(response.data);
          setTransactionDataCount(response.count);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => AppStore.dispatch({ type: "LOADING", loading: false }));
  };

  const getBranches = async (page) => {
    if (!user.id) return;
    setBranchLoading(true);
    let data = {
      resellerId: user.id,
      page: page ? page : branchPage,
      size: 20,
    };
    await postApiClaim(`get_child_resellers?searchKey=${branchInput}`, data)
      .then((response) => {
        if (response?.code === 200) {
          const newArray = [];
          response?.data.map((obj) => {
            const exists = branchList.some((item) => item.value === obj.id);
            if (!exists) {
              newArray.push({
                ...obj,
                label: `${obj?.resellerName} (${obj?.mobile})`,
                value: obj?.id,
              });
            }
          });
          setBranchList(() => [...branchList, ...newArray]);
          setCount(response?.count);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setBranchLoading(false));
  };
  const tableDataPageChange = (page) => {
    setTableDataPagination({ ...tableDataPagination, page: page });
  };
  const handleLoadMore = () => {
    setTransactionDataPagination((prev) => prev + 1);
  };
  useEffect(() => {
    getBranches();
    setBranchId(user.id);
  }, []);
  const getBranchDebounceFunction = useCallback(debounce(getBranches, 1000), [
    branchInput,
  ]);

  useEffect(() => {
    fetchTransactionData();
  }, [transactionDataDataPagination]);
  useEffect(() => {
    if (singleId === null) return;
    setTableDataLoader(true);
    const fetchSingleTransactionData = async (id) => {
      let data = {
        page: tableDataPagination?.page - 1,
        size: 5,
        vendorResellerId: branchId,
        startDate: moment(fromDate).format("YYYY-MM-DD"),
        endDate: moment(toDate).format("YYYY-MM-DD"),
      };
      await postApiClaim(`stock_view_detail/${id}`, data)
        .then((response) => {
          if (response.code === 200) {
            setTableData(response.data);
            setTableDataCount(response.count);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setTableDataLoader(false));
    };

    fetchSingleTransactionData(singleId);
  }, [singleId, tableDataPagination]);
  useEffect(() => {
    getBranchDebounceFunction();
    return getBranchDebounceFunction.cancel;
  }, [branchInput]);

  const onRefesh = () => {
    setTransactionData([]);
    setTableData([]);
    setTableDataCount(null);
    setTransactionDataCount(null);
    setBranchId("");
    setFromDate(null);
    setToDate(null);
    // setTransactionDate(null);
  };

  const getBranchesScroll = () => {
    getBranches(branchPage + 1);
    setBranchPage(branchPage + 1);
  };
  const onBranchSearchHandler = (value) => {
    if (value?.length > 2 && /^[a-zA-Z0-9\s_]+$/.test(value))
      setBranchInput(value);
  };
  return (
    <Main MessageRef={MessageRef}>
      <AppBackButton to="inventory" />
      <p
        className="text-center font-semibold text-lg"
        style={{ color: "#DB2228" }}
      >
        Medicine inventory details
      </p>
      <div className="flex items-center flex-wrap justify-between mt-10">
        <div className="flex flex-wrap gap-4">
          <div style={{ width: "150px", paddingTop: "8px" }}>
            <InputLabel label={"Branch"} requiredMark />
            <BranchSearchPicker
              data={branchList}
              name="branchId"
              value={branchId}
              onChange={(value) => setBranchId(value)}
              request={getBranchesScroll}
              count={count}
              onSearch={onBranchSearchHandler}
              loading={branchLoading}
            />
          </div>
          {/* <div className="mb-3">
            <InputLabel label={"Head office"} requiredMark />
            <div className="mt-2 mb-2">
              <SelectPicker
                data={[{ label: user.name, value: user.id }]}
                searchable={false}
                value={branchId}
                onChange={(value) => {
                  setBranchId(value);
                }}
                defaultValue={user.id}
                style={{
                  width: 200,
                }}
                placeholder="Select"
                renderMenuItem={(value, obj) => {
                  return (
                    <div
                      className="border-b-2 p-1 text-xs space-y-2"
                      style={{ width: "175px" }}
                    >
                      <div>
                        <span className="font-bold text-sm">{value}</span>{" "}
                        {obj?.value && (
                          <span className="float-right font-bold">
                            Id : {obj?.value}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }}
                className={`${true ? "border-primary" : ""}`}
              />
            </div>
          </div> */}
          <div className="mb-5">
            <InputLabel label="From" requiredMark />
            <div className="mt-2 flex space-x-4">
              <DatePicker
                appearance="default"
                style={{
                  height: "43px",
                }}
                name="date"
                oneTap
                placeholder="DD-MM-YYYY"
                format="dd-MM-yyyy"
                value={fromDate ? new Date(fromDate) : null}
                onChange={(value) => setFromDate(value)}
              />
            </div>
          </div>
          <div className="mb-5">
            <InputLabel label="To" requiredMark />
            <div className="mt-2 flex space-x-4">
              <DatePicker
                appearance="default"
                style={{
                  height: "43px",
                }}
                name="date"
                oneTap
                placeholder="DD-MM-YYYY"
                format="dd-MM-yyyy"
                value={toDate ? new Date(toDate) : null}
                onChange={(value) => setToDate(value)}
              />
            </div>
          </div>
          <Tooltip title="Search" placement="right-start" arrow>
            <div className="mt-5">
              <Button
                className="mt-3.5 px-4 py-2"
                paddingX="4"
                paddingY="1"
                title=""
                attrs={{
                  onClick: () => fetchTransactionData(),
                  disabled: !branchId || !fromDate || !toDate,
                }}
              >
                <SearchIcon style={{ fontWeight: "600" }} />
              </Button>
            </div>
          </Tooltip>
          <Tooltip title="Refresh" placement="right-start" arrow>
            <div className="mt-5">
              <div>
                <Button
                  className="mt-3.5 px-4 py-2"
                  paddingX="4"
                  paddingY="1"
                  title=""
                  attrs={{
                    onClick: () => onRefesh(),
                    disabled: !branchId || !fromDate || !toDate,
                  }}
                >
                  <RefreshIcon style={{ fontWeight: "600" }} />
                </Button>
              </div>
            </div>
          </Tooltip>
        </div>

        <button
          paddingX="4"
          paddingY="1"
          className="bg-primary-500 text-white p-2 rounded-xl mt-2 md:mt-0"
          onClick={() => {
            history.push(renderComponent);
          }}
        >
          Upload Data
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px",
        }}
      >
        {transactionData?.length > 0 ? (
          transactionData?.map((vendor, index) => {
            return (
              <>
                <Accordion
                  key={index}
                  className="mb-5"
                  style={{
                    boxShadow:
                      "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;",
                    color: "black",
                    borderColor: "black",
                  }}
                  expanded={expanded == vendor?.masterId}
                  onChange={(e, expanded) => {
                    onAccordClick(expanded, vendor?.masterId);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1a-content"
                  >
                    <div className="w-full flex flex-wrap justify-between">
                      <div className="flex flex-wrap justify-evenly items-center">
                        <img
                          src={UserIcon}
                          alt="profile.jpg"
                          className="h-9 w-9 mr-2"
                        />
                        <h4
                          style={{
                            textTransform: "capitalize",
                            fontWeight: "600",
                          }}
                        >
                          {vendor?.name}
                        </h4>
                      </div>
                      <div className="flex flex-wrap items-center gap-4">
                        <div className="flex gap-1 flex-wrap items-center">
                          <h3 className="text-center font-bold">
                            Transaction Date:{" "}
                          </h3>
                          <h5 className="ml-1 mr-2">
                            {vendor?.transactionDate
                              ? moment(vendor?.transactionDate).format(
                                  "DD-MM-YYYY"
                                )
                              : "---"}
                          </h5>
                        </div>
                        <div className="flex gap-1 flex-wrap items-center">
                          <h3 className="text-center font-bold">
                            Branch number :{" "}
                          </h3>
                          <h5 className="ml-1 mr-2">
                            {vendor?.branchNumber ? vendor?.branchNumber : "--"}
                          </h5>
                        </div>
                        <div className="flex gap-1 flex-wrap items-center">
                          <h3 className="text-center font-bold">
                            Master id :{" "}
                          </h3>
                          <h5 className="ml-1 mr-2">
                            {vendor?.masterId ? vendor.masterId : "---"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <div
                    style={{
                      width: "95%",
                      margin: "auto",
                    }}
                  >
                    <hr />
                  </div>
                  <AccordionDetails>
                    <div className="relative overflow-hidden">
                      <Table
                        headers={TableDataHeaders}
                        data={tableData}
                        total={tableDataCount}
                        loader={tableDataLoader}
                        page={tableDataPagination.page}
                        limit={tableDataPagination.size}
                        onPageChange={tableDataPageChange}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })
        ) : false ? (
          <>
            <Placeholder.Paragraph rows={8} />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <h2 style={{ fontSize: "18px" }}>No records found</h2>
          </div>
        )}
        {transactionData.length > 1 &&
          transactionDataCount !== transactionData.length && (
            <div className="flex flex-row-reverse">
              <Button
                paddingX="4"
                paddingY="1"
                className="mt-4"
                title="Load More"
                outline={true}
                attrs={{ onClick: () => handleLoadMore() }}
              />
            </div>
          )}
      </div>
    </Main>
  );
};

export default ExcelUploadPolicyReport;
