import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SampleHeader = ({ samplefile, fileName = "Samplefile.xlsx" }) => {
  return (
    <p className="text-center m-5">
      <FontAwesomeIcon className="arrow1" icon={faArrowRight}></FontAwesomeIcon>
      Download a{" "}
      <a
        href={samplefile}
        download={fileName}
        className="text-blue cursor-pointer"
      >
        sample .xlsx file{" "}
      </a>{" "}
      and compare it to your imported file to ensure you have the correct
      structure for it.
    </p>
  );
};

export default SampleHeader;
