import React from "react";
import { Placeholder } from "rsuite";
import { toIndianNumberFormat } from "../../../utils/formatNumber";

const SaleCountCard = ({ cardInfo = [], loading }) => {
  return (
    <>
      {cardInfo.map((item, index) => {
        return (
          <div
            key={index}
            className={
              "w-44 h-32 rounded-xl bg-primary-50 border border-primary p-4 w-34 text-primary-500 m-4"
            }
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 20px 36px 0px",
            }}
          >
            <h4
              style={{
                fontSize: "large",
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {item.label}
            </h4>
            {loading ? (
              <Placeholder.Paragraph rows={2} rowHeight={8} active />
            ) : item.rupeeSymbol ? (
              <div
                key={index}
                style={{
                  fontSize: "medium",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "black",
                }}
              >
                &#8377;
                {item.count ? toIndianNumberFormat(item.count) : 0}
              </div>
            ) : (
              <div
                key={index}
                style={{
                  fontSize: "medium",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "black",
                }}
              >
                {item.count ? toIndianNumberFormat(item.count) : 0}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SaleCountCard;
