import React from "react";

const CustomSelect = ({
  label,
  requiredMark,
  starMark,
  onChange,
  value,
  data,
  style,
  isMandatory = true,
  isSelect = true,
  disable = false,
}) => {
  return (
    <div>
      <label className="flex text-sm text-gray-700 justify-left pb-2">
        {label}
        {requiredMark || starMark ? (
          <span className="text-lg text-red-500">*</span>
        ) : (
          false
        )}
      </label>
      <select
        onChange={onChange}
        value={value}
        disabled={disable}
        style={style}
        className="focus:ring-0 focus:border-primary focus:bg-primary-50 block px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
      >
        {isMandatory && (
          <option value="" disabled selected>
            Select
          </option>
        )}
        {!isMandatory && <option value="ALL">ALL</option>}
        {data?.map((option, index) => {
          return (
            <option
              value={option.value}
              key={index}
              style={{ textTransform: "capitalize" }}
            >
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CustomSelect;
