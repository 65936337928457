import React from "react";
import AdminMain from "../admin/AdminMain";
import MedicineAllocate from "./MedicineAllocate";

const HomePageChemist = () => {
  return (
    <AdminMain >
      <MedicineAllocate />
    </AdminMain>
  );
};

export default HomePageChemist;
