import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import { ApiPost, setError, setMessage } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import AdminMain from "./AdminMain";
import { InputGroup } from "../../components/form/InputGroup";
import Button from "../../components/Button";
export class AddRole extends Component {
  BasicBtn = React.createRef();
  state = {
    errors: {},
    params: {
      role_name: "",
    },
  };

  render() {
    const { params, errors } = this.state;

    return (
      <AdminMain MessageRef={this.MessageRef}>
        <div className="w-11/12">
          <AppBackButton to="/">Back to Dashboard</AppBackButton>
        </div>
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            <AppHeading>Add Role</AppHeading>
            <div className="pt-10">
              <InputGroup
                error={errors.role_name}
                label="Enter Role Name"
                placeholder="Enter Role name..."
                attrs={{
                  value: params.role_name,
                  onChange: (e) => this.onChangeHandler(e, "role_name"),
                }}
                onEnter={this.submit}
              />

              <Button
                attrs={{ type: "button", onClick: this.submit }}
                size="md"
                title={"Submit"}
              />
            </div>
          </div>
        </div>
      </AdminMain>
    );
  }

  submit = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ errors: false });
    const response = await ApiPost("add-role", this.state.params);
    AppStore.dispatch({ type: "LOADING", loading: false });

    if (response.status && response.status === "error") {
      setError(response.data.message);
    } else if (response.status === "error") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (!_.isEmpty(response.errors)) {
        this.setState({ errors: response.errors });
      } else {
        this.MessageRef.current.show(response.message, "error");
      }
    } else {
      setMessage(response.message, () => {
        this.setState({ params: { role_name: "" } });
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };
}

export default withRouter(AddRole);
