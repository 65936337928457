import React, { Component } from "react";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Main from "../Main";
import Button from "../../components/Button";
import {
  TrashIcon,
  ChevronRightIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import * as XLSX from "xlsx";
import { AppStore } from "../../lib/AppStore";
import { headers } from "./Headers";
import samplefile from "./groupUploaderSampleFile.xlsx";
import FileSaver from "file-saver";
import {
  JavaApiGet,
  excelGroupUploder,
  getReportingEmail,
  getResellerId,
  setError,
  setMessage,
  validateEmail,
} from "../../lib/AppHelper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Encryption } from "../../helpers/Helper";
import { InputGroup } from "../../components/form/InputGroup";
import moment from "moment";
import { Footer } from "../../components/common/Footer";
import SampleHeader from "../../components/common/SampleHeader";
import VerticalStepper from "../../components/common/VerticalStepper";
import ErrorDataTable from "../../components/common/ErrorDataTable";
import {
  RESELLER_ENCRYPTION_KEY,
  groupExcelUploaderFileSize,
  groupExcelUploaderLimit,
} from "../../lib/constants";
import GetResellerAndProduct from "../../components/uploaderComponent/GetResellerAndProduct";
import FileSelector from "../../components/uploaderComponent/FileSelector";

export class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        group_id: null,
        file: "",
        uploadExcelDtoList: "",
        emailRecipients: "",
      },
      email: "",
      groupData: [],
      file: "",
      filename: "",
      errorDataRow: [],
      errorData: [],
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
      validate: false,
      errMsg: "",
      current: 0,
      totalStep: 2,
      toggleErrData: false,
      encResellerId: "",
      userEmail: "",
      reseller_id: "",
      isDragging: false,
      reportingEmail: getReportingEmail(),
      loading: false,
      tenure: [],
      uploadedBy: null,
    };
    this.fileRender = this.fileRender.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.validateHeader = this.validateHeader.bind(this);
    this.validateExcelData = this.validateExcelData.bind(this);
    this.onSubmitHandle = this.onSubmitHandle.bind(this);
    this.onProductChangeHandler = this.onProductChangeHandler.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.isWithinLastTwoMonths = this.isWithinLastTwoMonths.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.onResellerSelect = this.onResellerSelect.bind(this);
    this.onFileDeleteHandle = this.onFileDeleteHandle.bind(this);
  }

  isWithinLastTwoMonths(date) {
    // Get the present date
    const now = new Date();

    // Get the date from 2 months ago
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(now.getMonth() - 2);

    // Return true if the input date is between the last 2 months and the present date
    return date >= twoMonthsAgo && date <= now;
  }

  // submit handle
  onSubmitHandle() {
    const { params, parseErrorData, filename, encResellerId, uploadedBy } =
      this.state;
    const { user, history } = this.props;
    const state = history?.location?.state;
    const { permissions } = user;
    AppStore.dispatch({ type: "LOADING", loading: true });
    if (params.uploadExcelDtoList?.length > 0) {
      const data = [...params.uploadExcelDtoList, ...parseErrorData];
      // json to xlsx
      const ws = XLSX.utils.json_to_sheet(data);
      // remove blank row

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      const wbout = XLSX.write(wb, {
        bookSST: true,
        type: "binary",
        compression: true,
      });

      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };
      const file1 = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // blob to xlsx file
      const file = new File([file1], filename, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      excelGroupUploder(
        encResellerId,
        file,
        params.emailRecipients,
        params.group_id,
        uploadedBy
      )
        .then((res) => {
          if (res.code === 200) {
            setMessage("Excel file uploaded successfully", () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              permissions?.excel_uploader_history_table
                ? this.props.history.push({
                    pathname: "/excel-history",
                    state: state,
                  })
                : this.props.history.push("/");
            });
          } else {
            setError(res.message, () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              this.props.history.push("/");
            });
          }
        })
        .catch(() => {
          setError("Something went wrong", () => {
            AppStore.dispatch({ type: "LOADING", loading: false });
            this.props.history.push("/");
          });
        });
    } else {
      setError("no data found for the upload", () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  }

  // header match validation
  validateHeader = async () => {
    const { data, cols } = this.state;
    const header = data[0];
    const headerLength = header.length;
    const headersLength = headers.length;
    if (headerLength === headersLength) {
      for (let i = 0; i < headerLength; i++) {
        const headerName = header[i];
        const headerObj = headers[i].name;
        if (headerName.toUpperCase() === headerObj.toUpperCase()) {
          cols.push(headerObj);
        } else {
          return `${headerName} is not match with ${headerObj}`;
        }
      }
      return true;
    } else {
      return "headers not match please download sample excel file for reference";
    }
  };

  validationObject = {
    Coinsured_Name: {
      validation: (row, header, index) => {
        if (row[index]) {
          headers[index].mandatory = true;
          headers[index + 1].mandatory = true;
          headers[index + 2].mandatory = true;
          headers[index + 3].mandatory = true;
          headers[index + 4].mandatory = true;
          headers[index + 5].mandatory = true;
        }
      },
    },
    Family_1_name: {
      validation: (row, header, index) => {
        if (row[index]) {
          headers[index].mandatory = true;
          headers[index + 1].mandatory = true;
          headers[index + 2].mandatory = true;
          headers[index + 3].mandatory = true;
        }
      },
    },
    Family_2_name: {
      validation: (row, header, index) => {
        if (row[index]) {
          headers[index].mandatory = true;
          headers[index + 1].mandatory = true;
          headers[index + 2].mandatory = true;
          headers[index + 3].mandatory = true;
        }
      },
    },
    Family_3_name: {
      validation: (row, header, index) => {
        if (row[index]) {
          headers[index].mandatory = true;
          headers[index + 1].mandatory = true;
          headers[index + 2].mandatory = true;
          headers[index + 3].mandatory = true;
        }
      },
    },
    Family_4_name: {
      validation: (row, header, index) => {
        if (row[index]) {
          headers[index].mandatory = true;
          headers[index + 1].mandatory = true;
          headers[index + 2].mandatory = true;
          headers[index + 3].mandatory = true;
        }
      },
    },
    // Reseller_id: {
    //   validation: (row, header, index) => {
    //     row[index] = getResellerId();
    //   },
    // },
    Cust_Dob: {
      validation: (row, header, index) => {
        if (moment(row[index], "DD/MM/YYYY", true).isValid() === false) {
          return "please fill valid " + header.name;
        }
      },
    },
    Disbursementdate: {
      validation: (row, header, index) => {
        if (moment(row[index], "DD/MM/YYYY", true).isValid() === false) {
          return "please fill valid " + header.name;
        } else if (
          moment(row[index], "DD/MM/YYYY", true).isBefore(
            moment(row[9], "DD/MM/YYYY", true)
          )
        ) {
          return "disbursement date should be greater then customer date of birth";
        }
      },
    },
    Mobile: {
      validation: (row, header, index) => {
        if (`${row[index]}`.length != 10) {
          return "mobile number must contain 10 digit";
        }
      },
    },
    Pincode: {
      validation: (row, header, index) => {
        if (`${row[index]}`.length != 6) {
          return "Pincode must contain 6 digits";
        }
      },
    },
  };

  validateExcelData = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { data } = this.state;
    data[0].push("Error_Message");
    const errDataRow = [data[0]];
    const succData = [];
    let errMessage = [];
    let parseErrorData = [];
    let errCount = 0;
    let errorCountIndex = 0;
    headers?.filter((item) => (item.columnColor = []));
    data.map((row, index) => {
      if (index > 0) {
        let count = 0;
        let obj = {};
        headers.map((header, i) => {
          if (header.name === "GROUP_ID") {
            row[i] = this.state.params.group_id;
          }
          if (header.name === "Reseller_id") {
            row[i] = this.state.reseller_id;
          }
          if (header.mandatory) {
            const error = this.validationObject[header.name]?.validation(
              row,
              header,
              i
            );
            if (error) {
              errCount = 1;
              errMessage.push(error);
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else if (!row[i]) {
              errCount = 1;
              errMessage.push("please fill " + header.name);
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else if (
              header.type &&
              header.type === "number" &&
              /^[0-9]+$/.test(row[i]) === false
            ) {
              errCount = 1;
              errMessage.push("please fill valid " + header.name);
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else if (
              header.type === "name" &&
              /[0-9!@#$%*()]/.test(`${row[i]}`)
            ) {
              errCount = 1;
              errMessage.push(`Please enter a valid ${header.name}`);
              header.columnColor.push(errorCountIndex);
              Object.assign(obj, { [header.name]: row[i] });
            } else {
              count++;
              Object.assign(obj, { [header.name]: row[i] });
            }
          } else {
            count++;
            Object.assign(obj, { [header.name]: row[i] });
          }
        });
        if (count === headers.length) {
          Object.assign(obj, { ["Error_Message"]: "" });
          succData.push(obj);
        }
        if (errCount === 1) {
          row[headers.length] = errMessage
            .map((err) => {
              return err;
            })
            .join(", ");
          errDataRow.push(row);
          errCount = 0;
          errMessage = [];
          Object.assign(obj, { ["Error_Message"]: row[headers.length] });
          parseErrorData.push(obj);
          errorCountIndex = errorCountIndex + 1;
        }
      }
    });
    const { params } = this.state;
    this.setState({
      parseErrorData: parseErrorData,
      errorDataRow: errDataRow,
    });
    this.setState({ params: { ...params, uploadExcelDtoList: succData } });
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  fileRender = async (file) => {
    /* Boilerplate to set up FileReader */
    let res = false;
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    // return promise function
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellDates: true,
          dateNF: "dd/mm/yyyy;@",
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
        });

        /* Update state */
        this.setState({ data: data });
        if (data.length > groupExcelUploaderLimit) {
          setError(
            `Excel file should not be more than ${
              groupExcelUploaderLimit - 1
            } rows`,
            () => {}
          );
          return resolve(false);
        } else if (data.length === 0) {
          setError("Excel file should not be empty", () => {});
          return resolve(false);
        } else if (data[0][4] === "Loan_Code") {
          let valueArr = data.map(function (item, i) {
            if (item[4]) {
              return item[4];
            } else {
              return i + 199991;
            }
          });
          let isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx;
          });

          if (isDuplicate) {
            setError("Loan code/Reference number should be unique", () => {});
            return resolve(false);
          } else {
            return resolve(true);
          }
        } else {
          return resolve(true);
        }
      };
    });
  };

  onProductChangeHandler = (e, key) => {
    const { params } = this.state;
    const value = e.target.value;
    let tenure = null;
    this.state.groupData.filter((item) => {
      if (item.groupId == value) {
        tenure = item.tenure;
      }
    });
    this.setState({ params: { ...params, [key]: value }, tenure: tenure });
  };

  onChangeHandle(e) {
    const { params } = this.state;
    AppStore.dispatch({ type: "BACKDROP", loading: false });
    AppStore.dispatch({ type: "LOADING", loading: true });
    const file = e?.dataTransfer?.files[0]
      ? e?.dataTransfer?.files[0]
      : e?.target?.files[0];
    // get file size
    const fileSize = file.size;

    const ext = file.name.split(".").pop();

    if (fileSize > groupExcelUploaderFileSize) {
      setError("File size should not be more than 4 MB", () => {});
      // this.setState({ errMsg: "File size should be less than 4MB" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else if (ext === "xlsx") {
      this.fileRender(file).then((res) => {
        if (res) {
          this.setState({
            params: { ...params, file: file },
            filename: file.name,
            errMsg: "",
          });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
      });
    } else {
      setError("Please select only xlsx file", () => {});
      // this.setState({ errMsg: "Please select only xlsx file" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  }

  handleButtonTitle() {
    const { current } = this.state;
    if (current === 0 || current === 1) {
      return "Next";
    } else if (current === 2) {
      if (this.state.parseErrorData.length > 0) {
        return "Continue With Error";
      }
      return "Next";
    } else if (current === 3) {
      return "Upload";
    }
  }
  ////////////////Drag and Drop
  handleDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  }
  handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.onChangeHandle(event);
  }

  handleDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    // AppStore.dispatch({ type: "BACKDROP", backdrop: false });
  }
  // create and download xlsx file from array of data

  downloadFile = () => {
    const { errorDataRow } = this.state;
    const ws = XLSX.utils.json_to_sheet(errorDataRow, {
      skipHeader: true,
      cellStyles: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    FileSaver.saveAs(data, "MismatchDataFile.xlsx");
  };

  componentDidMount() {
    const { user, history } = this.props;
    const resId = history?.location?.state?.id;
    const partnerEmail = history?.location?.state?.email;
    let email = user.email;
    if (partnerEmail) {
      email = email + "," + partnerEmail;
    }
    const resellerID = resId || getResellerId();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    const encData = Encryption(resellerID, RESELLER_ENCRYPTION_KEY);

    this.setState({
      reseller_id: resellerID,
      userEmail: user.email,
      params: {
        ...this.state.params,
        emailRecipients: email,
      },
      encResellerId: encData,
    });
  }

  resize() {
    if (window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  onResellerSelect(value, item) {
    if (item?.id) {
      const encData = Encryption(item?.id, RESELLER_ENCRYPTION_KEY);
      this.setState({
        reseller_id: item?.id,
        userEmail: item?.email,
        params: {
          ...this.state?.params,
          emailRecipients: item?.email,
        },
        encResellerId: encData,
      });
    } else {
      this.setState({
        reseller_id: null,
        userEmail: null,
        params: {
          ...this.state?.params,
          emailRecipients: null,
        },
        encResellerId: null,
      });
    }
  }

  onFileDeleteHandle() {
    this.setState({
      filename: "",
      params: {
        ...this.state.params,
        file: "",
      },
      errMsg: "",
      data: [],
    });
  }

  render() {
    const { params, current, filename } = this.state;
    const steps = [
      { title: "Select Product" },
      { title: "Upload Excel File" },
      { title: "Preparation" },
      { title: "Upload" },
    ];

    return (
      <Main
        MessageRef={this.MessageRef}
        onDrop={current === 1 && this.handleDrop}
        onDragOver={current === 1 && this.handleDragOver}
        onDragLeave={current === 1 && this.handleDragLeave}
        current={current}
      >
        <AppBackButton to="/" />
        <AppHeading className="w-full text-center text-red-500">
          Group Uploader
        </AppHeading>
        <SampleHeader samplefile={samplefile} />
        <div className="flex h-96">
          <div className="mb-14 w-1/5">
            {this.state.isMobile ? (
              <VerticalStepper
                activeStep={current}
                steps={steps}
                isMobile={true}
              />
            ) : (
              <VerticalStepper
                activeStep={current}
                steps={steps}
                isMobile={false}
              />
            )}
          </div>
          <div className="w-4/5">
            <div>
              {current === 1 && (
                <div>
                  <FileSelector
                    file={params?.file}
                    filename={filename}
                    onChangeHandle={this.onChangeHandle}
                    onDeleteHandler={this.onFileDeleteHandle}
                  />
                  <div
                    className="flex justify-center mt-2 "
                    style={{ fontWeight: "600" }}
                  >
                    Or Drop Excel here
                  </div>
                  <div className="flex justify-center mt-2">
                    {this.state.errMsg ? <span>{this.state.errMsg}</span> : ""}
                  </div>
                </div>
              )}

              {current === 2 && (
                <div className="flex flex-col space-y-6 border-2 p-3">
                  <div className="md:flex justify-between block">
                    <p className="ml-4">
                      Total data :{" "}
                      <span className="text-green-500">
                        {this.state.params.uploadExcelDtoList.length +
                          this.state?.parseErrorData?.length}
                      </span>
                    </p>
                    <p className="ml-4">
                      Total data ready to be imported :{" "}
                      <span className="text-green-500">
                        {this.state.params.uploadExcelDtoList.length}
                      </span>
                    </p>

                    <p className="ml-4">
                      Mismatched data :{" "}
                      <span className="text-red-500">
                        {this.state?.parseErrorData?.length}
                      </span>
                    </p>
                  </div>

                  {this.state.errorDataRow.length > 1 ? (
                    <>
                      <hr />
                      <div className="flex flex-wrap justify-between mt-3 items-center p-1 pr-2 pl-2 rounded-md">
                        <div
                          className="flex cursor-pointer"
                          onClick={() =>
                            this.setState({
                              toggleErrData: !this.state.toggleErrData,
                            })
                          }
                        >
                          <ChevronRightIcon
                            className={`h-5 w-6 pt-1 cursor-pointer text-red ${
                              this.state.toggleErrData
                                ? "transform rotate-90 duration-200"
                                : "transform rotate-0 duration-200"
                            }`}
                          />

                          <p>
                            Total No. of data skipped :{" "}
                            <span className="text-yellow-400">
                              {this.state.parseErrorData?.length}
                            </span>
                          </p>
                        </div>
                        <div>
                          <span className="flex">
                            Download Unmatched Data Excel File{" "}
                            <DownloadIcon
                              onClick={this.downloadFile}
                              className=" h-5 w-5 text-red ml-2 cursor-pointer"
                            />
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="relative overflow-hidden">
                    {this.state.toggleErrData ? (
                      <ErrorDataTable
                        header={headers}
                        data={this.state?.parseErrorData}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>

            {current === 3 && (
              <>
                By default messages are sent to the users email{" "}
                {<span className="text-blue-500">{this.state.userEmail}</span>}.
                You can also send them to a specific email address.
                <div className="flex mt-4">
                  <InputGroup
                    label="Email"
                    placeholder="Enter email..."
                    attrs={{
                      maxLength: 64,
                      value: this.state.email,
                      onChange: (e) => {
                        this.setState({
                          email: e.target.value,
                        });
                      },
                    }}
                    validateError={(value) => {
                      if (validateEmail(value)) {
                        return false;
                      } else {
                        return "please enter correct email ID";
                      }
                    }}
                  />
                  <Button
                    className="m-2 mt-7"
                    outline={true}
                    title={"Add"}
                    attrs={{
                      onClick: this.emailChangeHandler,
                    }}
                  />
                </div>
                {this.state.params.emailRecipients.length > 0 && (
                  <div
                    style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}
                  >
                    {
                      // comma separated list of email recipients
                      this.state.params.emailRecipients
                        .split(",")
                        .map((item, index) => (
                          <div className="flex" key={index}>
                            <span className="text-sm">{item}</span>
                            <TrashIcon
                              className="h-5 w-6 text-red cursor-pointer"
                              onClick={() => {
                                this.removeEmail(index);
                              }}
                            />
                          </div>
                        ))
                    }
                  </div>
                )}{" "}
                {this.state.reportingEmail &&
                  this.state.reportingEmail.length > 0 && (
                    <div
                      style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}
                    >
                      {" "}
                      <span className="text-sm">
                        {this.state.reportingEmail}
                      </span>
                    </div>
                  )}
              </>
            )}

            <GetResellerAndProduct
              current={current}
              onProductChange={(e) =>
                this.onProductChangeHandler(e, "group_id")
              }
              product_id={params.group_id}
              onChangeReseller={this.onResellerSelect}
              uploaderType={"group_uploader"}
            />

            <div className="relative h-24 md:mr-10 flex justify-around mt-10 bottom-0 mb-10 right-0">
              {current > 0 && (
                <Button
                  title="Previous"
                  outline={true}
                  attrs={{
                    onClick: () => {
                      if (current > 0) {
                        this.setState({
                          current: current - 1,
                        });
                      }
                      if (current === 1) {
                        this.setState({
                          filename: "",
                          file: "",
                          params: {
                            ...params,
                            file: "",
                          },
                          current: current - 1,
                        });
                      } else if (current === 2) {
                        this.setState({
                          filename: "",
                          file: "",
                          params: {
                            ...params,
                            file: "",
                          },
                          current: current - 1,
                        });
                      }
                    },
                  }}
                />
              )}

              <Button
                outline={true}
                title={this.handleButtonTitle()}
                attrs={{
                  onClick: this.onClickHandler,
                  disabled: this.disabledHandler(),
                }}
              />
            </div>
            {current === 0 ? <Footer /> : ""}
          </div>
        </div>
      </Main>
    );
  }

  disabledHandler = () => {
    if (this.state.current === 0 && this.state.params.group_id) {
      return false;
    } else if (
      this.state.current === 1 &&
      this.state.params.group_id &&
      this.state.params.file
    ) {
      return false;
    } else if (
      this.state.current === 2 &&
      this.state.params.file &&
      this.state.params.uploadExcelDtoList.length > 0
    ) {
      return false;
    } else if (
      this.state.current === 3 &&
      this.state.params.file &&
      this.state.params.emailRecipients.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  onClickHandler = (e) => {
    const { current } = this.state;
    if (current == 1) {
      this.validateHeader().then((res) => {
        if (res == true) {
          this.validateExcelData().then((res) => {
            this.setState({ current: this.state.current + 1 });
          });
        } else {
          setError(res, () => {});
          // this.setState({
          //   errMsg: res,
          // });
        }
      });
    } else if (current == 0 || current == 2) {
      this.setState({ current: this.state.current + 1 });
    } else if (current == 3) {
      this.onSubmitHandle();
    }
  };

  emailChangeHandler = () => {
    if (validateEmail(this.state.email)) {
      // check if email is already present in the list
      if (
        this.state.params.emailRecipients
          .split(",")
          .indexOf(this.state.email) === -1
      ) {
        let email = this.state.params.emailRecipients;
        if (email.length > 0) {
          email = email + "," + this.state.email;
        } else {
          email = this.state.email;
        }
        this.setState({
          params: {
            ...this.state.params,
            emailRecipients: email,
          },
          email: "",
        });
      } else {
        this.setState({
          email: "",
        });
      }
    }
  };

  removeEmail = (index) => {
    let email = this.state.params.emailRecipients;
    let emailArray = email.split(",");
    emailArray.splice(index, 1);
    email = emailArray.join(",");
    this.setState({
      params: {
        ...this.state.params,
        emailRecipients: email,
      },
    });
  };
}

const mapStateToProps = (state) => {
  const { activeProfile, user, reseller_id, products } = state;
  return { user, reseller_id, products, activeProfile };
};
export default withRouter(connect(mapStateToProps)(Index));
