import React, { useCallback, useEffect, useRef, useState } from "react";
import Main from "../../Main";
import { AppBackButton, AppHeading } from "../../../components/AppComponents";
import {
  JavaApiGet,
  JavaApiPost,
  enrollmentPostApi,
  getResellerId,
} from "../../../lib/AppHelper";
import CustomSelect from "../../../components/CustomSelect";
import { InputDate } from "../../../components/form/InputDate";
import { DateFilter, LastWeekDates, WeekDates } from "../ReportMaster/constant";
import moment from "moment";
import useMobileDetect from "../../../utils/useMobileDetect";
import { Pagination, Toggle } from "rsuite";
import { toast } from "react-toastify";
import { AppStore } from "../../../lib/AppStore";
import ReportModal from "./ReportModal";
import InfintyDropdown from "../../StepClaimForm/InfintyDropdown";
import { RefreshIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import RsuiteTable from "./RsuiteTable";
import CommomModal from "./CommonModal";
import Button from "../../../components/Button";
import PaymentStatusPatnerToAiqa from "./paymentStatusPatnerToAiqa";
import InsuranceDataSentToVendor from "./insuranceDataSentToVendor";
import AiqaVendorPaymentStatus from "./aiqaVendorPaymentStatus";
import PolicyEnforcementVendor from "./policyEnforcementVendorStatus";

import VendorPolicyUpload from "./VendorPolicyUpload";
import ViewStatusPatnerPayment from "./paymentStatusPatnerToAiqa/ViewStatusPatnerPayment";
import ViewStatusInsuranceData from "./insuranceDataSentToVendor/ViewStatusInsuranceData";
import ViewStatusPaymentVendor from "./aiqaVendorPaymentStatus/ViewStatusPaymentVendor";
import ViewStatusPolicyEnforcement from "./policyEnforcementVendorStatus/ViewStatusPolicyEnforcement";
import TabModal from "./TabModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ViewStatusPolicyUpload from "./VendorPolicyUpload/ViewStatus";
import { toTitleCase } from "./constant";
import TextInput from "../../StepClaimForm/TextInput";
import { width } from "tailwindcss/defaultTheme";

const modalView = {
  PAYMENT_STATUS_PATNER_TO_AIQA: <PaymentStatusPatnerToAiqa />,
  INSURANCE_DATA_SENT_TO_VENDOR: <InsuranceDataSentToVendor />,
  PAYMENT_STATUS_AIQA_TO_VENDOR: <AiqaVendorPaymentStatus />,
  POLICY_ENFORCEMENT_VENDOR_STATUS: <PolicyEnforcementVendor />,
  VENDOR_POLICY_COUNT: <VendorPolicyUpload />,
};
const modalStatusView = {
  PAYMENT_STATUS_PATNER_TO_AIQA: <ViewStatusPatnerPayment />,
  INSURANCE_DATA_SENT_TO_VENDOR: <ViewStatusInsuranceData />,
  PAYMENT_STATUS_AIQA_TO_VENDOR: <ViewStatusPaymentVendor />,
  POLICY_ENFORCEMENT_VENDOR_STATUS: <ViewStatusPolicyEnforcement />,
  VENDOR_POLICY_COUNT: <ViewStatusPolicyUpload />,
};

const Index = () => {
  const userInfo = useSelector((state) => state.user);
  const MessageRef = useRef();
  const userRef = useRef(null || userInfo?.name);
  const { isMobile } = useMobileDetect();
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    duration: "thisMonth",
    from: WeekDates("month")[0],
    to: WeekDates("month")[1],
    sequenceType: "ASC",
    outsideInsurance: true,
    vendor: "ALL",
    status: "",
    reseller: { resellerId: getResellerId(), resellerName: userInfo.name },
    productSpecification: "",
    batchId: "",
  });
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(null);
  const [breakdownData, setBreakdownData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tenureData, setTenureData] = useState([]);
  const [commonModalState, setCommonModalState] = useState({
    open: false,
    title: "",
    status: "",
    rowData: null,
    view: false,
  });
  const [vendorList, setVendorList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [productSpecificationList, setProductSpecificationList] = useState([]);
  const [tabModel, setTabModal] = useState({ open: false, data: null });
  const [filterTableData, setFilterTableData] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  const fetchData = async (pg) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    let data = {
      resellerId: filter.reseller.resellerId,
      batchId: "",
      startDate: moment(filter?.from).format("YYYY-MM-DD"),
      endDate: moment(filter?.to).format("YYYY-MM-DD"),
      page: pg ? pg - 1 : page - 1,
      size: 10,
      sequenceType: filter?.sequenceType,
      batchId: filter?.batchId,
      vendorCode: filter?.vendor === "ALL" ? "" : filter.vendor,
      outsideInsurance: filter?.outsideInsurance,
      status: filter?.status === "ALL" ? "" : filter?.status,
      productSpecification:
        filter?.productSpecification === "ALL"
          ? ""
          : filter?.productSpecification,
    };
    try {
      const response = await JavaApiPost(
        "enrollment-service/report/api/batch_details",
        data
      );
      if (response.code === 200) {
        setTableData(response.responseObject);
        setTotalData(response.count);
        AppStore.dispatch({ type: "LOADING", loading: false });
      } else {
        setTableData([]);
        toast.error(response.message);
        setTotalData(0);
        AppStore.dispatch({ type: "LOADING", loading: false });
      }
    } catch (error) {
      toast.error(error.message);
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  function onPageChange(pg) {
    setPage(pg);
    fetchData(pg);
  }

  useEffect(() => {
    if (filter?.from && filter?.to && filter.reseller.resellerId) fetchData();
  }, [filter.sequenceType]);

  function handleSort(sorting) {
    if (tableData === null) return;
    setFilter({ ...filter, sequenceType: sorting });
  }
  const fetchBreakdownData = async (id) => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/batch_wise_details/${id}`
      );
      if (response.code === 200) {
        setBreakdownData(response.responseObject);
      } else {
        setBreakdownData([]);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const fetchTenureData = async (id) => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/tenure_wise_policy_count?batchId=${id}`
      );
      if (response.code === 200) {
        setTenureData(response.responseObject);
      } else {
        setTenureData([]);
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const fetchVendorData = async () => {
    try {
      const response = await JavaApiGet(
        "enrollment-service/report/api/get_vendors"
      );
      if (response.code === 200) {
        const list = response?.responseObject;
        let vendorData = list.map((item) => ({ label: item, value: item }));
        setVendorList(vendorData);
      } else {
        setVendorList([]);
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const fetchProductSpecificationData = async () => {
    try {
      const response = await JavaApiGet(
        `enrollment-service/report/api/product_specifications?vendorCode=${
          filter.vendor === "ALL" ? "" : filter.vendor
        }`
      );
      if (response.code === 200) {
        const list = response?.responseObject;
        let productList = list.map((item) => ({
          label: item.name,
          value: item.specificationCode,
        }));
        setProductSpecificationList(productList);
      } else {
        setProductSpecificationList([]);
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const fetchStatusData = async () => {
    try {
      const response = await JavaApiGet(
        "enrollment-service/report/api/status_for_batch"
      );
      if (response.code === 200) {
        const list = response?.responseObject;
        let statusData = list.map((item) => ({
          label: toTitleCase(item),
          value: item,
        }));
        setStatusList(statusData);
      } else {
        setStatusList([]);
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const fetchDataModal = async (id) => {
    setModalOpen(id);
    setLoading(true);
    try {
      await Promise.all([fetchBreakdownData(id), fetchTenureData(id), ,]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  function handleClickReset() {
    setFilter({
      duration: "thisMonth",
      from: WeekDates("month")[0],
      to: WeekDates("month")[1],
      sequenceType: "ASC",
      outsideInsurance: true,
      vendor: "",
      status: "",
      batchId: "",
      reseller: { resellerId: getResellerId(), resellerName: userInfo.name },
    });
    userRef.current = userInfo?.name;
    setFetchTrigger(true);
  }

  useEffect(() => {
    if (fetchTrigger) {
      fetchData();
      setFetchTrigger(false); // Reset trigger after fetch
    }
  }, [fetchTrigger]);

  useEffect(() => {
    fetchVendorData();
    fetchStatusData();
  }, []);

  useEffect(() => {
    if (filter.vendor) fetchProductSpecificationData();
  }, [filter.vendor]);
  return (
    <Main MessageRef={MessageRef}>
      <div className="grid md:grid-cols-2 gap-2 mb-xs">
        <div>
          <AppBackButton to="/">Back</AppBackButton>
        </div>
      </div>
      <div className="flex flex-wrap justify-center mb-5 mt-0">
        <AppHeading color="red" size="2xl">
          Report 360
        </AppHeading>
      </div>
      <div className="flex flex-col sm:flex-row items-start justify-between flex-wrap mb-8 ">
        <div className="flex flex-col flex-wrap flex-1">
          <div className="flex flex-wrap gap-4">
            <CustomSelect
              label="Date filter"
              value={filter.duration}
              onChange={(e) => {
                let value = e.target.value;
                let dates;
                if (value === "custom") {
                  setFilter((state) => ({
                    ...state,
                    duration: value,
                    from: null,
                    to: null,
                  }));
                  return;
                }
                if (value === "today") dates = WeekDates("day");
                if (value === "yesterday") dates = LastWeekDates("day");
                if (value === "thisWeek") dates = WeekDates("week");
                if (value === "previousWeek") dates = LastWeekDates("week");
                if (value === "thisMonth") dates = WeekDates("month");
                if (value === "previousMonth") dates = LastWeekDates("month");
                setFilter((state) => ({
                  ...state,
                  from: new Date(dates[0]),
                  to: new Date(dates[1]),
                  duration: value,
                }));
              }}
              data={DateFilter}
              style={{ width: isMobile() ? "300px" : "230px" }}
            />
            {filter.duration === "custom" && (
              <>
                <div>
                  <InputDate
                    label="From"
                    placeholder="from"
                    maxDate={
                      new Date(moment().add(1, "d").format("YYYY-MM-DD"))
                    }
                    attrs={{
                      style: { width: "230px" },
                      onChange: (e) => setFilter({ ...filter, from: e }),
                      value: filter?.from ? new Date(filter.from) : null,
                    }}
                  />
                </div>
                <div>
                  <InputDate
                    label="To"
                    placeholder="To"
                    maxDate={
                      new Date(moment().add(1, "d").format("YYYY-MM-DD"))
                    }
                    minDate={filter?.from && new Date(filter.from)}
                    attrs={{
                      style: { width: "230px" },
                      onChange: (e) => setFilter({ ...filter, to: e }),
                      value: filter?.to ? new Date(filter.to) : null,
                      readOnly: !filter?.from,
                    }}
                  />
                </div>
              </>
            )}
            <div style={{ marginTop: "-4px", width: "230px" }}>
              <InfintyDropdown
                label="Reseller name"
                request={enrollmentPostApi}
                endpoint={"enrollment-service/reseller/searchResellerName"}
                attrs={{
                  searchParam: "searchKey",
                  value: filter.reseller.resellerId || "",
                }}
                valueField="id"
                labelField="name"
                isCleanable={false}
                setValue={(data, item) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    reseller: {
                      ...prevFilter.reseller,
                      resellerId: item?.id,
                      resellerName: item?.label,
                    },
                  }));
                }}
                customRenderMenu={(value, obj, menu, index) => {
                  return (
                    <div
                      key={index}
                      className="p-4 text-xs space-y-2 border-b-2 cursor-pointer hover:bg-red-500 hover:text-white"
                      style={{ width: "100%" }}
                      onClick={(e) => menu.props.onSelect(obj?.id, obj, e)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="font-bold text-sm">{value}</div>
                      </div>
                      <div className="flex items-center justify-between">
                        {obj?.mobile && (
                          <div>
                            Mobile:{" "}
                            <span className="font-medium">{obj.mobile}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }}
                searchId={filter?.reseller?.resellerId}
              />
            </div>
            <div>
              <CustomSelect
                label="Vendor"
                value={filter.vendor}
                isMandatory={false}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilter({ ...filter, vendor: value });
                }}
                data={vendorList}
                style={{ width: isMobile() ? "300px" : "230px" }}
              />
            </div>
            <div>
              <CustomSelect
                label="Status"
                value={filter.status}
                isMandatory={false}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilter({ ...filter, status: value });
                }}
                data={statusList}
                style={{ width: isMobile() ? "300px" : "230px" }}
              />
            </div>
            <div>
              <CustomSelect
                label="Product specification"
                value={filter.productSpecification}
                isMandatory={false}
                disable={filter.vendor === "ALL" ? true : false}
                onChange={(e) => {
                  const value = e.target.value;
                  setFilter({ ...filter, productSpecification: value });
                }}
                data={productSpecificationList}
                style={{ width: isMobile() ? "300px" : "230px" }}
              />
            </div>
            <div>
              <TextInput
                label="Batch Id"
                requiredMark
                attrs={{
                  maxLength: 32,
                  value: filter?.batchId,
                  onChange: (e) => {
                    setFilter({ ...filter, batchId: e.target.value });
                  },
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-wrap gap-4 pt-2">
              <Button title="Search" attrs={{ onClick: () => fetchData() }} />
              <Tooltip title="Reset filter" arrow>
                <button
                  onClick={handleClickReset}
                  className="text-white p-1 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>
              </Tooltip>
            </div>
            <div className="flex flex-col mt-4 items-end">
              <label>Outside insurance</label>
              <div>
                <Toggle
                  size="lg"
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked
                  checked={filter.outsideInsurance}
                  onChange={(value) =>
                    setFilter({ ...filter, outsideInsurance: value })
                  }
                />
                <Tooltip
                  title="By switching to Yes, you will be able to punch payments"
                  arrow
                  placement="top"
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-4">
        <div style={{ fontSize: "14px", fontWeight: "600" }}>
          {filter.from &&
            filter.to &&
            `Data from ${moment(filter?.from).format("DD-MM-YYYY")} to ${moment(
              filter?.to
            ).format("DD-MM-YYYY")}`}
          &nbsp;
        </div>
      </div>

      <RsuiteTable
        data={tableData || []}
        onChangeBatchId={(id) => fetchDataModal(id)}
        sort={filter.sequenceType}
        handleSort={handleSort}
        onClickTenure={(id) => fetchTenureData(id)}
        setCommonModalState={setCommonModalState}
        isShowButton={filter.outsideInsurance}
        setTabModal={setTabModal}
      />
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={2}
          size="xs"
          layout={["total", "-", "pager", "skip"]}
          total={totalData}
          limit={10}
          activePage={page}
          onChangePage={(pg) => onPageChange(pg)}
        />
      </div>
      <ReportModal
        open={modalOpen}
        handleClose={() => {
          setModalOpen(null);
        }}
        breakdowndata={breakdownData}
        tenuredata={tenureData}
        loading={loading}
      />
      {commonModalState?.open && (
        <CommomModal
          open={commonModalState.open}
          title={commonModalState.title}
          rowData={commonModalState.rowData}
          vendorList={vendorList}
          handleClose={() =>
            setCommonModalState({
              open: false,
              title: "",
              status: "",
            })
          }
          handleSuccess={(message) => {
            toast.success(message);
            setCommonModalState({
              open: false,
              title: "",
              status: "",
            });
            fetchData();
          }}
          handelError={(message) => {
            toast.error(message);
          }}
        >
          {commonModalState?.view
            ? modalStatusView[commonModalState.status]
            : modalView[commonModalState.status]}
        </CommomModal>
      )}
      <TabModal
        open={tabModel.open}
        data={tabModel.data}
        handleClose={() => setTabModal({ open: false, data: null })}
      />
    </Main>
  );
};

export default Index;
