export const toIndianNumberFormat = (value) => {
  value = value.toString();
  let valArr = [value];
  if (value?.indexOf(".") > -1) {
    valArr = value.split(".");
  }
  value = valArr[0];
  var lastThree = value.substring(value.length - 3);
  var otherNumbers = value.substring(0, value.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  if (valArr[1]) {
    res = res + "." + valArr[1];
  }
  return res;
};
