import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Loader } from "rsuite";
import { Tooltip } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ReportModal({
  open,
  handleClose,
  breakdowndata,
  loading,
  tenuredata,
}) {
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="text-base font-medium"
        >
          Breakdown report
          <br />
          <span className="text-base font-medium">#&nbsp;{open}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <hr />
        <div className=" mt-4 mb-4">
          <div>
            <div>
              <span className="font-medium text-lg px-8">
                Batch wise report
              </span>
            </div>
          </div>{" "}
          <DialogContent>
            <div className="overflow-x-auto">
              <table className="min-w-full border" id="table-to-xls">
                <thead className="border-b">
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Sr.No
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Benefit
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    <Tooltip title="Number of policies" arrow placement="top">
                      <span>Nop's</span>
                    </Tooltip>
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Total members <br /> covered
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Total policies
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Vendor
                  </th>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Loader size="md" />
                      </td>
                    </tr>
                  ) : breakdowndata?.length ? (
                    breakdowndata?.map((item, index) => {
                      return (
                        <tr className="border-b" key={index}>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {index + 1}
                          </td>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {item?.benefit || "---"}
                          </td>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {item.nop}
                          </td>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {item?.membersCovered || "---"}
                          </td>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {item?.totalPolicies || "---"}
                          </td>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {item?.vendor || "---"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        className="text-red-500 font-large text-center"
                      >
                        No Data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </DialogContent>
        </div>
        <div>
          <div className="flex items-center justify-between px-8">
            <div>
              <span className="font-medium text-lg">Tenure wise report</span>
            </div>
            <div>
              <span className="font-medium">
                Number of policies:&nbsp;
                {tenuredata.reduce((acc, curr) => {
                  return (acc += curr.policyCount);
                }, 0)}
              </span>
            </div>
          </div>{" "}
          <DialogContent>
            <div className="overflow-x-auto">
              <table className="min-w-full border" id="table-to-xls">
                <thead className="border-b">
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Sr.No
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    Tenure (Months)
                  </th>
                  <th className="text-sm text-center font-large px-6 py-4 whitespace-nowrap bg-red-500 text-white">
                    <Tooltip title="Number of policies" arrow placement="top">
                      <span>Nop's</span>
                    </Tooltip>
                  </th>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <Loader size="md" />
                      </td>
                    </tr>
                  ) : tenuredata?.length ? (
                    tenuredata?.map((item, index) => {
                      return (
                        <tr className="border-b" key={index}>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {index + 1 || "---"}
                          </td>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {item?.tenure || "---"}
                          </td>
                          <td className="text-center px-4 py-2 font-small dark:border-neutral-500 capitalize">
                            {item.policyCount}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        className="text-red-500 font-large text-center"
                      >
                        No Data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </DialogContent>
        </div>
      </BootstrapDialog>
    </>
  );
}
