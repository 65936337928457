import React, { Suspense, memo, useRef, useState } from "react";
import { Toggle } from "rsuite";
import useMobileDetect from "../../utils/useMobileDetect";
import { SvgIcon, downloadExcel, filterData } from "./constant";
import ComparisonTable from "./ComparisonTable";

import "./style.css";
import ShimmerLoader from "../../components/common/ShimmerLoader";
const SummaryTable = React.lazy(() => import("./SummaryTableVertical"));

const DashboardTable = ({
  salesData,
  handel90days,
  setToggle,
  toggle,
  last90Days,
}) => {
  const { isMobile } = useMobileDetect();
  const [isActive, setIsActive] = useState(true);
  return (
    <>
      <div
        style={{
          textAlign: "center",
          fontSize: "20px",
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        <label>Summary</label>
      </div>
      <div
        className="w-full md:block overflow-scroll no-scrollbar"
        onClick={() => setIsActive(!isActive)}
      >
        <Suspense fallback={<ShimmerLoader row={5} />}>
          <SummaryTable salesData={salesData || []} />
        </Suspense>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "30px",
          marginBottom: "5px",
          padding: "0px 15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile() ? "column" : "row",
            justifyContent: "space-between",
            gap: "15px",
          }}
        >
          <div>
            <Toggle
              onChange={(value) => {
                handel90days();
                setToggle(value);
              }}
              checkedChildren="Active"
              unCheckedChildren="Not Active"
              checked={toggle}
              size="lg"
            />
          </div>
        </div>
        <div>
          <button
            className="Btn"
            onClick={(e) => {
              if (salesData?.data?.length > 0) {
                const filteredData = !toggle
                  ? filterData(salesData.data, last90Days.data)
                  : salesData.data;
                filteredData.foreach((item) => {
                  delete item.reportingId;
                  delete item.comparisonData;
                });
                downloadExcel(filteredData);
              }
            }}
            style={{
              background: salesData?.data.length > 0 ? "" : "grey",
              cursor: salesData?.data?.length > 0 ? "" : "not-allowed",
            }}
          >
            <div style={{ display: "flex", gap: "5px" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SvgIcon />
                <span className="icon2"></span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "20px",
          marginBottom: "10px",
          fontWeight: "600",
        }}
      >
        <label>Reseller wise sales</label>
      </div>
      <div id="table-to-xls">
        <ComparisonTable comparisonData={!toggle ? last90Days : salesData} />
      </div>
    </>
  );
};

export default memo(DashboardTable);
