import React from "react";
import { Prompt } from "react-router-dom";
import { Modal } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import Button from "../components/Button";
export const RouteLeavingGuard = ({
  navigate,
  when,
  shouldBlockNavigation,
}) => {
  const [modalVisible, updateModalVisible] = React.useState(false);
  const [lastLocation, updateLastLocation] = React.useState();
  const [confirmedNavigation, updateConfirmedNavigation] =
    React.useState(false);

  const showModal = (location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  React.useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal key={"changes"} open={modalVisible} onClose={() => {}} size="xs">
        <Modal.Body>
          <p style={{ textAlign: "center" }}>
            <RemindIcon style={{ color: "#ffb300", fontSize: 36 }} />
          </p>
          <p style={{ textAlign: "center" }}>
            Unsaved changes will be lost. Are you sure you want to continue?
          </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            size={"sm"}
            attrs={{
              onClick: () => {
                closeModal();
                handleConfirmNavigationClick();
              },
            }}
            title="Yes, I am sure"
          ></Button>

          <Button
            size={"sm"}
            attrs={{
              onClick: () => closeModal(),
            }}
            title={"cancel"}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RouteLeavingGuard;
