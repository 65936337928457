import React from "react";

const StatusWiseActionButton = ({ status }) => {
  let color = "red";
  switch (status) {
    case "missed":
      color = "red";
      break;
    case "cancelled":
      color = "red";
      break;
    case "completed":
      color = "green";
      break;
    case "pending":
      color = "yellow";
      break;
    default:
      color = "blue";
  }

  return <div className={`text-${color}-600 capitalize`}>{status}</div>;
};

export default StatusWiseActionButton;
