import React, { useEffect, useRef, useState } from "react";
import Main from "../Main";
import { AppHeading } from "../../components/AppComponents";
import { Filter } from "./Filter";
import Button from "../../components/Button";
import MemberAddDialog from "./MemberAddDialog";
import {
  ApiPost,
  JavaApiPost,
  downloadCSVFileForJavaEndPoint,
  getResellerId,
  uploadApiDocuments,
} from "../../lib/AppHelper";
import CardsContainer from "./CardsContainer";
import { useSelector } from "react-redux";
import {
  DRAFT,
  FINAL,
  REPORT,
  DRAFT_MEMBER,
  FINAL_REPORT,
  NEW_MEMBER,
  SAVED_MEMBER,
  SEARCH_PRODUCT_KEY,
  dropDownOptions,
  findProduct,
  API_URL,
  Page_SIZE,
  ViewedByOptions,
  POLICY_PUNCHED_BY,
} from "./constant";
import moment from "moment";
import { toast } from "react-toastify";
import { AppStore } from "../../lib/AppStore";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AddIcon from "@mui/icons-material/Add";
import { InputSelect } from "../../components/form/InputSelect";
import DataTable from "./DataTable";
import { InputDate } from "../../components/form/InputDate";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import Pagination from "./Pagination";

const HealthCamp = () => {
  const products = useSelector((state) => state?.products);
  const branch_code = useSelector((state) => state?.user?.branch_code);
  const pincode = JSON.parse(localStorage?.getItem("reseller_pin"));
  const MessageRef = useRef();
  const FilterChildRef = useRef(null);
  const [filterValues, setFilterValues] = useState({});
  const [sort, setSort] = useState({ label: "", sortBy: "" });
  const [open, setOpen] = React.useState(false);
  const [newMember, setNewMember] = useState({
    name: "",
    phone: "",
    dob: "",
    gender: "m",
    loanCode: "",
    governmentId: "",
  });
  const [document, setDocument] = useState(null);
  const [memberData, setMemberData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [screen, setScreen] = useState(NEW_MEMBER);
  const [page, setPage] = useState(0);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [count, setCount] = useState(0);
  const [viewBy, setViewBy] = useState(POLICY_PUNCHED_BY);

  const addDocument = async (file = null, data = null) => {
    setImageLoading(true);
    AppStore.dispatch({ type: "LOADING", loading: true });
    const formData = new FormData();
    formData.append("file", file || document);
    formData.append("resellerId", getResellerId());
    formData.append("loanCode", data?.loanCode);
    formData.append("mobile", data?.mobile);
    formData.append("branchCode", branch_code);
    formData.append("remarks", "");
    formData.append("documentType", "PRESCRIPTION");
    formData.append("memberId", data?.memberId || data?.member_id);
    formData.append(
      "applicationId",
      data?.applicationId || data?.application_id
    );
    try {
      const res = await uploadApiDocuments(
        "enrollment-service/api/uploadDocument",
        formData
      );
      if (res.code === 200) {
        setMemberData([]);
        toast.success("File uploaded successfully, consultation is complete");
        FilterChildRef?.current?.handleCancel();
        if (open) {
          setOpen(false);
          setDocument(null);
          setNewMember({
            name: "",
            phone: "",
            dob: "",
            gender: "m",
            loanCode: "",
          });
        }
        if (isDraft) {
          getMembers(DRAFT, 5);
        }
      } else toast.error(res?.message);
    } catch (error) {
      toast.error(error);
    } finally {
      setImageLoading(false);
      AppStore.dispatch({ type: "LOADING", loading: false });
      setImageLoading(false);
    }
  };

  const getMembers = async (pg = 0, sort = {}) => {
    setPage(pg);
    AppStore.dispatch({ type: "LOADING", loading: true });
    const data = {
      branchCode: branch_code,
      reseller_id: getResellerId(),
      loanCode:
        filterValues?.search_type === "loan_code" ? filterValues?.search : "",
      mobile:
        filterValues?.search_type === "mobile" ? filterValues?.search : "",
      name: filterValues?.search_type === "name" ? filterValues?.search : "",
      resellerId: getResellerId(),
      page: pg,
      size: Page_SIZE[screen],
      fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      viewedBy: viewBy,
      ...sort,
    };
    try {
      await JavaApiPost(
        `enrollment-service/api/health-camp/${API_URL[screen]}`,
        data
      ).then((res) => {
        if (res?.code === 200) {
          setCount(res?.count);
          setMemberData(res?.responseObject);
          if (res?.responseObject?.length === 0 && screen === NEW_MEMBER)
            setOpen(true);
        } else {
          if (screen === NEW_MEMBER) setOpen(true);
          else setOpen(false);
          setMemberData([]);
          setCount(0);
        }
      });
    } catch (error) {
      toast.error(error);
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };
  const downloadExcel = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const data = {
      branchCode: branch_code,
      reseller_id: getResellerId(),
      loanCode:
        filterValues?.search_type === "loan_code" ? filterValues?.search : "",
      mobile:
        filterValues?.search_type === "mobile" ? filterValues?.search : "",
      name: filterValues?.search_type === "name" ? filterValues?.search : "",
      resellerId: getResellerId(),
      size: 1000,
      fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      viewedBy: viewBy,
    };
    try {
      await downloadCSVFileForJavaEndPoint(
        "enrollment-service/api/health-camp/csv-report",
        data,
        "report.csv"
      );
    } catch (error) {
      toast.error(error);
    } finally {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  const addMember = async (key) => {
    setLoading(key);
    const data = {
      ...newMember,
      pincode: pincode?.value,
      product_id: findProduct(SEARCH_PRODUCT_KEY, products)?.product_id,
      loan_code: newMember.loanCode,
      dob: moment(newMember.dob).format("YYYY-MM-DD"),
      governmentIdType: "aadhar",
    };
    try {
      const res = await ApiPost(`add-single-member-policy`, data);
      if (res.status === "success") {
        if (key === FINAL) {
          addDocument(null, {
            ...res.data,
            applicationId: res?.application_id,
            memberId: res?.member_id,
          });
        } else {
          toast.success("your record has been saved in draft");
          setNewMember({
            name: "",
            phone: "",
            dob: "",
            gender: "m",
            loanCode: "",
          });
          setDocument(null);
          setOpen(false);
        }
      } else toast.error(res?.message);
    } catch (error) {
      console.log("error", error?.message);
      toast.error(error?.message);
    } finally {
      setLoading("");
    }
  };

  const handleClose = () => {
    setNewMember({
      name: "",
      phone: "",
      dob: "",
      gender: "m",
      loanCode: "",
    });
    setOpen(false);
    setDocument(null);
  };
  const onClearFilter = () => {
    if (memberData?.length === 1) setMemberData([]);
  };

  useEffect(() => {
    if (
      screen === NEW_MEMBER &&
      filterValues.search !== "" &&
      Object.keys(filterValues)?.length !== 0
    )
      getMembers(0);
    else if (screen !== NEW_MEMBER) getMembers(0);
    else setMemberData([]);
  }, [filterValues]);

  const onChangeHandlerDate = (value, type) => {
    if (type == "from") {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  const handleSort = (label) => {
    setSort((prevSort) => {
      if (prevSort.label === label) {
        return { label, sortBy: prevSort.sortBy === "ASC" ? "DESC" : "ASC" };
      }
      return { label, sortBy: "ASC" };
    });
  };
  useEffect(() => {
    if (sort.label === "") return;
    if (sort.label === "POLICY") {
      getMembers(0, { orderByCreatedOn: sort.sortBy, orderByCampOn: "" });
    } else {
      getMembers(0, { orderByCampOn: sort.sortBy, orderByCreatedOn: "" });
    }
  }, [sort]);
  return (
    <Main MessageRef={MessageRef}>
      <AppHeading
        className="pl-4 text-center font-medium"
        style={{ color: "#DB2228", marginBottom: "10px" }}
      >
        <HealthAndSafetyIcon style={{ fontSize: "35px" }} />
        Health Camp
      </AppHeading>
      <div className="flex justify-between items-center">
        <div>
          {screen === FINAL_REPORT && (
            <Button
              title=""
              outline={true}
              attrs={{
                style: { marginTop: "-5px" },

                onClick: () => downloadExcel(),
              }}
            >
              <DownloadIcon />
            </Button>
          )}
        </div>
        <div>
          <InputSelect
            attrs={{
              style: { width: "200px" },
              value: screen,
              onChange: (e) => {
                if (FilterChildRef?.current)
                  FilterChildRef?.current?.handleCancel();

                setScreen(e.target.value);
              },
            }}
            options={dropDownOptions || []}
          />
        </div>
      </div>
      <Filter
        setValue={setFilterValues}
        filterValues={filterValues}
        onClearFilter={onClearFilter}
        ref={FilterChildRef}
      />
      {screen !== NEW_MEMBER && screen !== DRAFT_MEMBER && (
        <div className="mt-5 mb-5">
          {screen === FINAL_REPORT && (
            <InputSelect
              label="Viewed by"
              attrs={{
                style: { width: "180px" },
                value: viewBy,
                onChange: (e) => {
                  setViewBy(e.target.value);
                },
              }}
              options={ViewedByOptions || []}
            />
          )}
          <div className="flex flex-wrap gap-2 mb-2 items-center">
            <InputDate
              label="From"
              placeholder="from"
              maxDate={new Date()}
              attrs={{
                style: { width: "130px" },
                onChange: (e) => onChangeHandlerDate(e, "from"),
                value: new Date(fromDate),
              }}
            />
            <InputDate
              label="To"
              placeholder="to"
              maxDate={new Date()}
              attrs={{
                style: { width: "130px" },
                onChange: (e) => onChangeHandlerDate(e, "to"),
                value: new Date(toDate),
              }}
            />
            <Button
              title=""
              className="mt-8"
              attrs={{
                onClick: () => getMembers(0),
              }}
            >
              <SearchIcon />
            </Button>
          </div>

          {/* <DataTable data={memberData} /> */}
        </div>
      )}
      {screen === FINAL_REPORT && (
        <DataTable
          data={memberData}
          sort={sort}
          setSort={setSort}
          handleSort={handleSort}
        />
      )}

      {memberData?.length > 0 && screen !== FINAL_REPORT && (
        <div style={{ height: "auto", marginTop: "20px" }}>
          <CardsContainer
            imageLoading={imageLoading}
            data={memberData}
            addDocument={addDocument}
            getMembers={getMembers}
            page={page}
            screen={screen}
            count={count}
          />
        </div>
      )}

      {memberData?.length === 0 &&
        screen !== NEW_MEMBER &&
        screen !== FINAL_REPORT && (
          <div className="h-96 grid place-items-center">
            <p>No record Found</p>
          </div>
        )}
      {screen !== NEW_MEMBER && (
        <Pagination
          getMembers={getMembers}
          count={count}
          memberData={memberData}
          page={page}
        />
      )}
      {screen === NEW_MEMBER && memberData?.length === 0 && (
        <div className="h-96 grid place-items-center">
          <div className="flex flex-col gap-4 items-center">
            <Button
              title="New member"
              attrs={{
                onClick: () => setOpen(true),
              }}
            >
              <AddIcon style={{ fontSize: "30px" }} />
            </Button>
          </div>
        </div>
      )}

      <MemberAddDialog
        imageLoading={imageLoading}
        open={open}
        setOpen={setOpen}
        setNewMember={setNewMember}
        newMember={newMember}
        onSubmit={(key) => addMember(key)}
        handleClose={handleClose}
        setDocument={setDocument}
        document={document}
        loading={loading}
      />
    </Main>
  );
};

export default HealthCamp;
