export const statusData = {
  SALES_DATA_BOOKED: "Sales data booked",
  SALES_DATA_BOOKED_PARTIALLY: "Sales data booked partially",
  INSURANCE_DATA_SENT: "Insurance data sent",
  PAYMENT_PAID_TO_VENDOR: "Payment paid to vendor",
  PAYMENT_PAID_TO_VENDOR_PARTIALLY: "Payment paid to vendor partially",
  VENDOR_POLICY_NUMBER_RECEIVED: "Vendor policy number received",
  VENDOR_POLICY_NUMBER_UPLOADED: "Vendor policy number uploaded",
};

export const PAYMENT_STATUS_AIQA_TO_VENDOR = "PAYMENT_STATUS_AIQA_TO_VENDOR";
export const INSURANCE_DATA_SENT_TO_VENDOR = "INSURANCE_DATA_SENT_TO_VENDOR";
export const PAYMENT_STATUS_PATNER_TO_AIQA = "PAYMENT_STATUS_PATNER_TO_AIQA";
export const POLICY_ENFORCEMENT_VENDOR_STATUS =
  "POLICY_ENFORCEMENT_VENDOR_STATUS";
export const VENDOR_POLICY_COUNT = "VENDOR_POLICY_COUNT";
export const VENDOR_POLICY_UPLOAD = "VENDOR_POLICY_UPLOAD";

export const vendorData = ["LIBERTY", "ABCL", "CARE", "ACKO"];

export const toTitleCase = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
