export const headers = [
  { name: "SrNo", mandatory: false, dataKey: "SrNo" },
  {
    name: "Reseller_id",
    mandatory: false,
    type: "number",
    dataKey: "Reseller_id",
  },
  {
    name: "Product_id",
    mandatory: false,
    type: "number",
    dataKey: "Product_id",
  },
  { name: "Customer_Id", mandatory: true, dataKey: "Customer_Id" },
  {
    name: "Branch_code",
    mandatory: false,
    dataKey: "Branch_code",
  },
  {
    name: "Branch_name",
    mandatory: false,
    dataKey: "Branch_name",
  },
  { name: "Tenure", mandatory: true, dataKey: "Tenure" },

  { name: "Loan_Code", mandatory: false, dataKey: "Loan_Code" },
  {
    name: "Disbursementdate",
    mandatory: true,
    dataKey: "Disbursementdate",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "State", mandatory: false, dataKey: "State" },
  { name: "Pincode", mandatory: true, type: "number", dataKey: "Pincode" },
  { name: "Cust_Name", mandatory: true, type: "name", dataKey: "Cust_Name" },
  {
    name: "Cust_Dob",
    mandatory: true,
    dataKey: "Cust_Dob",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "Cust_Gender", mandatory: true, dataKey: "Cust_Gender" },
  { name: "Mobile", mandatory: true, type: "number", dataKey: "Mobile" },
  { name: "Email_Id", mandatory: false, dataKey: "Email_Id" },
  { name: "Cust_Id_Type", mandatory: true, dataKey: "Cust_Id_Type" },
  { name: "Cust_Id_Number", mandatory: true, dataKey: "Cust_Id_Number" },
  {
    name: "Coinsured_Name",
    mandatory: false,
    type: "name",
    dataKey: "Coinsured_Name",
  },
  {
    name: "Coinsured_Dob",
    mandatory: false,
    dataKey: "Coinsured_Dob",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "Coinsured_Gender", mandatory: false, dataKey: "Coinsured_Gender" },
  {
    name: "Coinsured_Relation",
    mandatory: false,
    dataKey: "Coinsured_Relation",
  },
  { name: "Coinsured_Id_Type", mandatory: false, dataKey: "Coinsured_Id_Type" },
  {
    name: "Coinsured_Id_Number",
    mandatory: false,
    dataKey: "Coinsured_Id_Number",
  },
  {
    name: "Family_1_name",
    mandatory: false,
    type: "name",
    dataKey: "Family_1_name",
  },
  {
    name: "Family_1_DOB",
    mandatory: false,
    dataKey: "Family_1_DOB",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "Family_1_Gender", mandatory: false, dataKey: "Family_1_Gender" },
  { name: "Family_1_Relation", mandatory: false, dataKey: "Family_1_Relation" },
  {
    name: "Family_2_name",
    mandatory: false,
    type: "name",
    dataKey: "Family_2_name",
  },
  {
    name: "Family_2_DOB",
    mandatory: false,
    dataKey: "Family_2_DOB",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "Family_2_Gender", mandatory: false, dataKey: "Family_2_Gender" },
  { name: "Family_2_Relation", mandatory: false, dataKey: "Family_2_Relation" },
  {
    name: "Family_3_name",
    mandatory: false,
    type: "name",
    dataKey: "Family_3_name",
  },
  {
    name: "Family_3_DOB",
    mandatory: false,
    dataKey: "Family_3_DOB",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "Family_3_Gender", mandatory: false, dataKey: "Family_3_Gender" },
  { name: "Family_3_Relation", mandatory: false, dataKey: "Family_3_Relation" },
  {
    name: "Family_4_name",
    mandatory: false,
    type: "name",
    dataKey: "Family_4_name",
  },
  {
    name: "Family_4_DOB",
    mandatory: false,
    dataKey: "Family_4_DOB",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "Family_4_Gender", mandatory: false, dataKey: "Family_4_Gender" },
  { name: "Family_4_Relation", mandatory: false, dataKey: "Family_4_Relation" },
  {
    name: "Policy_Start_Date",
    mandatory: false,
    dataKey: "Policy_Start_Date",
    type: "date",
    format: "DD/MM/YYYY",
  },
  { name: "Abo_Id", mandatory: false, dataKey: "Abo_Id" },
  { name: "GROUP_ID", mandatory: false, dataKey: "GROUP_ID" },
];
