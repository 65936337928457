import React, { useState } from "react";
import moment from "moment";
import InventoryTable from "../component/InventoryTable";
import ExcelUploader from "../component/Uploader";
import { postApiClaim } from "../../../lib/AppHelper";
import { AppStore } from "../../../lib/AppStore";
import SampleFile from "./StockInSampleFile.xlsx";
import { headers } from "./Headers";
import { toast } from "react-toastify";
import { SINGLE_DATA, BULK_DATA, MAX_ROW } from "../component/constant";
import ButtonHeader from "../component/ButtonHeader";

const INITIAL_STATE = {
  medicineId: "",
  medicineName: "",
  sku: "",
  quantity: "",
  expiryDate: "",
  narration: "",
  maxMedicineCapicity: 10000,
};

const PurchaseStock = ({ component, windowSize }) => {
  const [purchaseData, setPurchasedata] = useState([{ ...INITIAL_STATE }]);
  const [view, setView] = useState(SINGLE_DATA);
  const [errors, setErrors] = useState({
    tableData: [],
    header: [],
  });
  const [vendorId, setVendorId] = useState();
  const [vendorName, setVendorName] = useState();
  const [batchNo, setBatchNo] = useState();
  const [receivingDate, setReceivingDate] = useState({
    title: "Purchase date",
    date: "",
    disableDateFunc: (date) => {
      if (date > new Date() || date < moment().subtract(30, "days").toDate()) {
        return true;
      }
    },
  });
  const [hoId, setHoId] = useState();
  const [multiRow, setMultiRow] = useState(1);

  const validateRows = () => {
    const newErrors = [];
    if (purchaseData.length) {
      purchaseData.forEach((row, index) => {
        const rowErrors = [];
        for (const key in row) {
          if (!row[key] && key !== "narration") {
            rowErrors.push(key);
          }
        }
        if (rowErrors.length > 0) {
          newErrors[index] = rowErrors;
        }
      });
    } else {
      return false;
    }
    setErrors({ ...errors, tableData: newErrors });
    return newErrors.length === 0;
  };

  const checkHeader = () => {
    let headerErr = [];
    if (!vendorId && !vendorName) {
      headerErr.push("vendorId");
    }
    if (!receivingDate.date) {
      headerErr.push("receivingDate");
    }
    if (!hoId) {
      headerErr.push("HO_ID");
    }
    setErrors({ ...errors, header: headerErr });
    return headerErr.length === 0;
  };

  const handelAddRow = () => {
    if (purchaseData.length < MAX_ROW)
      setPurchasedata((prevData) => [...prevData, { ...INITIAL_STATE }]);
    else
      toast.info(
        "you can add upto 10 row only for adding more row kindly use bulk uploader",
        {
          toastId: "warning1",
        }
      );
  };
  const handelDeleteRow = (rowIndex) => {
    toast.info("1 row deleted", {
      toastId: "delete",
    });
    setPurchasedata((prevData) =>
      prevData.filter((_, index) => index !== rowIndex)
    );
  };

  const handelChangeStockIn = (index, e, value) => {
    const data = [...purchaseData];

    if (e === "date") {
      data[index]["expiryDate"] = value
        ? moment(new Date(value)).format("YYYY-MM-DD")
        : "";
    } else if (e === "medicineId") {
      data[index]["medicineId"] = value?.medicineId ? value.medicineId : null;
      data[index]["medicineName"] = value?.medicineName
        ? value.medicineName
        : null;
      data[index]["sku"] = value?.sku ? value.sku : null;
    } else {
      const { name, value } = e.target;
      data[index][name] = value;
    }

    setPurchasedata(data);
  };

  const onMedicineClear = (index) => {
    const data = [...purchaseData];
    data[index]["medicineId"] = "";
    data[index]["medicineName"] = "";
    data[index]["quantity"] = "";
    data[index]["expiryDate"] = "";
    data[index]["sku"] = "";
    setPurchasedata(data);
  };

  const onAddMultiRow = () => {
    const numberOfRowsToAdd = parseInt(multiRow);

    if (!isNaN(numberOfRowsToAdd) && numberOfRowsToAdd > 0) {
      const newRows = Array.from({ length: numberOfRowsToAdd }, () => ({
        ...INITIAL_STATE,
      }));
      setPurchasedata((prevState) => [...prevState, ...newRows]);
    }
  };

  const handelSubmit = async (id) => {
    if (!checkHeader()) {
      toast.warning("Please fill mandatory field", {
        toastId: "error1",
      });
    } else if (!validateRows()) {
      toast.warning("Please fill mandatory field", {
        toastId: "error2",
      });
    } else {
      AppStore.dispatch({ type: "LOADING", loading: true });
      let data = {
        sendingResellerId: vendorId || id,
        receivingDate: receivingDate.date
          ? moment(new Date(receivingDate.date)).format("YYYY-MM-DD")
          : "",
        batchNumber: batchNo,
        stockTransactionRequestList: purchaseData.map((item) => {
          return {
            ...item,
            receivingResellerId: hoId,
            stockIn: item.quantity,
          };
        }),
      };
      await postApiClaim(`add_inventory_vendor_to_ho`, data)
        .then((response) => {
          if (response.code === 200) {
            if (response.data.length > 0) {
              toast.error(
                `${response.data.length} out of ${purchaseData.length} Stock transfered failed `
              );
              const tempData = response.data.map((item) => {
                return { ...item, quantity: item.stockOut };
              });
              setPurchasedata(tempData);
              tempData.forEach((item) => toast.error(item.errorMessage));
            } else {
              toast.success("Stock added successfully ");
              setPurchasedata([{ ...INITIAL_STATE }]);
              setVendorId("");
              setHoId("");
              setReceivingDate({
                title: "Purchase date",
                date: "",
              });
              setBatchNo("");
              setMultiRow(1);
              setVendorName("");
            }
          } else {
            toast.error(response?.message);
          }
        })
        .catch((error) => toast.error(error))
        .finally(() => AppStore.dispatch({ type: "LOADING", loading: false }));
    }
  };

  const setVendorDetails = (value, items) => {
    setVendorId(value);
    setVendorName(items?.name);
  };

  const onRefesh = () => {
    setPurchasedata([{ ...INITIAL_STATE }]);
    setVendorId("");
    setHoId("");
    setReceivingDate({
      title: "Purchase date",
      date: "",
      disableDateFunc: (date) => {
        if (
          date > new Date() ||
          date < moment().subtract(30, "days").toDate()
        ) {
          return true;
        }
      },
    });
    setVendorName("");
    setBatchNo("");
    setMultiRow(1);
  };

  return (
    <>
      <p
        className="text-center font-semibold text-lg"
        style={{ color: "#DB2228", marginBottom: "5px" }}
      >
        Purchase medicine
      </p>
      <ButtonHeader view={view} setView={setView} windowSize={windowSize} />
      {view === SINGLE_DATA && (
        <InventoryTable
          data={purchaseData}
          handelAddRow={handelAddRow}
          handelDeleteRow={(rowIndex) => handelDeleteRow(rowIndex)}
          onchange={(index, e, value) => handelChangeStockIn(index, e, value)}
          errors={errors}
          handelSubmit={handelSubmit}
          component={component}
          onMedicineClear={onMedicineClear}
          onAddMultiRow={onAddMultiRow}
          headerDate={receivingDate}
          setHeaderDate={setReceivingDate}
          medicineSearchUrlPrefix="purchase_medicine"
          vendorName={vendorName}
          setVendorDetails={setVendorDetails}
          setHoId={setHoId}
          hoId={hoId}
          setVendorName={setVendorName}
          vendorId={vendorId}
          setVendorId={setVendorId}
          onRefesh={onRefesh}
          batchNo={batchNo}
          setBatchNo={setBatchNo}
          checkHeader={checkHeader}
          setMultiRow={setMultiRow}
          multiRow={multiRow}
          windowSize={windowSize}
        />
      )}
      {view === BULK_DATA && (
        <ExcelUploader
          sampleFile={SampleFile}
          fileName="StockInSampleFile.xlsx"
          headers={headers}
          request="uploadStockTransactionData"
          transactionType="STOCK_IN"
        />
      )}
    </>
  );
};

export default PurchaseStock;
