import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";

export default function SimpleBackdrop({ onDrop, onDragOver, onDragLeave }) {
  const [open, setOpen] = React.useState(true);
  const loader = useSelector((state) => state.loading);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div onDrop={onDrop} onDragOver={onDragOver} onDragLeave={onDragLeave}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0,0,0,0.8)",
        }}
        open={open}
        onClick={handleClose}
      >
        <h1 style={{ fontSize: "32px", fontWeight: "bolder" }}>
          Please Drop your file here &nbsp;.&nbsp;.&nbsp;.
        </h1>
      </Backdrop>
    </div>
  );
}
