import React from "react";
import Button from "../../components/Button";

const Pagination = ({ getMembers, page, count, memberData }) => {
  return (
    <div className="flex flex-row gap-2 justify-between items-center">
      <div>
        Total data: <span>{count || 0}</span>
      </div>
      <div className="flex flex-row gap-2">
        <Button
          title=""
          display="inline-block"
          outline={true}
          attrs={{
            onClick: () => getMembers(page - 1),
            disabled: page === 0,
          }}
        >
          Prev
        </Button>
        {memberData.length > 0 && (
          <Button title="" display="inline-block" outline={true} disabled>
            {page + 1}
          </Button>
        )}

        <Button
          title=""
          display="inline-block"
          attrs={{
            onClick: () => getMembers(page + 1),
            disabled: memberData?.length < 5,
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
