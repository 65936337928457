import React, { useRef } from "react";
import { AppBackButton } from "../../components/AppComponents";
import { ApiGet, ApiPost } from "../../lib/AppHelper";
import { withRouter } from "react-router-dom";
import AdminMain from "../admin/AdminMain";
import SelectPicker from "rsuite/SelectPicker";
import { AppStore } from "../../lib/AppStore";
import { Input } from "rsuite";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const INITIAL_VALUE = {
  product_id: "",
  product_new_name: "",
  product_display_name: "",
  product_clasification: "",
  sub_product: [],
};
const CloneProducts = (props) => {
  const [data, setData] = React.useState([]);
  const [product, setProduct] = React.useState();
  const [error, setError] = React.useState();
  const [result, setResult] = React.useState({
    product_id: "",
    product_new_name: "",
    product_display_name: "",
    product_clasification: "",
    sub_product: [],
  });
  const MessageRef = React.createRef();
  const inputDisplayRef = useRef(null);
  const inputRef = useRef(null);
  const subInputDisplayRef = useRef([]);
  const subInputRef = useRef([]);
  const permissions = useSelector((state) => state?.user?.permissions);
  const searchProduct = (value) => {
    if (value.length > 2) {
      ApiGet(`search-products?search=${value}`).then((res) => {
        setData(res.data);
      });
    }
  };

  const handelSubmit = () => {
    let subProductValid = true;
    let productValid = true;
    setError();
    if (!result.product_new_name) {
      setError((prevError) => ({ ...prevError, product: true }));
      productValid = false;
    }
    if (result.sub_product.length > 0) {
      result.sub_product.map((item, index) => {
        if (!item.product_new_name) {
          setError((prevError) => ({
            ...prevError,
            [`subProduct${index}`]: true,
          }));
          subProductValid = false;
        }
      });
    }
    if (productValid && subProductValid) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      ApiPost("add-clone-product", result)
        .then((res) => {
          if (res.status == "success") {
            inputRef.current = null;
            inputDisplayRef.current = null;
            subInputRef.current = [];
            subInputDisplayRef.current = [];
            toast.success(res.message);
            setTimeout(() => {
              setProduct();
              setData([]);
            }, [1000]);
            AppStore.dispatch({ type: "LOADING", loading: false });
          } else {
            toast.error(res.message);
            AppStore.dispatch({ type: "LOADING", loading: false });
          }
        })
        .catch((err) => {
          toast.error(err);
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
    }
  };

  React.useEffect(() => {
    if (inputRef.current) inputRef.current.value = "";
    if (inputDisplayRef.current) inputDisplayRef.current.value = "";
    if (subInputRef?.current && subInputRef?.current?.length > 1) {
      subInputRef.current.map(
        (_, index) => (subInputRef.current[index].current.value = "")
      );
    }
    if (
      subInputDisplayRef?.current &&
      subInputDisplayRef?.current?.length > 1
    ) {
      subInputDisplayRef.current.map(
        (_, index) => (subInputDisplayRef.current[index].current.value = "")
      );
    }

    setError();
  }, [product]);

  return (
    <AdminMain MessageRef={MessageRef}>
      {permissions?.clone_product ? (
        <>
          <AppBackButton to="/" />
          <div
            onChange={(e) => {
              searchProduct(e.target.value);
            }}
          >
            <SelectPicker
              size="lg"
              placeholder="Search Product..."
              data={data}
              onClean={() => {
                setProduct();
                subInputRef.current = [];
              }}
              onSelect={(value, item) => {
                setProduct(item);
                setResult({
                  product_id: "",
                  product_new_name: "",
                  product_display_name: "",
                  product_clasification: "",
                  sub_product: [],
                });
                if (item.product_mappings.length > 0) {
                  const obj = {
                    sub_product_id: "",
                    product_new_name: "",
                    product_display_name: "",
                    product_clasification: "",
                  };
                  const subProducts = Array.from(
                    { length: item.product_mappings.length },
                    () => obj
                  );
                  setResult({
                    ...INITIAL_VALUE,
                    sub_product: subProducts,
                  });
                  if (subProducts.length > 0)
                    subInputRef.current =
                      subProducts &&
                      subProducts?.map(
                        (ref, index) =>
                          (subInputRef.current[index] = React.createRef())
                      );
                  subInputDisplayRef.current =
                    subProducts &&
                    subProducts?.map(
                      (ref, index) =>
                        (subInputDisplayRef.current[index] = React.createRef())
                    );
                } else {
                  subInputRef.current = [];
                  subInputDisplayRef.current = [];
                }
              }}
              style={{
                borderColor: "red",
                width: "100%",
                display: "block",
                marginBottom: 10,
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-x-auto">
                  {product ? (
                    <table className="min-w-full" id="table-to-xls">
                      <thead>
                        <table className="min-w-full border">
                          <tbody>
                            <tr>
                              <td>
                                <table className="min-w-full">
                                  <thead className="border-b">
                                    <tr
                                      className="bg-primary"
                                      style={{ color: "white" }}
                                    >
                                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                        Product Name
                                      </th>
                                      <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                        Classification
                                      </th>
                                      <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                        New Name
                                      </th>
                                      <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                        New Display Name
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="border-b">
                                      <td
                                        className="text-sm  font-bigger px-6 py-4 whitespace-nowrap"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {product?.product_name}
                                      </td>
                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {product?.product_clasification}
                                      </td>
                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        <Input
                                          placeholder="Product Name"
                                          defaultValue={
                                            inputRef.current &&
                                            inputRef.current.value
                                          }
                                          ref={inputRef}
                                          onChange={(e) => {
                                            const newObj = { ...result };
                                            newObj["product_id"] =
                                              product?.product_id;
                                            newObj["product_new_name"] = e;
                                            newObj["product_clasification"] =
                                              product?.product_clasification;
                                            setResult(newObj);
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: "#E23744",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {error?.product
                                            ? "Product Name is required"
                                            : ""}
                                        </span>
                                      </td>
                                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        <Input
                                          placeholder="Product Display Name"
                                          defaultValue={
                                            inputDisplayRef.current &&
                                            inputDisplayRef.current.value
                                          }
                                          ref={inputDisplayRef}
                                          onChange={(e) => {
                                            const newObj = { ...result };

                                            newObj["product_display_name"] = e;

                                            setResult(newObj);
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    {product &&
                                      product?.product_clasification ==
                                        "Combo" &&
                                      product?.product_mappings?.length > 1 &&
                                      product?.product_mappings?.map(
                                        (product_masters, index) => {
                                          return (
                                            <tr
                                              className="border-b"
                                              key={index}
                                            >
                                              <td
                                                className="text-sm  font-bigger px-6 py-4 whitespace-nowrap"
                                                style={{ fontWeight: "bold" }}
                                              >
                                                {
                                                  product_masters?.sub_product_name
                                                }
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                Individual
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                <Input
                                                  placeholder="Product Name"
                                                  defaultValue={
                                                    subInputRef.current &&
                                                    subInputRef.current[
                                                      index
                                                    ] &&
                                                    subInputRef.current[index]
                                                      .value
                                                  }
                                                  ref={
                                                    subInputRef.current &&
                                                    subInputRef.current[index]
                                                  }
                                                  onChange={(e) => {
                                                    const newObj = {
                                                      ...result,
                                                    };
                                                    newObj.sub_product[index] =
                                                      {
                                                        sub_product_id:
                                                          product_masters?.sub_product_id,
                                                        product_new_name: e,
                                                        product_clasification:
                                                          "Individual",
                                                      };

                                                    setResult(newObj);
                                                  }}
                                                />
                                                <span
                                                  style={{
                                                    color: "#E23744",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  {error?.[`subProduct${index}`]
                                                    ? "Product Name is required"
                                                    : ""}
                                                </span>
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                <Input
                                                  placeholder="Product Display Name"
                                                  defaultValue={
                                                    subInputDisplayRef.current &&
                                                    subInputDisplayRef.current[
                                                      index
                                                    ] &&
                                                    subInputDisplayRef.current[
                                                      index
                                                    ].value
                                                  }
                                                  ref={
                                                    subInputDisplayRef.current &&
                                                    subInputDisplayRef.current[
                                                      index
                                                    ]
                                                  }
                                                  onChange={(e) => {
                                                    const newObj = {
                                                      ...result,
                                                    };
                                                    newObj.sub_product[index] =
                                                      {
                                                        ...newObj.sub_product[
                                                          index
                                                        ],
                                                        product_display_name: e,
                                                      };

                                                    setResult(newObj);
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </thead>
                    </table>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
          {product && (
            <div className="float-right m-5">
              <button
                type="submit"
                className="bg-red text-white p-2 rounded-md"
                onClick={handelSubmit}
              >
                Submit
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="text-red-500">
          <h4>You do not have permission to Clone Product</h4>
        </div>
      )}
    </AdminMain>
  );
};

export default withRouter(CloneProducts);
