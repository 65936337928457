import React, { useEffect } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import InputSelect from "../../components/formikForm/InputSelect";
import { AppBackButton } from "../../components/AppComponents";
import InputGroup from "../../components/formikForm/InputGroup";
import InputLabel from "../../components/form/InputLabel";
import { addProductValidation } from "./addProductValidation";
import { initialValues } from "./initialValue";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import IndividualProduct from "./IndividualProduct";
import ComboProduct from "./ComboProduct";
import { ApiGet, ApiPost, setError, setMessage } from "../../lib/AppHelper";
import { withRouter } from "react-router-dom";
import AdminMain from "../admin/AdminMain";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import MultiSelector from "../../components/formikForm/MultiSelector";

const AddProducts = (props) => {
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [documentData, setDocumentData] = React.useState([]);
  const [partnerData, setPartnerData] = React.useState([]);
  const [specificationData, setspecificationData] = React.useState([]);

  const selectedProductLength = (values) => {
    let count = 0;
    for (let key in values.selectedProduct) {
      if (values.selectedProduct[key]?.id) {
        count++;
      }
    }
    return count;
  };

  const setSpecifcationKey = (values) => {
    for (let key in partnerData) {
      if (partnerData[key].value === values) {
        setspecificationData(partnerData[key]);
      }
    }
  };

  const onSubmit = (values) => {
    setLoading(true);
    // get key array of selected product
    const keyArray = Object.keys(values.selectedProduct);
    // get id value of selected product and push it to array
    const subProduct = keyArray.map((key) => {
      return values.selectedProduct[key].id;
    });

    // remove empty values from array
    const subProductId = subProduct.filter((item) => item !== "");

    if (step === 0) {
      if (
        values.productClasification === "Combo" &&
        subProductId.length === 0
      ) {
        setError(
          "Please select at least one product to create combo product",
          () => {},
          "top-center"
        );
        setLoading(false);
        return;
      }
      ApiPost("check-product-availability", {
        ...values,
        subProduct: subProductId.length > 0 ? subProductId : null,
      })
        .then((res) => {
          if (res.status === "success") {
            setStep(step + 1);
            setMessage(
              "Product not available now you create new product",
              () => {},
              "top-center"
            );
            setLoading(false);
          } else {
            setError(res?.message, () => {}, "top-center");
            setStep(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
    if (step === 1) {
      ApiPost("add-product", {
        ...values,
        subProduct: subProductId.length > 0 ? subProductId : null,
      })
        .then((res) => {
          if (res.status === "success") {
            setMessage("product created successfully", () => {}, "top-center");
            props.history.push("/");
          } else {
            setError(res?.message, () => {}, "top-center");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const MessageRef = React.createRef();
  const onChangeHandle = (e) => {
    setStep(0);
  };

  useEffect(() => {
    ApiGet("get-document").then((res) => {
      setDocumentData(res.data);
    });
    ApiGet("admin/partners").then((res) => {
      setPartnerData(res.data);
    });
  }, []);

  return (
    <AdminMain MessageRef={MessageRef}>
      <AppBackButton to="/" />
      <Formik
        initialValues={initialValues}
        validationSchema={addProductValidation}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ values, setFieldValue }) => (
          <Form onChange={onChangeHandle}>
            <div className="flex justify-between mt-3 items-center p-1 pr-2 pl-2">
              <p className="text-lg">Basic Information</p>
            </div>
            <hr className="mb-4" />
            <div className="grid lg:grid-cols-4 sm:grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <InputGroup
                  label="Product Name"
                  name="productName"
                  requiredMark="true"
                  onChange={(e) => {
                    setFieldValue("productName", e.target.value);
                  }}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="productName" />
                </div>
              </div>
              <div>
                <InputGroup
                  label="Product Display Name"
                  name="productDisplayName"
                  requiredMark="true"
                  onChange={(e) => {
                    setFieldValue("productDisplayName", e.target.value);
                  }}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="productDisplayName" />
                </div>
              </div>

              <div>
                <InputGroup
                  label="Product Tenure"
                  type="number"
                  name="tenure"
                  requiredMark="true"
                  placeholder="Enter number..."
                  value={values.tenure}
                  onChange={(e) => {
                    if (e.target.value == "00") {
                      e.target.value = 0;
                    }
                    if (
                      e.target.value === "" ||
                      (/^[0-9\b]+$/.test(e.target.value) &&
                        /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                    ) {
                      setFieldValue("tenure", parseInt(e.target.value));
                    }
                  }}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="tenure" />
                </div>
              </div>

              <div>
                <InputSelect
                  requiredMark="true"
                  label="Product Tenure Type"
                  name="productTenorType"
                  value={values.productTenorType}
                  onChange={(e) => {
                    setFieldValue("productTenorType", e.target.value);
                  }}
                  options={[
                    { value: "DAYS", label: "Days" },
                    { value: "MONTHS", label: "Months" },
                    { value: "YEARS", label: "Years" },
                  ]}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="productTenorType" />
                </div>
              </div>

              <div>
                <InputSelect
                  requiredMark="true"
                  label="Payment Mode"
                  value={values.paymentMode}
                  name="paymentMode"
                  onChange={(e) => {
                    setFieldValue("paymentMode", e.target.value);
                  }}
                  options={[
                    { value: "NA", label: "Unpaid" },
                    { value: "NF", label: "Paid" },
                  ]}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="paymentMode" />
                </div>
              </div>

              <div>
                <InputSelect
                  requiredMark="true"
                  label="Payment Partner"
                  name="paymentPartner"
                  value={values.paymentPartner}
                  onChange={(e) => {
                    setFieldValue("paymentPartner", e.target.value);
                  }}
                  options={[
                    { value: "DOC", label: "DOC" },
                    { value: "HOSPIDO", label: "HOSPIDO" },
                  ]}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="paymentPartner" />
                </div>
              </div>

              <div>
                <InputGroup
                  label="Base Price"
                  name="productBasePrice"
                  requiredMark="true"
                  placeholder="Enter price..."
                  type="number"
                  value={values.productBasePrice}
                  onChange={(e) => {
                    if (e.target.value == "00") {
                      e.target.value = 0;
                    }
                    if (
                      e.target.value === "" ||
                      (/^[0-9\b]+$/.test(e.target.value) &&
                        /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                    ) {
                      setFieldValue("productBasePrice", e.target.value);
                      setFieldValue("productMRP", e.target.value);
                    }
                    if (values.gst > 0 && e.target.value > 0) {
                      let productMRP =
                        parseInt((e.target.value * values.gst) / 100) +
                        parseInt(e.target.value);
                      setFieldValue("productMRP", productMRP);
                    }
                  }}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="productBasePrice" />
                </div>
              </div>

              <div>
                <InputGroup
                  requiredMark="true"
                  label="GST %"
                  name="gst"
                  type="number"
                  value={values.gst}
                  min="0"
                  onChange={(e) => {
                    if (e.target.value == "00") {
                      e.target.value = 0;
                    }
                    setFieldValue("gst", parseInt(e.target.value));
                    if (e.target.value > 0 && values.productBasePrice > 0) {
                      let productMRP =
                        parseInt(
                          (values.productBasePrice * e.target.value) / 100
                        ) + parseInt(values.productBasePrice);
                      setFieldValue("productMRP", productMRP);
                    } else {
                      setFieldValue("productMRP", values.productBasePrice);
                    }
                  }}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="gst" />
                </div>
              </div>

              <div>
                <InputGroup
                  label="Product MRP"
                  name="productMRP"
                  requiredMark="true"
                  placeholder=""
                  type="number"
                  readonly
                  value={values.productMRP}
                  onChange={(e) => {
                    // if (
                    //   e.target.value === "" ||
                    //   (/^[0-9\b]+$/.test(e.target.value) &&
                    //     /^(?:\d{0,5}|.*?\D.*)$/.test(e.target.value))
                    // ) {
                    //   setFieldValue("productMRP", e.target.value);
                    // }
                  }}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="productMRP" />
                </div>
              </div>

              <div>
                <InputSelect
                  requiredMark="true"
                  label="Sellable To Individual"
                  name="sellableToIndividual"
                  value={values.sellableToIndividual}
                  onChange={(e) => {
                    setFieldValue("sellableToIndividual", e.target.value);
                  }}
                  options={[
                    { value: "Y", label: "YES" },
                    { value: "N", label: "NO" },
                  ]}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="sellableToIndividual" />
                </div>
              </div>

              <div>
                <InputSelect
                  requiredMark="true"
                  label="Product Category"
                  name="productCategory"
                  value={values.productCategory}
                  onChange={(e) => {
                    setFieldValue("productCategory", e.target.value);
                  }}
                  options={[
                    { value: "MFI", label: "MFI" },
                    { value: "OTHERS", label: "OTHERS" },
                  ]}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="productCategory" />
                </div>
              </div>

              <div>
                <InputSelect
                  requiredMark="true"
                  label="Member Covered"
                  name="memberCoverd"
                  value={values.memberCoverd}
                  onChange={(e) => {
                    setFieldValue("memberCoverd", e.target.value);
                  }}
                  options={[
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                    { value: "6", label: "6" },
                  ]}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="memberCoverd" />
                </div>
              </div>

              <div>
                <InputSelect
                  requiredMark="true"
                  label="Partner"
                  name="partner"
                  value={values.partner}
                  onChange={(e) => {
                    setFieldValue("partner", e.target.value);
                    setSpecifcationKey(e.target.value);
                    if (!e.target.value) {
                      setFieldValue("specification", " ");
                    }
                    if (e.target.value == "DOC") {
                      setFieldValue("specification", "DOC");
                      setFieldValue("eligibleForClaim", "N");
                    }
                  }}
                  options={partnerData}
                />
                <div className="text-xs text-red-500">
                  <ErrorMessage name="partner" />
                </div>
              </div>

              {(values.partner === "AC" || values.partner === "HCH") &&
              values.partner ? (
                <div>
                  <InputGroup
                    label="Sum Assured Code"
                    name="sumAssuredCode"
                    requiredMark="true"
                    onChange={(e) => {
                      setFieldValue("sumAssuredCode", e.target.value);
                    }}
                  />
                  <div className="text-xs text-red-500">
                    <ErrorMessage name="sumAssuredCode" />
                  </div>
                </div>
              ) : null}

              {values.partner !== "LAC" && (
                <>
                  <div>
                    <InputGroup
                      label="Vendor PlanId"
                      name="vendorPlanId"
                      requiredMark="true"
                      onChange={(e) => {
                        setFieldValue("vendorPlanId", e.target.value);
                      }}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="vendorPlanId" />
                    </div>
                  </div>

                  <div>
                    <InputGroup
                      label="Vendor Plan Name"
                      name="vendorPlanName"
                      requiredMark="true"
                      onChange={(e) => {
                        setFieldValue("vendorPlanName", e.target.value);
                      }}
                    />
                    <div className="text-xs text-red-500">
                      <ErrorMessage name="vendorPlanName" />
                    </div>
                  </div>
                </>
              )}
              {values.partner !== "DOC" && values.partner ? (
                <div>
                  <InputSelect
                    requiredMark="true"
                    label="Eligible For Claim"
                    name="eligibleForClaim"
                    value={values.eligibleForClaim}
                    onChange={(e) => {
                      setFieldValue("eligibleForClaim", e.target.value);
                    }}
                    options={[
                      { value: "N", label: "No" },
                      { value: "Y", label: "Yes" },
                    ]}
                  />
                  <div className="text-xs text-red-500">
                    <ErrorMessage name="eligibleForClaim" />
                  </div>
                </div>
              ) : null}

              {values.eligibleForClaim === "Y" && (
                <div className="md:col-span-2">
                  <MultiSelector
                    requiredMark="true"
                    label="Claim Documents"
                    name="claimDoc"
                    onChange={(e) => {
                      setFieldValue("claimDoc", e);
                    }}
                    options={documentData}
                  />
                  <div className="text-xs text-red-500">
                    <ErrorMessage name="claimDoc" />
                  </div>
                </div>
              )}

              <div className="md:col-span-4">
                <div className="col-span-2 flex-shrink">
                  <InputLabel label="Product Description" requiredMark="true" />

                  <Field
                    as="textarea"
                    name="productDescription"
                    render={({ field, form: { isSubmitting } }) => (
                      <textarea
                        {...field}
                        // disabled={isSubmitting}
                        className="w-full mt-2 rounded-lg"
                        maxLength={256}
                      />
                    )}
                  />
                  <div className="text-xs text-red-500">
                    <ErrorMessage name="productDescription" />
                  </div>
                </div>

                <div className="col-span-2 flex-shrink">
                  <InputLabel label="Product Benefit" requiredMark="true" />
                  <Field
                    as="textarea"
                    name="productBenefitText"
                    render={({ field, form: { isSubmitting } }) => (
                      <textarea
                        {...field}
                        // disabled={isSubmitting}
                        className="w-full mt-2 rounded-lg"
                        maxLength={256}
                      />
                    )}
                  />
                  <div className="text-xs text-red-500">
                    <ErrorMessage name="productBenefitText" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex ">
              <ToggleButtonGroup
                name="productClasification"
                exclusive
                aria-label="text alignment"
                className="mt-4"
                color="error"
                value={values.productClasification}
                onChange={(event, newAlignment) => {
                  setFieldValue("productClasification", newAlignment);
                  setStep(0);
                }}
              >
                <ToggleButton
                  value="Individual"
                  disabled={values.productClasification === "Individual"}
                  aria-label="left aligned"
                >
                  Individual
                </ToggleButton>

                <ToggleButton
                  value="Combo"
                  aria-label="right aligned"
                  disabled={values.productClasification === "Combo"}
                >
                  Combo
                </ToggleButton>
              </ToggleButtonGroup>
              {values.productClasification === "Combo" ? (
                <div className="flex ml-3">
                  <ShoppingCartIcon className="h-8 mt-6 text-red" />
                  <span className="relative top-4 right-1 text-white bg-red-500 h-6 w-6 text-center rounded-xl">
                    {selectedProductLength(values)}
                  </span>
                </div>
              ) : null}
            </div>
            {values.productClasification === "Individual" ? (
              <IndividualProduct
                values={values}
                setFieldValue={setFieldValue}
                specificationData={specificationData}
              />
            ) : (
              <ComboProduct values={values} setFieldValue={setFieldValue} />
            )}

            <div className="float-right m-5">
              <button
                // disabled={loading ? "disabled" : ""}
                type="submit"
                className={
                  loading
                    ? "inline-flex items-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-red-500 rounded-md shadow cursor-not-allowed hover:bg-red-400"
                    : "bg-red text-white p-2 rounded-md"
                }
              >
                {loading ? (
                  <>
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    loading...
                  </>
                ) : step === 0 ? (
                  "Check Availability"
                ) : (
                  "Create Product"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </AdminMain>
  );
};

export default withRouter(AddProducts);
